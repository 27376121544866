export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BigInt: { input: any; output: any; }
  DateTime: { input: any; output: any; }
};

export type Activity = {
  __typename?: 'Activity';
  _count?: Maybe<ActivityCount>;
  client: Client;
  clientId: Scalars['String']['output'];
  comments: Array<ActivityComment>;
  content: Scalars['String']['output'];
  coverage: Array<CoveragePiece>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  docsId?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  files: Array<FileMetadata>;
  id: Scalars['String']['output'];
  index: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  priority: ActivityPriority;
  programPlannerItem?: Maybe<ProgramPlannerActivity>;
  status: ActivityStatus;
  users: Array<User>;
};


export type ActivityCommentsArgs = {
  cursor?: InputMaybe<ActivityCommentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityCommentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActivityCommentOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ActivityCommentWhereInput>;
};


export type ActivityCoverageArgs = {
  cursor?: InputMaybe<CoveragePieceWhereUniqueInput>;
  distinct?: InputMaybe<Array<CoveragePieceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CoveragePieceOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CoveragePieceWhereInput>;
};


export type ActivityFilesArgs = {
  cursor?: InputMaybe<FileMetadataWhereUniqueInput>;
  distinct?: InputMaybe<Array<FileMetadataScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FileMetadataOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FileMetadataWhereInput>;
};


export type ActivityUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};

export type ActivityComment = {
  __typename?: 'ActivityComment';
  _count?: Maybe<ActivityCommentCount>;
  activity: Activity;
  activityId: Scalars['String']['output'];
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  referencedUsers: Array<User>;
  user: User;
  userId: Scalars['String']['output'];
};


export type ActivityCommentReferencedUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};

export type ActivityCommentCount = {
  __typename?: 'ActivityCommentCount';
  referencedUsers: Scalars['Int']['output'];
};


export type ActivityCommentCountReferencedUsersArgs = {
  where?: InputMaybe<UserWhereInput>;
};

export type ActivityCommentCreateManyActivityInput = {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type ActivityCommentCreateManyActivityInputEnvelope = {
  data: Array<ActivityCommentCreateManyActivityInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ActivityCommentCreateManyUserInput = {
  activityId: Scalars['String']['input'];
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type ActivityCommentCreateManyUserInputEnvelope = {
  data: Array<ActivityCommentCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ActivityCommentCreateNestedManyWithoutActivityInput = {
  connect?: InputMaybe<Array<ActivityCommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCommentCreateOrConnectWithoutActivityInput>>;
  create?: InputMaybe<Array<ActivityCommentCreateWithoutActivityInput>>;
  createMany?: InputMaybe<ActivityCommentCreateManyActivityInputEnvelope>;
};

export type ActivityCommentCreateNestedManyWithoutReferencedUsersInput = {
  connect?: InputMaybe<Array<ActivityCommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCommentCreateOrConnectWithoutReferencedUsersInput>>;
  create?: InputMaybe<Array<ActivityCommentCreateWithoutReferencedUsersInput>>;
};

export type ActivityCommentCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<ActivityCommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCommentCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<ActivityCommentCreateWithoutUserInput>>;
  createMany?: InputMaybe<ActivityCommentCreateManyUserInputEnvelope>;
};

export type ActivityCommentCreateOrConnectWithoutActivityInput = {
  create: ActivityCommentCreateWithoutActivityInput;
  where: ActivityCommentWhereUniqueInput;
};

export type ActivityCommentCreateOrConnectWithoutReferencedUsersInput = {
  create: ActivityCommentCreateWithoutReferencedUsersInput;
  where: ActivityCommentWhereUniqueInput;
};

export type ActivityCommentCreateOrConnectWithoutUserInput = {
  create: ActivityCommentCreateWithoutUserInput;
  where: ActivityCommentWhereUniqueInput;
};

export type ActivityCommentCreateWithoutActivityInput = {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  referencedUsers?: InputMaybe<UserCreateNestedManyWithoutReferencedInActivityCommentsInput>;
  user: UserCreateNestedOneWithoutActivityCommentsInput;
};

export type ActivityCommentCreateWithoutReferencedUsersInput = {
  activity: ActivityCreateNestedOneWithoutCommentsInput;
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  user: UserCreateNestedOneWithoutActivityCommentsInput;
};

export type ActivityCommentCreateWithoutUserInput = {
  activity: ActivityCreateNestedOneWithoutCommentsInput;
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  referencedUsers?: InputMaybe<UserCreateNestedManyWithoutReferencedInActivityCommentsInput>;
};

export type ActivityCommentListRelationFilter = {
  every?: InputMaybe<ActivityCommentWhereInput>;
  none?: InputMaybe<ActivityCommentWhereInput>;
  some?: InputMaybe<ActivityCommentWhereInput>;
};

export type ActivityCommentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum ActivityCommentOrderByRelevanceFieldEnum {
  ActivityId = 'activityId',
  Content = 'content',
  Id = 'id',
  UserId = 'userId'
}

export type ActivityCommentOrderByRelevanceInput = {
  fields: Array<ActivityCommentOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type ActivityCommentOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<ActivityCommentOrderByRelevanceInput>;
  activity?: InputMaybe<ActivityOrderByWithRelationAndSearchRelevanceInput>;
  activityId?: InputMaybe<SortOrder>;
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  referencedUsers?: InputMaybe<UserOrderByRelationAggregateInput>;
  user?: InputMaybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum ActivityCommentScalarFieldEnum {
  ActivityId = 'activityId',
  Content = 'content',
  CreatedAt = 'createdAt',
  Id = 'id',
  UserId = 'userId'
}

export type ActivityCommentScalarWhereInput = {
  AND?: InputMaybe<Array<ActivityCommentScalarWhereInput>>;
  NOT?: InputMaybe<Array<ActivityCommentScalarWhereInput>>;
  OR?: InputMaybe<Array<ActivityCommentScalarWhereInput>>;
  activityId?: InputMaybe<StringFilter>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type ActivityCommentUpdateManyMutationInput = {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ActivityCommentUpdateManyWithWhereWithoutActivityInput = {
  data: ActivityCommentUpdateManyMutationInput;
  where: ActivityCommentScalarWhereInput;
};

export type ActivityCommentUpdateManyWithWhereWithoutReferencedUsersInput = {
  data: ActivityCommentUpdateManyMutationInput;
  where: ActivityCommentScalarWhereInput;
};

export type ActivityCommentUpdateManyWithWhereWithoutUserInput = {
  data: ActivityCommentUpdateManyMutationInput;
  where: ActivityCommentScalarWhereInput;
};

export type ActivityCommentUpdateManyWithoutActivityNestedInput = {
  connect?: InputMaybe<Array<ActivityCommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCommentCreateOrConnectWithoutActivityInput>>;
  create?: InputMaybe<Array<ActivityCommentCreateWithoutActivityInput>>;
  createMany?: InputMaybe<ActivityCommentCreateManyActivityInputEnvelope>;
  delete?: InputMaybe<Array<ActivityCommentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ActivityCommentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ActivityCommentWhereUniqueInput>>;
  set?: InputMaybe<Array<ActivityCommentWhereUniqueInput>>;
  update?: InputMaybe<Array<ActivityCommentUpdateWithWhereUniqueWithoutActivityInput>>;
  updateMany?: InputMaybe<Array<ActivityCommentUpdateManyWithWhereWithoutActivityInput>>;
  upsert?: InputMaybe<Array<ActivityCommentUpsertWithWhereUniqueWithoutActivityInput>>;
};

export type ActivityCommentUpdateManyWithoutReferencedUsersNestedInput = {
  connect?: InputMaybe<Array<ActivityCommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCommentCreateOrConnectWithoutReferencedUsersInput>>;
  create?: InputMaybe<Array<ActivityCommentCreateWithoutReferencedUsersInput>>;
  delete?: InputMaybe<Array<ActivityCommentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ActivityCommentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ActivityCommentWhereUniqueInput>>;
  set?: InputMaybe<Array<ActivityCommentWhereUniqueInput>>;
  update?: InputMaybe<Array<ActivityCommentUpdateWithWhereUniqueWithoutReferencedUsersInput>>;
  updateMany?: InputMaybe<Array<ActivityCommentUpdateManyWithWhereWithoutReferencedUsersInput>>;
  upsert?: InputMaybe<Array<ActivityCommentUpsertWithWhereUniqueWithoutReferencedUsersInput>>;
};

export type ActivityCommentUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<ActivityCommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCommentCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<ActivityCommentCreateWithoutUserInput>>;
  createMany?: InputMaybe<ActivityCommentCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<ActivityCommentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ActivityCommentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ActivityCommentWhereUniqueInput>>;
  set?: InputMaybe<Array<ActivityCommentWhereUniqueInput>>;
  update?: InputMaybe<Array<ActivityCommentUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<ActivityCommentUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<ActivityCommentUpsertWithWhereUniqueWithoutUserInput>>;
};

export type ActivityCommentUpdateWithWhereUniqueWithoutActivityInput = {
  data: ActivityCommentUpdateWithoutActivityInput;
  where: ActivityCommentWhereUniqueInput;
};

export type ActivityCommentUpdateWithWhereUniqueWithoutReferencedUsersInput = {
  data: ActivityCommentUpdateWithoutReferencedUsersInput;
  where: ActivityCommentWhereUniqueInput;
};

export type ActivityCommentUpdateWithWhereUniqueWithoutUserInput = {
  data: ActivityCommentUpdateWithoutUserInput;
  where: ActivityCommentWhereUniqueInput;
};

export type ActivityCommentUpdateWithoutActivityInput = {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  referencedUsers?: InputMaybe<UserUpdateManyWithoutReferencedInActivityCommentsNestedInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutActivityCommentsNestedInput>;
};

export type ActivityCommentUpdateWithoutReferencedUsersInput = {
  activity?: InputMaybe<ActivityUpdateOneRequiredWithoutCommentsNestedInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutActivityCommentsNestedInput>;
};

export type ActivityCommentUpdateWithoutUserInput = {
  activity?: InputMaybe<ActivityUpdateOneRequiredWithoutCommentsNestedInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  referencedUsers?: InputMaybe<UserUpdateManyWithoutReferencedInActivityCommentsNestedInput>;
};

export type ActivityCommentUpsertWithWhereUniqueWithoutActivityInput = {
  create: ActivityCommentCreateWithoutActivityInput;
  update: ActivityCommentUpdateWithoutActivityInput;
  where: ActivityCommentWhereUniqueInput;
};

export type ActivityCommentUpsertWithWhereUniqueWithoutReferencedUsersInput = {
  create: ActivityCommentCreateWithoutReferencedUsersInput;
  update: ActivityCommentUpdateWithoutReferencedUsersInput;
  where: ActivityCommentWhereUniqueInput;
};

export type ActivityCommentUpsertWithWhereUniqueWithoutUserInput = {
  create: ActivityCommentCreateWithoutUserInput;
  update: ActivityCommentUpdateWithoutUserInput;
  where: ActivityCommentWhereUniqueInput;
};

export type ActivityCommentWhereInput = {
  AND?: InputMaybe<Array<ActivityCommentWhereInput>>;
  NOT?: InputMaybe<Array<ActivityCommentWhereInput>>;
  OR?: InputMaybe<Array<ActivityCommentWhereInput>>;
  activity?: InputMaybe<ActivityRelationFilter>;
  activityId?: InputMaybe<StringFilter>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  referencedUsers?: InputMaybe<UserListRelationFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type ActivityCommentWhereUniqueInput = {
  AND?: InputMaybe<Array<ActivityCommentWhereInput>>;
  NOT?: InputMaybe<Array<ActivityCommentWhereInput>>;
  OR?: InputMaybe<Array<ActivityCommentWhereInput>>;
  activity?: InputMaybe<ActivityRelationFilter>;
  activityId?: InputMaybe<StringFilter>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  referencedUsers?: InputMaybe<UserListRelationFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type ActivityCount = {
  __typename?: 'ActivityCount';
  comments: Scalars['Int']['output'];
  coverage: Scalars['Int']['output'];
  files: Scalars['Int']['output'];
  users: Scalars['Int']['output'];
};


export type ActivityCountCommentsArgs = {
  where?: InputMaybe<ActivityCommentWhereInput>;
};


export type ActivityCountCoverageArgs = {
  where?: InputMaybe<CoveragePieceWhereInput>;
};


export type ActivityCountFilesArgs = {
  where?: InputMaybe<FileMetadataWhereInput>;
};


export type ActivityCountUsersArgs = {
  where?: InputMaybe<UserWhereInput>;
};

export type ActivityCreateManyClientInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  docsId?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  index?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  priority?: InputMaybe<ActivityPriority>;
  status?: InputMaybe<ActivityStatus>;
};

export type ActivityCreateManyClientInputEnvelope = {
  data: Array<ActivityCreateManyClientInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ActivityCreateNestedManyWithoutClientInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutClientInput>>;
  createMany?: InputMaybe<ActivityCreateManyClientInputEnvelope>;
};

export type ActivityCreateNestedManyWithoutCoverageInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutCoverageInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutCoverageInput>>;
};

export type ActivityCreateNestedManyWithoutUsersInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutUsersInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutUsersInput>>;
};

export type ActivityCreateNestedOneWithoutCommentsInput = {
  connect?: InputMaybe<ActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ActivityCreateOrConnectWithoutCommentsInput>;
  create?: InputMaybe<ActivityCreateWithoutCommentsInput>;
};

export type ActivityCreateNestedOneWithoutFilesInput = {
  connect?: InputMaybe<ActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ActivityCreateOrConnectWithoutFilesInput>;
  create?: InputMaybe<ActivityCreateWithoutFilesInput>;
};

export type ActivityCreateNestedOneWithoutProgramPlannerItemInput = {
  connect?: InputMaybe<ActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ActivityCreateOrConnectWithoutProgramPlannerItemInput>;
  create?: InputMaybe<ActivityCreateWithoutProgramPlannerItemInput>;
};

export type ActivityCreateOrConnectWithoutClientInput = {
  create: ActivityCreateWithoutClientInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityCreateOrConnectWithoutCommentsInput = {
  create: ActivityCreateWithoutCommentsInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityCreateOrConnectWithoutCoverageInput = {
  create: ActivityCreateWithoutCoverageInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityCreateOrConnectWithoutFilesInput = {
  create: ActivityCreateWithoutFilesInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityCreateOrConnectWithoutProgramPlannerItemInput = {
  create: ActivityCreateWithoutProgramPlannerItemInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityCreateOrConnectWithoutUsersInput = {
  create: ActivityCreateWithoutUsersInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityCreateWithoutClientInput = {
  comments?: InputMaybe<ActivityCommentCreateNestedManyWithoutActivityInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  coverage?: InputMaybe<CoveragePieceCreateNestedManyWithoutActivitiesInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  docsId?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  files?: InputMaybe<FileMetadataCreateNestedManyWithoutActivityInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  index?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  priority?: InputMaybe<ActivityPriority>;
  programPlannerItem?: InputMaybe<ProgramPlannerActivityCreateNestedOneWithoutActivityInput>;
  status?: InputMaybe<ActivityStatus>;
  users?: InputMaybe<UserCreateNestedManyWithoutActivitiesInput>;
};

export type ActivityCreateWithoutCommentsInput = {
  client: ClientCreateNestedOneWithoutActivitiesInput;
  content?: InputMaybe<Scalars['String']['input']>;
  coverage?: InputMaybe<CoveragePieceCreateNestedManyWithoutActivitiesInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  docsId?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  files?: InputMaybe<FileMetadataCreateNestedManyWithoutActivityInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  index?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  priority?: InputMaybe<ActivityPriority>;
  programPlannerItem?: InputMaybe<ProgramPlannerActivityCreateNestedOneWithoutActivityInput>;
  status?: InputMaybe<ActivityStatus>;
  users?: InputMaybe<UserCreateNestedManyWithoutActivitiesInput>;
};

export type ActivityCreateWithoutCoverageInput = {
  client: ClientCreateNestedOneWithoutActivitiesInput;
  comments?: InputMaybe<ActivityCommentCreateNestedManyWithoutActivityInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  docsId?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  files?: InputMaybe<FileMetadataCreateNestedManyWithoutActivityInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  index?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  priority?: InputMaybe<ActivityPriority>;
  programPlannerItem?: InputMaybe<ProgramPlannerActivityCreateNestedOneWithoutActivityInput>;
  status?: InputMaybe<ActivityStatus>;
  users?: InputMaybe<UserCreateNestedManyWithoutActivitiesInput>;
};

export type ActivityCreateWithoutFilesInput = {
  client: ClientCreateNestedOneWithoutActivitiesInput;
  comments?: InputMaybe<ActivityCommentCreateNestedManyWithoutActivityInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  coverage?: InputMaybe<CoveragePieceCreateNestedManyWithoutActivitiesInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  docsId?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  index?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  priority?: InputMaybe<ActivityPriority>;
  programPlannerItem?: InputMaybe<ProgramPlannerActivityCreateNestedOneWithoutActivityInput>;
  status?: InputMaybe<ActivityStatus>;
  users?: InputMaybe<UserCreateNestedManyWithoutActivitiesInput>;
};

export type ActivityCreateWithoutProgramPlannerItemInput = {
  client: ClientCreateNestedOneWithoutActivitiesInput;
  comments?: InputMaybe<ActivityCommentCreateNestedManyWithoutActivityInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  coverage?: InputMaybe<CoveragePieceCreateNestedManyWithoutActivitiesInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  docsId?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  files?: InputMaybe<FileMetadataCreateNestedManyWithoutActivityInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  index?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  priority?: InputMaybe<ActivityPriority>;
  status?: InputMaybe<ActivityStatus>;
  users?: InputMaybe<UserCreateNestedManyWithoutActivitiesInput>;
};

export type ActivityCreateWithoutUsersInput = {
  client: ClientCreateNestedOneWithoutActivitiesInput;
  comments?: InputMaybe<ActivityCommentCreateNestedManyWithoutActivityInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  coverage?: InputMaybe<CoveragePieceCreateNestedManyWithoutActivitiesInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  docsId?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  files?: InputMaybe<FileMetadataCreateNestedManyWithoutActivityInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  index?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  priority?: InputMaybe<ActivityPriority>;
  programPlannerItem?: InputMaybe<ProgramPlannerActivityCreateNestedOneWithoutActivityInput>;
  status?: InputMaybe<ActivityStatus>;
};

export type ActivityIndexInput = {
  id: Scalars['String']['input'];
  index: Scalars['Int']['input'];
};

export type ActivityListRelationFilter = {
  every?: InputMaybe<ActivityWhereInput>;
  none?: InputMaybe<ActivityWhereInput>;
  some?: InputMaybe<ActivityWhereInput>;
};

export type ActivityNullableRelationFilter = {
  is?: InputMaybe<ActivityWhereInput>;
  isNot?: InputMaybe<ActivityWhereInput>;
};

export type ActivityOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum ActivityOrderByRelevanceFieldEnum {
  ClientId = 'clientId',
  Content = 'content',
  DocsId = 'docsId',
  Id = 'id',
  Name = 'name'
}

export type ActivityOrderByRelevanceInput = {
  fields: Array<ActivityOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type ActivityOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<ActivityOrderByRelevanceInput>;
  client?: InputMaybe<ClientOrderByWithRelationAndSearchRelevanceInput>;
  clientId?: InputMaybe<SortOrder>;
  comments?: InputMaybe<ActivityCommentOrderByRelationAggregateInput>;
  content?: InputMaybe<SortOrder>;
  coverage?: InputMaybe<CoveragePieceOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrderInput>;
  docsId?: InputMaybe<SortOrderInput>;
  dueDate?: InputMaybe<SortOrderInput>;
  files?: InputMaybe<FileMetadataOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  index?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  priority?: InputMaybe<SortOrder>;
  programPlannerItem?: InputMaybe<ProgramPlannerActivityOrderByWithRelationAndSearchRelevanceInput>;
  status?: InputMaybe<SortOrder>;
  users?: InputMaybe<UserOrderByRelationAggregateInput>;
};

export enum ActivityPriority {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM'
}

export type ActivityRelationFilter = {
  is?: InputMaybe<ActivityWhereInput>;
  isNot?: InputMaybe<ActivityWhereInput>;
};

export enum ActivityScalarFieldEnum {
  ClientId = 'clientId',
  Content = 'content',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  DocsId = 'docsId',
  DueDate = 'dueDate',
  Id = 'id',
  Index = 'index',
  Name = 'name',
  Priority = 'priority',
  Status = 'status'
}

export type ActivityScalarWhereInput = {
  AND?: InputMaybe<Array<ActivityScalarWhereInput>>;
  NOT?: InputMaybe<Array<ActivityScalarWhereInput>>;
  OR?: InputMaybe<Array<ActivityScalarWhereInput>>;
  clientId?: InputMaybe<StringFilter>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  docsId?: InputMaybe<StringNullableFilter>;
  dueDate?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  index?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  priority?: InputMaybe<EnumActivityPriorityFilter>;
  status?: InputMaybe<EnumActivityStatusFilter>;
};

export enum ActivityStatus {
  ClientApproved = 'CLIENT_APPROVED',
  ClientReviewing = 'CLIENT_REVIEWING',
  Closed = 'CLOSED',
  Complete = 'COMPLETE',
  Declined = 'DECLINED',
  Editing = 'EDITING',
  Interview = 'INTERVIEW',
  Missed = 'MISSED',
  NeedsChanges = 'NEEDS_CHANGES',
  NotStarted = 'NOT_STARTED',
  OnHold = 'ON_HOLD',
  PendingApproval = 'PENDING_APPROVAL',
  PendingCoverage = 'PENDING_COVERAGE',
  Pitched = 'PITCHED',
  Pitching = 'PITCHING',
  PotentialLead = 'POTENTIAL_LEAD',
  WaitingForInfo = 'WAITING_FOR_INFO'
}

export type ActivityUpdateInput = {
  client?: InputMaybe<ClientUpdateOneRequiredWithoutActivitiesNestedInput>;
  comments?: InputMaybe<ActivityCommentUpdateManyWithoutActivityNestedInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  coverage?: InputMaybe<CoveragePieceUpdateManyWithoutActivitiesNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  docsId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  dueDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  files?: InputMaybe<FileMetadataUpdateManyWithoutActivityNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  index?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  priority?: InputMaybe<EnumActivityPriorityFieldUpdateOperationsInput>;
  programPlannerItem?: InputMaybe<ProgramPlannerActivityUpdateOneWithoutActivityNestedInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutActivitiesNestedInput>;
};

export type ActivityUpdateManyMutationInput = {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  docsId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  dueDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  index?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  priority?: InputMaybe<EnumActivityPriorityFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
};

export type ActivityUpdateManyWithWhereWithoutClientInput = {
  data: ActivityUpdateManyMutationInput;
  where: ActivityScalarWhereInput;
};

export type ActivityUpdateManyWithWhereWithoutCoverageInput = {
  data: ActivityUpdateManyMutationInput;
  where: ActivityScalarWhereInput;
};

export type ActivityUpdateManyWithWhereWithoutUsersInput = {
  data: ActivityUpdateManyMutationInput;
  where: ActivityScalarWhereInput;
};

export type ActivityUpdateManyWithoutClientNestedInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutClientInput>>;
  createMany?: InputMaybe<ActivityCreateManyClientInputEnvelope>;
  delete?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<ActivityUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: InputMaybe<Array<ActivityUpdateManyWithWhereWithoutClientInput>>;
  upsert?: InputMaybe<Array<ActivityUpsertWithWhereUniqueWithoutClientInput>>;
};

export type ActivityUpdateManyWithoutCoverageNestedInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutCoverageInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutCoverageInput>>;
  delete?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<ActivityUpdateWithWhereUniqueWithoutCoverageInput>>;
  updateMany?: InputMaybe<Array<ActivityUpdateManyWithWhereWithoutCoverageInput>>;
  upsert?: InputMaybe<Array<ActivityUpsertWithWhereUniqueWithoutCoverageInput>>;
};

export type ActivityUpdateManyWithoutUsersNestedInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutUsersInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutUsersInput>>;
  delete?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<ActivityUpdateWithWhereUniqueWithoutUsersInput>>;
  updateMany?: InputMaybe<Array<ActivityUpdateManyWithWhereWithoutUsersInput>>;
  upsert?: InputMaybe<Array<ActivityUpsertWithWhereUniqueWithoutUsersInput>>;
};

export type ActivityUpdateOneRequiredWithoutCommentsNestedInput = {
  connect?: InputMaybe<ActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ActivityCreateOrConnectWithoutCommentsInput>;
  create?: InputMaybe<ActivityCreateWithoutCommentsInput>;
  update?: InputMaybe<ActivityUpdateToOneWithWhereWithoutCommentsInput>;
  upsert?: InputMaybe<ActivityUpsertWithoutCommentsInput>;
};

export type ActivityUpdateOneRequiredWithoutProgramPlannerItemNestedInput = {
  connect?: InputMaybe<ActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ActivityCreateOrConnectWithoutProgramPlannerItemInput>;
  create?: InputMaybe<ActivityCreateWithoutProgramPlannerItemInput>;
  update?: InputMaybe<ActivityUpdateToOneWithWhereWithoutProgramPlannerItemInput>;
  upsert?: InputMaybe<ActivityUpsertWithoutProgramPlannerItemInput>;
};

export type ActivityUpdateOneWithoutFilesNestedInput = {
  connect?: InputMaybe<ActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ActivityCreateOrConnectWithoutFilesInput>;
  create?: InputMaybe<ActivityCreateWithoutFilesInput>;
  delete?: InputMaybe<ActivityWhereInput>;
  disconnect?: InputMaybe<ActivityWhereInput>;
  update?: InputMaybe<ActivityUpdateToOneWithWhereWithoutFilesInput>;
  upsert?: InputMaybe<ActivityUpsertWithoutFilesInput>;
};

export type ActivityUpdateToOneWithWhereWithoutCommentsInput = {
  data: ActivityUpdateWithoutCommentsInput;
  where?: InputMaybe<ActivityWhereInput>;
};

export type ActivityUpdateToOneWithWhereWithoutFilesInput = {
  data: ActivityUpdateWithoutFilesInput;
  where?: InputMaybe<ActivityWhereInput>;
};

export type ActivityUpdateToOneWithWhereWithoutProgramPlannerItemInput = {
  data: ActivityUpdateWithoutProgramPlannerItemInput;
  where?: InputMaybe<ActivityWhereInput>;
};

export type ActivityUpdateWithWhereUniqueWithoutClientInput = {
  data: ActivityUpdateWithoutClientInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpdateWithWhereUniqueWithoutCoverageInput = {
  data: ActivityUpdateWithoutCoverageInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpdateWithWhereUniqueWithoutUsersInput = {
  data: ActivityUpdateWithoutUsersInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpdateWithoutClientInput = {
  comments?: InputMaybe<ActivityCommentUpdateManyWithoutActivityNestedInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  coverage?: InputMaybe<CoveragePieceUpdateManyWithoutActivitiesNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  docsId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  dueDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  files?: InputMaybe<FileMetadataUpdateManyWithoutActivityNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  index?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  priority?: InputMaybe<EnumActivityPriorityFieldUpdateOperationsInput>;
  programPlannerItem?: InputMaybe<ProgramPlannerActivityUpdateOneWithoutActivityNestedInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutActivitiesNestedInput>;
};

export type ActivityUpdateWithoutCommentsInput = {
  client?: InputMaybe<ClientUpdateOneRequiredWithoutActivitiesNestedInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  coverage?: InputMaybe<CoveragePieceUpdateManyWithoutActivitiesNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  docsId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  dueDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  files?: InputMaybe<FileMetadataUpdateManyWithoutActivityNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  index?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  priority?: InputMaybe<EnumActivityPriorityFieldUpdateOperationsInput>;
  programPlannerItem?: InputMaybe<ProgramPlannerActivityUpdateOneWithoutActivityNestedInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutActivitiesNestedInput>;
};

export type ActivityUpdateWithoutCoverageInput = {
  client?: InputMaybe<ClientUpdateOneRequiredWithoutActivitiesNestedInput>;
  comments?: InputMaybe<ActivityCommentUpdateManyWithoutActivityNestedInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  docsId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  dueDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  files?: InputMaybe<FileMetadataUpdateManyWithoutActivityNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  index?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  priority?: InputMaybe<EnumActivityPriorityFieldUpdateOperationsInput>;
  programPlannerItem?: InputMaybe<ProgramPlannerActivityUpdateOneWithoutActivityNestedInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutActivitiesNestedInput>;
};

export type ActivityUpdateWithoutFilesInput = {
  client?: InputMaybe<ClientUpdateOneRequiredWithoutActivitiesNestedInput>;
  comments?: InputMaybe<ActivityCommentUpdateManyWithoutActivityNestedInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  coverage?: InputMaybe<CoveragePieceUpdateManyWithoutActivitiesNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  docsId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  dueDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  index?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  priority?: InputMaybe<EnumActivityPriorityFieldUpdateOperationsInput>;
  programPlannerItem?: InputMaybe<ProgramPlannerActivityUpdateOneWithoutActivityNestedInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutActivitiesNestedInput>;
};

export type ActivityUpdateWithoutProgramPlannerItemInput = {
  client?: InputMaybe<ClientUpdateOneRequiredWithoutActivitiesNestedInput>;
  comments?: InputMaybe<ActivityCommentUpdateManyWithoutActivityNestedInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  coverage?: InputMaybe<CoveragePieceUpdateManyWithoutActivitiesNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  docsId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  dueDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  files?: InputMaybe<FileMetadataUpdateManyWithoutActivityNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  index?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  priority?: InputMaybe<EnumActivityPriorityFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutActivitiesNestedInput>;
};

export type ActivityUpdateWithoutUsersInput = {
  client?: InputMaybe<ClientUpdateOneRequiredWithoutActivitiesNestedInput>;
  comments?: InputMaybe<ActivityCommentUpdateManyWithoutActivityNestedInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  coverage?: InputMaybe<CoveragePieceUpdateManyWithoutActivitiesNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  docsId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  dueDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  files?: InputMaybe<FileMetadataUpdateManyWithoutActivityNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  index?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  priority?: InputMaybe<EnumActivityPriorityFieldUpdateOperationsInput>;
  programPlannerItem?: InputMaybe<ProgramPlannerActivityUpdateOneWithoutActivityNestedInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
};

export type ActivityUpsertWithWhereUniqueWithoutClientInput = {
  create: ActivityCreateWithoutClientInput;
  update: ActivityUpdateWithoutClientInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpsertWithWhereUniqueWithoutCoverageInput = {
  create: ActivityCreateWithoutCoverageInput;
  update: ActivityUpdateWithoutCoverageInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpsertWithWhereUniqueWithoutUsersInput = {
  create: ActivityCreateWithoutUsersInput;
  update: ActivityUpdateWithoutUsersInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpsertWithoutCommentsInput = {
  create: ActivityCreateWithoutCommentsInput;
  update: ActivityUpdateWithoutCommentsInput;
  where?: InputMaybe<ActivityWhereInput>;
};

export type ActivityUpsertWithoutFilesInput = {
  create: ActivityCreateWithoutFilesInput;
  update: ActivityUpdateWithoutFilesInput;
  where?: InputMaybe<ActivityWhereInput>;
};

export type ActivityUpsertWithoutProgramPlannerItemInput = {
  create: ActivityCreateWithoutProgramPlannerItemInput;
  update: ActivityUpdateWithoutProgramPlannerItemInput;
  where?: InputMaybe<ActivityWhereInput>;
};

export type ActivityWhere = {
  id: Scalars['String']['input'];
};

export type ActivityWhereInput = {
  AND?: InputMaybe<Array<ActivityWhereInput>>;
  NOT?: InputMaybe<Array<ActivityWhereInput>>;
  OR?: InputMaybe<Array<ActivityWhereInput>>;
  client?: InputMaybe<ClientRelationFilter>;
  clientId?: InputMaybe<StringFilter>;
  comments?: InputMaybe<ActivityCommentListRelationFilter>;
  content?: InputMaybe<StringFilter>;
  coverage?: InputMaybe<CoveragePieceListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  docsId?: InputMaybe<StringNullableFilter>;
  dueDate?: InputMaybe<DateTimeNullableFilter>;
  files?: InputMaybe<FileMetadataListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  index?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  priority?: InputMaybe<EnumActivityPriorityFilter>;
  programPlannerItem?: InputMaybe<ProgramPlannerActivityNullableRelationFilter>;
  status?: InputMaybe<EnumActivityStatusFilter>;
  users?: InputMaybe<UserListRelationFilter>;
};

export type ActivityWhereUniqueInput = {
  AND?: InputMaybe<Array<ActivityWhereInput>>;
  NOT?: InputMaybe<Array<ActivityWhereInput>>;
  OR?: InputMaybe<Array<ActivityWhereInput>>;
  client?: InputMaybe<ClientRelationFilter>;
  clientId?: InputMaybe<StringFilter>;
  comments?: InputMaybe<ActivityCommentListRelationFilter>;
  content?: InputMaybe<StringFilter>;
  coverage?: InputMaybe<CoveragePieceListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  docsId?: InputMaybe<StringNullableFilter>;
  dueDate?: InputMaybe<DateTimeNullableFilter>;
  files?: InputMaybe<FileMetadataListRelationFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  index?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  priority?: InputMaybe<EnumActivityPriorityFilter>;
  programPlannerItem?: InputMaybe<ProgramPlannerActivityNullableRelationFilter>;
  status?: InputMaybe<EnumActivityStatusFilter>;
  users?: InputMaybe<UserListRelationFilter>;
};

export type AffectedRowsOutput = {
  __typename?: 'AffectedRowsOutput';
  count: Scalars['Int']['output'];
};

export type AgencyDashboardResponse = {
  __typename?: 'AgencyDashboardResponse';
  clients: Array<Client>;
  coveragePieces: Array<CoveragePiece>;
  newsWrap?: Maybe<NewsWrap>;
  suggestedCoverage: Array<ArticleUnion>;
};

export type AgencyDashboardStatistics = {
  __typename?: 'AgencyDashboardStatistics';
  audience: Scalars['Float']['output'];
  hits: Scalars['Float']['output'];
  predictedViews: Scalars['Float']['output'];
};

export type ArbitraryTextInput = {
  command: Scalars['String']['input'];
  context: Scalars['String']['input'];
  selectedText: Scalars['String']['input'];
};

export type ArticleCreateNestedManyWithoutAuthorsInput = {
  connect?: InputMaybe<Array<ArticleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ArticleCreateOrConnectWithoutAuthorsInput>>;
  create?: InputMaybe<Array<ArticleCreateWithoutAuthorsInput>>;
};

export type ArticleCreateNestedOneWithoutClientMentionsInput = {
  connect?: InputMaybe<ArticleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ArticleCreateOrConnectWithoutClientMentionsInput>;
  create?: InputMaybe<ArticleCreateWithoutClientMentionsInput>;
};

export type ArticleCreateNestedOneWithoutCoveragePiecesInput = {
  connect?: InputMaybe<ArticleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ArticleCreateOrConnectWithoutCoveragePiecesInput>;
  create?: InputMaybe<ArticleCreateWithoutCoveragePiecesInput>;
};

export type ArticleCreateNestedOneWithoutIgnoredSuggestedCoverageInput = {
  connect?: InputMaybe<ArticleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ArticleCreateOrConnectWithoutIgnoredSuggestedCoverageInput>;
  create?: InputMaybe<ArticleCreateWithoutIgnoredSuggestedCoverageInput>;
};

export type ArticleCreateNestedOneWithoutNewsWrapContentsInput = {
  connect?: InputMaybe<ArticleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ArticleCreateOrConnectWithoutNewsWrapContentsInput>;
  create?: InputMaybe<ArticleCreateWithoutNewsWrapContentsInput>;
};

export type ArticleCreateNestedOneWithoutOnlineArticleInput = {
  connect?: InputMaybe<ArticleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ArticleCreateOrConnectWithoutOnlineArticleInput>;
  create?: InputMaybe<ArticleCreateWithoutOnlineArticleInput>;
};

export type ArticleCreateNestedOneWithoutPrintArticleInput = {
  connect?: InputMaybe<ArticleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ArticleCreateOrConnectWithoutPrintArticleInput>;
  create?: InputMaybe<ArticleCreateWithoutPrintArticleInput>;
};

export type ArticleCreateOrConnectWithoutAuthorsInput = {
  create: ArticleCreateWithoutAuthorsInput;
  where: ArticleWhereUniqueInput;
};

export type ArticleCreateOrConnectWithoutClientMentionsInput = {
  create: ArticleCreateWithoutClientMentionsInput;
  where: ArticleWhereUniqueInput;
};

export type ArticleCreateOrConnectWithoutCoveragePiecesInput = {
  create: ArticleCreateWithoutCoveragePiecesInput;
  where: ArticleWhereUniqueInput;
};

export type ArticleCreateOrConnectWithoutIgnoredSuggestedCoverageInput = {
  create: ArticleCreateWithoutIgnoredSuggestedCoverageInput;
  where: ArticleWhereUniqueInput;
};

export type ArticleCreateOrConnectWithoutNewsWrapContentsInput = {
  create: ArticleCreateWithoutNewsWrapContentsInput;
  where: ArticleWhereUniqueInput;
};

export type ArticleCreateOrConnectWithoutOnlineArticleInput = {
  create: ArticleCreateWithoutOnlineArticleInput;
  where: ArticleWhereUniqueInput;
};

export type ArticleCreateOrConnectWithoutPrintArticleInput = {
  create: ArticleCreateWithoutPrintArticleInput;
  where: ArticleWhereUniqueInput;
};

export type ArticleCreateWithoutAuthorsInput = {
  clientMentions?: InputMaybe<ClientMentionCreateNestedManyWithoutArticleInput>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutArticleInput>;
  embeddings?: InputMaybe<ArticleEmbeddingCreateNestedManyWithoutArticleInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageCreateNestedManyWithoutArticleInput>;
  newsWrapContents?: InputMaybe<NewsWrapContentCreateNestedManyWithoutArticleInput>;
  onlineArticle?: InputMaybe<OnlineArticleCreateNestedOneWithoutArticleInput>;
  printArticle?: InputMaybe<PrintArticleCreateNestedOneWithoutArticleInput>;
  type: ArticleType;
};

export type ArticleCreateWithoutClientMentionsInput = {
  authors?: InputMaybe<AuthorCreateNestedManyWithoutArticlesInput>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutArticleInput>;
  embeddings?: InputMaybe<ArticleEmbeddingCreateNestedManyWithoutArticleInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageCreateNestedManyWithoutArticleInput>;
  newsWrapContents?: InputMaybe<NewsWrapContentCreateNestedManyWithoutArticleInput>;
  onlineArticle?: InputMaybe<OnlineArticleCreateNestedOneWithoutArticleInput>;
  printArticle?: InputMaybe<PrintArticleCreateNestedOneWithoutArticleInput>;
  type: ArticleType;
};

export type ArticleCreateWithoutCoveragePiecesInput = {
  authors?: InputMaybe<AuthorCreateNestedManyWithoutArticlesInput>;
  clientMentions?: InputMaybe<ClientMentionCreateNestedManyWithoutArticleInput>;
  embeddings?: InputMaybe<ArticleEmbeddingCreateNestedManyWithoutArticleInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageCreateNestedManyWithoutArticleInput>;
  newsWrapContents?: InputMaybe<NewsWrapContentCreateNestedManyWithoutArticleInput>;
  onlineArticle?: InputMaybe<OnlineArticleCreateNestedOneWithoutArticleInput>;
  printArticle?: InputMaybe<PrintArticleCreateNestedOneWithoutArticleInput>;
  type: ArticleType;
};

export type ArticleCreateWithoutIgnoredSuggestedCoverageInput = {
  authors?: InputMaybe<AuthorCreateNestedManyWithoutArticlesInput>;
  clientMentions?: InputMaybe<ClientMentionCreateNestedManyWithoutArticleInput>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutArticleInput>;
  embeddings?: InputMaybe<ArticleEmbeddingCreateNestedManyWithoutArticleInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  newsWrapContents?: InputMaybe<NewsWrapContentCreateNestedManyWithoutArticleInput>;
  onlineArticle?: InputMaybe<OnlineArticleCreateNestedOneWithoutArticleInput>;
  printArticle?: InputMaybe<PrintArticleCreateNestedOneWithoutArticleInput>;
  type: ArticleType;
};

export type ArticleCreateWithoutNewsWrapContentsInput = {
  authors?: InputMaybe<AuthorCreateNestedManyWithoutArticlesInput>;
  clientMentions?: InputMaybe<ClientMentionCreateNestedManyWithoutArticleInput>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutArticleInput>;
  embeddings?: InputMaybe<ArticleEmbeddingCreateNestedManyWithoutArticleInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageCreateNestedManyWithoutArticleInput>;
  onlineArticle?: InputMaybe<OnlineArticleCreateNestedOneWithoutArticleInput>;
  printArticle?: InputMaybe<PrintArticleCreateNestedOneWithoutArticleInput>;
  type: ArticleType;
};

export type ArticleCreateWithoutOnlineArticleInput = {
  authors?: InputMaybe<AuthorCreateNestedManyWithoutArticlesInput>;
  clientMentions?: InputMaybe<ClientMentionCreateNestedManyWithoutArticleInput>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutArticleInput>;
  embeddings?: InputMaybe<ArticleEmbeddingCreateNestedManyWithoutArticleInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageCreateNestedManyWithoutArticleInput>;
  newsWrapContents?: InputMaybe<NewsWrapContentCreateNestedManyWithoutArticleInput>;
  printArticle?: InputMaybe<PrintArticleCreateNestedOneWithoutArticleInput>;
  type: ArticleType;
};

export type ArticleCreateWithoutPrintArticleInput = {
  authors?: InputMaybe<AuthorCreateNestedManyWithoutArticlesInput>;
  clientMentions?: InputMaybe<ClientMentionCreateNestedManyWithoutArticleInput>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutArticleInput>;
  embeddings?: InputMaybe<ArticleEmbeddingCreateNestedManyWithoutArticleInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageCreateNestedManyWithoutArticleInput>;
  newsWrapContents?: InputMaybe<NewsWrapContentCreateNestedManyWithoutArticleInput>;
  onlineArticle?: InputMaybe<OnlineArticleCreateNestedOneWithoutArticleInput>;
  type: ArticleType;
};

export type ArticleEmbeddingCreateNestedManyWithoutArticleInput = {
  connect?: InputMaybe<Array<ArticleEmbeddingWhereUniqueInput>>;
};

export type ArticleEmbeddingListRelationFilter = {
  every?: InputMaybe<ArticleEmbeddingWhereInput>;
  none?: InputMaybe<ArticleEmbeddingWhereInput>;
  some?: InputMaybe<ArticleEmbeddingWhereInput>;
};

export type ArticleEmbeddingOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ArticleEmbeddingScalarWhereInput = {
  AND?: InputMaybe<Array<ArticleEmbeddingScalarWhereInput>>;
  NOT?: InputMaybe<Array<ArticleEmbeddingScalarWhereInput>>;
  OR?: InputMaybe<Array<ArticleEmbeddingScalarWhereInput>>;
  articleId?: InputMaybe<StringFilter>;
  end?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  newsworthiness?: InputMaybe<FloatFilter>;
  start?: InputMaybe<IntFilter>;
  text?: InputMaybe<StringFilter>;
};

export type ArticleEmbeddingUpdateManyMutationInput = {
  end?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  newsworthiness?: InputMaybe<FloatFieldUpdateOperationsInput>;
  start?: InputMaybe<IntFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ArticleEmbeddingUpdateManyWithWhereWithoutArticleInput = {
  data: ArticleEmbeddingUpdateManyMutationInput;
  where: ArticleEmbeddingScalarWhereInput;
};

export type ArticleEmbeddingUpdateManyWithoutArticleNestedInput = {
  connect?: InputMaybe<Array<ArticleEmbeddingWhereUniqueInput>>;
  delete?: InputMaybe<Array<ArticleEmbeddingWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ArticleEmbeddingScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ArticleEmbeddingWhereUniqueInput>>;
  set?: InputMaybe<Array<ArticleEmbeddingWhereUniqueInput>>;
  update?: InputMaybe<Array<ArticleEmbeddingUpdateWithWhereUniqueWithoutArticleInput>>;
  updateMany?: InputMaybe<Array<ArticleEmbeddingUpdateManyWithWhereWithoutArticleInput>>;
};

export type ArticleEmbeddingUpdateWithWhereUniqueWithoutArticleInput = {
  data: ArticleEmbeddingUpdateWithoutArticleInput;
  where: ArticleEmbeddingWhereUniqueInput;
};

export type ArticleEmbeddingUpdateWithoutArticleInput = {
  end?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  newsworthiness?: InputMaybe<FloatFieldUpdateOperationsInput>;
  start?: InputMaybe<IntFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ArticleEmbeddingWhereInput = {
  AND?: InputMaybe<Array<ArticleEmbeddingWhereInput>>;
  NOT?: InputMaybe<Array<ArticleEmbeddingWhereInput>>;
  OR?: InputMaybe<Array<ArticleEmbeddingWhereInput>>;
  article?: InputMaybe<ArticleRelationFilter>;
  articleId?: InputMaybe<StringFilter>;
  end?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  newsworthiness?: InputMaybe<FloatFilter>;
  start?: InputMaybe<IntFilter>;
  text?: InputMaybe<StringFilter>;
};

export type ArticleEmbeddingWhereUniqueInput = {
  AND?: InputMaybe<Array<ArticleEmbeddingWhereInput>>;
  NOT?: InputMaybe<Array<ArticleEmbeddingWhereInput>>;
  OR?: InputMaybe<Array<ArticleEmbeddingWhereInput>>;
  article?: InputMaybe<ArticleRelationFilter>;
  articleId?: InputMaybe<StringFilter>;
  end?: InputMaybe<IntFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  newsworthiness?: InputMaybe<FloatFilter>;
  start?: InputMaybe<IntFilter>;
  text?: InputMaybe<StringFilter>;
};

export type ArticleListRelationFilter = {
  every?: InputMaybe<ArticleWhereInput>;
  none?: InputMaybe<ArticleWhereInput>;
  some?: InputMaybe<ArticleWhereInput>;
};

export type ArticleNullableRelationFilter = {
  is?: InputMaybe<ArticleWhereInput>;
  isNot?: InputMaybe<ArticleWhereInput>;
};

export type ArticleOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum ArticleOrderByRelevanceFieldEnum {
  Id = 'id'
}

export type ArticleOrderByRelevanceInput = {
  fields: Array<ArticleOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type ArticleOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<ArticleOrderByRelevanceInput>;
  authors?: InputMaybe<AuthorOrderByRelationAggregateInput>;
  clientMentions?: InputMaybe<ClientMentionOrderByRelationAggregateInput>;
  coveragePieces?: InputMaybe<CoveragePieceOrderByRelationAggregateInput>;
  embeddings?: InputMaybe<ArticleEmbeddingOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageOrderByRelationAggregateInput>;
  newsWrapContents?: InputMaybe<NewsWrapContentOrderByRelationAggregateInput>;
  onlineArticle?: InputMaybe<OnlineArticleOrderByWithRelationAndSearchRelevanceInput>;
  printArticle?: InputMaybe<PrintArticleOrderByWithRelationAndSearchRelevanceInput>;
  type?: InputMaybe<SortOrder>;
};

export type ArticleRelationFilter = {
  is?: InputMaybe<ArticleWhereInput>;
  isNot?: InputMaybe<ArticleWhereInput>;
};

export type ArticleScalarWhereInput = {
  AND?: InputMaybe<Array<ArticleScalarWhereInput>>;
  NOT?: InputMaybe<Array<ArticleScalarWhereInput>>;
  OR?: InputMaybe<Array<ArticleScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumArticleTypeFilter>;
};

export enum ArticleType {
  Online = 'ONLINE',
  Print = 'PRINT'
}

export type ArticleUnion = OnlineArticle | PrintArticle;

export type ArticleUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumArticleTypeFieldUpdateOperationsInput>;
};

export type ArticleUpdateManyWithWhereWithoutAuthorsInput = {
  data: ArticleUpdateManyMutationInput;
  where: ArticleScalarWhereInput;
};

export type ArticleUpdateManyWithoutAuthorsNestedInput = {
  connect?: InputMaybe<Array<ArticleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ArticleCreateOrConnectWithoutAuthorsInput>>;
  create?: InputMaybe<Array<ArticleCreateWithoutAuthorsInput>>;
  delete?: InputMaybe<Array<ArticleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ArticleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ArticleWhereUniqueInput>>;
  set?: InputMaybe<Array<ArticleWhereUniqueInput>>;
  update?: InputMaybe<Array<ArticleUpdateWithWhereUniqueWithoutAuthorsInput>>;
  updateMany?: InputMaybe<Array<ArticleUpdateManyWithWhereWithoutAuthorsInput>>;
  upsert?: InputMaybe<Array<ArticleUpsertWithWhereUniqueWithoutAuthorsInput>>;
};

export type ArticleUpdateOneRequiredWithoutClientMentionsNestedInput = {
  connect?: InputMaybe<ArticleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ArticleCreateOrConnectWithoutClientMentionsInput>;
  create?: InputMaybe<ArticleCreateWithoutClientMentionsInput>;
  update?: InputMaybe<ArticleUpdateToOneWithWhereWithoutClientMentionsInput>;
  upsert?: InputMaybe<ArticleUpsertWithoutClientMentionsInput>;
};

export type ArticleUpdateOneRequiredWithoutNewsWrapContentsNestedInput = {
  connect?: InputMaybe<ArticleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ArticleCreateOrConnectWithoutNewsWrapContentsInput>;
  create?: InputMaybe<ArticleCreateWithoutNewsWrapContentsInput>;
  update?: InputMaybe<ArticleUpdateToOneWithWhereWithoutNewsWrapContentsInput>;
  upsert?: InputMaybe<ArticleUpsertWithoutNewsWrapContentsInput>;
};

export type ArticleUpdateOneRequiredWithoutOnlineArticleNestedInput = {
  connect?: InputMaybe<ArticleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ArticleCreateOrConnectWithoutOnlineArticleInput>;
  create?: InputMaybe<ArticleCreateWithoutOnlineArticleInput>;
  update?: InputMaybe<ArticleUpdateToOneWithWhereWithoutOnlineArticleInput>;
  upsert?: InputMaybe<ArticleUpsertWithoutOnlineArticleInput>;
};

export type ArticleUpdateOneRequiredWithoutPrintArticleNestedInput = {
  connect?: InputMaybe<ArticleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ArticleCreateOrConnectWithoutPrintArticleInput>;
  create?: InputMaybe<ArticleCreateWithoutPrintArticleInput>;
  update?: InputMaybe<ArticleUpdateToOneWithWhereWithoutPrintArticleInput>;
  upsert?: InputMaybe<ArticleUpsertWithoutPrintArticleInput>;
};

export type ArticleUpdateOneWithoutCoveragePiecesNestedInput = {
  connect?: InputMaybe<ArticleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ArticleCreateOrConnectWithoutCoveragePiecesInput>;
  create?: InputMaybe<ArticleCreateWithoutCoveragePiecesInput>;
  delete?: InputMaybe<ArticleWhereInput>;
  disconnect?: InputMaybe<ArticleWhereInput>;
  update?: InputMaybe<ArticleUpdateToOneWithWhereWithoutCoveragePiecesInput>;
  upsert?: InputMaybe<ArticleUpsertWithoutCoveragePiecesInput>;
};

export type ArticleUpdateOneWithoutIgnoredSuggestedCoverageNestedInput = {
  connect?: InputMaybe<ArticleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ArticleCreateOrConnectWithoutIgnoredSuggestedCoverageInput>;
  create?: InputMaybe<ArticleCreateWithoutIgnoredSuggestedCoverageInput>;
  delete?: InputMaybe<ArticleWhereInput>;
  disconnect?: InputMaybe<ArticleWhereInput>;
  update?: InputMaybe<ArticleUpdateToOneWithWhereWithoutIgnoredSuggestedCoverageInput>;
  upsert?: InputMaybe<ArticleUpsertWithoutIgnoredSuggestedCoverageInput>;
};

export type ArticleUpdateToOneWithWhereWithoutClientMentionsInput = {
  data: ArticleUpdateWithoutClientMentionsInput;
  where?: InputMaybe<ArticleWhereInput>;
};

export type ArticleUpdateToOneWithWhereWithoutCoveragePiecesInput = {
  data: ArticleUpdateWithoutCoveragePiecesInput;
  where?: InputMaybe<ArticleWhereInput>;
};

export type ArticleUpdateToOneWithWhereWithoutIgnoredSuggestedCoverageInput = {
  data: ArticleUpdateWithoutIgnoredSuggestedCoverageInput;
  where?: InputMaybe<ArticleWhereInput>;
};

export type ArticleUpdateToOneWithWhereWithoutNewsWrapContentsInput = {
  data: ArticleUpdateWithoutNewsWrapContentsInput;
  where?: InputMaybe<ArticleWhereInput>;
};

export type ArticleUpdateToOneWithWhereWithoutOnlineArticleInput = {
  data: ArticleUpdateWithoutOnlineArticleInput;
  where?: InputMaybe<ArticleWhereInput>;
};

export type ArticleUpdateToOneWithWhereWithoutPrintArticleInput = {
  data: ArticleUpdateWithoutPrintArticleInput;
  where?: InputMaybe<ArticleWhereInput>;
};

export type ArticleUpdateWithWhereUniqueWithoutAuthorsInput = {
  data: ArticleUpdateWithoutAuthorsInput;
  where: ArticleWhereUniqueInput;
};

export type ArticleUpdateWithoutAuthorsInput = {
  clientMentions?: InputMaybe<ClientMentionUpdateManyWithoutArticleNestedInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutArticleNestedInput>;
  embeddings?: InputMaybe<ArticleEmbeddingUpdateManyWithoutArticleNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageUpdateManyWithoutArticleNestedInput>;
  newsWrapContents?: InputMaybe<NewsWrapContentUpdateManyWithoutArticleNestedInput>;
  onlineArticle?: InputMaybe<OnlineArticleUpdateOneWithoutArticleNestedInput>;
  printArticle?: InputMaybe<PrintArticleUpdateOneWithoutArticleNestedInput>;
  type?: InputMaybe<EnumArticleTypeFieldUpdateOperationsInput>;
};

export type ArticleUpdateWithoutClientMentionsInput = {
  authors?: InputMaybe<AuthorUpdateManyWithoutArticlesNestedInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutArticleNestedInput>;
  embeddings?: InputMaybe<ArticleEmbeddingUpdateManyWithoutArticleNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageUpdateManyWithoutArticleNestedInput>;
  newsWrapContents?: InputMaybe<NewsWrapContentUpdateManyWithoutArticleNestedInput>;
  onlineArticle?: InputMaybe<OnlineArticleUpdateOneWithoutArticleNestedInput>;
  printArticle?: InputMaybe<PrintArticleUpdateOneWithoutArticleNestedInput>;
  type?: InputMaybe<EnumArticleTypeFieldUpdateOperationsInput>;
};

export type ArticleUpdateWithoutCoveragePiecesInput = {
  authors?: InputMaybe<AuthorUpdateManyWithoutArticlesNestedInput>;
  clientMentions?: InputMaybe<ClientMentionUpdateManyWithoutArticleNestedInput>;
  embeddings?: InputMaybe<ArticleEmbeddingUpdateManyWithoutArticleNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageUpdateManyWithoutArticleNestedInput>;
  newsWrapContents?: InputMaybe<NewsWrapContentUpdateManyWithoutArticleNestedInput>;
  onlineArticle?: InputMaybe<OnlineArticleUpdateOneWithoutArticleNestedInput>;
  printArticle?: InputMaybe<PrintArticleUpdateOneWithoutArticleNestedInput>;
  type?: InputMaybe<EnumArticleTypeFieldUpdateOperationsInput>;
};

export type ArticleUpdateWithoutIgnoredSuggestedCoverageInput = {
  authors?: InputMaybe<AuthorUpdateManyWithoutArticlesNestedInput>;
  clientMentions?: InputMaybe<ClientMentionUpdateManyWithoutArticleNestedInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutArticleNestedInput>;
  embeddings?: InputMaybe<ArticleEmbeddingUpdateManyWithoutArticleNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  newsWrapContents?: InputMaybe<NewsWrapContentUpdateManyWithoutArticleNestedInput>;
  onlineArticle?: InputMaybe<OnlineArticleUpdateOneWithoutArticleNestedInput>;
  printArticle?: InputMaybe<PrintArticleUpdateOneWithoutArticleNestedInput>;
  type?: InputMaybe<EnumArticleTypeFieldUpdateOperationsInput>;
};

export type ArticleUpdateWithoutNewsWrapContentsInput = {
  authors?: InputMaybe<AuthorUpdateManyWithoutArticlesNestedInput>;
  clientMentions?: InputMaybe<ClientMentionUpdateManyWithoutArticleNestedInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutArticleNestedInput>;
  embeddings?: InputMaybe<ArticleEmbeddingUpdateManyWithoutArticleNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageUpdateManyWithoutArticleNestedInput>;
  onlineArticle?: InputMaybe<OnlineArticleUpdateOneWithoutArticleNestedInput>;
  printArticle?: InputMaybe<PrintArticleUpdateOneWithoutArticleNestedInput>;
  type?: InputMaybe<EnumArticleTypeFieldUpdateOperationsInput>;
};

export type ArticleUpdateWithoutOnlineArticleInput = {
  authors?: InputMaybe<AuthorUpdateManyWithoutArticlesNestedInput>;
  clientMentions?: InputMaybe<ClientMentionUpdateManyWithoutArticleNestedInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutArticleNestedInput>;
  embeddings?: InputMaybe<ArticleEmbeddingUpdateManyWithoutArticleNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageUpdateManyWithoutArticleNestedInput>;
  newsWrapContents?: InputMaybe<NewsWrapContentUpdateManyWithoutArticleNestedInput>;
  printArticle?: InputMaybe<PrintArticleUpdateOneWithoutArticleNestedInput>;
  type?: InputMaybe<EnumArticleTypeFieldUpdateOperationsInput>;
};

export type ArticleUpdateWithoutPrintArticleInput = {
  authors?: InputMaybe<AuthorUpdateManyWithoutArticlesNestedInput>;
  clientMentions?: InputMaybe<ClientMentionUpdateManyWithoutArticleNestedInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutArticleNestedInput>;
  embeddings?: InputMaybe<ArticleEmbeddingUpdateManyWithoutArticleNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageUpdateManyWithoutArticleNestedInput>;
  newsWrapContents?: InputMaybe<NewsWrapContentUpdateManyWithoutArticleNestedInput>;
  onlineArticle?: InputMaybe<OnlineArticleUpdateOneWithoutArticleNestedInput>;
  type?: InputMaybe<EnumArticleTypeFieldUpdateOperationsInput>;
};

export type ArticleUpsertWithWhereUniqueWithoutAuthorsInput = {
  create: ArticleCreateWithoutAuthorsInput;
  update: ArticleUpdateWithoutAuthorsInput;
  where: ArticleWhereUniqueInput;
};

export type ArticleUpsertWithoutClientMentionsInput = {
  create: ArticleCreateWithoutClientMentionsInput;
  update: ArticleUpdateWithoutClientMentionsInput;
  where?: InputMaybe<ArticleWhereInput>;
};

export type ArticleUpsertWithoutCoveragePiecesInput = {
  create: ArticleCreateWithoutCoveragePiecesInput;
  update: ArticleUpdateWithoutCoveragePiecesInput;
  where?: InputMaybe<ArticleWhereInput>;
};

export type ArticleUpsertWithoutIgnoredSuggestedCoverageInput = {
  create: ArticleCreateWithoutIgnoredSuggestedCoverageInput;
  update: ArticleUpdateWithoutIgnoredSuggestedCoverageInput;
  where?: InputMaybe<ArticleWhereInput>;
};

export type ArticleUpsertWithoutNewsWrapContentsInput = {
  create: ArticleCreateWithoutNewsWrapContentsInput;
  update: ArticleUpdateWithoutNewsWrapContentsInput;
  where?: InputMaybe<ArticleWhereInput>;
};

export type ArticleUpsertWithoutOnlineArticleInput = {
  create: ArticleCreateWithoutOnlineArticleInput;
  update: ArticleUpdateWithoutOnlineArticleInput;
  where?: InputMaybe<ArticleWhereInput>;
};

export type ArticleUpsertWithoutPrintArticleInput = {
  create: ArticleCreateWithoutPrintArticleInput;
  update: ArticleUpdateWithoutPrintArticleInput;
  where?: InputMaybe<ArticleWhereInput>;
};

export type ArticleWhereInput = {
  AND?: InputMaybe<Array<ArticleWhereInput>>;
  NOT?: InputMaybe<Array<ArticleWhereInput>>;
  OR?: InputMaybe<Array<ArticleWhereInput>>;
  authors?: InputMaybe<AuthorListRelationFilter>;
  clientMentions?: InputMaybe<ClientMentionListRelationFilter>;
  coveragePieces?: InputMaybe<CoveragePieceListRelationFilter>;
  embeddings?: InputMaybe<ArticleEmbeddingListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageListRelationFilter>;
  newsWrapContents?: InputMaybe<NewsWrapContentListRelationFilter>;
  onlineArticle?: InputMaybe<OnlineArticleNullableRelationFilter>;
  printArticle?: InputMaybe<PrintArticleNullableRelationFilter>;
  type?: InputMaybe<EnumArticleTypeFilter>;
};

export type ArticleWhereUniqueInput = {
  AND?: InputMaybe<Array<ArticleWhereInput>>;
  NOT?: InputMaybe<Array<ArticleWhereInput>>;
  OR?: InputMaybe<Array<ArticleWhereInput>>;
  authors?: InputMaybe<AuthorListRelationFilter>;
  clientMentions?: InputMaybe<ClientMentionListRelationFilter>;
  coveragePieces?: InputMaybe<CoveragePieceListRelationFilter>;
  embeddings?: InputMaybe<ArticleEmbeddingListRelationFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageListRelationFilter>;
  newsWrapContents?: InputMaybe<NewsWrapContentListRelationFilter>;
  onlineArticle?: InputMaybe<OnlineArticleNullableRelationFilter>;
  printArticle?: InputMaybe<PrintArticleNullableRelationFilter>;
  type?: InputMaybe<EnumArticleTypeFilter>;
};

export type Author = {
  __typename?: 'Author';
  _count?: Maybe<AuthorCount>;
  bio: Scalars['String']['output'];
  id: Scalars['String']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  publicationHistory: Array<AuthorPublicationHistory>;
};

export type AuthorCount = {
  __typename?: 'AuthorCount';
  articles: Scalars['Int']['output'];
  publicationHistory: Scalars['Int']['output'];
};


export type AuthorCountArticlesArgs = {
  where?: InputMaybe<ArticleWhereInput>;
};


export type AuthorCountPublicationHistoryArgs = {
  where?: InputMaybe<AuthorPublicationHistoryWhereInput>;
};

export type AuthorCreateNestedManyWithoutArticlesInput = {
  connect?: InputMaybe<Array<AuthorWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AuthorCreateOrConnectWithoutArticlesInput>>;
  create?: InputMaybe<Array<AuthorCreateWithoutArticlesInput>>;
};

export type AuthorCreateNestedOneWithoutPublicationHistoryInput = {
  connect?: InputMaybe<AuthorWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AuthorCreateOrConnectWithoutPublicationHistoryInput>;
  create?: InputMaybe<AuthorCreateWithoutPublicationHistoryInput>;
};

export type AuthorCreateOrConnectWithoutArticlesInput = {
  create: AuthorCreateWithoutArticlesInput;
  where: AuthorWhereUniqueInput;
};

export type AuthorCreateOrConnectWithoutPublicationHistoryInput = {
  create: AuthorCreateWithoutPublicationHistoryInput;
  where: AuthorWhereUniqueInput;
};

export type AuthorCreateWithoutArticlesInput = {
  bio?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  publicationHistory?: InputMaybe<AuthorPublicationHistoryCreateNestedManyWithoutAuthorInput>;
};

export type AuthorCreateWithoutPublicationHistoryInput = {
  articles?: InputMaybe<ArticleCreateNestedManyWithoutAuthorsInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type AuthorListRelationFilter = {
  every?: InputMaybe<AuthorWhereInput>;
  none?: InputMaybe<AuthorWhereInput>;
  some?: InputMaybe<AuthorWhereInput>;
};

export type AuthorLocation = {
  __typename?: 'AuthorLocation';
  authorId: Scalars['String']['output'];
  city: Scalars['String']['output'];
  countryId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  timezone: Scalars['String']['output'];
};

export type AuthorLocationCreateManyCountryInput = {
  authorId: Scalars['String']['input'];
  city: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  timezone: Scalars['String']['input'];
};

export type AuthorLocationCreateManyCountryInputEnvelope = {
  data: Array<AuthorLocationCreateManyCountryInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AuthorLocationCreateNestedManyWithoutCountryInput = {
  connect?: InputMaybe<Array<AuthorLocationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AuthorLocationCreateOrConnectWithoutCountryInput>>;
  create?: InputMaybe<Array<AuthorLocationCreateWithoutCountryInput>>;
  createMany?: InputMaybe<AuthorLocationCreateManyCountryInputEnvelope>;
};

export type AuthorLocationCreateNestedOneWithoutAuthorInput = {
  connect?: InputMaybe<AuthorLocationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AuthorLocationCreateOrConnectWithoutAuthorInput>;
  create?: InputMaybe<AuthorLocationCreateWithoutAuthorInput>;
};

export type AuthorLocationCreateOrConnectWithoutAuthorInput = {
  create: AuthorLocationCreateWithoutAuthorInput;
  where: AuthorLocationWhereUniqueInput;
};

export type AuthorLocationCreateOrConnectWithoutCountryInput = {
  create: AuthorLocationCreateWithoutCountryInput;
  where: AuthorLocationWhereUniqueInput;
};

export type AuthorLocationCreateWithoutAuthorInput = {
  city: Scalars['String']['input'];
  country: CountryCreateNestedOneWithoutAuthorLocationsInput;
  id?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  timezone: Scalars['String']['input'];
};

export type AuthorLocationCreateWithoutCountryInput = {
  author: AuthorPublicationHistoryCreateNestedOneWithoutLocationInput;
  city: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  timezone: Scalars['String']['input'];
};

export type AuthorLocationListRelationFilter = {
  every?: InputMaybe<AuthorLocationWhereInput>;
  none?: InputMaybe<AuthorLocationWhereInput>;
  some?: InputMaybe<AuthorLocationWhereInput>;
};

export type AuthorLocationNullableRelationFilter = {
  is?: InputMaybe<AuthorLocationWhereInput>;
  isNot?: InputMaybe<AuthorLocationWhereInput>;
};

export type AuthorLocationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type AuthorLocationScalarWhereInput = {
  AND?: InputMaybe<Array<AuthorLocationScalarWhereInput>>;
  NOT?: InputMaybe<Array<AuthorLocationScalarWhereInput>>;
  OR?: InputMaybe<Array<AuthorLocationScalarWhereInput>>;
  authorId?: InputMaybe<StringFilter>;
  city?: InputMaybe<StringFilter>;
  countryId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  latitude?: InputMaybe<FloatFilter>;
  longitude?: InputMaybe<FloatFilter>;
  timezone?: InputMaybe<StringFilter>;
};

export type AuthorLocationUpdateManyMutationInput = {
  city?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  longitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  timezone?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type AuthorLocationUpdateManyWithWhereWithoutCountryInput = {
  data: AuthorLocationUpdateManyMutationInput;
  where: AuthorLocationScalarWhereInput;
};

export type AuthorLocationUpdateManyWithoutCountryNestedInput = {
  connect?: InputMaybe<Array<AuthorLocationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AuthorLocationCreateOrConnectWithoutCountryInput>>;
  create?: InputMaybe<Array<AuthorLocationCreateWithoutCountryInput>>;
  createMany?: InputMaybe<AuthorLocationCreateManyCountryInputEnvelope>;
  delete?: InputMaybe<Array<AuthorLocationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AuthorLocationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AuthorLocationWhereUniqueInput>>;
  set?: InputMaybe<Array<AuthorLocationWhereUniqueInput>>;
  update?: InputMaybe<Array<AuthorLocationUpdateWithWhereUniqueWithoutCountryInput>>;
  updateMany?: InputMaybe<Array<AuthorLocationUpdateManyWithWhereWithoutCountryInput>>;
  upsert?: InputMaybe<Array<AuthorLocationUpsertWithWhereUniqueWithoutCountryInput>>;
};

export type AuthorLocationUpdateOneWithoutAuthorNestedInput = {
  connect?: InputMaybe<AuthorLocationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AuthorLocationCreateOrConnectWithoutAuthorInput>;
  create?: InputMaybe<AuthorLocationCreateWithoutAuthorInput>;
  delete?: InputMaybe<AuthorLocationWhereInput>;
  disconnect?: InputMaybe<AuthorLocationWhereInput>;
  update?: InputMaybe<AuthorLocationUpdateToOneWithWhereWithoutAuthorInput>;
  upsert?: InputMaybe<AuthorLocationUpsertWithoutAuthorInput>;
};

export type AuthorLocationUpdateToOneWithWhereWithoutAuthorInput = {
  data: AuthorLocationUpdateWithoutAuthorInput;
  where?: InputMaybe<AuthorLocationWhereInput>;
};

export type AuthorLocationUpdateWithWhereUniqueWithoutCountryInput = {
  data: AuthorLocationUpdateWithoutCountryInput;
  where: AuthorLocationWhereUniqueInput;
};

export type AuthorLocationUpdateWithoutAuthorInput = {
  city?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<CountryUpdateOneRequiredWithoutAuthorLocationsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  longitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  timezone?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type AuthorLocationUpdateWithoutCountryInput = {
  author?: InputMaybe<AuthorPublicationHistoryUpdateOneRequiredWithoutLocationNestedInput>;
  city?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  longitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  timezone?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type AuthorLocationUpsertWithWhereUniqueWithoutCountryInput = {
  create: AuthorLocationCreateWithoutCountryInput;
  update: AuthorLocationUpdateWithoutCountryInput;
  where: AuthorLocationWhereUniqueInput;
};

export type AuthorLocationUpsertWithoutAuthorInput = {
  create: AuthorLocationCreateWithoutAuthorInput;
  update: AuthorLocationUpdateWithoutAuthorInput;
  where?: InputMaybe<AuthorLocationWhereInput>;
};

export type AuthorLocationWhereInput = {
  AND?: InputMaybe<Array<AuthorLocationWhereInput>>;
  NOT?: InputMaybe<Array<AuthorLocationWhereInput>>;
  OR?: InputMaybe<Array<AuthorLocationWhereInput>>;
  author?: InputMaybe<AuthorPublicationHistoryRelationFilter>;
  authorId?: InputMaybe<StringFilter>;
  city?: InputMaybe<StringFilter>;
  country?: InputMaybe<CountryRelationFilter>;
  countryId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  latitude?: InputMaybe<FloatFilter>;
  longitude?: InputMaybe<FloatFilter>;
  timezone?: InputMaybe<StringFilter>;
};

export type AuthorLocationWhereUniqueInput = {
  AND?: InputMaybe<Array<AuthorLocationWhereInput>>;
  NOT?: InputMaybe<Array<AuthorLocationWhereInput>>;
  OR?: InputMaybe<Array<AuthorLocationWhereInput>>;
  author?: InputMaybe<AuthorPublicationHistoryRelationFilter>;
  authorId?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<StringFilter>;
  country?: InputMaybe<CountryRelationFilter>;
  countryId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<FloatFilter>;
  longitude?: InputMaybe<FloatFilter>;
  timezone?: InputMaybe<StringFilter>;
};

export type AuthorOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum AuthorOrderByRelevanceFieldEnum {
  Bio = 'bio',
  Id = 'id',
  ImageUrl = 'imageUrl',
  Name = 'name'
}

export type AuthorOrderByRelevanceInput = {
  fields: Array<AuthorOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type AuthorOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<AuthorOrderByRelevanceInput>;
  articles?: InputMaybe<ArticleOrderByRelationAggregateInput>;
  bio?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  imageUrl?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  publicationHistory?: InputMaybe<AuthorPublicationHistoryOrderByRelationAggregateInput>;
};

export type AuthorPublicationHistory = {
  __typename?: 'AuthorPublicationHistory';
  author: Author;
  authorId: Scalars['String']['output'];
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  location?: Maybe<AuthorLocation>;
  publication: Publication;
  publicationId: Scalars['String']['output'];
  role: Scalars['String']['output'];
  startDate: Scalars['DateTime']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type AuthorPublicationHistoryAuthorIdPublicationIdCompoundUniqueInput = {
  authorId: Scalars['String']['input'];
  publicationId: Scalars['String']['input'];
};

export type AuthorPublicationHistoryCreateInput = {
  author: AuthorCreateNestedOneWithoutPublicationHistoryInput;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<AuthorLocationCreateNestedOneWithoutAuthorInput>;
  publication: PublicationCreateNestedOneWithoutAuthorsInput;
  role?: InputMaybe<Scalars['String']['input']>;
  startDate: Scalars['DateTime']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type AuthorPublicationHistoryCreateManyAuthorInput = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  publicationId: Scalars['String']['input'];
  role?: InputMaybe<Scalars['String']['input']>;
  startDate: Scalars['DateTime']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type AuthorPublicationHistoryCreateManyAuthorInputEnvelope = {
  data: Array<AuthorPublicationHistoryCreateManyAuthorInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AuthorPublicationHistoryCreateManyPublicationInput = {
  authorId: Scalars['String']['input'];
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  startDate: Scalars['DateTime']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type AuthorPublicationHistoryCreateManyPublicationInputEnvelope = {
  data: Array<AuthorPublicationHistoryCreateManyPublicationInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AuthorPublicationHistoryCreateNestedManyWithoutAuthorInput = {
  connect?: InputMaybe<Array<AuthorPublicationHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AuthorPublicationHistoryCreateOrConnectWithoutAuthorInput>>;
  create?: InputMaybe<Array<AuthorPublicationHistoryCreateWithoutAuthorInput>>;
  createMany?: InputMaybe<AuthorPublicationHistoryCreateManyAuthorInputEnvelope>;
};

export type AuthorPublicationHistoryCreateNestedManyWithoutPublicationInput = {
  connect?: InputMaybe<Array<AuthorPublicationHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AuthorPublicationHistoryCreateOrConnectWithoutPublicationInput>>;
  create?: InputMaybe<Array<AuthorPublicationHistoryCreateWithoutPublicationInput>>;
  createMany?: InputMaybe<AuthorPublicationHistoryCreateManyPublicationInputEnvelope>;
};

export type AuthorPublicationHistoryCreateNestedOneWithoutLocationInput = {
  connect?: InputMaybe<AuthorPublicationHistoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AuthorPublicationHistoryCreateOrConnectWithoutLocationInput>;
  create?: InputMaybe<AuthorPublicationHistoryCreateWithoutLocationInput>;
};

export type AuthorPublicationHistoryCreateOrConnectWithoutAuthorInput = {
  create: AuthorPublicationHistoryCreateWithoutAuthorInput;
  where: AuthorPublicationHistoryWhereUniqueInput;
};

export type AuthorPublicationHistoryCreateOrConnectWithoutLocationInput = {
  create: AuthorPublicationHistoryCreateWithoutLocationInput;
  where: AuthorPublicationHistoryWhereUniqueInput;
};

export type AuthorPublicationHistoryCreateOrConnectWithoutPublicationInput = {
  create: AuthorPublicationHistoryCreateWithoutPublicationInput;
  where: AuthorPublicationHistoryWhereUniqueInput;
};

export type AuthorPublicationHistoryCreateWithoutAuthorInput = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<AuthorLocationCreateNestedOneWithoutAuthorInput>;
  publication: PublicationCreateNestedOneWithoutAuthorsInput;
  role?: InputMaybe<Scalars['String']['input']>;
  startDate: Scalars['DateTime']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type AuthorPublicationHistoryCreateWithoutLocationInput = {
  author: AuthorCreateNestedOneWithoutPublicationHistoryInput;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  publication: PublicationCreateNestedOneWithoutAuthorsInput;
  role?: InputMaybe<Scalars['String']['input']>;
  startDate: Scalars['DateTime']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type AuthorPublicationHistoryCreateWithoutPublicationInput = {
  author: AuthorCreateNestedOneWithoutPublicationHistoryInput;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<AuthorLocationCreateNestedOneWithoutAuthorInput>;
  role?: InputMaybe<Scalars['String']['input']>;
  startDate: Scalars['DateTime']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type AuthorPublicationHistoryListRelationFilter = {
  every?: InputMaybe<AuthorPublicationHistoryWhereInput>;
  none?: InputMaybe<AuthorPublicationHistoryWhereInput>;
  some?: InputMaybe<AuthorPublicationHistoryWhereInput>;
};

export type AuthorPublicationHistoryOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type AuthorPublicationHistoryRelationFilter = {
  is?: InputMaybe<AuthorPublicationHistoryWhereInput>;
  isNot?: InputMaybe<AuthorPublicationHistoryWhereInput>;
};

export type AuthorPublicationHistoryScalarWhereInput = {
  AND?: InputMaybe<Array<AuthorPublicationHistoryScalarWhereInput>>;
  NOT?: InputMaybe<Array<AuthorPublicationHistoryScalarWhereInput>>;
  OR?: InputMaybe<Array<AuthorPublicationHistoryScalarWhereInput>>;
  authorId?: InputMaybe<StringFilter>;
  endDate?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  publicationId?: InputMaybe<StringFilter>;
  role?: InputMaybe<StringFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  url?: InputMaybe<StringNullableFilter>;
};

export type AuthorPublicationHistoryUpdateInput = {
  author?: InputMaybe<AuthorUpdateOneRequiredWithoutPublicationHistoryNestedInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  location?: InputMaybe<AuthorLocationUpdateOneWithoutAuthorNestedInput>;
  publication?: InputMaybe<PublicationUpdateOneRequiredWithoutAuthorsNestedInput>;
  role?: InputMaybe<StringFieldUpdateOperationsInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type AuthorPublicationHistoryUpdateManyMutationInput = {
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  role?: InputMaybe<StringFieldUpdateOperationsInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type AuthorPublicationHistoryUpdateManyWithWhereWithoutAuthorInput = {
  data: AuthorPublicationHistoryUpdateManyMutationInput;
  where: AuthorPublicationHistoryScalarWhereInput;
};

export type AuthorPublicationHistoryUpdateManyWithWhereWithoutPublicationInput = {
  data: AuthorPublicationHistoryUpdateManyMutationInput;
  where: AuthorPublicationHistoryScalarWhereInput;
};

export type AuthorPublicationHistoryUpdateManyWithoutAuthorNestedInput = {
  connect?: InputMaybe<Array<AuthorPublicationHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AuthorPublicationHistoryCreateOrConnectWithoutAuthorInput>>;
  create?: InputMaybe<Array<AuthorPublicationHistoryCreateWithoutAuthorInput>>;
  createMany?: InputMaybe<AuthorPublicationHistoryCreateManyAuthorInputEnvelope>;
  delete?: InputMaybe<Array<AuthorPublicationHistoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AuthorPublicationHistoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AuthorPublicationHistoryWhereUniqueInput>>;
  set?: InputMaybe<Array<AuthorPublicationHistoryWhereUniqueInput>>;
  update?: InputMaybe<Array<AuthorPublicationHistoryUpdateWithWhereUniqueWithoutAuthorInput>>;
  updateMany?: InputMaybe<Array<AuthorPublicationHistoryUpdateManyWithWhereWithoutAuthorInput>>;
  upsert?: InputMaybe<Array<AuthorPublicationHistoryUpsertWithWhereUniqueWithoutAuthorInput>>;
};

export type AuthorPublicationHistoryUpdateManyWithoutPublicationNestedInput = {
  connect?: InputMaybe<Array<AuthorPublicationHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AuthorPublicationHistoryCreateOrConnectWithoutPublicationInput>>;
  create?: InputMaybe<Array<AuthorPublicationHistoryCreateWithoutPublicationInput>>;
  createMany?: InputMaybe<AuthorPublicationHistoryCreateManyPublicationInputEnvelope>;
  delete?: InputMaybe<Array<AuthorPublicationHistoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AuthorPublicationHistoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AuthorPublicationHistoryWhereUniqueInput>>;
  set?: InputMaybe<Array<AuthorPublicationHistoryWhereUniqueInput>>;
  update?: InputMaybe<Array<AuthorPublicationHistoryUpdateWithWhereUniqueWithoutPublicationInput>>;
  updateMany?: InputMaybe<Array<AuthorPublicationHistoryUpdateManyWithWhereWithoutPublicationInput>>;
  upsert?: InputMaybe<Array<AuthorPublicationHistoryUpsertWithWhereUniqueWithoutPublicationInput>>;
};

export type AuthorPublicationHistoryUpdateOneRequiredWithoutLocationNestedInput = {
  connect?: InputMaybe<AuthorPublicationHistoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AuthorPublicationHistoryCreateOrConnectWithoutLocationInput>;
  create?: InputMaybe<AuthorPublicationHistoryCreateWithoutLocationInput>;
  update?: InputMaybe<AuthorPublicationHistoryUpdateToOneWithWhereWithoutLocationInput>;
  upsert?: InputMaybe<AuthorPublicationHistoryUpsertWithoutLocationInput>;
};

export type AuthorPublicationHistoryUpdateToOneWithWhereWithoutLocationInput = {
  data: AuthorPublicationHistoryUpdateWithoutLocationInput;
  where?: InputMaybe<AuthorPublicationHistoryWhereInput>;
};

export type AuthorPublicationHistoryUpdateWithWhereUniqueWithoutAuthorInput = {
  data: AuthorPublicationHistoryUpdateWithoutAuthorInput;
  where: AuthorPublicationHistoryWhereUniqueInput;
};

export type AuthorPublicationHistoryUpdateWithWhereUniqueWithoutPublicationInput = {
  data: AuthorPublicationHistoryUpdateWithoutPublicationInput;
  where: AuthorPublicationHistoryWhereUniqueInput;
};

export type AuthorPublicationHistoryUpdateWithoutAuthorInput = {
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  location?: InputMaybe<AuthorLocationUpdateOneWithoutAuthorNestedInput>;
  publication?: InputMaybe<PublicationUpdateOneRequiredWithoutAuthorsNestedInput>;
  role?: InputMaybe<StringFieldUpdateOperationsInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type AuthorPublicationHistoryUpdateWithoutLocationInput = {
  author?: InputMaybe<AuthorUpdateOneRequiredWithoutPublicationHistoryNestedInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  publication?: InputMaybe<PublicationUpdateOneRequiredWithoutAuthorsNestedInput>;
  role?: InputMaybe<StringFieldUpdateOperationsInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type AuthorPublicationHistoryUpdateWithoutPublicationInput = {
  author?: InputMaybe<AuthorUpdateOneRequiredWithoutPublicationHistoryNestedInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  location?: InputMaybe<AuthorLocationUpdateOneWithoutAuthorNestedInput>;
  role?: InputMaybe<StringFieldUpdateOperationsInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type AuthorPublicationHistoryUpsertWithWhereUniqueWithoutAuthorInput = {
  create: AuthorPublicationHistoryCreateWithoutAuthorInput;
  update: AuthorPublicationHistoryUpdateWithoutAuthorInput;
  where: AuthorPublicationHistoryWhereUniqueInput;
};

export type AuthorPublicationHistoryUpsertWithWhereUniqueWithoutPublicationInput = {
  create: AuthorPublicationHistoryCreateWithoutPublicationInput;
  update: AuthorPublicationHistoryUpdateWithoutPublicationInput;
  where: AuthorPublicationHistoryWhereUniqueInput;
};

export type AuthorPublicationHistoryUpsertWithoutLocationInput = {
  create: AuthorPublicationHistoryCreateWithoutLocationInput;
  update: AuthorPublicationHistoryUpdateWithoutLocationInput;
  where?: InputMaybe<AuthorPublicationHistoryWhereInput>;
};

export type AuthorPublicationHistoryWhereInput = {
  AND?: InputMaybe<Array<AuthorPublicationHistoryWhereInput>>;
  NOT?: InputMaybe<Array<AuthorPublicationHistoryWhereInput>>;
  OR?: InputMaybe<Array<AuthorPublicationHistoryWhereInput>>;
  author?: InputMaybe<AuthorRelationFilter>;
  authorId?: InputMaybe<StringFilter>;
  endDate?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  location?: InputMaybe<AuthorLocationNullableRelationFilter>;
  publication?: InputMaybe<PublicationRelationFilter>;
  publicationId?: InputMaybe<StringFilter>;
  role?: InputMaybe<StringFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  url?: InputMaybe<StringNullableFilter>;
};

export type AuthorPublicationHistoryWhereUniqueInput = {
  AND?: InputMaybe<Array<AuthorPublicationHistoryWhereInput>>;
  NOT?: InputMaybe<Array<AuthorPublicationHistoryWhereInput>>;
  OR?: InputMaybe<Array<AuthorPublicationHistoryWhereInput>>;
  author?: InputMaybe<AuthorRelationFilter>;
  authorId?: InputMaybe<StringFilter>;
  authorId_publicationId?: InputMaybe<AuthorPublicationHistoryAuthorIdPublicationIdCompoundUniqueInput>;
  endDate?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<AuthorLocationNullableRelationFilter>;
  publication?: InputMaybe<PublicationRelationFilter>;
  publicationId?: InputMaybe<StringFilter>;
  role?: InputMaybe<StringFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  url?: InputMaybe<StringNullableFilter>;
};

export type AuthorRelationFilter = {
  is?: InputMaybe<AuthorWhereInput>;
  isNot?: InputMaybe<AuthorWhereInput>;
};

export enum AuthorScalarFieldEnum {
  Bio = 'bio',
  Id = 'id',
  ImageUrl = 'imageUrl',
  Name = 'name'
}

export type AuthorScalarWhereInput = {
  AND?: InputMaybe<Array<AuthorScalarWhereInput>>;
  NOT?: InputMaybe<Array<AuthorScalarWhereInput>>;
  OR?: InputMaybe<Array<AuthorScalarWhereInput>>;
  bio?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  imageUrl?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
};

export type AuthorUpdateInput = {
  articles?: InputMaybe<ArticleUpdateManyWithoutAuthorsNestedInput>;
  bio?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  publicationHistory?: InputMaybe<AuthorPublicationHistoryUpdateManyWithoutAuthorNestedInput>;
};

export type AuthorUpdateManyMutationInput = {
  bio?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type AuthorUpdateManyWithWhereWithoutArticlesInput = {
  data: AuthorUpdateManyMutationInput;
  where: AuthorScalarWhereInput;
};

export type AuthorUpdateManyWithoutArticlesNestedInput = {
  connect?: InputMaybe<Array<AuthorWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AuthorCreateOrConnectWithoutArticlesInput>>;
  create?: InputMaybe<Array<AuthorCreateWithoutArticlesInput>>;
  delete?: InputMaybe<Array<AuthorWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AuthorScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AuthorWhereUniqueInput>>;
  set?: InputMaybe<Array<AuthorWhereUniqueInput>>;
  update?: InputMaybe<Array<AuthorUpdateWithWhereUniqueWithoutArticlesInput>>;
  updateMany?: InputMaybe<Array<AuthorUpdateManyWithWhereWithoutArticlesInput>>;
  upsert?: InputMaybe<Array<AuthorUpsertWithWhereUniqueWithoutArticlesInput>>;
};

export type AuthorUpdateOneRequiredWithoutPublicationHistoryNestedInput = {
  connect?: InputMaybe<AuthorWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AuthorCreateOrConnectWithoutPublicationHistoryInput>;
  create?: InputMaybe<AuthorCreateWithoutPublicationHistoryInput>;
  update?: InputMaybe<AuthorUpdateToOneWithWhereWithoutPublicationHistoryInput>;
  upsert?: InputMaybe<AuthorUpsertWithoutPublicationHistoryInput>;
};

export type AuthorUpdateToOneWithWhereWithoutPublicationHistoryInput = {
  data: AuthorUpdateWithoutPublicationHistoryInput;
  where?: InputMaybe<AuthorWhereInput>;
};

export type AuthorUpdateWithWhereUniqueWithoutArticlesInput = {
  data: AuthorUpdateWithoutArticlesInput;
  where: AuthorWhereUniqueInput;
};

export type AuthorUpdateWithoutArticlesInput = {
  bio?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  publicationHistory?: InputMaybe<AuthorPublicationHistoryUpdateManyWithoutAuthorNestedInput>;
};

export type AuthorUpdateWithoutPublicationHistoryInput = {
  articles?: InputMaybe<ArticleUpdateManyWithoutAuthorsNestedInput>;
  bio?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type AuthorUpsertWithWhereUniqueWithoutArticlesInput = {
  create: AuthorCreateWithoutArticlesInput;
  update: AuthorUpdateWithoutArticlesInput;
  where: AuthorWhereUniqueInput;
};

export type AuthorUpsertWithoutPublicationHistoryInput = {
  create: AuthorCreateWithoutPublicationHistoryInput;
  update: AuthorUpdateWithoutPublicationHistoryInput;
  where?: InputMaybe<AuthorWhereInput>;
};

export type AuthorWhereInput = {
  AND?: InputMaybe<Array<AuthorWhereInput>>;
  NOT?: InputMaybe<Array<AuthorWhereInput>>;
  OR?: InputMaybe<Array<AuthorWhereInput>>;
  articles?: InputMaybe<ArticleListRelationFilter>;
  bio?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  imageUrl?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  publicationHistory?: InputMaybe<AuthorPublicationHistoryListRelationFilter>;
};

export type AuthorWhereUniqueInput = {
  AND?: InputMaybe<Array<AuthorWhereInput>>;
  NOT?: InputMaybe<Array<AuthorWhereInput>>;
  OR?: InputMaybe<Array<AuthorWhereInput>>;
  articles?: InputMaybe<ArticleListRelationFilter>;
  bio?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  publicationHistory?: InputMaybe<AuthorPublicationHistoryListRelationFilter>;
};

export type BigIntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['BigInt']['input']>;
  divide?: InputMaybe<Scalars['BigInt']['input']>;
  increment?: InputMaybe<Scalars['BigInt']['input']>;
  multiply?: InputMaybe<Scalars['BigInt']['input']>;
  set?: InputMaybe<Scalars['BigInt']['input']>;
};

export type BigIntFilter = {
  equals?: InputMaybe<Scalars['BigInt']['input']>;
  gt?: InputMaybe<Scalars['BigInt']['input']>;
  gte?: InputMaybe<Scalars['BigInt']['input']>;
  in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  lt?: InputMaybe<Scalars['BigInt']['input']>;
  lte?: InputMaybe<Scalars['BigInt']['input']>;
  not?: InputMaybe<NestedBigIntFilter>;
  notIn?: InputMaybe<Array<Scalars['BigInt']['input']>>;
};

export type BoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type BulkUploadCoveragePieceInput = {
  author?: InputMaybe<Scalars['String']['input']>;
  category: CoveragePieceCreatecategoryInput;
  clientId: Scalars['String']['input'];
  date?: InputMaybe<Scalars['DateTime']['input']>;
  extract?: InputMaybe<Scalars['String']['input']>;
  publication?: InputMaybe<Scalars['String']['input']>;
  spokespeople?: InputMaybe<Array<Scalars['String']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
  type: CoverageType;
  url: Scalars['String']['input'];
};

export type BulkUploadFailure = {
  __typename?: 'BulkUploadFailure';
  error: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type BulkUploadResult = {
  __typename?: 'BulkUploadResult';
  failure: Array<BulkUploadFailure>;
  success: Array<Scalars['String']['output']>;
};

export type Client = {
  __typename?: 'Client';
  _count?: Maybe<ClientCount>;
  activities: Array<Activity>;
  agencyManagers: Array<User>;
  alternateNames: Array<Scalars['String']['output']>;
  boilerplate: Scalars['String']['output'];
  clientComments: Array<ClientComment>;
  competitors: Array<Client>;
  content: Array<Content>;
  coverageLastSentAt?: Maybe<Scalars['DateTime']['output']>;
  coveragePieces: Array<CoveragePiece>;
  employees: Array<User>;
  entitlements: Scalars['String']['output'];
  files: Array<FileMetadata>;
  id: Scalars['String']['output'];
  ignoredSuggestedCoverage: Array<IgnoredSuggestedCoverage>;
  isCompetitor: Scalars['Boolean']['output'];
  isPublic: Scalars['Boolean']['output'];
  location: Location;
  locationId: Scalars['String']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  meetingNotes: Array<MeetingNotes>;
  name: Scalars['String']['output'];
  nameUniqueness: NameUniqueness;
  notes: Scalars['String']['output'];
  programPlannerItems: Array<ProgramPlannerItem>;
  spokespeople: Array<Spokesperson>;
  status: ClientStatus;
  tags: Array<ClientTag>;
  workspace: Workspace;
  workspaceId: Scalars['String']['output'];
};


export type ClientActivitiesArgs = {
  cursor?: InputMaybe<ActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActivityOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ActivityWhereInput>;
};


export type ClientAgencyManagersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};


export type ClientClientCommentsArgs = {
  cursor?: InputMaybe<ClientCommentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ClientCommentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ClientCommentOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ClientCommentWhereInput>;
};


export type ClientContentArgs = {
  cursor?: InputMaybe<ContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ContentOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContentWhereInput>;
};


export type ClientCoveragePiecesArgs = {
  cursor?: InputMaybe<CoveragePieceWhereUniqueInput>;
  distinct?: InputMaybe<Array<CoveragePieceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CoveragePieceOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CoveragePieceWhereInput>;
};


export type ClientEmployeesArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};


export type ClientFilesArgs = {
  cursor?: InputMaybe<FileMetadataWhereUniqueInput>;
  distinct?: InputMaybe<Array<FileMetadataScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FileMetadataOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FileMetadataWhereInput>;
};


export type ClientIgnoredSuggestedCoverageArgs = {
  cursor?: InputMaybe<IgnoredSuggestedCoverageWhereUniqueInput>;
  distinct?: InputMaybe<Array<IgnoredSuggestedCoverageScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<IgnoredSuggestedCoverageOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IgnoredSuggestedCoverageWhereInput>;
};


export type ClientMeetingNotesArgs = {
  cursor?: InputMaybe<MeetingNotesWhereUniqueInput>;
  distinct?: InputMaybe<Array<MeetingNotesScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MeetingNotesOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MeetingNotesWhereInput>;
};


export type ClientProgramPlannerItemsArgs = {
  cursor?: InputMaybe<ProgramPlannerItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProgramPlannerItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProgramPlannerItemOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProgramPlannerItemWhereInput>;
};


export type ClientSpokespeopleArgs = {
  cursor?: InputMaybe<SpokespersonWhereUniqueInput>;
  distinct?: InputMaybe<Array<SpokespersonScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SpokespersonOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SpokespersonWhereInput>;
};


export type ClientTagsArgs = {
  cursor?: InputMaybe<ClientTagWhereUniqueInput>;
  distinct?: InputMaybe<Array<ClientTagScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ClientTagOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ClientTagWhereInput>;
};

export type ClientComment = {
  __typename?: 'ClientComment';
  _count?: Maybe<ClientCommentCount>;
  client: Client;
  clientId: Scalars['String']['output'];
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  referencedUsers: Array<User>;
  tags: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  user: User;
  userId: Scalars['String']['output'];
};


export type ClientCommentReferencedUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};

export type ClientCommentCount = {
  __typename?: 'ClientCommentCount';
  referencedUsers: Scalars['Int']['output'];
};


export type ClientCommentCountReferencedUsersArgs = {
  where?: InputMaybe<UserWhereInput>;
};

export type ClientCommentCreateManyClientInput = {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<ClientCommentCreatetagsInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type ClientCommentCreateManyClientInputEnvelope = {
  data: Array<ClientCommentCreateManyClientInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ClientCommentCreateManyUserInput = {
  clientId: Scalars['String']['input'];
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<ClientCommentCreatetagsInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ClientCommentCreateManyUserInputEnvelope = {
  data: Array<ClientCommentCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ClientCommentCreateNestedManyWithoutClientInput = {
  connect?: InputMaybe<Array<ClientCommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClientCommentCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<ClientCommentCreateWithoutClientInput>>;
  createMany?: InputMaybe<ClientCommentCreateManyClientInputEnvelope>;
};

export type ClientCommentCreateNestedManyWithoutReferencedUsersInput = {
  connect?: InputMaybe<Array<ClientCommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClientCommentCreateOrConnectWithoutReferencedUsersInput>>;
  create?: InputMaybe<Array<ClientCommentCreateWithoutReferencedUsersInput>>;
};

export type ClientCommentCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<ClientCommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClientCommentCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<ClientCommentCreateWithoutUserInput>>;
  createMany?: InputMaybe<ClientCommentCreateManyUserInputEnvelope>;
};

export type ClientCommentCreateOrConnectWithoutClientInput = {
  create: ClientCommentCreateWithoutClientInput;
  where: ClientCommentWhereUniqueInput;
};

export type ClientCommentCreateOrConnectWithoutReferencedUsersInput = {
  create: ClientCommentCreateWithoutReferencedUsersInput;
  where: ClientCommentWhereUniqueInput;
};

export type ClientCommentCreateOrConnectWithoutUserInput = {
  create: ClientCommentCreateWithoutUserInput;
  where: ClientCommentWhereUniqueInput;
};

export type ClientCommentCreateWithoutClientInput = {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  referencedUsers?: InputMaybe<UserCreateNestedManyWithoutReferencedInClientCommentsInput>;
  tags?: InputMaybe<ClientCommentCreatetagsInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  user: UserCreateNestedOneWithoutClientCommentsInput;
};

export type ClientCommentCreateWithoutReferencedUsersInput = {
  client: ClientCreateNestedOneWithoutClientCommentsInput;
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<ClientCommentCreatetagsInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  user: UserCreateNestedOneWithoutClientCommentsInput;
};

export type ClientCommentCreateWithoutUserInput = {
  client: ClientCreateNestedOneWithoutClientCommentsInput;
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  referencedUsers?: InputMaybe<UserCreateNestedManyWithoutReferencedInClientCommentsInput>;
  tags?: InputMaybe<ClientCommentCreatetagsInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ClientCommentCreatetagsInput = {
  set: Array<Scalars['String']['input']>;
};

export type ClientCommentListRelationFilter = {
  every?: InputMaybe<ClientCommentWhereInput>;
  none?: InputMaybe<ClientCommentWhereInput>;
  some?: InputMaybe<ClientCommentWhereInput>;
};

export type ClientCommentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum ClientCommentOrderByRelevanceFieldEnum {
  ClientId = 'clientId',
  Content = 'content',
  Id = 'id',
  Tags = 'tags',
  Title = 'title',
  UserId = 'userId'
}

export type ClientCommentOrderByRelevanceInput = {
  fields: Array<ClientCommentOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type ClientCommentOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<ClientCommentOrderByRelevanceInput>;
  client?: InputMaybe<ClientOrderByWithRelationAndSearchRelevanceInput>;
  clientId?: InputMaybe<SortOrder>;
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  referencedUsers?: InputMaybe<UserOrderByRelationAggregateInput>;
  tags?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum ClientCommentScalarFieldEnum {
  ClientId = 'clientId',
  Content = 'content',
  CreatedAt = 'createdAt',
  Id = 'id',
  Tags = 'tags',
  Title = 'title',
  UserId = 'userId'
}

export type ClientCommentScalarWhereInput = {
  AND?: InputMaybe<Array<ClientCommentScalarWhereInput>>;
  NOT?: InputMaybe<Array<ClientCommentScalarWhereInput>>;
  OR?: InputMaybe<Array<ClientCommentScalarWhereInput>>;
  clientId?: InputMaybe<StringFilter>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  tags?: InputMaybe<StringNullableListFilter>;
  title?: InputMaybe<StringFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type ClientCommentUpdateManyMutationInput = {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  tags?: InputMaybe<ClientCommentUpdatetagsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ClientCommentUpdateManyWithWhereWithoutClientInput = {
  data: ClientCommentUpdateManyMutationInput;
  where: ClientCommentScalarWhereInput;
};

export type ClientCommentUpdateManyWithWhereWithoutReferencedUsersInput = {
  data: ClientCommentUpdateManyMutationInput;
  where: ClientCommentScalarWhereInput;
};

export type ClientCommentUpdateManyWithWhereWithoutUserInput = {
  data: ClientCommentUpdateManyMutationInput;
  where: ClientCommentScalarWhereInput;
};

export type ClientCommentUpdateManyWithoutClientNestedInput = {
  connect?: InputMaybe<Array<ClientCommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClientCommentCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<ClientCommentCreateWithoutClientInput>>;
  createMany?: InputMaybe<ClientCommentCreateManyClientInputEnvelope>;
  delete?: InputMaybe<Array<ClientCommentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ClientCommentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ClientCommentWhereUniqueInput>>;
  set?: InputMaybe<Array<ClientCommentWhereUniqueInput>>;
  update?: InputMaybe<Array<ClientCommentUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: InputMaybe<Array<ClientCommentUpdateManyWithWhereWithoutClientInput>>;
  upsert?: InputMaybe<Array<ClientCommentUpsertWithWhereUniqueWithoutClientInput>>;
};

export type ClientCommentUpdateManyWithoutReferencedUsersNestedInput = {
  connect?: InputMaybe<Array<ClientCommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClientCommentCreateOrConnectWithoutReferencedUsersInput>>;
  create?: InputMaybe<Array<ClientCommentCreateWithoutReferencedUsersInput>>;
  delete?: InputMaybe<Array<ClientCommentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ClientCommentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ClientCommentWhereUniqueInput>>;
  set?: InputMaybe<Array<ClientCommentWhereUniqueInput>>;
  update?: InputMaybe<Array<ClientCommentUpdateWithWhereUniqueWithoutReferencedUsersInput>>;
  updateMany?: InputMaybe<Array<ClientCommentUpdateManyWithWhereWithoutReferencedUsersInput>>;
  upsert?: InputMaybe<Array<ClientCommentUpsertWithWhereUniqueWithoutReferencedUsersInput>>;
};

export type ClientCommentUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<ClientCommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClientCommentCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<ClientCommentCreateWithoutUserInput>>;
  createMany?: InputMaybe<ClientCommentCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<ClientCommentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ClientCommentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ClientCommentWhereUniqueInput>>;
  set?: InputMaybe<Array<ClientCommentWhereUniqueInput>>;
  update?: InputMaybe<Array<ClientCommentUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<ClientCommentUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<ClientCommentUpsertWithWhereUniqueWithoutUserInput>>;
};

export type ClientCommentUpdateWithWhereUniqueWithoutClientInput = {
  data: ClientCommentUpdateWithoutClientInput;
  where: ClientCommentWhereUniqueInput;
};

export type ClientCommentUpdateWithWhereUniqueWithoutReferencedUsersInput = {
  data: ClientCommentUpdateWithoutReferencedUsersInput;
  where: ClientCommentWhereUniqueInput;
};

export type ClientCommentUpdateWithWhereUniqueWithoutUserInput = {
  data: ClientCommentUpdateWithoutUserInput;
  where: ClientCommentWhereUniqueInput;
};

export type ClientCommentUpdateWithoutClientInput = {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  referencedUsers?: InputMaybe<UserUpdateManyWithoutReferencedInClientCommentsNestedInput>;
  tags?: InputMaybe<ClientCommentUpdatetagsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutClientCommentsNestedInput>;
};

export type ClientCommentUpdateWithoutReferencedUsersInput = {
  client?: InputMaybe<ClientUpdateOneRequiredWithoutClientCommentsNestedInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  tags?: InputMaybe<ClientCommentUpdatetagsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutClientCommentsNestedInput>;
};

export type ClientCommentUpdateWithoutUserInput = {
  client?: InputMaybe<ClientUpdateOneRequiredWithoutClientCommentsNestedInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  referencedUsers?: InputMaybe<UserUpdateManyWithoutReferencedInClientCommentsNestedInput>;
  tags?: InputMaybe<ClientCommentUpdatetagsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ClientCommentUpdatetagsInput = {
  push?: InputMaybe<Array<Scalars['String']['input']>>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ClientCommentUpsertWithWhereUniqueWithoutClientInput = {
  create: ClientCommentCreateWithoutClientInput;
  update: ClientCommentUpdateWithoutClientInput;
  where: ClientCommentWhereUniqueInput;
};

export type ClientCommentUpsertWithWhereUniqueWithoutReferencedUsersInput = {
  create: ClientCommentCreateWithoutReferencedUsersInput;
  update: ClientCommentUpdateWithoutReferencedUsersInput;
  where: ClientCommentWhereUniqueInput;
};

export type ClientCommentUpsertWithWhereUniqueWithoutUserInput = {
  create: ClientCommentCreateWithoutUserInput;
  update: ClientCommentUpdateWithoutUserInput;
  where: ClientCommentWhereUniqueInput;
};

export type ClientCommentWhereInput = {
  AND?: InputMaybe<Array<ClientCommentWhereInput>>;
  NOT?: InputMaybe<Array<ClientCommentWhereInput>>;
  OR?: InputMaybe<Array<ClientCommentWhereInput>>;
  client?: InputMaybe<ClientRelationFilter>;
  clientId?: InputMaybe<StringFilter>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  referencedUsers?: InputMaybe<UserListRelationFilter>;
  tags?: InputMaybe<StringNullableListFilter>;
  title?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type ClientCommentWhereUniqueInput = {
  AND?: InputMaybe<Array<ClientCommentWhereInput>>;
  NOT?: InputMaybe<Array<ClientCommentWhereInput>>;
  OR?: InputMaybe<Array<ClientCommentWhereInput>>;
  client?: InputMaybe<ClientRelationFilter>;
  clientId?: InputMaybe<StringFilter>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  referencedUsers?: InputMaybe<UserListRelationFilter>;
  tags?: InputMaybe<StringNullableListFilter>;
  title?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type ClientCount = {
  __typename?: 'ClientCount';
  activities: Scalars['Int']['output'];
  agencyManagers: Scalars['Int']['output'];
  clientComments: Scalars['Int']['output'];
  competitorOf: Scalars['Int']['output'];
  competitors: Scalars['Int']['output'];
  content: Scalars['Int']['output'];
  coveragePieces: Scalars['Int']['output'];
  employees: Scalars['Int']['output'];
  files: Scalars['Int']['output'];
  ignoredSuggestedCoverage: Scalars['Int']['output'];
  meetingNotes: Scalars['Int']['output'];
  mentions: Scalars['Int']['output'];
  newsJackItems: Scalars['Int']['output'];
  programPlannerItems: Scalars['Int']['output'];
  receivedNewsWraps: Scalars['Int']['output'];
  spokespeople: Scalars['Int']['output'];
  tags: Scalars['Int']['output'];
};


export type ClientCountActivitiesArgs = {
  where?: InputMaybe<ActivityWhereInput>;
};


export type ClientCountAgencyManagersArgs = {
  where?: InputMaybe<UserWhereInput>;
};


export type ClientCountClientCommentsArgs = {
  where?: InputMaybe<ClientCommentWhereInput>;
};


export type ClientCountCompetitorOfArgs = {
  where?: InputMaybe<ClientWhereInput>;
};


export type ClientCountCompetitorsArgs = {
  where?: InputMaybe<ClientWhereInput>;
};


export type ClientCountContentArgs = {
  where?: InputMaybe<ContentWhereInput>;
};


export type ClientCountCoveragePiecesArgs = {
  where?: InputMaybe<CoveragePieceWhereInput>;
};


export type ClientCountEmployeesArgs = {
  where?: InputMaybe<UserWhereInput>;
};


export type ClientCountFilesArgs = {
  where?: InputMaybe<FileMetadataWhereInput>;
};


export type ClientCountIgnoredSuggestedCoverageArgs = {
  where?: InputMaybe<IgnoredSuggestedCoverageWhereInput>;
};


export type ClientCountMeetingNotesArgs = {
  where?: InputMaybe<MeetingNotesWhereInput>;
};


export type ClientCountMentionsArgs = {
  where?: InputMaybe<ClientMentionWhereInput>;
};


export type ClientCountNewsJackItemsArgs = {
  where?: InputMaybe<NewsJackItemWhereInput>;
};


export type ClientCountProgramPlannerItemsArgs = {
  where?: InputMaybe<ProgramPlannerItemWhereInput>;
};


export type ClientCountReceivedNewsWrapsArgs = {
  where?: InputMaybe<NewsWrapWhereInput>;
};


export type ClientCountSpokespeopleArgs = {
  where?: InputMaybe<SpokespersonWhereInput>;
};


export type ClientCountTagsArgs = {
  where?: InputMaybe<ClientTagWhereInput>;
};

export type ClientCreateInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutClientInput>;
  agencyManagers?: InputMaybe<UserCreateNestedManyWithoutManagedClientsInput>;
  alternateNames?: InputMaybe<ClientCreatealternateNamesInput>;
  boilerplate?: InputMaybe<Scalars['String']['input']>;
  clientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutClientInput>;
  competitorOf?: InputMaybe<ClientCreateNestedManyWithoutCompetitorsInput>;
  competitors?: InputMaybe<ClientCreateNestedManyWithoutCompetitorOfInput>;
  content?: InputMaybe<ContentCreateNestedManyWithoutClientInput>;
  coverageLastSentAt?: InputMaybe<Scalars['DateTime']['input']>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutClientsInput>;
  employees?: InputMaybe<UserCreateNestedManyWithoutClientEmployerInput>;
  entitlements?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<FileMetadataCreateNestedManyWithoutClientInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageCreateNestedManyWithoutClientInput>;
  isCompetitor?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  location: LocationCreateNestedOneWithoutClientsInput;
  logo?: InputMaybe<Scalars['String']['input']>;
  meetingNotes?: InputMaybe<MeetingNotesCreateNestedManyWithoutClientInput>;
  mentions?: InputMaybe<ClientMentionCreateNestedManyWithoutClientInput>;
  name: Scalars['String']['input'];
  nameUniqueness?: InputMaybe<NameUniqueness>;
  newsJackItems?: InputMaybe<NewsJackItemCreateNestedManyWithoutClientInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  programPlannerItems?: InputMaybe<ProgramPlannerItemCreateNestedManyWithoutClientInput>;
  receivedNewsWraps?: InputMaybe<NewsWrapCreateNestedManyWithoutSentToInput>;
  spokespeople?: InputMaybe<SpokespersonCreateNestedManyWithoutClientInput>;
  status: ClientStatus;
  tags?: InputMaybe<ClientTagCreateNestedManyWithoutClientInput>;
};

export type ClientCreateManyLocationInput = {
  alternateNames?: InputMaybe<ClientCreatealternateNamesInput>;
  boilerplate?: InputMaybe<Scalars['String']['input']>;
  coverageLastSentAt?: InputMaybe<Scalars['DateTime']['input']>;
  entitlements?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isCompetitor?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  nameUniqueness?: InputMaybe<NameUniqueness>;
  notes?: InputMaybe<Scalars['String']['input']>;
  status: ClientStatus;
  workspaceId: Scalars['String']['input'];
};

export type ClientCreateManyLocationInputEnvelope = {
  data: Array<ClientCreateManyLocationInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ClientCreateManyWorkspaceInput = {
  alternateNames?: InputMaybe<ClientCreatealternateNamesInput>;
  boilerplate?: InputMaybe<Scalars['String']['input']>;
  coverageLastSentAt?: InputMaybe<Scalars['DateTime']['input']>;
  entitlements?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isCompetitor?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  locationId: Scalars['String']['input'];
  logo?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  nameUniqueness?: InputMaybe<NameUniqueness>;
  notes?: InputMaybe<Scalars['String']['input']>;
  status: ClientStatus;
};

export type ClientCreateManyWorkspaceInputEnvelope = {
  data: Array<ClientCreateManyWorkspaceInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ClientCreateNestedManyWithoutAgencyManagersInput = {
  connect?: InputMaybe<Array<ClientWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClientCreateOrConnectWithoutAgencyManagersInput>>;
  create?: InputMaybe<Array<ClientCreateWithoutAgencyManagersInput>>;
};

export type ClientCreateNestedManyWithoutCompetitorOfInput = {
  connect?: InputMaybe<Array<ClientWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClientCreateOrConnectWithoutCompetitorOfInput>>;
  create?: InputMaybe<Array<ClientCreateWithoutCompetitorOfInput>>;
};

export type ClientCreateNestedManyWithoutCompetitorsInput = {
  connect?: InputMaybe<Array<ClientWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClientCreateOrConnectWithoutCompetitorsInput>>;
  create?: InputMaybe<Array<ClientCreateWithoutCompetitorsInput>>;
};

export type ClientCreateNestedManyWithoutCoveragePiecesInput = {
  connect?: InputMaybe<Array<ClientWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClientCreateOrConnectWithoutCoveragePiecesInput>>;
  create?: InputMaybe<Array<ClientCreateWithoutCoveragePiecesInput>>;
};

export type ClientCreateNestedManyWithoutReceivedNewsWrapsInput = {
  connect?: InputMaybe<Array<ClientWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClientCreateOrConnectWithoutReceivedNewsWrapsInput>>;
  create?: InputMaybe<Array<ClientCreateWithoutReceivedNewsWrapsInput>>;
};

export type ClientCreateNestedManyWithoutWorkspaceInput = {
  connect?: InputMaybe<Array<ClientWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClientCreateOrConnectWithoutWorkspaceInput>>;
  create?: InputMaybe<Array<ClientCreateWithoutWorkspaceInput>>;
  createMany?: InputMaybe<ClientCreateManyWorkspaceInputEnvelope>;
};

export type ClientCreateNestedOneWithoutActivitiesInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutActivitiesInput>;
  create?: InputMaybe<ClientCreateWithoutActivitiesInput>;
};

export type ClientCreateNestedOneWithoutClientCommentsInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutClientCommentsInput>;
  create?: InputMaybe<ClientCreateWithoutClientCommentsInput>;
};

export type ClientCreateNestedOneWithoutContentInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutContentInput>;
  create?: InputMaybe<ClientCreateWithoutContentInput>;
};

export type ClientCreateNestedOneWithoutEmployeesInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutEmployeesInput>;
  create?: InputMaybe<ClientCreateWithoutEmployeesInput>;
};

export type ClientCreateNestedOneWithoutFilesInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutFilesInput>;
  create?: InputMaybe<ClientCreateWithoutFilesInput>;
};

export type ClientCreateNestedOneWithoutIgnoredSuggestedCoverageInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutIgnoredSuggestedCoverageInput>;
  create?: InputMaybe<ClientCreateWithoutIgnoredSuggestedCoverageInput>;
};

export type ClientCreateNestedOneWithoutMeetingNotesInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutMeetingNotesInput>;
  create?: InputMaybe<ClientCreateWithoutMeetingNotesInput>;
};

export type ClientCreateNestedOneWithoutMentionsInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutMentionsInput>;
  create?: InputMaybe<ClientCreateWithoutMentionsInput>;
};

export type ClientCreateNestedOneWithoutNewsJackItemsInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutNewsJackItemsInput>;
  create?: InputMaybe<ClientCreateWithoutNewsJackItemsInput>;
};

export type ClientCreateNestedOneWithoutProgramPlannerItemsInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutProgramPlannerItemsInput>;
  create?: InputMaybe<ClientCreateWithoutProgramPlannerItemsInput>;
};

export type ClientCreateNestedOneWithoutSpokespeopleInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutSpokespeopleInput>;
  create?: InputMaybe<ClientCreateWithoutSpokespeopleInput>;
};

export type ClientCreateOrConnectWithoutActivitiesInput = {
  create: ClientCreateWithoutActivitiesInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutAgencyManagersInput = {
  create: ClientCreateWithoutAgencyManagersInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutClientCommentsInput = {
  create: ClientCreateWithoutClientCommentsInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutCompetitorOfInput = {
  create: ClientCreateWithoutCompetitorOfInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutCompetitorsInput = {
  create: ClientCreateWithoutCompetitorsInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutContentInput = {
  create: ClientCreateWithoutContentInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutCoveragePiecesInput = {
  create: ClientCreateWithoutCoveragePiecesInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutEmployeesInput = {
  create: ClientCreateWithoutEmployeesInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutFilesInput = {
  create: ClientCreateWithoutFilesInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutIgnoredSuggestedCoverageInput = {
  create: ClientCreateWithoutIgnoredSuggestedCoverageInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutLocationInput = {
  create: ClientCreateWithoutLocationInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutMeetingNotesInput = {
  create: ClientCreateWithoutMeetingNotesInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutMentionsInput = {
  create: ClientCreateWithoutMentionsInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutNewsJackItemsInput = {
  create: ClientCreateWithoutNewsJackItemsInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutProgramPlannerItemsInput = {
  create: ClientCreateWithoutProgramPlannerItemsInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutReceivedNewsWrapsInput = {
  create: ClientCreateWithoutReceivedNewsWrapsInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutSpokespeopleInput = {
  create: ClientCreateWithoutSpokespeopleInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutWorkspaceInput = {
  create: ClientCreateWithoutWorkspaceInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateWithoutActivitiesInput = {
  agencyManagers?: InputMaybe<UserCreateNestedManyWithoutManagedClientsInput>;
  alternateNames?: InputMaybe<ClientCreatealternateNamesInput>;
  boilerplate?: InputMaybe<Scalars['String']['input']>;
  clientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutClientInput>;
  competitorOf?: InputMaybe<ClientCreateNestedManyWithoutCompetitorsInput>;
  competitors?: InputMaybe<ClientCreateNestedManyWithoutCompetitorOfInput>;
  content?: InputMaybe<ContentCreateNestedManyWithoutClientInput>;
  coverageLastSentAt?: InputMaybe<Scalars['DateTime']['input']>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutClientsInput>;
  employees?: InputMaybe<UserCreateNestedManyWithoutClientEmployerInput>;
  entitlements?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<FileMetadataCreateNestedManyWithoutClientInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageCreateNestedManyWithoutClientInput>;
  isCompetitor?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  location: LocationCreateNestedOneWithoutClientsInput;
  logo?: InputMaybe<Scalars['String']['input']>;
  meetingNotes?: InputMaybe<MeetingNotesCreateNestedManyWithoutClientInput>;
  mentions?: InputMaybe<ClientMentionCreateNestedManyWithoutClientInput>;
  name: Scalars['String']['input'];
  nameUniqueness?: InputMaybe<NameUniqueness>;
  newsJackItems?: InputMaybe<NewsJackItemCreateNestedManyWithoutClientInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  programPlannerItems?: InputMaybe<ProgramPlannerItemCreateNestedManyWithoutClientInput>;
  receivedNewsWraps?: InputMaybe<NewsWrapCreateNestedManyWithoutSentToInput>;
  spokespeople?: InputMaybe<SpokespersonCreateNestedManyWithoutClientInput>;
  status: ClientStatus;
  tags?: InputMaybe<ClientTagCreateNestedManyWithoutClientInput>;
};

export type ClientCreateWithoutAgencyManagersInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutClientInput>;
  alternateNames?: InputMaybe<ClientCreatealternateNamesInput>;
  boilerplate?: InputMaybe<Scalars['String']['input']>;
  clientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutClientInput>;
  competitorOf?: InputMaybe<ClientCreateNestedManyWithoutCompetitorsInput>;
  competitors?: InputMaybe<ClientCreateNestedManyWithoutCompetitorOfInput>;
  content?: InputMaybe<ContentCreateNestedManyWithoutClientInput>;
  coverageLastSentAt?: InputMaybe<Scalars['DateTime']['input']>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutClientsInput>;
  employees?: InputMaybe<UserCreateNestedManyWithoutClientEmployerInput>;
  entitlements?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<FileMetadataCreateNestedManyWithoutClientInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageCreateNestedManyWithoutClientInput>;
  isCompetitor?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  location: LocationCreateNestedOneWithoutClientsInput;
  logo?: InputMaybe<Scalars['String']['input']>;
  meetingNotes?: InputMaybe<MeetingNotesCreateNestedManyWithoutClientInput>;
  mentions?: InputMaybe<ClientMentionCreateNestedManyWithoutClientInput>;
  name: Scalars['String']['input'];
  nameUniqueness?: InputMaybe<NameUniqueness>;
  newsJackItems?: InputMaybe<NewsJackItemCreateNestedManyWithoutClientInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  programPlannerItems?: InputMaybe<ProgramPlannerItemCreateNestedManyWithoutClientInput>;
  receivedNewsWraps?: InputMaybe<NewsWrapCreateNestedManyWithoutSentToInput>;
  spokespeople?: InputMaybe<SpokespersonCreateNestedManyWithoutClientInput>;
  status: ClientStatus;
  tags?: InputMaybe<ClientTagCreateNestedManyWithoutClientInput>;
};

export type ClientCreateWithoutClientCommentsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutClientInput>;
  agencyManagers?: InputMaybe<UserCreateNestedManyWithoutManagedClientsInput>;
  alternateNames?: InputMaybe<ClientCreatealternateNamesInput>;
  boilerplate?: InputMaybe<Scalars['String']['input']>;
  competitorOf?: InputMaybe<ClientCreateNestedManyWithoutCompetitorsInput>;
  competitors?: InputMaybe<ClientCreateNestedManyWithoutCompetitorOfInput>;
  content?: InputMaybe<ContentCreateNestedManyWithoutClientInput>;
  coverageLastSentAt?: InputMaybe<Scalars['DateTime']['input']>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutClientsInput>;
  employees?: InputMaybe<UserCreateNestedManyWithoutClientEmployerInput>;
  entitlements?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<FileMetadataCreateNestedManyWithoutClientInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageCreateNestedManyWithoutClientInput>;
  isCompetitor?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  location: LocationCreateNestedOneWithoutClientsInput;
  logo?: InputMaybe<Scalars['String']['input']>;
  meetingNotes?: InputMaybe<MeetingNotesCreateNestedManyWithoutClientInput>;
  mentions?: InputMaybe<ClientMentionCreateNestedManyWithoutClientInput>;
  name: Scalars['String']['input'];
  nameUniqueness?: InputMaybe<NameUniqueness>;
  newsJackItems?: InputMaybe<NewsJackItemCreateNestedManyWithoutClientInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  programPlannerItems?: InputMaybe<ProgramPlannerItemCreateNestedManyWithoutClientInput>;
  receivedNewsWraps?: InputMaybe<NewsWrapCreateNestedManyWithoutSentToInput>;
  spokespeople?: InputMaybe<SpokespersonCreateNestedManyWithoutClientInput>;
  status: ClientStatus;
  tags?: InputMaybe<ClientTagCreateNestedManyWithoutClientInput>;
};

export type ClientCreateWithoutCompetitorOfInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutClientInput>;
  agencyManagers?: InputMaybe<UserCreateNestedManyWithoutManagedClientsInput>;
  alternateNames?: InputMaybe<ClientCreatealternateNamesInput>;
  boilerplate?: InputMaybe<Scalars['String']['input']>;
  clientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutClientInput>;
  competitors?: InputMaybe<ClientCreateNestedManyWithoutCompetitorOfInput>;
  content?: InputMaybe<ContentCreateNestedManyWithoutClientInput>;
  coverageLastSentAt?: InputMaybe<Scalars['DateTime']['input']>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutClientsInput>;
  employees?: InputMaybe<UserCreateNestedManyWithoutClientEmployerInput>;
  entitlements?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<FileMetadataCreateNestedManyWithoutClientInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageCreateNestedManyWithoutClientInput>;
  isCompetitor?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  location: LocationCreateNestedOneWithoutClientsInput;
  logo?: InputMaybe<Scalars['String']['input']>;
  meetingNotes?: InputMaybe<MeetingNotesCreateNestedManyWithoutClientInput>;
  mentions?: InputMaybe<ClientMentionCreateNestedManyWithoutClientInput>;
  name: Scalars['String']['input'];
  nameUniqueness?: InputMaybe<NameUniqueness>;
  newsJackItems?: InputMaybe<NewsJackItemCreateNestedManyWithoutClientInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  programPlannerItems?: InputMaybe<ProgramPlannerItemCreateNestedManyWithoutClientInput>;
  receivedNewsWraps?: InputMaybe<NewsWrapCreateNestedManyWithoutSentToInput>;
  spokespeople?: InputMaybe<SpokespersonCreateNestedManyWithoutClientInput>;
  status: ClientStatus;
  tags?: InputMaybe<ClientTagCreateNestedManyWithoutClientInput>;
};

export type ClientCreateWithoutCompetitorsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutClientInput>;
  agencyManagers?: InputMaybe<UserCreateNestedManyWithoutManagedClientsInput>;
  alternateNames?: InputMaybe<ClientCreatealternateNamesInput>;
  boilerplate?: InputMaybe<Scalars['String']['input']>;
  clientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutClientInput>;
  competitorOf?: InputMaybe<ClientCreateNestedManyWithoutCompetitorsInput>;
  content?: InputMaybe<ContentCreateNestedManyWithoutClientInput>;
  coverageLastSentAt?: InputMaybe<Scalars['DateTime']['input']>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutClientsInput>;
  employees?: InputMaybe<UserCreateNestedManyWithoutClientEmployerInput>;
  entitlements?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<FileMetadataCreateNestedManyWithoutClientInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageCreateNestedManyWithoutClientInput>;
  isCompetitor?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  location: LocationCreateNestedOneWithoutClientsInput;
  logo?: InputMaybe<Scalars['String']['input']>;
  meetingNotes?: InputMaybe<MeetingNotesCreateNestedManyWithoutClientInput>;
  mentions?: InputMaybe<ClientMentionCreateNestedManyWithoutClientInput>;
  name: Scalars['String']['input'];
  nameUniqueness?: InputMaybe<NameUniqueness>;
  newsJackItems?: InputMaybe<NewsJackItemCreateNestedManyWithoutClientInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  programPlannerItems?: InputMaybe<ProgramPlannerItemCreateNestedManyWithoutClientInput>;
  receivedNewsWraps?: InputMaybe<NewsWrapCreateNestedManyWithoutSentToInput>;
  spokespeople?: InputMaybe<SpokespersonCreateNestedManyWithoutClientInput>;
  status: ClientStatus;
  tags?: InputMaybe<ClientTagCreateNestedManyWithoutClientInput>;
};

export type ClientCreateWithoutContentInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutClientInput>;
  agencyManagers?: InputMaybe<UserCreateNestedManyWithoutManagedClientsInput>;
  alternateNames?: InputMaybe<ClientCreatealternateNamesInput>;
  boilerplate?: InputMaybe<Scalars['String']['input']>;
  clientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutClientInput>;
  competitorOf?: InputMaybe<ClientCreateNestedManyWithoutCompetitorsInput>;
  competitors?: InputMaybe<ClientCreateNestedManyWithoutCompetitorOfInput>;
  coverageLastSentAt?: InputMaybe<Scalars['DateTime']['input']>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutClientsInput>;
  employees?: InputMaybe<UserCreateNestedManyWithoutClientEmployerInput>;
  entitlements?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<FileMetadataCreateNestedManyWithoutClientInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageCreateNestedManyWithoutClientInput>;
  isCompetitor?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  location: LocationCreateNestedOneWithoutClientsInput;
  logo?: InputMaybe<Scalars['String']['input']>;
  meetingNotes?: InputMaybe<MeetingNotesCreateNestedManyWithoutClientInput>;
  mentions?: InputMaybe<ClientMentionCreateNestedManyWithoutClientInput>;
  name: Scalars['String']['input'];
  nameUniqueness?: InputMaybe<NameUniqueness>;
  newsJackItems?: InputMaybe<NewsJackItemCreateNestedManyWithoutClientInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  programPlannerItems?: InputMaybe<ProgramPlannerItemCreateNestedManyWithoutClientInput>;
  receivedNewsWraps?: InputMaybe<NewsWrapCreateNestedManyWithoutSentToInput>;
  spokespeople?: InputMaybe<SpokespersonCreateNestedManyWithoutClientInput>;
  status: ClientStatus;
  tags?: InputMaybe<ClientTagCreateNestedManyWithoutClientInput>;
};

export type ClientCreateWithoutCoveragePiecesInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutClientInput>;
  agencyManagers?: InputMaybe<UserCreateNestedManyWithoutManagedClientsInput>;
  alternateNames?: InputMaybe<ClientCreatealternateNamesInput>;
  boilerplate?: InputMaybe<Scalars['String']['input']>;
  clientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutClientInput>;
  competitorOf?: InputMaybe<ClientCreateNestedManyWithoutCompetitorsInput>;
  competitors?: InputMaybe<ClientCreateNestedManyWithoutCompetitorOfInput>;
  content?: InputMaybe<ContentCreateNestedManyWithoutClientInput>;
  coverageLastSentAt?: InputMaybe<Scalars['DateTime']['input']>;
  employees?: InputMaybe<UserCreateNestedManyWithoutClientEmployerInput>;
  entitlements?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<FileMetadataCreateNestedManyWithoutClientInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageCreateNestedManyWithoutClientInput>;
  isCompetitor?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  location: LocationCreateNestedOneWithoutClientsInput;
  logo?: InputMaybe<Scalars['String']['input']>;
  meetingNotes?: InputMaybe<MeetingNotesCreateNestedManyWithoutClientInput>;
  mentions?: InputMaybe<ClientMentionCreateNestedManyWithoutClientInput>;
  name: Scalars['String']['input'];
  nameUniqueness?: InputMaybe<NameUniqueness>;
  newsJackItems?: InputMaybe<NewsJackItemCreateNestedManyWithoutClientInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  programPlannerItems?: InputMaybe<ProgramPlannerItemCreateNestedManyWithoutClientInput>;
  receivedNewsWraps?: InputMaybe<NewsWrapCreateNestedManyWithoutSentToInput>;
  spokespeople?: InputMaybe<SpokespersonCreateNestedManyWithoutClientInput>;
  status: ClientStatus;
  tags?: InputMaybe<ClientTagCreateNestedManyWithoutClientInput>;
};

export type ClientCreateWithoutEmployeesInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutClientInput>;
  agencyManagers?: InputMaybe<UserCreateNestedManyWithoutManagedClientsInput>;
  alternateNames?: InputMaybe<ClientCreatealternateNamesInput>;
  boilerplate?: InputMaybe<Scalars['String']['input']>;
  clientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutClientInput>;
  competitorOf?: InputMaybe<ClientCreateNestedManyWithoutCompetitorsInput>;
  competitors?: InputMaybe<ClientCreateNestedManyWithoutCompetitorOfInput>;
  content?: InputMaybe<ContentCreateNestedManyWithoutClientInput>;
  coverageLastSentAt?: InputMaybe<Scalars['DateTime']['input']>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutClientsInput>;
  entitlements?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<FileMetadataCreateNestedManyWithoutClientInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageCreateNestedManyWithoutClientInput>;
  isCompetitor?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  location: LocationCreateNestedOneWithoutClientsInput;
  logo?: InputMaybe<Scalars['String']['input']>;
  meetingNotes?: InputMaybe<MeetingNotesCreateNestedManyWithoutClientInput>;
  mentions?: InputMaybe<ClientMentionCreateNestedManyWithoutClientInput>;
  name: Scalars['String']['input'];
  nameUniqueness?: InputMaybe<NameUniqueness>;
  newsJackItems?: InputMaybe<NewsJackItemCreateNestedManyWithoutClientInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  programPlannerItems?: InputMaybe<ProgramPlannerItemCreateNestedManyWithoutClientInput>;
  receivedNewsWraps?: InputMaybe<NewsWrapCreateNestedManyWithoutSentToInput>;
  spokespeople?: InputMaybe<SpokespersonCreateNestedManyWithoutClientInput>;
  status: ClientStatus;
  tags?: InputMaybe<ClientTagCreateNestedManyWithoutClientInput>;
};

export type ClientCreateWithoutFilesInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutClientInput>;
  agencyManagers?: InputMaybe<UserCreateNestedManyWithoutManagedClientsInput>;
  alternateNames?: InputMaybe<ClientCreatealternateNamesInput>;
  boilerplate?: InputMaybe<Scalars['String']['input']>;
  clientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutClientInput>;
  competitorOf?: InputMaybe<ClientCreateNestedManyWithoutCompetitorsInput>;
  competitors?: InputMaybe<ClientCreateNestedManyWithoutCompetitorOfInput>;
  content?: InputMaybe<ContentCreateNestedManyWithoutClientInput>;
  coverageLastSentAt?: InputMaybe<Scalars['DateTime']['input']>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutClientsInput>;
  employees?: InputMaybe<UserCreateNestedManyWithoutClientEmployerInput>;
  entitlements?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageCreateNestedManyWithoutClientInput>;
  isCompetitor?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  location: LocationCreateNestedOneWithoutClientsInput;
  logo?: InputMaybe<Scalars['String']['input']>;
  meetingNotes?: InputMaybe<MeetingNotesCreateNestedManyWithoutClientInput>;
  mentions?: InputMaybe<ClientMentionCreateNestedManyWithoutClientInput>;
  name: Scalars['String']['input'];
  nameUniqueness?: InputMaybe<NameUniqueness>;
  newsJackItems?: InputMaybe<NewsJackItemCreateNestedManyWithoutClientInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  programPlannerItems?: InputMaybe<ProgramPlannerItemCreateNestedManyWithoutClientInput>;
  receivedNewsWraps?: InputMaybe<NewsWrapCreateNestedManyWithoutSentToInput>;
  spokespeople?: InputMaybe<SpokespersonCreateNestedManyWithoutClientInput>;
  status: ClientStatus;
  tags?: InputMaybe<ClientTagCreateNestedManyWithoutClientInput>;
};

export type ClientCreateWithoutIgnoredSuggestedCoverageInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutClientInput>;
  agencyManagers?: InputMaybe<UserCreateNestedManyWithoutManagedClientsInput>;
  alternateNames?: InputMaybe<ClientCreatealternateNamesInput>;
  boilerplate?: InputMaybe<Scalars['String']['input']>;
  clientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutClientInput>;
  competitorOf?: InputMaybe<ClientCreateNestedManyWithoutCompetitorsInput>;
  competitors?: InputMaybe<ClientCreateNestedManyWithoutCompetitorOfInput>;
  content?: InputMaybe<ContentCreateNestedManyWithoutClientInput>;
  coverageLastSentAt?: InputMaybe<Scalars['DateTime']['input']>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutClientsInput>;
  employees?: InputMaybe<UserCreateNestedManyWithoutClientEmployerInput>;
  entitlements?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<FileMetadataCreateNestedManyWithoutClientInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  isCompetitor?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  location: LocationCreateNestedOneWithoutClientsInput;
  logo?: InputMaybe<Scalars['String']['input']>;
  meetingNotes?: InputMaybe<MeetingNotesCreateNestedManyWithoutClientInput>;
  mentions?: InputMaybe<ClientMentionCreateNestedManyWithoutClientInput>;
  name: Scalars['String']['input'];
  nameUniqueness?: InputMaybe<NameUniqueness>;
  newsJackItems?: InputMaybe<NewsJackItemCreateNestedManyWithoutClientInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  programPlannerItems?: InputMaybe<ProgramPlannerItemCreateNestedManyWithoutClientInput>;
  receivedNewsWraps?: InputMaybe<NewsWrapCreateNestedManyWithoutSentToInput>;
  spokespeople?: InputMaybe<SpokespersonCreateNestedManyWithoutClientInput>;
  status: ClientStatus;
  tags?: InputMaybe<ClientTagCreateNestedManyWithoutClientInput>;
};

export type ClientCreateWithoutLocationInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutClientInput>;
  agencyManagers?: InputMaybe<UserCreateNestedManyWithoutManagedClientsInput>;
  alternateNames?: InputMaybe<ClientCreatealternateNamesInput>;
  boilerplate?: InputMaybe<Scalars['String']['input']>;
  clientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutClientInput>;
  competitorOf?: InputMaybe<ClientCreateNestedManyWithoutCompetitorsInput>;
  competitors?: InputMaybe<ClientCreateNestedManyWithoutCompetitorOfInput>;
  content?: InputMaybe<ContentCreateNestedManyWithoutClientInput>;
  coverageLastSentAt?: InputMaybe<Scalars['DateTime']['input']>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutClientsInput>;
  employees?: InputMaybe<UserCreateNestedManyWithoutClientEmployerInput>;
  entitlements?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<FileMetadataCreateNestedManyWithoutClientInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageCreateNestedManyWithoutClientInput>;
  isCompetitor?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  meetingNotes?: InputMaybe<MeetingNotesCreateNestedManyWithoutClientInput>;
  mentions?: InputMaybe<ClientMentionCreateNestedManyWithoutClientInput>;
  name: Scalars['String']['input'];
  nameUniqueness?: InputMaybe<NameUniqueness>;
  newsJackItems?: InputMaybe<NewsJackItemCreateNestedManyWithoutClientInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  programPlannerItems?: InputMaybe<ProgramPlannerItemCreateNestedManyWithoutClientInput>;
  receivedNewsWraps?: InputMaybe<NewsWrapCreateNestedManyWithoutSentToInput>;
  spokespeople?: InputMaybe<SpokespersonCreateNestedManyWithoutClientInput>;
  status: ClientStatus;
  tags?: InputMaybe<ClientTagCreateNestedManyWithoutClientInput>;
};

export type ClientCreateWithoutMeetingNotesInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutClientInput>;
  agencyManagers?: InputMaybe<UserCreateNestedManyWithoutManagedClientsInput>;
  alternateNames?: InputMaybe<ClientCreatealternateNamesInput>;
  boilerplate?: InputMaybe<Scalars['String']['input']>;
  clientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutClientInput>;
  competitorOf?: InputMaybe<ClientCreateNestedManyWithoutCompetitorsInput>;
  competitors?: InputMaybe<ClientCreateNestedManyWithoutCompetitorOfInput>;
  content?: InputMaybe<ContentCreateNestedManyWithoutClientInput>;
  coverageLastSentAt?: InputMaybe<Scalars['DateTime']['input']>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutClientsInput>;
  employees?: InputMaybe<UserCreateNestedManyWithoutClientEmployerInput>;
  entitlements?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<FileMetadataCreateNestedManyWithoutClientInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageCreateNestedManyWithoutClientInput>;
  isCompetitor?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  location: LocationCreateNestedOneWithoutClientsInput;
  logo?: InputMaybe<Scalars['String']['input']>;
  mentions?: InputMaybe<ClientMentionCreateNestedManyWithoutClientInput>;
  name: Scalars['String']['input'];
  nameUniqueness?: InputMaybe<NameUniqueness>;
  newsJackItems?: InputMaybe<NewsJackItemCreateNestedManyWithoutClientInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  programPlannerItems?: InputMaybe<ProgramPlannerItemCreateNestedManyWithoutClientInput>;
  receivedNewsWraps?: InputMaybe<NewsWrapCreateNestedManyWithoutSentToInput>;
  spokespeople?: InputMaybe<SpokespersonCreateNestedManyWithoutClientInput>;
  status: ClientStatus;
  tags?: InputMaybe<ClientTagCreateNestedManyWithoutClientInput>;
};

export type ClientCreateWithoutMentionsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutClientInput>;
  agencyManagers?: InputMaybe<UserCreateNestedManyWithoutManagedClientsInput>;
  alternateNames?: InputMaybe<ClientCreatealternateNamesInput>;
  boilerplate?: InputMaybe<Scalars['String']['input']>;
  clientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutClientInput>;
  competitorOf?: InputMaybe<ClientCreateNestedManyWithoutCompetitorsInput>;
  competitors?: InputMaybe<ClientCreateNestedManyWithoutCompetitorOfInput>;
  content?: InputMaybe<ContentCreateNestedManyWithoutClientInput>;
  coverageLastSentAt?: InputMaybe<Scalars['DateTime']['input']>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutClientsInput>;
  employees?: InputMaybe<UserCreateNestedManyWithoutClientEmployerInput>;
  entitlements?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<FileMetadataCreateNestedManyWithoutClientInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageCreateNestedManyWithoutClientInput>;
  isCompetitor?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  location: LocationCreateNestedOneWithoutClientsInput;
  logo?: InputMaybe<Scalars['String']['input']>;
  meetingNotes?: InputMaybe<MeetingNotesCreateNestedManyWithoutClientInput>;
  name: Scalars['String']['input'];
  nameUniqueness?: InputMaybe<NameUniqueness>;
  newsJackItems?: InputMaybe<NewsJackItemCreateNestedManyWithoutClientInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  programPlannerItems?: InputMaybe<ProgramPlannerItemCreateNestedManyWithoutClientInput>;
  receivedNewsWraps?: InputMaybe<NewsWrapCreateNestedManyWithoutSentToInput>;
  spokespeople?: InputMaybe<SpokespersonCreateNestedManyWithoutClientInput>;
  status: ClientStatus;
  tags?: InputMaybe<ClientTagCreateNestedManyWithoutClientInput>;
};

export type ClientCreateWithoutNewsJackItemsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutClientInput>;
  agencyManagers?: InputMaybe<UserCreateNestedManyWithoutManagedClientsInput>;
  alternateNames?: InputMaybe<ClientCreatealternateNamesInput>;
  boilerplate?: InputMaybe<Scalars['String']['input']>;
  clientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutClientInput>;
  competitorOf?: InputMaybe<ClientCreateNestedManyWithoutCompetitorsInput>;
  competitors?: InputMaybe<ClientCreateNestedManyWithoutCompetitorOfInput>;
  content?: InputMaybe<ContentCreateNestedManyWithoutClientInput>;
  coverageLastSentAt?: InputMaybe<Scalars['DateTime']['input']>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutClientsInput>;
  employees?: InputMaybe<UserCreateNestedManyWithoutClientEmployerInput>;
  entitlements?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<FileMetadataCreateNestedManyWithoutClientInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageCreateNestedManyWithoutClientInput>;
  isCompetitor?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  location: LocationCreateNestedOneWithoutClientsInput;
  logo?: InputMaybe<Scalars['String']['input']>;
  meetingNotes?: InputMaybe<MeetingNotesCreateNestedManyWithoutClientInput>;
  mentions?: InputMaybe<ClientMentionCreateNestedManyWithoutClientInput>;
  name: Scalars['String']['input'];
  nameUniqueness?: InputMaybe<NameUniqueness>;
  notes?: InputMaybe<Scalars['String']['input']>;
  programPlannerItems?: InputMaybe<ProgramPlannerItemCreateNestedManyWithoutClientInput>;
  receivedNewsWraps?: InputMaybe<NewsWrapCreateNestedManyWithoutSentToInput>;
  spokespeople?: InputMaybe<SpokespersonCreateNestedManyWithoutClientInput>;
  status: ClientStatus;
  tags?: InputMaybe<ClientTagCreateNestedManyWithoutClientInput>;
};

export type ClientCreateWithoutProgramPlannerItemsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutClientInput>;
  agencyManagers?: InputMaybe<UserCreateNestedManyWithoutManagedClientsInput>;
  alternateNames?: InputMaybe<ClientCreatealternateNamesInput>;
  boilerplate?: InputMaybe<Scalars['String']['input']>;
  clientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutClientInput>;
  competitorOf?: InputMaybe<ClientCreateNestedManyWithoutCompetitorsInput>;
  competitors?: InputMaybe<ClientCreateNestedManyWithoutCompetitorOfInput>;
  content?: InputMaybe<ContentCreateNestedManyWithoutClientInput>;
  coverageLastSentAt?: InputMaybe<Scalars['DateTime']['input']>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutClientsInput>;
  employees?: InputMaybe<UserCreateNestedManyWithoutClientEmployerInput>;
  entitlements?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<FileMetadataCreateNestedManyWithoutClientInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageCreateNestedManyWithoutClientInput>;
  isCompetitor?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  location: LocationCreateNestedOneWithoutClientsInput;
  logo?: InputMaybe<Scalars['String']['input']>;
  meetingNotes?: InputMaybe<MeetingNotesCreateNestedManyWithoutClientInput>;
  mentions?: InputMaybe<ClientMentionCreateNestedManyWithoutClientInput>;
  name: Scalars['String']['input'];
  nameUniqueness?: InputMaybe<NameUniqueness>;
  newsJackItems?: InputMaybe<NewsJackItemCreateNestedManyWithoutClientInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  receivedNewsWraps?: InputMaybe<NewsWrapCreateNestedManyWithoutSentToInput>;
  spokespeople?: InputMaybe<SpokespersonCreateNestedManyWithoutClientInput>;
  status: ClientStatus;
  tags?: InputMaybe<ClientTagCreateNestedManyWithoutClientInput>;
};

export type ClientCreateWithoutReceivedNewsWrapsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutClientInput>;
  agencyManagers?: InputMaybe<UserCreateNestedManyWithoutManagedClientsInput>;
  alternateNames?: InputMaybe<ClientCreatealternateNamesInput>;
  boilerplate?: InputMaybe<Scalars['String']['input']>;
  clientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutClientInput>;
  competitorOf?: InputMaybe<ClientCreateNestedManyWithoutCompetitorsInput>;
  competitors?: InputMaybe<ClientCreateNestedManyWithoutCompetitorOfInput>;
  content?: InputMaybe<ContentCreateNestedManyWithoutClientInput>;
  coverageLastSentAt?: InputMaybe<Scalars['DateTime']['input']>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutClientsInput>;
  employees?: InputMaybe<UserCreateNestedManyWithoutClientEmployerInput>;
  entitlements?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<FileMetadataCreateNestedManyWithoutClientInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageCreateNestedManyWithoutClientInput>;
  isCompetitor?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  location: LocationCreateNestedOneWithoutClientsInput;
  logo?: InputMaybe<Scalars['String']['input']>;
  meetingNotes?: InputMaybe<MeetingNotesCreateNestedManyWithoutClientInput>;
  mentions?: InputMaybe<ClientMentionCreateNestedManyWithoutClientInput>;
  name: Scalars['String']['input'];
  nameUniqueness?: InputMaybe<NameUniqueness>;
  newsJackItems?: InputMaybe<NewsJackItemCreateNestedManyWithoutClientInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  programPlannerItems?: InputMaybe<ProgramPlannerItemCreateNestedManyWithoutClientInput>;
  spokespeople?: InputMaybe<SpokespersonCreateNestedManyWithoutClientInput>;
  status: ClientStatus;
  tags?: InputMaybe<ClientTagCreateNestedManyWithoutClientInput>;
};

export type ClientCreateWithoutSpokespeopleInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutClientInput>;
  agencyManagers?: InputMaybe<UserCreateNestedManyWithoutManagedClientsInput>;
  alternateNames?: InputMaybe<ClientCreatealternateNamesInput>;
  boilerplate?: InputMaybe<Scalars['String']['input']>;
  clientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutClientInput>;
  competitorOf?: InputMaybe<ClientCreateNestedManyWithoutCompetitorsInput>;
  competitors?: InputMaybe<ClientCreateNestedManyWithoutCompetitorOfInput>;
  content?: InputMaybe<ContentCreateNestedManyWithoutClientInput>;
  coverageLastSentAt?: InputMaybe<Scalars['DateTime']['input']>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutClientsInput>;
  employees?: InputMaybe<UserCreateNestedManyWithoutClientEmployerInput>;
  entitlements?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<FileMetadataCreateNestedManyWithoutClientInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageCreateNestedManyWithoutClientInput>;
  isCompetitor?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  location: LocationCreateNestedOneWithoutClientsInput;
  logo?: InputMaybe<Scalars['String']['input']>;
  meetingNotes?: InputMaybe<MeetingNotesCreateNestedManyWithoutClientInput>;
  mentions?: InputMaybe<ClientMentionCreateNestedManyWithoutClientInput>;
  name: Scalars['String']['input'];
  nameUniqueness?: InputMaybe<NameUniqueness>;
  newsJackItems?: InputMaybe<NewsJackItemCreateNestedManyWithoutClientInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  programPlannerItems?: InputMaybe<ProgramPlannerItemCreateNestedManyWithoutClientInput>;
  receivedNewsWraps?: InputMaybe<NewsWrapCreateNestedManyWithoutSentToInput>;
  status: ClientStatus;
  tags?: InputMaybe<ClientTagCreateNestedManyWithoutClientInput>;
};

export type ClientCreateWithoutWorkspaceInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutClientInput>;
  agencyManagers?: InputMaybe<UserCreateNestedManyWithoutManagedClientsInput>;
  alternateNames?: InputMaybe<ClientCreatealternateNamesInput>;
  boilerplate?: InputMaybe<Scalars['String']['input']>;
  clientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutClientInput>;
  competitorOf?: InputMaybe<ClientCreateNestedManyWithoutCompetitorsInput>;
  competitors?: InputMaybe<ClientCreateNestedManyWithoutCompetitorOfInput>;
  content?: InputMaybe<ContentCreateNestedManyWithoutClientInput>;
  coverageLastSentAt?: InputMaybe<Scalars['DateTime']['input']>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutClientsInput>;
  employees?: InputMaybe<UserCreateNestedManyWithoutClientEmployerInput>;
  entitlements?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<FileMetadataCreateNestedManyWithoutClientInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageCreateNestedManyWithoutClientInput>;
  isCompetitor?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  location: LocationCreateNestedOneWithoutClientsInput;
  logo?: InputMaybe<Scalars['String']['input']>;
  meetingNotes?: InputMaybe<MeetingNotesCreateNestedManyWithoutClientInput>;
  mentions?: InputMaybe<ClientMentionCreateNestedManyWithoutClientInput>;
  name: Scalars['String']['input'];
  nameUniqueness?: InputMaybe<NameUniqueness>;
  newsJackItems?: InputMaybe<NewsJackItemCreateNestedManyWithoutClientInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  programPlannerItems?: InputMaybe<ProgramPlannerItemCreateNestedManyWithoutClientInput>;
  receivedNewsWraps?: InputMaybe<NewsWrapCreateNestedManyWithoutSentToInput>;
  spokespeople?: InputMaybe<SpokespersonCreateNestedManyWithoutClientInput>;
  status: ClientStatus;
  tags?: InputMaybe<ClientTagCreateNestedManyWithoutClientInput>;
};

export type ClientCreatealternateNamesInput = {
  set: Array<Scalars['String']['input']>;
};

export type ClientDashboardNewsWrap = {
  __typename?: 'ClientDashboardNewsWrap';
  content: Array<NewsWrapContent>;
  date: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  sentAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ClientDashboardResponse = {
  __typename?: 'ClientDashboardResponse';
  client: Client;
  content: Array<Content>;
  coveragePieces: Array<CoveragePiece>;
  newsWrap?: Maybe<ClientDashboardNewsWrap>;
};

export type ClientListRelationFilter = {
  every?: InputMaybe<ClientWhereInput>;
  none?: InputMaybe<ClientWhereInput>;
  some?: InputMaybe<ClientWhereInput>;
};

export type ClientMention = {
  __typename?: 'ClientMention';
  articleId: Scalars['String']['output'];
  client: Client;
  clientId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isSponsored: Scalars['Boolean']['output'];
  negativeNews: Array<Scalars['String']['output']>;
  positiveNews: Array<Scalars['String']['output']>;
  sentences: Array<Scalars['String']['output']>;
  sentimentScore: Scalars['Float']['output'];
  summary: Scalars['String']['output'];
};

export type ClientMentionArticleIdClientIdCompoundUniqueInput = {
  articleId: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
};

export type ClientMentionCreateInput = {
  article: ArticleCreateNestedOneWithoutClientMentionsInput;
  client: ClientCreateNestedOneWithoutMentionsInput;
  id?: InputMaybe<Scalars['String']['input']>;
  isSponsored?: InputMaybe<Scalars['Boolean']['input']>;
  negativeNews?: InputMaybe<ClientMentionCreatenegativeNewsInput>;
  positiveNews?: InputMaybe<ClientMentionCreatepositiveNewsInput>;
  sentences?: InputMaybe<ClientMentionCreatesentencesInput>;
  sentimentScore: Scalars['Float']['input'];
  summary: Scalars['String']['input'];
};

export type ClientMentionCreateManyArticleInput = {
  clientId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isSponsored?: InputMaybe<Scalars['Boolean']['input']>;
  negativeNews?: InputMaybe<ClientMentionCreatenegativeNewsInput>;
  positiveNews?: InputMaybe<ClientMentionCreatepositiveNewsInput>;
  sentences?: InputMaybe<ClientMentionCreatesentencesInput>;
  sentimentScore: Scalars['Float']['input'];
  summary: Scalars['String']['input'];
};

export type ClientMentionCreateManyArticleInputEnvelope = {
  data: Array<ClientMentionCreateManyArticleInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ClientMentionCreateManyClientInput = {
  articleId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isSponsored?: InputMaybe<Scalars['Boolean']['input']>;
  negativeNews?: InputMaybe<ClientMentionCreatenegativeNewsInput>;
  positiveNews?: InputMaybe<ClientMentionCreatepositiveNewsInput>;
  sentences?: InputMaybe<ClientMentionCreatesentencesInput>;
  sentimentScore: Scalars['Float']['input'];
  summary: Scalars['String']['input'];
};

export type ClientMentionCreateManyClientInputEnvelope = {
  data: Array<ClientMentionCreateManyClientInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ClientMentionCreateNestedManyWithoutArticleInput = {
  connect?: InputMaybe<Array<ClientMentionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClientMentionCreateOrConnectWithoutArticleInput>>;
  create?: InputMaybe<Array<ClientMentionCreateWithoutArticleInput>>;
  createMany?: InputMaybe<ClientMentionCreateManyArticleInputEnvelope>;
};

export type ClientMentionCreateNestedManyWithoutClientInput = {
  connect?: InputMaybe<Array<ClientMentionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClientMentionCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<ClientMentionCreateWithoutClientInput>>;
  createMany?: InputMaybe<ClientMentionCreateManyClientInputEnvelope>;
};

export type ClientMentionCreateOrConnectWithoutArticleInput = {
  create: ClientMentionCreateWithoutArticleInput;
  where: ClientMentionWhereUniqueInput;
};

export type ClientMentionCreateOrConnectWithoutClientInput = {
  create: ClientMentionCreateWithoutClientInput;
  where: ClientMentionWhereUniqueInput;
};

export type ClientMentionCreateWithoutArticleInput = {
  client: ClientCreateNestedOneWithoutMentionsInput;
  id?: InputMaybe<Scalars['String']['input']>;
  isSponsored?: InputMaybe<Scalars['Boolean']['input']>;
  negativeNews?: InputMaybe<ClientMentionCreatenegativeNewsInput>;
  positiveNews?: InputMaybe<ClientMentionCreatepositiveNewsInput>;
  sentences?: InputMaybe<ClientMentionCreatesentencesInput>;
  sentimentScore: Scalars['Float']['input'];
  summary: Scalars['String']['input'];
};

export type ClientMentionCreateWithoutClientInput = {
  article: ArticleCreateNestedOneWithoutClientMentionsInput;
  id?: InputMaybe<Scalars['String']['input']>;
  isSponsored?: InputMaybe<Scalars['Boolean']['input']>;
  negativeNews?: InputMaybe<ClientMentionCreatenegativeNewsInput>;
  positiveNews?: InputMaybe<ClientMentionCreatepositiveNewsInput>;
  sentences?: InputMaybe<ClientMentionCreatesentencesInput>;
  sentimentScore: Scalars['Float']['input'];
  summary: Scalars['String']['input'];
};

export type ClientMentionCreatenegativeNewsInput = {
  set: Array<Scalars['String']['input']>;
};

export type ClientMentionCreatepositiveNewsInput = {
  set: Array<Scalars['String']['input']>;
};

export type ClientMentionCreatesentencesInput = {
  set: Array<Scalars['String']['input']>;
};

export type ClientMentionListRelationFilter = {
  every?: InputMaybe<ClientMentionWhereInput>;
  none?: InputMaybe<ClientMentionWhereInput>;
  some?: InputMaybe<ClientMentionWhereInput>;
};

export type ClientMentionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum ClientMentionOrderByRelevanceFieldEnum {
  ArticleId = 'articleId',
  ClientId = 'clientId',
  Id = 'id',
  NegativeNews = 'negativeNews',
  PositiveNews = 'positiveNews',
  Sentences = 'sentences',
  Summary = 'summary'
}

export type ClientMentionOrderByRelevanceInput = {
  fields: Array<ClientMentionOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type ClientMentionOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<ClientMentionOrderByRelevanceInput>;
  article?: InputMaybe<ArticleOrderByWithRelationAndSearchRelevanceInput>;
  articleId?: InputMaybe<SortOrder>;
  client?: InputMaybe<ClientOrderByWithRelationAndSearchRelevanceInput>;
  clientId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isSponsored?: InputMaybe<SortOrder>;
  negativeNews?: InputMaybe<SortOrder>;
  positiveNews?: InputMaybe<SortOrder>;
  sentences?: InputMaybe<SortOrder>;
  sentimentScore?: InputMaybe<SortOrder>;
  summary?: InputMaybe<SortOrder>;
};

export enum ClientMentionScalarFieldEnum {
  ArticleId = 'articleId',
  ClientId = 'clientId',
  Id = 'id',
  IsSponsored = 'isSponsored',
  NegativeNews = 'negativeNews',
  PositiveNews = 'positiveNews',
  Sentences = 'sentences',
  SentimentScore = 'sentimentScore',
  Summary = 'summary'
}

export type ClientMentionScalarWhereInput = {
  AND?: InputMaybe<Array<ClientMentionScalarWhereInput>>;
  NOT?: InputMaybe<Array<ClientMentionScalarWhereInput>>;
  OR?: InputMaybe<Array<ClientMentionScalarWhereInput>>;
  articleId?: InputMaybe<StringFilter>;
  clientId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  isSponsored?: InputMaybe<BoolFilter>;
  negativeNews?: InputMaybe<StringNullableListFilter>;
  positiveNews?: InputMaybe<StringNullableListFilter>;
  sentences?: InputMaybe<StringNullableListFilter>;
  sentimentScore?: InputMaybe<FloatFilter>;
  summary?: InputMaybe<StringFilter>;
};

export type ClientMentionUpdateInput = {
  article?: InputMaybe<ArticleUpdateOneRequiredWithoutClientMentionsNestedInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutMentionsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isSponsored?: InputMaybe<BoolFieldUpdateOperationsInput>;
  negativeNews?: InputMaybe<ClientMentionUpdatenegativeNewsInput>;
  positiveNews?: InputMaybe<ClientMentionUpdatepositiveNewsInput>;
  sentences?: InputMaybe<ClientMentionUpdatesentencesInput>;
  sentimentScore?: InputMaybe<FloatFieldUpdateOperationsInput>;
  summary?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ClientMentionUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isSponsored?: InputMaybe<BoolFieldUpdateOperationsInput>;
  negativeNews?: InputMaybe<ClientMentionUpdatenegativeNewsInput>;
  positiveNews?: InputMaybe<ClientMentionUpdatepositiveNewsInput>;
  sentences?: InputMaybe<ClientMentionUpdatesentencesInput>;
  sentimentScore?: InputMaybe<FloatFieldUpdateOperationsInput>;
  summary?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ClientMentionUpdateManyWithWhereWithoutArticleInput = {
  data: ClientMentionUpdateManyMutationInput;
  where: ClientMentionScalarWhereInput;
};

export type ClientMentionUpdateManyWithWhereWithoutClientInput = {
  data: ClientMentionUpdateManyMutationInput;
  where: ClientMentionScalarWhereInput;
};

export type ClientMentionUpdateManyWithoutArticleNestedInput = {
  connect?: InputMaybe<Array<ClientMentionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClientMentionCreateOrConnectWithoutArticleInput>>;
  create?: InputMaybe<Array<ClientMentionCreateWithoutArticleInput>>;
  createMany?: InputMaybe<ClientMentionCreateManyArticleInputEnvelope>;
  delete?: InputMaybe<Array<ClientMentionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ClientMentionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ClientMentionWhereUniqueInput>>;
  set?: InputMaybe<Array<ClientMentionWhereUniqueInput>>;
  update?: InputMaybe<Array<ClientMentionUpdateWithWhereUniqueWithoutArticleInput>>;
  updateMany?: InputMaybe<Array<ClientMentionUpdateManyWithWhereWithoutArticleInput>>;
  upsert?: InputMaybe<Array<ClientMentionUpsertWithWhereUniqueWithoutArticleInput>>;
};

export type ClientMentionUpdateManyWithoutClientNestedInput = {
  connect?: InputMaybe<Array<ClientMentionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClientMentionCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<ClientMentionCreateWithoutClientInput>>;
  createMany?: InputMaybe<ClientMentionCreateManyClientInputEnvelope>;
  delete?: InputMaybe<Array<ClientMentionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ClientMentionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ClientMentionWhereUniqueInput>>;
  set?: InputMaybe<Array<ClientMentionWhereUniqueInput>>;
  update?: InputMaybe<Array<ClientMentionUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: InputMaybe<Array<ClientMentionUpdateManyWithWhereWithoutClientInput>>;
  upsert?: InputMaybe<Array<ClientMentionUpsertWithWhereUniqueWithoutClientInput>>;
};

export type ClientMentionUpdateWithWhereUniqueWithoutArticleInput = {
  data: ClientMentionUpdateWithoutArticleInput;
  where: ClientMentionWhereUniqueInput;
};

export type ClientMentionUpdateWithWhereUniqueWithoutClientInput = {
  data: ClientMentionUpdateWithoutClientInput;
  where: ClientMentionWhereUniqueInput;
};

export type ClientMentionUpdateWithoutArticleInput = {
  client?: InputMaybe<ClientUpdateOneRequiredWithoutMentionsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isSponsored?: InputMaybe<BoolFieldUpdateOperationsInput>;
  negativeNews?: InputMaybe<ClientMentionUpdatenegativeNewsInput>;
  positiveNews?: InputMaybe<ClientMentionUpdatepositiveNewsInput>;
  sentences?: InputMaybe<ClientMentionUpdatesentencesInput>;
  sentimentScore?: InputMaybe<FloatFieldUpdateOperationsInput>;
  summary?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ClientMentionUpdateWithoutClientInput = {
  article?: InputMaybe<ArticleUpdateOneRequiredWithoutClientMentionsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isSponsored?: InputMaybe<BoolFieldUpdateOperationsInput>;
  negativeNews?: InputMaybe<ClientMentionUpdatenegativeNewsInput>;
  positiveNews?: InputMaybe<ClientMentionUpdatepositiveNewsInput>;
  sentences?: InputMaybe<ClientMentionUpdatesentencesInput>;
  sentimentScore?: InputMaybe<FloatFieldUpdateOperationsInput>;
  summary?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ClientMentionUpdatenegativeNewsInput = {
  push?: InputMaybe<Array<Scalars['String']['input']>>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ClientMentionUpdatepositiveNewsInput = {
  push?: InputMaybe<Array<Scalars['String']['input']>>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ClientMentionUpdatesentencesInput = {
  push?: InputMaybe<Array<Scalars['String']['input']>>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ClientMentionUpsertWithWhereUniqueWithoutArticleInput = {
  create: ClientMentionCreateWithoutArticleInput;
  update: ClientMentionUpdateWithoutArticleInput;
  where: ClientMentionWhereUniqueInput;
};

export type ClientMentionUpsertWithWhereUniqueWithoutClientInput = {
  create: ClientMentionCreateWithoutClientInput;
  update: ClientMentionUpdateWithoutClientInput;
  where: ClientMentionWhereUniqueInput;
};

export type ClientMentionWhereInput = {
  AND?: InputMaybe<Array<ClientMentionWhereInput>>;
  NOT?: InputMaybe<Array<ClientMentionWhereInput>>;
  OR?: InputMaybe<Array<ClientMentionWhereInput>>;
  article?: InputMaybe<ArticleRelationFilter>;
  articleId?: InputMaybe<StringFilter>;
  client?: InputMaybe<ClientRelationFilter>;
  clientId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  isSponsored?: InputMaybe<BoolFilter>;
  negativeNews?: InputMaybe<StringNullableListFilter>;
  positiveNews?: InputMaybe<StringNullableListFilter>;
  sentences?: InputMaybe<StringNullableListFilter>;
  sentimentScore?: InputMaybe<FloatFilter>;
  summary?: InputMaybe<StringFilter>;
};

export type ClientMentionWhereUniqueInput = {
  AND?: InputMaybe<Array<ClientMentionWhereInput>>;
  NOT?: InputMaybe<Array<ClientMentionWhereInput>>;
  OR?: InputMaybe<Array<ClientMentionWhereInput>>;
  article?: InputMaybe<ArticleRelationFilter>;
  articleId?: InputMaybe<StringFilter>;
  articleId_clientId?: InputMaybe<ClientMentionArticleIdClientIdCompoundUniqueInput>;
  client?: InputMaybe<ClientRelationFilter>;
  clientId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  isSponsored?: InputMaybe<BoolFilter>;
  negativeNews?: InputMaybe<StringNullableListFilter>;
  positiveNews?: InputMaybe<StringNullableListFilter>;
  sentences?: InputMaybe<StringNullableListFilter>;
  sentimentScore?: InputMaybe<FloatFilter>;
  summary?: InputMaybe<StringFilter>;
};

export type ClientNullableRelationFilter = {
  is?: InputMaybe<ClientWhereInput>;
  isNot?: InputMaybe<ClientWhereInput>;
};

export type ClientOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum ClientOrderByRelevanceFieldEnum {
  AlternateNames = 'alternateNames',
  Boilerplate = 'boilerplate',
  Entitlements = 'entitlements',
  Id = 'id',
  LocationId = 'locationId',
  Logo = 'logo',
  Name = 'name',
  Notes = 'notes',
  WorkspaceId = 'workspaceId'
}

export type ClientOrderByRelevanceInput = {
  fields: Array<ClientOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type ClientOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<ClientOrderByRelevanceInput>;
  activities?: InputMaybe<ActivityOrderByRelationAggregateInput>;
  agencyManagers?: InputMaybe<UserOrderByRelationAggregateInput>;
  alternateNames?: InputMaybe<SortOrder>;
  boilerplate?: InputMaybe<SortOrder>;
  clientComments?: InputMaybe<ClientCommentOrderByRelationAggregateInput>;
  competitorOf?: InputMaybe<ClientOrderByRelationAggregateInput>;
  competitors?: InputMaybe<ClientOrderByRelationAggregateInput>;
  content?: InputMaybe<ContentOrderByRelationAggregateInput>;
  coverageLastSentAt?: InputMaybe<SortOrderInput>;
  coveragePieces?: InputMaybe<CoveragePieceOrderByRelationAggregateInput>;
  employees?: InputMaybe<UserOrderByRelationAggregateInput>;
  entitlements?: InputMaybe<SortOrder>;
  files?: InputMaybe<FileMetadataOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageOrderByRelationAggregateInput>;
  isCompetitor?: InputMaybe<SortOrder>;
  isPublic?: InputMaybe<SortOrder>;
  location?: InputMaybe<LocationOrderByWithRelationAndSearchRelevanceInput>;
  locationId?: InputMaybe<SortOrder>;
  logo?: InputMaybe<SortOrderInput>;
  meetingNotes?: InputMaybe<MeetingNotesOrderByRelationAggregateInput>;
  mentions?: InputMaybe<ClientMentionOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  nameUniqueness?: InputMaybe<SortOrder>;
  newsJackItems?: InputMaybe<NewsJackItemOrderByRelationAggregateInput>;
  notes?: InputMaybe<SortOrder>;
  programPlannerItems?: InputMaybe<ProgramPlannerItemOrderByRelationAggregateInput>;
  receivedNewsWraps?: InputMaybe<NewsWrapOrderByRelationAggregateInput>;
  spokespeople?: InputMaybe<SpokespersonOrderByRelationAggregateInput>;
  status?: InputMaybe<SortOrder>;
  tags?: InputMaybe<ClientTagOrderByRelationAggregateInput>;
  workspaceId?: InputMaybe<SortOrder>;
};

export type ClientRelationFilter = {
  is?: InputMaybe<ClientWhereInput>;
  isNot?: InputMaybe<ClientWhereInput>;
};

export enum ClientScalarFieldEnum {
  AlternateNames = 'alternateNames',
  Boilerplate = 'boilerplate',
  CoverageLastSentAt = 'coverageLastSentAt',
  Entitlements = 'entitlements',
  Id = 'id',
  IsCompetitor = 'isCompetitor',
  IsPublic = 'isPublic',
  LocationId = 'locationId',
  Logo = 'logo',
  Name = 'name',
  NameUniqueness = 'nameUniqueness',
  Notes = 'notes',
  Status = 'status',
  WorkspaceId = 'workspaceId'
}

export type ClientScalarWhereInput = {
  AND?: InputMaybe<Array<ClientScalarWhereInput>>;
  NOT?: InputMaybe<Array<ClientScalarWhereInput>>;
  OR?: InputMaybe<Array<ClientScalarWhereInput>>;
  alternateNames?: InputMaybe<StringNullableListFilter>;
  boilerplate?: InputMaybe<StringFilter>;
  coverageLastSentAt?: InputMaybe<DateTimeNullableFilter>;
  entitlements?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  isCompetitor?: InputMaybe<BoolFilter>;
  isPublic?: InputMaybe<BoolFilter>;
  locationId?: InputMaybe<StringFilter>;
  logo?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  nameUniqueness?: InputMaybe<EnumNameUniquenessFilter>;
  notes?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumClientStatusFilter>;
  workspaceId?: InputMaybe<StringFilter>;
};

export enum ClientStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED'
}

export type ClientTag = {
  __typename?: 'ClientTag';
  clientId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ClientTagCreateManyClientInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type ClientTagCreateManyClientInputEnvelope = {
  data: Array<ClientTagCreateManyClientInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ClientTagCreateNestedManyWithoutClientInput = {
  connect?: InputMaybe<Array<ClientTagWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClientTagCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<ClientTagCreateWithoutClientInput>>;
  createMany?: InputMaybe<ClientTagCreateManyClientInputEnvelope>;
};

export type ClientTagCreateOrConnectWithoutClientInput = {
  create: ClientTagCreateWithoutClientInput;
  where: ClientTagWhereUniqueInput;
};

export type ClientTagCreateWithoutClientInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type ClientTagListRelationFilter = {
  every?: InputMaybe<ClientTagWhereInput>;
  none?: InputMaybe<ClientTagWhereInput>;
  some?: InputMaybe<ClientTagWhereInput>;
};

export type ClientTagOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum ClientTagOrderByRelevanceFieldEnum {
  ClientId = 'clientId',
  Id = 'id',
  Name = 'name'
}

export type ClientTagOrderByRelevanceInput = {
  fields: Array<ClientTagOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type ClientTagOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<ClientTagOrderByRelevanceInput>;
  client?: InputMaybe<ClientOrderByWithRelationAndSearchRelevanceInput>;
  clientId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export enum ClientTagScalarFieldEnum {
  ClientId = 'clientId',
  Id = 'id',
  Name = 'name'
}

export type ClientTagScalarWhereInput = {
  AND?: InputMaybe<Array<ClientTagScalarWhereInput>>;
  NOT?: InputMaybe<Array<ClientTagScalarWhereInput>>;
  OR?: InputMaybe<Array<ClientTagScalarWhereInput>>;
  clientId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
};

export type ClientTagUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ClientTagUpdateManyWithWhereWithoutClientInput = {
  data: ClientTagUpdateManyMutationInput;
  where: ClientTagScalarWhereInput;
};

export type ClientTagUpdateManyWithoutClientNestedInput = {
  connect?: InputMaybe<Array<ClientTagWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClientTagCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<ClientTagCreateWithoutClientInput>>;
  createMany?: InputMaybe<ClientTagCreateManyClientInputEnvelope>;
  delete?: InputMaybe<Array<ClientTagWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ClientTagScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ClientTagWhereUniqueInput>>;
  set?: InputMaybe<Array<ClientTagWhereUniqueInput>>;
  update?: InputMaybe<Array<ClientTagUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: InputMaybe<Array<ClientTagUpdateManyWithWhereWithoutClientInput>>;
  upsert?: InputMaybe<Array<ClientTagUpsertWithWhereUniqueWithoutClientInput>>;
};

export type ClientTagUpdateWithWhereUniqueWithoutClientInput = {
  data: ClientTagUpdateWithoutClientInput;
  where: ClientTagWhereUniqueInput;
};

export type ClientTagUpdateWithoutClientInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ClientTagUpsertWithWhereUniqueWithoutClientInput = {
  create: ClientTagCreateWithoutClientInput;
  update: ClientTagUpdateWithoutClientInput;
  where: ClientTagWhereUniqueInput;
};

export type ClientTagWhereInput = {
  AND?: InputMaybe<Array<ClientTagWhereInput>>;
  NOT?: InputMaybe<Array<ClientTagWhereInput>>;
  OR?: InputMaybe<Array<ClientTagWhereInput>>;
  client?: InputMaybe<ClientRelationFilter>;
  clientId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
};

export type ClientTagWhereUniqueInput = {
  AND?: InputMaybe<Array<ClientTagWhereInput>>;
  NOT?: InputMaybe<Array<ClientTagWhereInput>>;
  OR?: InputMaybe<Array<ClientTagWhereInput>>;
  client?: InputMaybe<ClientRelationFilter>;
  clientId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<StringFilter>;
};

export type ClientUpdateManyMutationInput = {
  alternateNames?: InputMaybe<ClientUpdatealternateNamesInput>;
  boilerplate?: InputMaybe<StringFieldUpdateOperationsInput>;
  coverageLastSentAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  entitlements?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isCompetitor?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPublic?: InputMaybe<BoolFieldUpdateOperationsInput>;
  logo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameUniqueness?: InputMaybe<EnumNameUniquenessFieldUpdateOperationsInput>;
  notes?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumClientStatusFieldUpdateOperationsInput>;
};

export type ClientUpdateManyWithWhereWithoutAgencyManagersInput = {
  data: ClientUpdateManyMutationInput;
  where: ClientScalarWhereInput;
};

export type ClientUpdateManyWithWhereWithoutCompetitorOfInput = {
  data: ClientUpdateManyMutationInput;
  where: ClientScalarWhereInput;
};

export type ClientUpdateManyWithWhereWithoutCompetitorsInput = {
  data: ClientUpdateManyMutationInput;
  where: ClientScalarWhereInput;
};

export type ClientUpdateManyWithWhereWithoutCoveragePiecesInput = {
  data: ClientUpdateManyMutationInput;
  where: ClientScalarWhereInput;
};

export type ClientUpdateManyWithWhereWithoutLocationInput = {
  data: ClientUpdateManyMutationInput;
  where: ClientScalarWhereInput;
};

export type ClientUpdateManyWithWhereWithoutReceivedNewsWrapsInput = {
  data: ClientUpdateManyMutationInput;
  where: ClientScalarWhereInput;
};

export type ClientUpdateManyWithWhereWithoutWorkspaceInput = {
  data: ClientUpdateManyMutationInput;
  where: ClientScalarWhereInput;
};

export type ClientUpdateManyWithoutAgencyManagersNestedInput = {
  connect?: InputMaybe<Array<ClientWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClientCreateOrConnectWithoutAgencyManagersInput>>;
  create?: InputMaybe<Array<ClientCreateWithoutAgencyManagersInput>>;
  delete?: InputMaybe<Array<ClientWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ClientScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ClientWhereUniqueInput>>;
  set?: InputMaybe<Array<ClientWhereUniqueInput>>;
  update?: InputMaybe<Array<ClientUpdateWithWhereUniqueWithoutAgencyManagersInput>>;
  updateMany?: InputMaybe<Array<ClientUpdateManyWithWhereWithoutAgencyManagersInput>>;
  upsert?: InputMaybe<Array<ClientUpsertWithWhereUniqueWithoutAgencyManagersInput>>;
};

export type ClientUpdateManyWithoutCompetitorOfNestedInput = {
  connect?: InputMaybe<Array<ClientWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClientCreateOrConnectWithoutCompetitorOfInput>>;
  create?: InputMaybe<Array<ClientCreateWithoutCompetitorOfInput>>;
  delete?: InputMaybe<Array<ClientWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ClientScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ClientWhereUniqueInput>>;
  set?: InputMaybe<Array<ClientWhereUniqueInput>>;
  update?: InputMaybe<Array<ClientUpdateWithWhereUniqueWithoutCompetitorOfInput>>;
  updateMany?: InputMaybe<Array<ClientUpdateManyWithWhereWithoutCompetitorOfInput>>;
  upsert?: InputMaybe<Array<ClientUpsertWithWhereUniqueWithoutCompetitorOfInput>>;
};

export type ClientUpdateManyWithoutCompetitorsNestedInput = {
  connect?: InputMaybe<Array<ClientWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClientCreateOrConnectWithoutCompetitorsInput>>;
  create?: InputMaybe<Array<ClientCreateWithoutCompetitorsInput>>;
  delete?: InputMaybe<Array<ClientWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ClientScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ClientWhereUniqueInput>>;
  set?: InputMaybe<Array<ClientWhereUniqueInput>>;
  update?: InputMaybe<Array<ClientUpdateWithWhereUniqueWithoutCompetitorsInput>>;
  updateMany?: InputMaybe<Array<ClientUpdateManyWithWhereWithoutCompetitorsInput>>;
  upsert?: InputMaybe<Array<ClientUpsertWithWhereUniqueWithoutCompetitorsInput>>;
};

export type ClientUpdateManyWithoutCoveragePiecesNestedInput = {
  connect?: InputMaybe<Array<ClientWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClientCreateOrConnectWithoutCoveragePiecesInput>>;
  create?: InputMaybe<Array<ClientCreateWithoutCoveragePiecesInput>>;
  delete?: InputMaybe<Array<ClientWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ClientScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ClientWhereUniqueInput>>;
  set?: InputMaybe<Array<ClientWhereUniqueInput>>;
  update?: InputMaybe<Array<ClientUpdateWithWhereUniqueWithoutCoveragePiecesInput>>;
  updateMany?: InputMaybe<Array<ClientUpdateManyWithWhereWithoutCoveragePiecesInput>>;
  upsert?: InputMaybe<Array<ClientUpsertWithWhereUniqueWithoutCoveragePiecesInput>>;
};

export type ClientUpdateManyWithoutLocationNestedInput = {
  connect?: InputMaybe<Array<ClientWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClientCreateOrConnectWithoutLocationInput>>;
  create?: InputMaybe<Array<ClientCreateWithoutLocationInput>>;
  createMany?: InputMaybe<ClientCreateManyLocationInputEnvelope>;
  delete?: InputMaybe<Array<ClientWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ClientScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ClientWhereUniqueInput>>;
  set?: InputMaybe<Array<ClientWhereUniqueInput>>;
  update?: InputMaybe<Array<ClientUpdateWithWhereUniqueWithoutLocationInput>>;
  updateMany?: InputMaybe<Array<ClientUpdateManyWithWhereWithoutLocationInput>>;
  upsert?: InputMaybe<Array<ClientUpsertWithWhereUniqueWithoutLocationInput>>;
};

export type ClientUpdateManyWithoutReceivedNewsWrapsNestedInput = {
  connect?: InputMaybe<Array<ClientWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClientCreateOrConnectWithoutReceivedNewsWrapsInput>>;
  create?: InputMaybe<Array<ClientCreateWithoutReceivedNewsWrapsInput>>;
  delete?: InputMaybe<Array<ClientWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ClientScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ClientWhereUniqueInput>>;
  set?: InputMaybe<Array<ClientWhereUniqueInput>>;
  update?: InputMaybe<Array<ClientUpdateWithWhereUniqueWithoutReceivedNewsWrapsInput>>;
  updateMany?: InputMaybe<Array<ClientUpdateManyWithWhereWithoutReceivedNewsWrapsInput>>;
  upsert?: InputMaybe<Array<ClientUpsertWithWhereUniqueWithoutReceivedNewsWrapsInput>>;
};

export type ClientUpdateManyWithoutWorkspaceNestedInput = {
  connect?: InputMaybe<Array<ClientWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClientCreateOrConnectWithoutWorkspaceInput>>;
  create?: InputMaybe<Array<ClientCreateWithoutWorkspaceInput>>;
  createMany?: InputMaybe<ClientCreateManyWorkspaceInputEnvelope>;
  delete?: InputMaybe<Array<ClientWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ClientScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ClientWhereUniqueInput>>;
  set?: InputMaybe<Array<ClientWhereUniqueInput>>;
  update?: InputMaybe<Array<ClientUpdateWithWhereUniqueWithoutWorkspaceInput>>;
  updateMany?: InputMaybe<Array<ClientUpdateManyWithWhereWithoutWorkspaceInput>>;
  upsert?: InputMaybe<Array<ClientUpsertWithWhereUniqueWithoutWorkspaceInput>>;
};

export type ClientUpdateOneRequiredWithoutActivitiesNestedInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutActivitiesInput>;
  create?: InputMaybe<ClientCreateWithoutActivitiesInput>;
  update?: InputMaybe<ClientUpdateToOneWithWhereWithoutActivitiesInput>;
  upsert?: InputMaybe<ClientUpsertWithoutActivitiesInput>;
};

export type ClientUpdateOneRequiredWithoutClientCommentsNestedInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutClientCommentsInput>;
  create?: InputMaybe<ClientCreateWithoutClientCommentsInput>;
  update?: InputMaybe<ClientUpdateToOneWithWhereWithoutClientCommentsInput>;
  upsert?: InputMaybe<ClientUpsertWithoutClientCommentsInput>;
};

export type ClientUpdateOneRequiredWithoutContentNestedInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutContentInput>;
  create?: InputMaybe<ClientCreateWithoutContentInput>;
  update?: InputMaybe<ClientUpdateToOneWithWhereWithoutContentInput>;
  upsert?: InputMaybe<ClientUpsertWithoutContentInput>;
};

export type ClientUpdateOneRequiredWithoutIgnoredSuggestedCoverageNestedInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutIgnoredSuggestedCoverageInput>;
  create?: InputMaybe<ClientCreateWithoutIgnoredSuggestedCoverageInput>;
  update?: InputMaybe<ClientUpdateToOneWithWhereWithoutIgnoredSuggestedCoverageInput>;
  upsert?: InputMaybe<ClientUpsertWithoutIgnoredSuggestedCoverageInput>;
};

export type ClientUpdateOneRequiredWithoutMeetingNotesNestedInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutMeetingNotesInput>;
  create?: InputMaybe<ClientCreateWithoutMeetingNotesInput>;
  update?: InputMaybe<ClientUpdateToOneWithWhereWithoutMeetingNotesInput>;
  upsert?: InputMaybe<ClientUpsertWithoutMeetingNotesInput>;
};

export type ClientUpdateOneRequiredWithoutMentionsNestedInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutMentionsInput>;
  create?: InputMaybe<ClientCreateWithoutMentionsInput>;
  update?: InputMaybe<ClientUpdateToOneWithWhereWithoutMentionsInput>;
  upsert?: InputMaybe<ClientUpsertWithoutMentionsInput>;
};

export type ClientUpdateOneRequiredWithoutNewsJackItemsNestedInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutNewsJackItemsInput>;
  create?: InputMaybe<ClientCreateWithoutNewsJackItemsInput>;
  update?: InputMaybe<ClientUpdateToOneWithWhereWithoutNewsJackItemsInput>;
  upsert?: InputMaybe<ClientUpsertWithoutNewsJackItemsInput>;
};

export type ClientUpdateOneRequiredWithoutProgramPlannerItemsNestedInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutProgramPlannerItemsInput>;
  create?: InputMaybe<ClientCreateWithoutProgramPlannerItemsInput>;
  update?: InputMaybe<ClientUpdateToOneWithWhereWithoutProgramPlannerItemsInput>;
  upsert?: InputMaybe<ClientUpsertWithoutProgramPlannerItemsInput>;
};

export type ClientUpdateOneRequiredWithoutSpokespeopleNestedInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutSpokespeopleInput>;
  create?: InputMaybe<ClientCreateWithoutSpokespeopleInput>;
  update?: InputMaybe<ClientUpdateToOneWithWhereWithoutSpokespeopleInput>;
  upsert?: InputMaybe<ClientUpsertWithoutSpokespeopleInput>;
};

export type ClientUpdateOneWithoutEmployeesNestedInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutEmployeesInput>;
  create?: InputMaybe<ClientCreateWithoutEmployeesInput>;
  delete?: InputMaybe<ClientWhereInput>;
  disconnect?: InputMaybe<ClientWhereInput>;
  update?: InputMaybe<ClientUpdateToOneWithWhereWithoutEmployeesInput>;
  upsert?: InputMaybe<ClientUpsertWithoutEmployeesInput>;
};

export type ClientUpdateOneWithoutFilesNestedInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutFilesInput>;
  create?: InputMaybe<ClientCreateWithoutFilesInput>;
  delete?: InputMaybe<ClientWhereInput>;
  disconnect?: InputMaybe<ClientWhereInput>;
  update?: InputMaybe<ClientUpdateToOneWithWhereWithoutFilesInput>;
  upsert?: InputMaybe<ClientUpsertWithoutFilesInput>;
};

export type ClientUpdateToOneWithWhereWithoutActivitiesInput = {
  data: ClientUpdateWithoutActivitiesInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpdateToOneWithWhereWithoutClientCommentsInput = {
  data: ClientUpdateWithoutClientCommentsInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpdateToOneWithWhereWithoutContentInput = {
  data: ClientUpdateWithoutContentInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpdateToOneWithWhereWithoutEmployeesInput = {
  data: ClientUpdateWithoutEmployeesInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpdateToOneWithWhereWithoutFilesInput = {
  data: ClientUpdateWithoutFilesInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpdateToOneWithWhereWithoutIgnoredSuggestedCoverageInput = {
  data: ClientUpdateWithoutIgnoredSuggestedCoverageInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpdateToOneWithWhereWithoutMeetingNotesInput = {
  data: ClientUpdateWithoutMeetingNotesInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpdateToOneWithWhereWithoutMentionsInput = {
  data: ClientUpdateWithoutMentionsInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpdateToOneWithWhereWithoutNewsJackItemsInput = {
  data: ClientUpdateWithoutNewsJackItemsInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpdateToOneWithWhereWithoutProgramPlannerItemsInput = {
  data: ClientUpdateWithoutProgramPlannerItemsInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpdateToOneWithWhereWithoutSpokespeopleInput = {
  data: ClientUpdateWithoutSpokespeopleInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpdateWithWhereUniqueWithoutAgencyManagersInput = {
  data: ClientUpdateWithoutAgencyManagersInput;
  where: ClientWhereUniqueInput;
};

export type ClientUpdateWithWhereUniqueWithoutCompetitorOfInput = {
  data: ClientUpdateWithoutCompetitorOfInput;
  where: ClientWhereUniqueInput;
};

export type ClientUpdateWithWhereUniqueWithoutCompetitorsInput = {
  data: ClientUpdateWithoutCompetitorsInput;
  where: ClientWhereUniqueInput;
};

export type ClientUpdateWithWhereUniqueWithoutCoveragePiecesInput = {
  data: ClientUpdateWithoutCoveragePiecesInput;
  where: ClientWhereUniqueInput;
};

export type ClientUpdateWithWhereUniqueWithoutLocationInput = {
  data: ClientUpdateWithoutLocationInput;
  where: ClientWhereUniqueInput;
};

export type ClientUpdateWithWhereUniqueWithoutReceivedNewsWrapsInput = {
  data: ClientUpdateWithoutReceivedNewsWrapsInput;
  where: ClientWhereUniqueInput;
};

export type ClientUpdateWithWhereUniqueWithoutWorkspaceInput = {
  data: ClientUpdateWithoutWorkspaceInput;
  where: ClientWhereUniqueInput;
};

export type ClientUpdateWithoutActivitiesInput = {
  agencyManagers?: InputMaybe<UserUpdateManyWithoutManagedClientsNestedInput>;
  alternateNames?: InputMaybe<ClientUpdatealternateNamesInput>;
  boilerplate?: InputMaybe<StringFieldUpdateOperationsInput>;
  clientComments?: InputMaybe<ClientCommentUpdateManyWithoutClientNestedInput>;
  competitorOf?: InputMaybe<ClientUpdateManyWithoutCompetitorsNestedInput>;
  competitors?: InputMaybe<ClientUpdateManyWithoutCompetitorOfNestedInput>;
  content?: InputMaybe<ContentUpdateManyWithoutClientNestedInput>;
  coverageLastSentAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutClientsNestedInput>;
  employees?: InputMaybe<UserUpdateManyWithoutClientEmployerNestedInput>;
  entitlements?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileMetadataUpdateManyWithoutClientNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageUpdateManyWithoutClientNestedInput>;
  isCompetitor?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPublic?: InputMaybe<BoolFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneRequiredWithoutClientsNestedInput>;
  logo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meetingNotes?: InputMaybe<MeetingNotesUpdateManyWithoutClientNestedInput>;
  mentions?: InputMaybe<ClientMentionUpdateManyWithoutClientNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameUniqueness?: InputMaybe<EnumNameUniquenessFieldUpdateOperationsInput>;
  newsJackItems?: InputMaybe<NewsJackItemUpdateManyWithoutClientNestedInput>;
  notes?: InputMaybe<StringFieldUpdateOperationsInput>;
  programPlannerItems?: InputMaybe<ProgramPlannerItemUpdateManyWithoutClientNestedInput>;
  receivedNewsWraps?: InputMaybe<NewsWrapUpdateManyWithoutSentToNestedInput>;
  spokespeople?: InputMaybe<SpokespersonUpdateManyWithoutClientNestedInput>;
  status?: InputMaybe<EnumClientStatusFieldUpdateOperationsInput>;
  tags?: InputMaybe<ClientTagUpdateManyWithoutClientNestedInput>;
};

export type ClientUpdateWithoutAgencyManagersInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutClientNestedInput>;
  alternateNames?: InputMaybe<ClientUpdatealternateNamesInput>;
  boilerplate?: InputMaybe<StringFieldUpdateOperationsInput>;
  clientComments?: InputMaybe<ClientCommentUpdateManyWithoutClientNestedInput>;
  competitorOf?: InputMaybe<ClientUpdateManyWithoutCompetitorsNestedInput>;
  competitors?: InputMaybe<ClientUpdateManyWithoutCompetitorOfNestedInput>;
  content?: InputMaybe<ContentUpdateManyWithoutClientNestedInput>;
  coverageLastSentAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutClientsNestedInput>;
  employees?: InputMaybe<UserUpdateManyWithoutClientEmployerNestedInput>;
  entitlements?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileMetadataUpdateManyWithoutClientNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageUpdateManyWithoutClientNestedInput>;
  isCompetitor?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPublic?: InputMaybe<BoolFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneRequiredWithoutClientsNestedInput>;
  logo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meetingNotes?: InputMaybe<MeetingNotesUpdateManyWithoutClientNestedInput>;
  mentions?: InputMaybe<ClientMentionUpdateManyWithoutClientNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameUniqueness?: InputMaybe<EnumNameUniquenessFieldUpdateOperationsInput>;
  newsJackItems?: InputMaybe<NewsJackItemUpdateManyWithoutClientNestedInput>;
  notes?: InputMaybe<StringFieldUpdateOperationsInput>;
  programPlannerItems?: InputMaybe<ProgramPlannerItemUpdateManyWithoutClientNestedInput>;
  receivedNewsWraps?: InputMaybe<NewsWrapUpdateManyWithoutSentToNestedInput>;
  spokespeople?: InputMaybe<SpokespersonUpdateManyWithoutClientNestedInput>;
  status?: InputMaybe<EnumClientStatusFieldUpdateOperationsInput>;
  tags?: InputMaybe<ClientTagUpdateManyWithoutClientNestedInput>;
};

export type ClientUpdateWithoutClientCommentsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutClientNestedInput>;
  agencyManagers?: InputMaybe<UserUpdateManyWithoutManagedClientsNestedInput>;
  alternateNames?: InputMaybe<ClientUpdatealternateNamesInput>;
  boilerplate?: InputMaybe<StringFieldUpdateOperationsInput>;
  competitorOf?: InputMaybe<ClientUpdateManyWithoutCompetitorsNestedInput>;
  competitors?: InputMaybe<ClientUpdateManyWithoutCompetitorOfNestedInput>;
  content?: InputMaybe<ContentUpdateManyWithoutClientNestedInput>;
  coverageLastSentAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutClientsNestedInput>;
  employees?: InputMaybe<UserUpdateManyWithoutClientEmployerNestedInput>;
  entitlements?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileMetadataUpdateManyWithoutClientNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageUpdateManyWithoutClientNestedInput>;
  isCompetitor?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPublic?: InputMaybe<BoolFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneRequiredWithoutClientsNestedInput>;
  logo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meetingNotes?: InputMaybe<MeetingNotesUpdateManyWithoutClientNestedInput>;
  mentions?: InputMaybe<ClientMentionUpdateManyWithoutClientNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameUniqueness?: InputMaybe<EnumNameUniquenessFieldUpdateOperationsInput>;
  newsJackItems?: InputMaybe<NewsJackItemUpdateManyWithoutClientNestedInput>;
  notes?: InputMaybe<StringFieldUpdateOperationsInput>;
  programPlannerItems?: InputMaybe<ProgramPlannerItemUpdateManyWithoutClientNestedInput>;
  receivedNewsWraps?: InputMaybe<NewsWrapUpdateManyWithoutSentToNestedInput>;
  spokespeople?: InputMaybe<SpokespersonUpdateManyWithoutClientNestedInput>;
  status?: InputMaybe<EnumClientStatusFieldUpdateOperationsInput>;
  tags?: InputMaybe<ClientTagUpdateManyWithoutClientNestedInput>;
};

export type ClientUpdateWithoutCompetitorOfInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutClientNestedInput>;
  agencyManagers?: InputMaybe<UserUpdateManyWithoutManagedClientsNestedInput>;
  alternateNames?: InputMaybe<ClientUpdatealternateNamesInput>;
  boilerplate?: InputMaybe<StringFieldUpdateOperationsInput>;
  clientComments?: InputMaybe<ClientCommentUpdateManyWithoutClientNestedInput>;
  competitors?: InputMaybe<ClientUpdateManyWithoutCompetitorOfNestedInput>;
  content?: InputMaybe<ContentUpdateManyWithoutClientNestedInput>;
  coverageLastSentAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutClientsNestedInput>;
  employees?: InputMaybe<UserUpdateManyWithoutClientEmployerNestedInput>;
  entitlements?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileMetadataUpdateManyWithoutClientNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageUpdateManyWithoutClientNestedInput>;
  isCompetitor?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPublic?: InputMaybe<BoolFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneRequiredWithoutClientsNestedInput>;
  logo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meetingNotes?: InputMaybe<MeetingNotesUpdateManyWithoutClientNestedInput>;
  mentions?: InputMaybe<ClientMentionUpdateManyWithoutClientNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameUniqueness?: InputMaybe<EnumNameUniquenessFieldUpdateOperationsInput>;
  newsJackItems?: InputMaybe<NewsJackItemUpdateManyWithoutClientNestedInput>;
  notes?: InputMaybe<StringFieldUpdateOperationsInput>;
  programPlannerItems?: InputMaybe<ProgramPlannerItemUpdateManyWithoutClientNestedInput>;
  receivedNewsWraps?: InputMaybe<NewsWrapUpdateManyWithoutSentToNestedInput>;
  spokespeople?: InputMaybe<SpokespersonUpdateManyWithoutClientNestedInput>;
  status?: InputMaybe<EnumClientStatusFieldUpdateOperationsInput>;
  tags?: InputMaybe<ClientTagUpdateManyWithoutClientNestedInput>;
};

export type ClientUpdateWithoutCompetitorsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutClientNestedInput>;
  agencyManagers?: InputMaybe<UserUpdateManyWithoutManagedClientsNestedInput>;
  alternateNames?: InputMaybe<ClientUpdatealternateNamesInput>;
  boilerplate?: InputMaybe<StringFieldUpdateOperationsInput>;
  clientComments?: InputMaybe<ClientCommentUpdateManyWithoutClientNestedInput>;
  competitorOf?: InputMaybe<ClientUpdateManyWithoutCompetitorsNestedInput>;
  content?: InputMaybe<ContentUpdateManyWithoutClientNestedInput>;
  coverageLastSentAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutClientsNestedInput>;
  employees?: InputMaybe<UserUpdateManyWithoutClientEmployerNestedInput>;
  entitlements?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileMetadataUpdateManyWithoutClientNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageUpdateManyWithoutClientNestedInput>;
  isCompetitor?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPublic?: InputMaybe<BoolFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneRequiredWithoutClientsNestedInput>;
  logo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meetingNotes?: InputMaybe<MeetingNotesUpdateManyWithoutClientNestedInput>;
  mentions?: InputMaybe<ClientMentionUpdateManyWithoutClientNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameUniqueness?: InputMaybe<EnumNameUniquenessFieldUpdateOperationsInput>;
  newsJackItems?: InputMaybe<NewsJackItemUpdateManyWithoutClientNestedInput>;
  notes?: InputMaybe<StringFieldUpdateOperationsInput>;
  programPlannerItems?: InputMaybe<ProgramPlannerItemUpdateManyWithoutClientNestedInput>;
  receivedNewsWraps?: InputMaybe<NewsWrapUpdateManyWithoutSentToNestedInput>;
  spokespeople?: InputMaybe<SpokespersonUpdateManyWithoutClientNestedInput>;
  status?: InputMaybe<EnumClientStatusFieldUpdateOperationsInput>;
  tags?: InputMaybe<ClientTagUpdateManyWithoutClientNestedInput>;
};

export type ClientUpdateWithoutContentInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutClientNestedInput>;
  agencyManagers?: InputMaybe<UserUpdateManyWithoutManagedClientsNestedInput>;
  alternateNames?: InputMaybe<ClientUpdatealternateNamesInput>;
  boilerplate?: InputMaybe<StringFieldUpdateOperationsInput>;
  clientComments?: InputMaybe<ClientCommentUpdateManyWithoutClientNestedInput>;
  competitorOf?: InputMaybe<ClientUpdateManyWithoutCompetitorsNestedInput>;
  competitors?: InputMaybe<ClientUpdateManyWithoutCompetitorOfNestedInput>;
  coverageLastSentAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutClientsNestedInput>;
  employees?: InputMaybe<UserUpdateManyWithoutClientEmployerNestedInput>;
  entitlements?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileMetadataUpdateManyWithoutClientNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageUpdateManyWithoutClientNestedInput>;
  isCompetitor?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPublic?: InputMaybe<BoolFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneRequiredWithoutClientsNestedInput>;
  logo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meetingNotes?: InputMaybe<MeetingNotesUpdateManyWithoutClientNestedInput>;
  mentions?: InputMaybe<ClientMentionUpdateManyWithoutClientNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameUniqueness?: InputMaybe<EnumNameUniquenessFieldUpdateOperationsInput>;
  newsJackItems?: InputMaybe<NewsJackItemUpdateManyWithoutClientNestedInput>;
  notes?: InputMaybe<StringFieldUpdateOperationsInput>;
  programPlannerItems?: InputMaybe<ProgramPlannerItemUpdateManyWithoutClientNestedInput>;
  receivedNewsWraps?: InputMaybe<NewsWrapUpdateManyWithoutSentToNestedInput>;
  spokespeople?: InputMaybe<SpokespersonUpdateManyWithoutClientNestedInput>;
  status?: InputMaybe<EnumClientStatusFieldUpdateOperationsInput>;
  tags?: InputMaybe<ClientTagUpdateManyWithoutClientNestedInput>;
};

export type ClientUpdateWithoutCoveragePiecesInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutClientNestedInput>;
  agencyManagers?: InputMaybe<UserUpdateManyWithoutManagedClientsNestedInput>;
  alternateNames?: InputMaybe<ClientUpdatealternateNamesInput>;
  boilerplate?: InputMaybe<StringFieldUpdateOperationsInput>;
  clientComments?: InputMaybe<ClientCommentUpdateManyWithoutClientNestedInput>;
  competitorOf?: InputMaybe<ClientUpdateManyWithoutCompetitorsNestedInput>;
  competitors?: InputMaybe<ClientUpdateManyWithoutCompetitorOfNestedInput>;
  content?: InputMaybe<ContentUpdateManyWithoutClientNestedInput>;
  coverageLastSentAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  employees?: InputMaybe<UserUpdateManyWithoutClientEmployerNestedInput>;
  entitlements?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileMetadataUpdateManyWithoutClientNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageUpdateManyWithoutClientNestedInput>;
  isCompetitor?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPublic?: InputMaybe<BoolFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneRequiredWithoutClientsNestedInput>;
  logo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meetingNotes?: InputMaybe<MeetingNotesUpdateManyWithoutClientNestedInput>;
  mentions?: InputMaybe<ClientMentionUpdateManyWithoutClientNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameUniqueness?: InputMaybe<EnumNameUniquenessFieldUpdateOperationsInput>;
  newsJackItems?: InputMaybe<NewsJackItemUpdateManyWithoutClientNestedInput>;
  notes?: InputMaybe<StringFieldUpdateOperationsInput>;
  programPlannerItems?: InputMaybe<ProgramPlannerItemUpdateManyWithoutClientNestedInput>;
  receivedNewsWraps?: InputMaybe<NewsWrapUpdateManyWithoutSentToNestedInput>;
  spokespeople?: InputMaybe<SpokespersonUpdateManyWithoutClientNestedInput>;
  status?: InputMaybe<EnumClientStatusFieldUpdateOperationsInput>;
  tags?: InputMaybe<ClientTagUpdateManyWithoutClientNestedInput>;
};

export type ClientUpdateWithoutEmployeesInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutClientNestedInput>;
  agencyManagers?: InputMaybe<UserUpdateManyWithoutManagedClientsNestedInput>;
  alternateNames?: InputMaybe<ClientUpdatealternateNamesInput>;
  boilerplate?: InputMaybe<StringFieldUpdateOperationsInput>;
  clientComments?: InputMaybe<ClientCommentUpdateManyWithoutClientNestedInput>;
  competitorOf?: InputMaybe<ClientUpdateManyWithoutCompetitorsNestedInput>;
  competitors?: InputMaybe<ClientUpdateManyWithoutCompetitorOfNestedInput>;
  content?: InputMaybe<ContentUpdateManyWithoutClientNestedInput>;
  coverageLastSentAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutClientsNestedInput>;
  entitlements?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileMetadataUpdateManyWithoutClientNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageUpdateManyWithoutClientNestedInput>;
  isCompetitor?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPublic?: InputMaybe<BoolFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneRequiredWithoutClientsNestedInput>;
  logo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meetingNotes?: InputMaybe<MeetingNotesUpdateManyWithoutClientNestedInput>;
  mentions?: InputMaybe<ClientMentionUpdateManyWithoutClientNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameUniqueness?: InputMaybe<EnumNameUniquenessFieldUpdateOperationsInput>;
  newsJackItems?: InputMaybe<NewsJackItemUpdateManyWithoutClientNestedInput>;
  notes?: InputMaybe<StringFieldUpdateOperationsInput>;
  programPlannerItems?: InputMaybe<ProgramPlannerItemUpdateManyWithoutClientNestedInput>;
  receivedNewsWraps?: InputMaybe<NewsWrapUpdateManyWithoutSentToNestedInput>;
  spokespeople?: InputMaybe<SpokespersonUpdateManyWithoutClientNestedInput>;
  status?: InputMaybe<EnumClientStatusFieldUpdateOperationsInput>;
  tags?: InputMaybe<ClientTagUpdateManyWithoutClientNestedInput>;
};

export type ClientUpdateWithoutFilesInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutClientNestedInput>;
  agencyManagers?: InputMaybe<UserUpdateManyWithoutManagedClientsNestedInput>;
  alternateNames?: InputMaybe<ClientUpdatealternateNamesInput>;
  boilerplate?: InputMaybe<StringFieldUpdateOperationsInput>;
  clientComments?: InputMaybe<ClientCommentUpdateManyWithoutClientNestedInput>;
  competitorOf?: InputMaybe<ClientUpdateManyWithoutCompetitorsNestedInput>;
  competitors?: InputMaybe<ClientUpdateManyWithoutCompetitorOfNestedInput>;
  content?: InputMaybe<ContentUpdateManyWithoutClientNestedInput>;
  coverageLastSentAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutClientsNestedInput>;
  employees?: InputMaybe<UserUpdateManyWithoutClientEmployerNestedInput>;
  entitlements?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageUpdateManyWithoutClientNestedInput>;
  isCompetitor?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPublic?: InputMaybe<BoolFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneRequiredWithoutClientsNestedInput>;
  logo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meetingNotes?: InputMaybe<MeetingNotesUpdateManyWithoutClientNestedInput>;
  mentions?: InputMaybe<ClientMentionUpdateManyWithoutClientNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameUniqueness?: InputMaybe<EnumNameUniquenessFieldUpdateOperationsInput>;
  newsJackItems?: InputMaybe<NewsJackItemUpdateManyWithoutClientNestedInput>;
  notes?: InputMaybe<StringFieldUpdateOperationsInput>;
  programPlannerItems?: InputMaybe<ProgramPlannerItemUpdateManyWithoutClientNestedInput>;
  receivedNewsWraps?: InputMaybe<NewsWrapUpdateManyWithoutSentToNestedInput>;
  spokespeople?: InputMaybe<SpokespersonUpdateManyWithoutClientNestedInput>;
  status?: InputMaybe<EnumClientStatusFieldUpdateOperationsInput>;
  tags?: InputMaybe<ClientTagUpdateManyWithoutClientNestedInput>;
};

export type ClientUpdateWithoutIgnoredSuggestedCoverageInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutClientNestedInput>;
  agencyManagers?: InputMaybe<UserUpdateManyWithoutManagedClientsNestedInput>;
  alternateNames?: InputMaybe<ClientUpdatealternateNamesInput>;
  boilerplate?: InputMaybe<StringFieldUpdateOperationsInput>;
  clientComments?: InputMaybe<ClientCommentUpdateManyWithoutClientNestedInput>;
  competitorOf?: InputMaybe<ClientUpdateManyWithoutCompetitorsNestedInput>;
  competitors?: InputMaybe<ClientUpdateManyWithoutCompetitorOfNestedInput>;
  content?: InputMaybe<ContentUpdateManyWithoutClientNestedInput>;
  coverageLastSentAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutClientsNestedInput>;
  employees?: InputMaybe<UserUpdateManyWithoutClientEmployerNestedInput>;
  entitlements?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileMetadataUpdateManyWithoutClientNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isCompetitor?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPublic?: InputMaybe<BoolFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneRequiredWithoutClientsNestedInput>;
  logo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meetingNotes?: InputMaybe<MeetingNotesUpdateManyWithoutClientNestedInput>;
  mentions?: InputMaybe<ClientMentionUpdateManyWithoutClientNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameUniqueness?: InputMaybe<EnumNameUniquenessFieldUpdateOperationsInput>;
  newsJackItems?: InputMaybe<NewsJackItemUpdateManyWithoutClientNestedInput>;
  notes?: InputMaybe<StringFieldUpdateOperationsInput>;
  programPlannerItems?: InputMaybe<ProgramPlannerItemUpdateManyWithoutClientNestedInput>;
  receivedNewsWraps?: InputMaybe<NewsWrapUpdateManyWithoutSentToNestedInput>;
  spokespeople?: InputMaybe<SpokespersonUpdateManyWithoutClientNestedInput>;
  status?: InputMaybe<EnumClientStatusFieldUpdateOperationsInput>;
  tags?: InputMaybe<ClientTagUpdateManyWithoutClientNestedInput>;
};

export type ClientUpdateWithoutLocationInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutClientNestedInput>;
  agencyManagers?: InputMaybe<UserUpdateManyWithoutManagedClientsNestedInput>;
  alternateNames?: InputMaybe<ClientUpdatealternateNamesInput>;
  boilerplate?: InputMaybe<StringFieldUpdateOperationsInput>;
  clientComments?: InputMaybe<ClientCommentUpdateManyWithoutClientNestedInput>;
  competitorOf?: InputMaybe<ClientUpdateManyWithoutCompetitorsNestedInput>;
  competitors?: InputMaybe<ClientUpdateManyWithoutCompetitorOfNestedInput>;
  content?: InputMaybe<ContentUpdateManyWithoutClientNestedInput>;
  coverageLastSentAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutClientsNestedInput>;
  employees?: InputMaybe<UserUpdateManyWithoutClientEmployerNestedInput>;
  entitlements?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileMetadataUpdateManyWithoutClientNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageUpdateManyWithoutClientNestedInput>;
  isCompetitor?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPublic?: InputMaybe<BoolFieldUpdateOperationsInput>;
  logo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meetingNotes?: InputMaybe<MeetingNotesUpdateManyWithoutClientNestedInput>;
  mentions?: InputMaybe<ClientMentionUpdateManyWithoutClientNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameUniqueness?: InputMaybe<EnumNameUniquenessFieldUpdateOperationsInput>;
  newsJackItems?: InputMaybe<NewsJackItemUpdateManyWithoutClientNestedInput>;
  notes?: InputMaybe<StringFieldUpdateOperationsInput>;
  programPlannerItems?: InputMaybe<ProgramPlannerItemUpdateManyWithoutClientNestedInput>;
  receivedNewsWraps?: InputMaybe<NewsWrapUpdateManyWithoutSentToNestedInput>;
  spokespeople?: InputMaybe<SpokespersonUpdateManyWithoutClientNestedInput>;
  status?: InputMaybe<EnumClientStatusFieldUpdateOperationsInput>;
  tags?: InputMaybe<ClientTagUpdateManyWithoutClientNestedInput>;
};

export type ClientUpdateWithoutMeetingNotesInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutClientNestedInput>;
  agencyManagers?: InputMaybe<UserUpdateManyWithoutManagedClientsNestedInput>;
  alternateNames?: InputMaybe<ClientUpdatealternateNamesInput>;
  boilerplate?: InputMaybe<StringFieldUpdateOperationsInput>;
  clientComments?: InputMaybe<ClientCommentUpdateManyWithoutClientNestedInput>;
  competitorOf?: InputMaybe<ClientUpdateManyWithoutCompetitorsNestedInput>;
  competitors?: InputMaybe<ClientUpdateManyWithoutCompetitorOfNestedInput>;
  content?: InputMaybe<ContentUpdateManyWithoutClientNestedInput>;
  coverageLastSentAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutClientsNestedInput>;
  employees?: InputMaybe<UserUpdateManyWithoutClientEmployerNestedInput>;
  entitlements?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileMetadataUpdateManyWithoutClientNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageUpdateManyWithoutClientNestedInput>;
  isCompetitor?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPublic?: InputMaybe<BoolFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneRequiredWithoutClientsNestedInput>;
  logo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mentions?: InputMaybe<ClientMentionUpdateManyWithoutClientNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameUniqueness?: InputMaybe<EnumNameUniquenessFieldUpdateOperationsInput>;
  newsJackItems?: InputMaybe<NewsJackItemUpdateManyWithoutClientNestedInput>;
  notes?: InputMaybe<StringFieldUpdateOperationsInput>;
  programPlannerItems?: InputMaybe<ProgramPlannerItemUpdateManyWithoutClientNestedInput>;
  receivedNewsWraps?: InputMaybe<NewsWrapUpdateManyWithoutSentToNestedInput>;
  spokespeople?: InputMaybe<SpokespersonUpdateManyWithoutClientNestedInput>;
  status?: InputMaybe<EnumClientStatusFieldUpdateOperationsInput>;
  tags?: InputMaybe<ClientTagUpdateManyWithoutClientNestedInput>;
};

export type ClientUpdateWithoutMentionsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutClientNestedInput>;
  agencyManagers?: InputMaybe<UserUpdateManyWithoutManagedClientsNestedInput>;
  alternateNames?: InputMaybe<ClientUpdatealternateNamesInput>;
  boilerplate?: InputMaybe<StringFieldUpdateOperationsInput>;
  clientComments?: InputMaybe<ClientCommentUpdateManyWithoutClientNestedInput>;
  competitorOf?: InputMaybe<ClientUpdateManyWithoutCompetitorsNestedInput>;
  competitors?: InputMaybe<ClientUpdateManyWithoutCompetitorOfNestedInput>;
  content?: InputMaybe<ContentUpdateManyWithoutClientNestedInput>;
  coverageLastSentAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutClientsNestedInput>;
  employees?: InputMaybe<UserUpdateManyWithoutClientEmployerNestedInput>;
  entitlements?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileMetadataUpdateManyWithoutClientNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageUpdateManyWithoutClientNestedInput>;
  isCompetitor?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPublic?: InputMaybe<BoolFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneRequiredWithoutClientsNestedInput>;
  logo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meetingNotes?: InputMaybe<MeetingNotesUpdateManyWithoutClientNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameUniqueness?: InputMaybe<EnumNameUniquenessFieldUpdateOperationsInput>;
  newsJackItems?: InputMaybe<NewsJackItemUpdateManyWithoutClientNestedInput>;
  notes?: InputMaybe<StringFieldUpdateOperationsInput>;
  programPlannerItems?: InputMaybe<ProgramPlannerItemUpdateManyWithoutClientNestedInput>;
  receivedNewsWraps?: InputMaybe<NewsWrapUpdateManyWithoutSentToNestedInput>;
  spokespeople?: InputMaybe<SpokespersonUpdateManyWithoutClientNestedInput>;
  status?: InputMaybe<EnumClientStatusFieldUpdateOperationsInput>;
  tags?: InputMaybe<ClientTagUpdateManyWithoutClientNestedInput>;
};

export type ClientUpdateWithoutNewsJackItemsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutClientNestedInput>;
  agencyManagers?: InputMaybe<UserUpdateManyWithoutManagedClientsNestedInput>;
  alternateNames?: InputMaybe<ClientUpdatealternateNamesInput>;
  boilerplate?: InputMaybe<StringFieldUpdateOperationsInput>;
  clientComments?: InputMaybe<ClientCommentUpdateManyWithoutClientNestedInput>;
  competitorOf?: InputMaybe<ClientUpdateManyWithoutCompetitorsNestedInput>;
  competitors?: InputMaybe<ClientUpdateManyWithoutCompetitorOfNestedInput>;
  content?: InputMaybe<ContentUpdateManyWithoutClientNestedInput>;
  coverageLastSentAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutClientsNestedInput>;
  employees?: InputMaybe<UserUpdateManyWithoutClientEmployerNestedInput>;
  entitlements?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileMetadataUpdateManyWithoutClientNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageUpdateManyWithoutClientNestedInput>;
  isCompetitor?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPublic?: InputMaybe<BoolFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneRequiredWithoutClientsNestedInput>;
  logo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meetingNotes?: InputMaybe<MeetingNotesUpdateManyWithoutClientNestedInput>;
  mentions?: InputMaybe<ClientMentionUpdateManyWithoutClientNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameUniqueness?: InputMaybe<EnumNameUniquenessFieldUpdateOperationsInput>;
  notes?: InputMaybe<StringFieldUpdateOperationsInput>;
  programPlannerItems?: InputMaybe<ProgramPlannerItemUpdateManyWithoutClientNestedInput>;
  receivedNewsWraps?: InputMaybe<NewsWrapUpdateManyWithoutSentToNestedInput>;
  spokespeople?: InputMaybe<SpokespersonUpdateManyWithoutClientNestedInput>;
  status?: InputMaybe<EnumClientStatusFieldUpdateOperationsInput>;
  tags?: InputMaybe<ClientTagUpdateManyWithoutClientNestedInput>;
};

export type ClientUpdateWithoutProgramPlannerItemsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutClientNestedInput>;
  agencyManagers?: InputMaybe<UserUpdateManyWithoutManagedClientsNestedInput>;
  alternateNames?: InputMaybe<ClientUpdatealternateNamesInput>;
  boilerplate?: InputMaybe<StringFieldUpdateOperationsInput>;
  clientComments?: InputMaybe<ClientCommentUpdateManyWithoutClientNestedInput>;
  competitorOf?: InputMaybe<ClientUpdateManyWithoutCompetitorsNestedInput>;
  competitors?: InputMaybe<ClientUpdateManyWithoutCompetitorOfNestedInput>;
  content?: InputMaybe<ContentUpdateManyWithoutClientNestedInput>;
  coverageLastSentAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutClientsNestedInput>;
  employees?: InputMaybe<UserUpdateManyWithoutClientEmployerNestedInput>;
  entitlements?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileMetadataUpdateManyWithoutClientNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageUpdateManyWithoutClientNestedInput>;
  isCompetitor?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPublic?: InputMaybe<BoolFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneRequiredWithoutClientsNestedInput>;
  logo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meetingNotes?: InputMaybe<MeetingNotesUpdateManyWithoutClientNestedInput>;
  mentions?: InputMaybe<ClientMentionUpdateManyWithoutClientNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameUniqueness?: InputMaybe<EnumNameUniquenessFieldUpdateOperationsInput>;
  newsJackItems?: InputMaybe<NewsJackItemUpdateManyWithoutClientNestedInput>;
  notes?: InputMaybe<StringFieldUpdateOperationsInput>;
  receivedNewsWraps?: InputMaybe<NewsWrapUpdateManyWithoutSentToNestedInput>;
  spokespeople?: InputMaybe<SpokespersonUpdateManyWithoutClientNestedInput>;
  status?: InputMaybe<EnumClientStatusFieldUpdateOperationsInput>;
  tags?: InputMaybe<ClientTagUpdateManyWithoutClientNestedInput>;
};

export type ClientUpdateWithoutReceivedNewsWrapsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutClientNestedInput>;
  agencyManagers?: InputMaybe<UserUpdateManyWithoutManagedClientsNestedInput>;
  alternateNames?: InputMaybe<ClientUpdatealternateNamesInput>;
  boilerplate?: InputMaybe<StringFieldUpdateOperationsInput>;
  clientComments?: InputMaybe<ClientCommentUpdateManyWithoutClientNestedInput>;
  competitorOf?: InputMaybe<ClientUpdateManyWithoutCompetitorsNestedInput>;
  competitors?: InputMaybe<ClientUpdateManyWithoutCompetitorOfNestedInput>;
  content?: InputMaybe<ContentUpdateManyWithoutClientNestedInput>;
  coverageLastSentAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutClientsNestedInput>;
  employees?: InputMaybe<UserUpdateManyWithoutClientEmployerNestedInput>;
  entitlements?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileMetadataUpdateManyWithoutClientNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageUpdateManyWithoutClientNestedInput>;
  isCompetitor?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPublic?: InputMaybe<BoolFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneRequiredWithoutClientsNestedInput>;
  logo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meetingNotes?: InputMaybe<MeetingNotesUpdateManyWithoutClientNestedInput>;
  mentions?: InputMaybe<ClientMentionUpdateManyWithoutClientNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameUniqueness?: InputMaybe<EnumNameUniquenessFieldUpdateOperationsInput>;
  newsJackItems?: InputMaybe<NewsJackItemUpdateManyWithoutClientNestedInput>;
  notes?: InputMaybe<StringFieldUpdateOperationsInput>;
  programPlannerItems?: InputMaybe<ProgramPlannerItemUpdateManyWithoutClientNestedInput>;
  spokespeople?: InputMaybe<SpokespersonUpdateManyWithoutClientNestedInput>;
  status?: InputMaybe<EnumClientStatusFieldUpdateOperationsInput>;
  tags?: InputMaybe<ClientTagUpdateManyWithoutClientNestedInput>;
};

export type ClientUpdateWithoutSpokespeopleInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutClientNestedInput>;
  agencyManagers?: InputMaybe<UserUpdateManyWithoutManagedClientsNestedInput>;
  alternateNames?: InputMaybe<ClientUpdatealternateNamesInput>;
  boilerplate?: InputMaybe<StringFieldUpdateOperationsInput>;
  clientComments?: InputMaybe<ClientCommentUpdateManyWithoutClientNestedInput>;
  competitorOf?: InputMaybe<ClientUpdateManyWithoutCompetitorsNestedInput>;
  competitors?: InputMaybe<ClientUpdateManyWithoutCompetitorOfNestedInput>;
  content?: InputMaybe<ContentUpdateManyWithoutClientNestedInput>;
  coverageLastSentAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutClientsNestedInput>;
  employees?: InputMaybe<UserUpdateManyWithoutClientEmployerNestedInput>;
  entitlements?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileMetadataUpdateManyWithoutClientNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageUpdateManyWithoutClientNestedInput>;
  isCompetitor?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPublic?: InputMaybe<BoolFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneRequiredWithoutClientsNestedInput>;
  logo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meetingNotes?: InputMaybe<MeetingNotesUpdateManyWithoutClientNestedInput>;
  mentions?: InputMaybe<ClientMentionUpdateManyWithoutClientNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameUniqueness?: InputMaybe<EnumNameUniquenessFieldUpdateOperationsInput>;
  newsJackItems?: InputMaybe<NewsJackItemUpdateManyWithoutClientNestedInput>;
  notes?: InputMaybe<StringFieldUpdateOperationsInput>;
  programPlannerItems?: InputMaybe<ProgramPlannerItemUpdateManyWithoutClientNestedInput>;
  receivedNewsWraps?: InputMaybe<NewsWrapUpdateManyWithoutSentToNestedInput>;
  status?: InputMaybe<EnumClientStatusFieldUpdateOperationsInput>;
  tags?: InputMaybe<ClientTagUpdateManyWithoutClientNestedInput>;
};

export type ClientUpdateWithoutWorkspaceInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutClientNestedInput>;
  agencyManagers?: InputMaybe<UserUpdateManyWithoutManagedClientsNestedInput>;
  alternateNames?: InputMaybe<ClientUpdatealternateNamesInput>;
  boilerplate?: InputMaybe<StringFieldUpdateOperationsInput>;
  clientComments?: InputMaybe<ClientCommentUpdateManyWithoutClientNestedInput>;
  competitorOf?: InputMaybe<ClientUpdateManyWithoutCompetitorsNestedInput>;
  competitors?: InputMaybe<ClientUpdateManyWithoutCompetitorOfNestedInput>;
  content?: InputMaybe<ContentUpdateManyWithoutClientNestedInput>;
  coverageLastSentAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutClientsNestedInput>;
  employees?: InputMaybe<UserUpdateManyWithoutClientEmployerNestedInput>;
  entitlements?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileMetadataUpdateManyWithoutClientNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageUpdateManyWithoutClientNestedInput>;
  isCompetitor?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPublic?: InputMaybe<BoolFieldUpdateOperationsInput>;
  location?: InputMaybe<LocationUpdateOneRequiredWithoutClientsNestedInput>;
  logo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meetingNotes?: InputMaybe<MeetingNotesUpdateManyWithoutClientNestedInput>;
  mentions?: InputMaybe<ClientMentionUpdateManyWithoutClientNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameUniqueness?: InputMaybe<EnumNameUniquenessFieldUpdateOperationsInput>;
  newsJackItems?: InputMaybe<NewsJackItemUpdateManyWithoutClientNestedInput>;
  notes?: InputMaybe<StringFieldUpdateOperationsInput>;
  programPlannerItems?: InputMaybe<ProgramPlannerItemUpdateManyWithoutClientNestedInput>;
  receivedNewsWraps?: InputMaybe<NewsWrapUpdateManyWithoutSentToNestedInput>;
  spokespeople?: InputMaybe<SpokespersonUpdateManyWithoutClientNestedInput>;
  status?: InputMaybe<EnumClientStatusFieldUpdateOperationsInput>;
  tags?: InputMaybe<ClientTagUpdateManyWithoutClientNestedInput>;
};

export type ClientUpdatealternateNamesInput = {
  push?: InputMaybe<Array<Scalars['String']['input']>>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ClientUpsertWithWhereUniqueWithoutAgencyManagersInput = {
  create: ClientCreateWithoutAgencyManagersInput;
  update: ClientUpdateWithoutAgencyManagersInput;
  where: ClientWhereUniqueInput;
};

export type ClientUpsertWithWhereUniqueWithoutCompetitorOfInput = {
  create: ClientCreateWithoutCompetitorOfInput;
  update: ClientUpdateWithoutCompetitorOfInput;
  where: ClientWhereUniqueInput;
};

export type ClientUpsertWithWhereUniqueWithoutCompetitorsInput = {
  create: ClientCreateWithoutCompetitorsInput;
  update: ClientUpdateWithoutCompetitorsInput;
  where: ClientWhereUniqueInput;
};

export type ClientUpsertWithWhereUniqueWithoutCoveragePiecesInput = {
  create: ClientCreateWithoutCoveragePiecesInput;
  update: ClientUpdateWithoutCoveragePiecesInput;
  where: ClientWhereUniqueInput;
};

export type ClientUpsertWithWhereUniqueWithoutLocationInput = {
  create: ClientCreateWithoutLocationInput;
  update: ClientUpdateWithoutLocationInput;
  where: ClientWhereUniqueInput;
};

export type ClientUpsertWithWhereUniqueWithoutReceivedNewsWrapsInput = {
  create: ClientCreateWithoutReceivedNewsWrapsInput;
  update: ClientUpdateWithoutReceivedNewsWrapsInput;
  where: ClientWhereUniqueInput;
};

export type ClientUpsertWithWhereUniqueWithoutWorkspaceInput = {
  create: ClientCreateWithoutWorkspaceInput;
  update: ClientUpdateWithoutWorkspaceInput;
  where: ClientWhereUniqueInput;
};

export type ClientUpsertWithoutActivitiesInput = {
  create: ClientCreateWithoutActivitiesInput;
  update: ClientUpdateWithoutActivitiesInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpsertWithoutClientCommentsInput = {
  create: ClientCreateWithoutClientCommentsInput;
  update: ClientUpdateWithoutClientCommentsInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpsertWithoutContentInput = {
  create: ClientCreateWithoutContentInput;
  update: ClientUpdateWithoutContentInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpsertWithoutEmployeesInput = {
  create: ClientCreateWithoutEmployeesInput;
  update: ClientUpdateWithoutEmployeesInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpsertWithoutFilesInput = {
  create: ClientCreateWithoutFilesInput;
  update: ClientUpdateWithoutFilesInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpsertWithoutIgnoredSuggestedCoverageInput = {
  create: ClientCreateWithoutIgnoredSuggestedCoverageInput;
  update: ClientUpdateWithoutIgnoredSuggestedCoverageInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpsertWithoutMeetingNotesInput = {
  create: ClientCreateWithoutMeetingNotesInput;
  update: ClientUpdateWithoutMeetingNotesInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpsertWithoutMentionsInput = {
  create: ClientCreateWithoutMentionsInput;
  update: ClientUpdateWithoutMentionsInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpsertWithoutNewsJackItemsInput = {
  create: ClientCreateWithoutNewsJackItemsInput;
  update: ClientUpdateWithoutNewsJackItemsInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpsertWithoutProgramPlannerItemsInput = {
  create: ClientCreateWithoutProgramPlannerItemsInput;
  update: ClientUpdateWithoutProgramPlannerItemsInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpsertWithoutSpokespeopleInput = {
  create: ClientCreateWithoutSpokespeopleInput;
  update: ClientUpdateWithoutSpokespeopleInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientWhereInput = {
  AND?: InputMaybe<Array<ClientWhereInput>>;
  NOT?: InputMaybe<Array<ClientWhereInput>>;
  OR?: InputMaybe<Array<ClientWhereInput>>;
  activities?: InputMaybe<ActivityListRelationFilter>;
  agencyManagers?: InputMaybe<UserListRelationFilter>;
  alternateNames?: InputMaybe<StringNullableListFilter>;
  boilerplate?: InputMaybe<StringFilter>;
  clientComments?: InputMaybe<ClientCommentListRelationFilter>;
  competitorOf?: InputMaybe<ClientListRelationFilter>;
  competitors?: InputMaybe<ClientListRelationFilter>;
  content?: InputMaybe<ContentListRelationFilter>;
  coverageLastSentAt?: InputMaybe<DateTimeNullableFilter>;
  coveragePieces?: InputMaybe<CoveragePieceListRelationFilter>;
  employees?: InputMaybe<UserListRelationFilter>;
  entitlements?: InputMaybe<StringFilter>;
  files?: InputMaybe<FileMetadataListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageListRelationFilter>;
  isCompetitor?: InputMaybe<BoolFilter>;
  isPublic?: InputMaybe<BoolFilter>;
  location?: InputMaybe<LocationRelationFilter>;
  locationId?: InputMaybe<StringFilter>;
  logo?: InputMaybe<StringNullableFilter>;
  meetingNotes?: InputMaybe<MeetingNotesListRelationFilter>;
  mentions?: InputMaybe<ClientMentionListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  nameUniqueness?: InputMaybe<EnumNameUniquenessFilter>;
  newsJackItems?: InputMaybe<NewsJackItemListRelationFilter>;
  notes?: InputMaybe<StringFilter>;
  programPlannerItems?: InputMaybe<ProgramPlannerItemListRelationFilter>;
  receivedNewsWraps?: InputMaybe<NewsWrapListRelationFilter>;
  spokespeople?: InputMaybe<SpokespersonListRelationFilter>;
  status?: InputMaybe<EnumClientStatusFilter>;
  tags?: InputMaybe<ClientTagListRelationFilter>;
  workspaceId?: InputMaybe<StringFilter>;
};

export type ClientWhereUniqueInput = {
  AND?: InputMaybe<Array<ClientWhereInput>>;
  NOT?: InputMaybe<Array<ClientWhereInput>>;
  OR?: InputMaybe<Array<ClientWhereInput>>;
  activities?: InputMaybe<ActivityListRelationFilter>;
  agencyManagers?: InputMaybe<UserListRelationFilter>;
  alternateNames?: InputMaybe<StringNullableListFilter>;
  boilerplate?: InputMaybe<StringFilter>;
  clientComments?: InputMaybe<ClientCommentListRelationFilter>;
  competitorOf?: InputMaybe<ClientListRelationFilter>;
  competitors?: InputMaybe<ClientListRelationFilter>;
  content?: InputMaybe<ContentListRelationFilter>;
  coverageLastSentAt?: InputMaybe<DateTimeNullableFilter>;
  coveragePieces?: InputMaybe<CoveragePieceListRelationFilter>;
  employees?: InputMaybe<UserListRelationFilter>;
  entitlements?: InputMaybe<StringFilter>;
  files?: InputMaybe<FileMetadataListRelationFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignoredSuggestedCoverage?: InputMaybe<IgnoredSuggestedCoverageListRelationFilter>;
  isCompetitor?: InputMaybe<BoolFilter>;
  isPublic?: InputMaybe<BoolFilter>;
  location?: InputMaybe<LocationRelationFilter>;
  locationId?: InputMaybe<StringFilter>;
  logo?: InputMaybe<StringNullableFilter>;
  meetingNotes?: InputMaybe<MeetingNotesListRelationFilter>;
  mentions?: InputMaybe<ClientMentionListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  nameUniqueness?: InputMaybe<EnumNameUniquenessFilter>;
  newsJackItems?: InputMaybe<NewsJackItemListRelationFilter>;
  notes?: InputMaybe<StringFilter>;
  programPlannerItems?: InputMaybe<ProgramPlannerItemListRelationFilter>;
  receivedNewsWraps?: InputMaybe<NewsWrapListRelationFilter>;
  spokespeople?: InputMaybe<SpokespersonListRelationFilter>;
  status?: InputMaybe<EnumClientStatusFilter>;
  tags?: InputMaybe<ClientTagListRelationFilter>;
  workspaceId?: InputMaybe<StringFilter>;
};

export type ClientWithSentiment = {
  __typename?: 'ClientWithSentiment';
  _count?: Maybe<ClientCount>;
  activities: Array<Activity>;
  agencyManagers: Array<User>;
  alternateNames: Array<Scalars['String']['output']>;
  boilerplate: Scalars['String']['output'];
  clientComments: Array<ClientComment>;
  competitors: Array<Client>;
  content: Array<Content>;
  coverageLastSentAt?: Maybe<Scalars['DateTime']['output']>;
  coveragePieces: Array<CoveragePiece>;
  employees: Array<User>;
  entitlements: Scalars['String']['output'];
  files: Array<FileMetadata>;
  id: Scalars['String']['output'];
  ignoredSuggestedCoverage: Array<IgnoredSuggestedCoverage>;
  isCompetitor: Scalars['Boolean']['output'];
  isPublic: Scalars['Boolean']['output'];
  location: Location;
  locationId: Scalars['String']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  meetingNotes: Array<MeetingNotes>;
  name: Scalars['String']['output'];
  nameUniqueness: NameUniqueness;
  notes: Scalars['String']['output'];
  programPlannerItems: Array<ProgramPlannerItem>;
  sentimentScore: Scalars['Float']['output'];
  spokespeople: Array<Spokesperson>;
  status: ClientStatus;
  tags: Array<ClientTag>;
  workspace: Workspace;
  workspaceId: Scalars['String']['output'];
};


export type ClientWithSentimentActivitiesArgs = {
  cursor?: InputMaybe<ActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActivityOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ActivityWhereInput>;
};


export type ClientWithSentimentAgencyManagersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};


export type ClientWithSentimentClientCommentsArgs = {
  cursor?: InputMaybe<ClientCommentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ClientCommentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ClientCommentOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ClientCommentWhereInput>;
};


export type ClientWithSentimentContentArgs = {
  cursor?: InputMaybe<ContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ContentOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContentWhereInput>;
};


export type ClientWithSentimentCoveragePiecesArgs = {
  cursor?: InputMaybe<CoveragePieceWhereUniqueInput>;
  distinct?: InputMaybe<Array<CoveragePieceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CoveragePieceOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CoveragePieceWhereInput>;
};


export type ClientWithSentimentEmployeesArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};


export type ClientWithSentimentFilesArgs = {
  cursor?: InputMaybe<FileMetadataWhereUniqueInput>;
  distinct?: InputMaybe<Array<FileMetadataScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FileMetadataOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FileMetadataWhereInput>;
};


export type ClientWithSentimentIgnoredSuggestedCoverageArgs = {
  cursor?: InputMaybe<IgnoredSuggestedCoverageWhereUniqueInput>;
  distinct?: InputMaybe<Array<IgnoredSuggestedCoverageScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<IgnoredSuggestedCoverageOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IgnoredSuggestedCoverageWhereInput>;
};


export type ClientWithSentimentMeetingNotesArgs = {
  cursor?: InputMaybe<MeetingNotesWhereUniqueInput>;
  distinct?: InputMaybe<Array<MeetingNotesScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MeetingNotesOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MeetingNotesWhereInput>;
};


export type ClientWithSentimentProgramPlannerItemsArgs = {
  cursor?: InputMaybe<ProgramPlannerItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProgramPlannerItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProgramPlannerItemOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProgramPlannerItemWhereInput>;
};


export type ClientWithSentimentSpokespeopleArgs = {
  cursor?: InputMaybe<SpokespersonWhereUniqueInput>;
  distinct?: InputMaybe<Array<SpokespersonScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SpokespersonOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SpokespersonWhereInput>;
};


export type ClientWithSentimentTagsArgs = {
  cursor?: InputMaybe<ClientTagWhereUniqueInput>;
  distinct?: InputMaybe<Array<ClientTagScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ClientTagOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ClientTagWhereInput>;
};

export type CompetitorAnalysisByMonth = {
  __typename?: 'CompetitorAnalysisByMonth';
  coverageCount: Scalars['Int']['output'];
  month: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

export type CompetitorCoverageWhere = {
  country?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<DateTimeFilter>;
  publicationId?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type Content = {
  __typename?: 'Content';
  _count?: Maybe<ContentCount>;
  client: Client;
  clientId: Scalars['String']['output'];
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  createdById: Scalars['String']['output'];
  files: Array<FileMetadata>;
  id: Scalars['String']['output'];
  isPublic: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  plaintext: Scalars['String']['output'];
  recentlyViewed: Array<RecentlyViewedContent>;
  screenshot?: Maybe<Scalars['String']['output']>;
};


export type ContentFilesArgs = {
  cursor?: InputMaybe<FileMetadataWhereUniqueInput>;
  distinct?: InputMaybe<Array<FileMetadataScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FileMetadataOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FileMetadataWhereInput>;
};


export type ContentRecentlyViewedArgs = {
  cursor?: InputMaybe<RecentlyViewedContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<RecentlyViewedContentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RecentlyViewedContentOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RecentlyViewedContentWhereInput>;
};

export type ContentCount = {
  __typename?: 'ContentCount';
  embeddings: Scalars['Int']['output'];
  files: Scalars['Int']['output'];
  recentlyViewed: Scalars['Int']['output'];
};


export type ContentCountEmbeddingsArgs = {
  where?: InputMaybe<ContentEmbeddingWhereInput>;
};


export type ContentCountFilesArgs = {
  where?: InputMaybe<FileMetadataWhereInput>;
};


export type ContentCountRecentlyViewedArgs = {
  where?: InputMaybe<RecentlyViewedContentWhereInput>;
};

export type ContentCreateInput = {
  client: ClientCreateNestedOneWithoutContentInput;
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy: UserCreateNestedOneWithoutContentInput;
  embeddings?: InputMaybe<ContentEmbeddingCreateNestedManyWithoutContentInput>;
  files?: InputMaybe<FileMetadataCreateNestedManyWithoutContentInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  plaintext?: InputMaybe<Scalars['String']['input']>;
  recentlyViewed?: InputMaybe<RecentlyViewedContentCreateNestedManyWithoutContentInput>;
  screenshot?: InputMaybe<Scalars['String']['input']>;
};

export type ContentCreateManyClientInput = {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdById: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  plaintext?: InputMaybe<Scalars['String']['input']>;
  screenshot?: InputMaybe<Scalars['String']['input']>;
};

export type ContentCreateManyClientInputEnvelope = {
  data: Array<ContentCreateManyClientInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContentCreateManyCreatedByInput = {
  clientId: Scalars['String']['input'];
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  plaintext?: InputMaybe<Scalars['String']['input']>;
  screenshot?: InputMaybe<Scalars['String']['input']>;
};

export type ContentCreateManyCreatedByInputEnvelope = {
  data: Array<ContentCreateManyCreatedByInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContentCreateNestedManyWithoutClientInput = {
  connect?: InputMaybe<Array<ContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContentCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<ContentCreateWithoutClientInput>>;
  createMany?: InputMaybe<ContentCreateManyClientInputEnvelope>;
};

export type ContentCreateNestedManyWithoutCreatedByInput = {
  connect?: InputMaybe<Array<ContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContentCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<ContentCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<ContentCreateManyCreatedByInputEnvelope>;
};

export type ContentCreateNestedManyWithoutFilesInput = {
  connect?: InputMaybe<Array<ContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContentCreateOrConnectWithoutFilesInput>>;
  create?: InputMaybe<Array<ContentCreateWithoutFilesInput>>;
};

export type ContentCreateNestedOneWithoutRecentlyViewedInput = {
  connect?: InputMaybe<ContentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ContentCreateOrConnectWithoutRecentlyViewedInput>;
  create?: InputMaybe<ContentCreateWithoutRecentlyViewedInput>;
};

export type ContentCreateOrConnectWithoutClientInput = {
  create: ContentCreateWithoutClientInput;
  where: ContentWhereUniqueInput;
};

export type ContentCreateOrConnectWithoutCreatedByInput = {
  create: ContentCreateWithoutCreatedByInput;
  where: ContentWhereUniqueInput;
};

export type ContentCreateOrConnectWithoutFilesInput = {
  create: ContentCreateWithoutFilesInput;
  where: ContentWhereUniqueInput;
};

export type ContentCreateOrConnectWithoutRecentlyViewedInput = {
  create: ContentCreateWithoutRecentlyViewedInput;
  where: ContentWhereUniqueInput;
};

export type ContentCreateWithoutClientInput = {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy: UserCreateNestedOneWithoutContentInput;
  embeddings?: InputMaybe<ContentEmbeddingCreateNestedManyWithoutContentInput>;
  files?: InputMaybe<FileMetadataCreateNestedManyWithoutContentInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  plaintext?: InputMaybe<Scalars['String']['input']>;
  recentlyViewed?: InputMaybe<RecentlyViewedContentCreateNestedManyWithoutContentInput>;
  screenshot?: InputMaybe<Scalars['String']['input']>;
};

export type ContentCreateWithoutCreatedByInput = {
  client: ClientCreateNestedOneWithoutContentInput;
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  embeddings?: InputMaybe<ContentEmbeddingCreateNestedManyWithoutContentInput>;
  files?: InputMaybe<FileMetadataCreateNestedManyWithoutContentInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  plaintext?: InputMaybe<Scalars['String']['input']>;
  recentlyViewed?: InputMaybe<RecentlyViewedContentCreateNestedManyWithoutContentInput>;
  screenshot?: InputMaybe<Scalars['String']['input']>;
};

export type ContentCreateWithoutFilesInput = {
  client: ClientCreateNestedOneWithoutContentInput;
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy: UserCreateNestedOneWithoutContentInput;
  embeddings?: InputMaybe<ContentEmbeddingCreateNestedManyWithoutContentInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  plaintext?: InputMaybe<Scalars['String']['input']>;
  recentlyViewed?: InputMaybe<RecentlyViewedContentCreateNestedManyWithoutContentInput>;
  screenshot?: InputMaybe<Scalars['String']['input']>;
};

export type ContentCreateWithoutRecentlyViewedInput = {
  client: ClientCreateNestedOneWithoutContentInput;
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy: UserCreateNestedOneWithoutContentInput;
  embeddings?: InputMaybe<ContentEmbeddingCreateNestedManyWithoutContentInput>;
  files?: InputMaybe<FileMetadataCreateNestedManyWithoutContentInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  plaintext?: InputMaybe<Scalars['String']['input']>;
  screenshot?: InputMaybe<Scalars['String']['input']>;
};

export type ContentEmbeddingCreateNestedManyWithoutContentInput = {
  connect?: InputMaybe<Array<ContentEmbeddingWhereUniqueInput>>;
};

export type ContentEmbeddingListRelationFilter = {
  every?: InputMaybe<ContentEmbeddingWhereInput>;
  none?: InputMaybe<ContentEmbeddingWhereInput>;
  some?: InputMaybe<ContentEmbeddingWhereInput>;
};

export type ContentEmbeddingOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ContentEmbeddingScalarWhereInput = {
  AND?: InputMaybe<Array<ContentEmbeddingScalarWhereInput>>;
  NOT?: InputMaybe<Array<ContentEmbeddingScalarWhereInput>>;
  OR?: InputMaybe<Array<ContentEmbeddingScalarWhereInput>>;
  contentId?: InputMaybe<StringFilter>;
  end?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  start?: InputMaybe<IntFilter>;
  text?: InputMaybe<StringFilter>;
};

export type ContentEmbeddingUpdateManyMutationInput = {
  end?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  start?: InputMaybe<IntFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ContentEmbeddingUpdateManyWithWhereWithoutContentInput = {
  data: ContentEmbeddingUpdateManyMutationInput;
  where: ContentEmbeddingScalarWhereInput;
};

export type ContentEmbeddingUpdateManyWithoutContentNestedInput = {
  connect?: InputMaybe<Array<ContentEmbeddingWhereUniqueInput>>;
  delete?: InputMaybe<Array<ContentEmbeddingWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ContentEmbeddingScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ContentEmbeddingWhereUniqueInput>>;
  set?: InputMaybe<Array<ContentEmbeddingWhereUniqueInput>>;
  update?: InputMaybe<Array<ContentEmbeddingUpdateWithWhereUniqueWithoutContentInput>>;
  updateMany?: InputMaybe<Array<ContentEmbeddingUpdateManyWithWhereWithoutContentInput>>;
};

export type ContentEmbeddingUpdateWithWhereUniqueWithoutContentInput = {
  data: ContentEmbeddingUpdateWithoutContentInput;
  where: ContentEmbeddingWhereUniqueInput;
};

export type ContentEmbeddingUpdateWithoutContentInput = {
  end?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  start?: InputMaybe<IntFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ContentEmbeddingWhereInput = {
  AND?: InputMaybe<Array<ContentEmbeddingWhereInput>>;
  NOT?: InputMaybe<Array<ContentEmbeddingWhereInput>>;
  OR?: InputMaybe<Array<ContentEmbeddingWhereInput>>;
  content?: InputMaybe<ContentRelationFilter>;
  contentId?: InputMaybe<StringFilter>;
  end?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  start?: InputMaybe<IntFilter>;
  text?: InputMaybe<StringFilter>;
};

export type ContentEmbeddingWhereUniqueInput = {
  AND?: InputMaybe<Array<ContentEmbeddingWhereInput>>;
  NOT?: InputMaybe<Array<ContentEmbeddingWhereInput>>;
  OR?: InputMaybe<Array<ContentEmbeddingWhereInput>>;
  content?: InputMaybe<ContentRelationFilter>;
  contentId?: InputMaybe<StringFilter>;
  end?: InputMaybe<IntFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<IntFilter>;
  text?: InputMaybe<StringFilter>;
};

export type ContentListRelationFilter = {
  every?: InputMaybe<ContentWhereInput>;
  none?: InputMaybe<ContentWhereInput>;
  some?: InputMaybe<ContentWhereInput>;
};

export type ContentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum ContentOrderByRelevanceFieldEnum {
  ClientId = 'clientId',
  Content = 'content',
  CreatedById = 'createdById',
  Id = 'id',
  Name = 'name',
  Plaintext = 'plaintext',
  Screenshot = 'screenshot'
}

export type ContentOrderByRelevanceInput = {
  fields: Array<ContentOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type ContentOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<ContentOrderByRelevanceInput>;
  client?: InputMaybe<ClientOrderByWithRelationAndSearchRelevanceInput>;
  clientId?: InputMaybe<SortOrder>;
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  createdById?: InputMaybe<SortOrder>;
  embeddings?: InputMaybe<ContentEmbeddingOrderByRelationAggregateInput>;
  files?: InputMaybe<FileMetadataOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  isPublic?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  plaintext?: InputMaybe<SortOrder>;
  recentlyViewed?: InputMaybe<RecentlyViewedContentOrderByRelationAggregateInput>;
  screenshot?: InputMaybe<SortOrderInput>;
};

export type ContentRelationFilter = {
  is?: InputMaybe<ContentWhereInput>;
  isNot?: InputMaybe<ContentWhereInput>;
};

export enum ContentScalarFieldEnum {
  ClientId = 'clientId',
  Content = 'content',
  CreatedAt = 'createdAt',
  CreatedById = 'createdById',
  Id = 'id',
  IsPublic = 'isPublic',
  Name = 'name',
  Plaintext = 'plaintext',
  Screenshot = 'screenshot'
}

export type ContentScalarWhereInput = {
  AND?: InputMaybe<Array<ContentScalarWhereInput>>;
  NOT?: InputMaybe<Array<ContentScalarWhereInput>>;
  OR?: InputMaybe<Array<ContentScalarWhereInput>>;
  clientId?: InputMaybe<StringFilter>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdById?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  isPublic?: InputMaybe<BoolFilter>;
  name?: InputMaybe<StringFilter>;
  plaintext?: InputMaybe<StringFilter>;
  screenshot?: InputMaybe<StringNullableFilter>;
};

export type ContentUpdateInput = {
  client?: InputMaybe<ClientUpdateOneRequiredWithoutContentNestedInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutContentNestedInput>;
  embeddings?: InputMaybe<ContentEmbeddingUpdateManyWithoutContentNestedInput>;
  files?: InputMaybe<FileMetadataUpdateManyWithoutContentNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPublic?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  plaintext?: InputMaybe<StringFieldUpdateOperationsInput>;
  recentlyViewed?: InputMaybe<RecentlyViewedContentUpdateManyWithoutContentNestedInput>;
  screenshot?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ContentUpdateManyMutationInput = {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPublic?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  plaintext?: InputMaybe<StringFieldUpdateOperationsInput>;
  screenshot?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ContentUpdateManyWithWhereWithoutClientInput = {
  data: ContentUpdateManyMutationInput;
  where: ContentScalarWhereInput;
};

export type ContentUpdateManyWithWhereWithoutCreatedByInput = {
  data: ContentUpdateManyMutationInput;
  where: ContentScalarWhereInput;
};

export type ContentUpdateManyWithWhereWithoutFilesInput = {
  data: ContentUpdateManyMutationInput;
  where: ContentScalarWhereInput;
};

export type ContentUpdateManyWithoutClientNestedInput = {
  connect?: InputMaybe<Array<ContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContentCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<ContentCreateWithoutClientInput>>;
  createMany?: InputMaybe<ContentCreateManyClientInputEnvelope>;
  delete?: InputMaybe<Array<ContentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ContentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ContentWhereUniqueInput>>;
  set?: InputMaybe<Array<ContentWhereUniqueInput>>;
  update?: InputMaybe<Array<ContentUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: InputMaybe<Array<ContentUpdateManyWithWhereWithoutClientInput>>;
  upsert?: InputMaybe<Array<ContentUpsertWithWhereUniqueWithoutClientInput>>;
};

export type ContentUpdateManyWithoutCreatedByNestedInput = {
  connect?: InputMaybe<Array<ContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContentCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<ContentCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<ContentCreateManyCreatedByInputEnvelope>;
  delete?: InputMaybe<Array<ContentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ContentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ContentWhereUniqueInput>>;
  set?: InputMaybe<Array<ContentWhereUniqueInput>>;
  update?: InputMaybe<Array<ContentUpdateWithWhereUniqueWithoutCreatedByInput>>;
  updateMany?: InputMaybe<Array<ContentUpdateManyWithWhereWithoutCreatedByInput>>;
  upsert?: InputMaybe<Array<ContentUpsertWithWhereUniqueWithoutCreatedByInput>>;
};

export type ContentUpdateManyWithoutFilesNestedInput = {
  connect?: InputMaybe<Array<ContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContentCreateOrConnectWithoutFilesInput>>;
  create?: InputMaybe<Array<ContentCreateWithoutFilesInput>>;
  delete?: InputMaybe<Array<ContentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ContentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ContentWhereUniqueInput>>;
  set?: InputMaybe<Array<ContentWhereUniqueInput>>;
  update?: InputMaybe<Array<ContentUpdateWithWhereUniqueWithoutFilesInput>>;
  updateMany?: InputMaybe<Array<ContentUpdateManyWithWhereWithoutFilesInput>>;
  upsert?: InputMaybe<Array<ContentUpsertWithWhereUniqueWithoutFilesInput>>;
};

export type ContentUpdateOneRequiredWithoutRecentlyViewedNestedInput = {
  connect?: InputMaybe<ContentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ContentCreateOrConnectWithoutRecentlyViewedInput>;
  create?: InputMaybe<ContentCreateWithoutRecentlyViewedInput>;
  update?: InputMaybe<ContentUpdateToOneWithWhereWithoutRecentlyViewedInput>;
  upsert?: InputMaybe<ContentUpsertWithoutRecentlyViewedInput>;
};

export type ContentUpdateToOneWithWhereWithoutRecentlyViewedInput = {
  data: ContentUpdateWithoutRecentlyViewedInput;
  where?: InputMaybe<ContentWhereInput>;
};

export type ContentUpdateWithWhereUniqueWithoutClientInput = {
  data: ContentUpdateWithoutClientInput;
  where: ContentWhereUniqueInput;
};

export type ContentUpdateWithWhereUniqueWithoutCreatedByInput = {
  data: ContentUpdateWithoutCreatedByInput;
  where: ContentWhereUniqueInput;
};

export type ContentUpdateWithWhereUniqueWithoutFilesInput = {
  data: ContentUpdateWithoutFilesInput;
  where: ContentWhereUniqueInput;
};

export type ContentUpdateWithoutClientInput = {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutContentNestedInput>;
  embeddings?: InputMaybe<ContentEmbeddingUpdateManyWithoutContentNestedInput>;
  files?: InputMaybe<FileMetadataUpdateManyWithoutContentNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPublic?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  plaintext?: InputMaybe<StringFieldUpdateOperationsInput>;
  recentlyViewed?: InputMaybe<RecentlyViewedContentUpdateManyWithoutContentNestedInput>;
  screenshot?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ContentUpdateWithoutCreatedByInput = {
  client?: InputMaybe<ClientUpdateOneRequiredWithoutContentNestedInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  embeddings?: InputMaybe<ContentEmbeddingUpdateManyWithoutContentNestedInput>;
  files?: InputMaybe<FileMetadataUpdateManyWithoutContentNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPublic?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  plaintext?: InputMaybe<StringFieldUpdateOperationsInput>;
  recentlyViewed?: InputMaybe<RecentlyViewedContentUpdateManyWithoutContentNestedInput>;
  screenshot?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ContentUpdateWithoutFilesInput = {
  client?: InputMaybe<ClientUpdateOneRequiredWithoutContentNestedInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutContentNestedInput>;
  embeddings?: InputMaybe<ContentEmbeddingUpdateManyWithoutContentNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPublic?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  plaintext?: InputMaybe<StringFieldUpdateOperationsInput>;
  recentlyViewed?: InputMaybe<RecentlyViewedContentUpdateManyWithoutContentNestedInput>;
  screenshot?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ContentUpdateWithoutRecentlyViewedInput = {
  client?: InputMaybe<ClientUpdateOneRequiredWithoutContentNestedInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutContentNestedInput>;
  embeddings?: InputMaybe<ContentEmbeddingUpdateManyWithoutContentNestedInput>;
  files?: InputMaybe<FileMetadataUpdateManyWithoutContentNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPublic?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  plaintext?: InputMaybe<StringFieldUpdateOperationsInput>;
  screenshot?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ContentUpsertWithWhereUniqueWithoutClientInput = {
  create: ContentCreateWithoutClientInput;
  update: ContentUpdateWithoutClientInput;
  where: ContentWhereUniqueInput;
};

export type ContentUpsertWithWhereUniqueWithoutCreatedByInput = {
  create: ContentCreateWithoutCreatedByInput;
  update: ContentUpdateWithoutCreatedByInput;
  where: ContentWhereUniqueInput;
};

export type ContentUpsertWithWhereUniqueWithoutFilesInput = {
  create: ContentCreateWithoutFilesInput;
  update: ContentUpdateWithoutFilesInput;
  where: ContentWhereUniqueInput;
};

export type ContentUpsertWithoutRecentlyViewedInput = {
  create: ContentCreateWithoutRecentlyViewedInput;
  update: ContentUpdateWithoutRecentlyViewedInput;
  where?: InputMaybe<ContentWhereInput>;
};

export type ContentWhereInput = {
  AND?: InputMaybe<Array<ContentWhereInput>>;
  NOT?: InputMaybe<Array<ContentWhereInput>>;
  OR?: InputMaybe<Array<ContentWhereInput>>;
  client?: InputMaybe<ClientRelationFilter>;
  clientId?: InputMaybe<StringFilter>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<UserRelationFilter>;
  createdById?: InputMaybe<StringFilter>;
  embeddings?: InputMaybe<ContentEmbeddingListRelationFilter>;
  files?: InputMaybe<FileMetadataListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  isPublic?: InputMaybe<BoolFilter>;
  name?: InputMaybe<StringFilter>;
  plaintext?: InputMaybe<StringFilter>;
  recentlyViewed?: InputMaybe<RecentlyViewedContentListRelationFilter>;
  screenshot?: InputMaybe<StringNullableFilter>;
};

export type ContentWhereUniqueInput = {
  AND?: InputMaybe<Array<ContentWhereInput>>;
  NOT?: InputMaybe<Array<ContentWhereInput>>;
  OR?: InputMaybe<Array<ContentWhereInput>>;
  client?: InputMaybe<ClientRelationFilter>;
  clientId?: InputMaybe<StringFilter>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<UserRelationFilter>;
  createdById?: InputMaybe<StringFilter>;
  embeddings?: InputMaybe<ContentEmbeddingListRelationFilter>;
  files?: InputMaybe<FileMetadataListRelationFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPublic?: InputMaybe<BoolFilter>;
  name?: InputMaybe<StringFilter>;
  plaintext?: InputMaybe<StringFilter>;
  recentlyViewed?: InputMaybe<RecentlyViewedContentListRelationFilter>;
  screenshot?: InputMaybe<StringNullableFilter>;
};

/** The type of content to be written */
export enum ContentWriterType {
  Article = 'ARTICLE',
  ArticleComment = 'ARTICLE_COMMENT',
  BlogPost = 'BLOG_POST',
  Comment = 'COMMENT',
  GenericComment = 'GENERIC_COMMENT',
  LinkedinPost = 'LINKEDIN_POST',
  Pitch = 'PITCH',
  PressRelease = 'PRESS_RELEASE',
  TwitterPost = 'TWITTER_POST'
}

export type ContinueOnInput = {
  lastParagraphs: Scalars['String']['input'];
};

export type Country = {
  __typename?: 'Country';
  _count?: Maybe<CountryCount>;
  id: Scalars['String']['output'];
  isoCode2Char: Scalars['String']['output'];
  isoCode3Char: Scalars['String']['output'];
  name: Scalars['String']['output'];
  tlds: Array<Scalars['String']['output']>;
};

export type CountryCount = {
  __typename?: 'CountryCount';
  authorLocations: Scalars['Int']['output'];
  publications: Scalars['Int']['output'];
  regions: Scalars['Int']['output'];
};


export type CountryCountAuthorLocationsArgs = {
  where?: InputMaybe<AuthorLocationWhereInput>;
};


export type CountryCountPublicationsArgs = {
  where?: InputMaybe<PublicationWhereInput>;
};


export type CountryCountRegionsArgs = {
  where?: InputMaybe<RegionWhereInput>;
};

export type CountryCreateNestedOneWithoutAuthorLocationsInput = {
  connect?: InputMaybe<CountryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CountryCreateOrConnectWithoutAuthorLocationsInput>;
  create?: InputMaybe<CountryCreateWithoutAuthorLocationsInput>;
};

export type CountryCreateNestedOneWithoutPublicationsInput = {
  connect?: InputMaybe<CountryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CountryCreateOrConnectWithoutPublicationsInput>;
  create?: InputMaybe<CountryCreateWithoutPublicationsInput>;
};

export type CountryCreateOrConnectWithoutAuthorLocationsInput = {
  create: CountryCreateWithoutAuthorLocationsInput;
  where: CountryWhereUniqueInput;
};

export type CountryCreateOrConnectWithoutPublicationsInput = {
  create: CountryCreateWithoutPublicationsInput;
  where: CountryWhereUniqueInput;
};

export type CountryCreateWithoutAuthorLocationsInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  isoCode2Char: Scalars['String']['input'];
  isoCode3Char: Scalars['String']['input'];
  name: Scalars['String']['input'];
  publications?: InputMaybe<PublicationCreateNestedManyWithoutCountryInput>;
  regions?: InputMaybe<RegionCreateNestedManyWithoutCountriesInput>;
  tlds?: InputMaybe<CountryCreatetldsInput>;
};

export type CountryCreateWithoutPublicationsInput = {
  authorLocations?: InputMaybe<AuthorLocationCreateNestedManyWithoutCountryInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  isoCode2Char: Scalars['String']['input'];
  isoCode3Char: Scalars['String']['input'];
  name: Scalars['String']['input'];
  regions?: InputMaybe<RegionCreateNestedManyWithoutCountriesInput>;
  tlds?: InputMaybe<CountryCreatetldsInput>;
};

export type CountryCreatetldsInput = {
  set: Array<Scalars['String']['input']>;
};

export type CountryListRelationFilter = {
  every?: InputMaybe<CountryWhereInput>;
  none?: InputMaybe<CountryWhereInput>;
  some?: InputMaybe<CountryWhereInput>;
};

export type CountryNullableRelationFilter = {
  is?: InputMaybe<CountryWhereInput>;
  isNot?: InputMaybe<CountryWhereInput>;
};

export enum CountryOrderByRelevanceFieldEnum {
  Id = 'id',
  IsoCode2Char = 'isoCode2Char',
  IsoCode3Char = 'isoCode3Char',
  Name = 'name',
  Tlds = 'tlds'
}

export type CountryOrderByRelevanceInput = {
  fields: Array<CountryOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type CountryOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<CountryOrderByRelevanceInput>;
  authorLocations?: InputMaybe<AuthorLocationOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  isoCode2Char?: InputMaybe<SortOrder>;
  isoCode3Char?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  publications?: InputMaybe<PublicationOrderByRelationAggregateInput>;
  regions?: InputMaybe<RegionOrderByRelationAggregateInput>;
  tlds?: InputMaybe<SortOrder>;
};

export type CountryRelationFilter = {
  is?: InputMaybe<CountryWhereInput>;
  isNot?: InputMaybe<CountryWhereInput>;
};

export type CountryUpdateOneRequiredWithoutAuthorLocationsNestedInput = {
  connect?: InputMaybe<CountryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CountryCreateOrConnectWithoutAuthorLocationsInput>;
  create?: InputMaybe<CountryCreateWithoutAuthorLocationsInput>;
  update?: InputMaybe<CountryUpdateToOneWithWhereWithoutAuthorLocationsInput>;
  upsert?: InputMaybe<CountryUpsertWithoutAuthorLocationsInput>;
};

export type CountryUpdateOneWithoutPublicationsNestedInput = {
  connect?: InputMaybe<CountryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CountryCreateOrConnectWithoutPublicationsInput>;
  create?: InputMaybe<CountryCreateWithoutPublicationsInput>;
  delete?: InputMaybe<CountryWhereInput>;
  disconnect?: InputMaybe<CountryWhereInput>;
  update?: InputMaybe<CountryUpdateToOneWithWhereWithoutPublicationsInput>;
  upsert?: InputMaybe<CountryUpsertWithoutPublicationsInput>;
};

export type CountryUpdateToOneWithWhereWithoutAuthorLocationsInput = {
  data: CountryUpdateWithoutAuthorLocationsInput;
  where?: InputMaybe<CountryWhereInput>;
};

export type CountryUpdateToOneWithWhereWithoutPublicationsInput = {
  data: CountryUpdateWithoutPublicationsInput;
  where?: InputMaybe<CountryWhereInput>;
};

export type CountryUpdateWithoutAuthorLocationsInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isoCode2Char?: InputMaybe<StringFieldUpdateOperationsInput>;
  isoCode3Char?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  publications?: InputMaybe<PublicationUpdateManyWithoutCountryNestedInput>;
  regions?: InputMaybe<RegionUpdateManyWithoutCountriesNestedInput>;
  tlds?: InputMaybe<CountryUpdatetldsInput>;
};

export type CountryUpdateWithoutPublicationsInput = {
  authorLocations?: InputMaybe<AuthorLocationUpdateManyWithoutCountryNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isoCode2Char?: InputMaybe<StringFieldUpdateOperationsInput>;
  isoCode3Char?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  regions?: InputMaybe<RegionUpdateManyWithoutCountriesNestedInput>;
  tlds?: InputMaybe<CountryUpdatetldsInput>;
};

export type CountryUpdatetldsInput = {
  push?: InputMaybe<Array<Scalars['String']['input']>>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CountryUpsertWithoutAuthorLocationsInput = {
  create: CountryCreateWithoutAuthorLocationsInput;
  update: CountryUpdateWithoutAuthorLocationsInput;
  where?: InputMaybe<CountryWhereInput>;
};

export type CountryUpsertWithoutPublicationsInput = {
  create: CountryCreateWithoutPublicationsInput;
  update: CountryUpdateWithoutPublicationsInput;
  where?: InputMaybe<CountryWhereInput>;
};

export type CountryWhereInput = {
  AND?: InputMaybe<Array<CountryWhereInput>>;
  NOT?: InputMaybe<Array<CountryWhereInput>>;
  OR?: InputMaybe<Array<CountryWhereInput>>;
  authorLocations?: InputMaybe<AuthorLocationListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  isoCode2Char?: InputMaybe<StringFilter>;
  isoCode3Char?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  publications?: InputMaybe<PublicationListRelationFilter>;
  regions?: InputMaybe<RegionListRelationFilter>;
  tlds?: InputMaybe<StringNullableListFilter>;
};

export type CountryWhereUniqueInput = {
  AND?: InputMaybe<Array<CountryWhereInput>>;
  NOT?: InputMaybe<Array<CountryWhereInput>>;
  OR?: InputMaybe<Array<CountryWhereInput>>;
  authorLocations?: InputMaybe<AuthorLocationListRelationFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  isoCode2Char?: InputMaybe<Scalars['String']['input']>;
  isoCode3Char?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  publications?: InputMaybe<PublicationListRelationFilter>;
  regions?: InputMaybe<RegionListRelationFilter>;
  tlds?: InputMaybe<StringNullableListFilter>;
};

export enum CoverageCategory {
  Byline = 'BYLINE',
  CaseStudy = 'CASE_STUDY',
  Comments = 'COMMENTS',
  CorporateAnnouncement = 'CORPORATE_ANNOUNCEMENT',
  DataResearch = 'DATA_RESEARCH',
  Feature = 'FEATURE',
  Mention = 'MENTION',
  None = 'NONE',
  PressRelease = 'PRESS_RELEASE',
  Profile = 'PROFILE',
  ReactiveStatement = 'REACTIVE_STATEMENT'
}

export type CoverageForCompetitorAnalysisResult = {
  __typename?: 'CoverageForCompetitorAnalysisResult';
  author: Scalars['String']['output'];
  clients: Array<ClientWithSentiment>;
  date: Scalars['DateTime']['output'];
  extract: Scalars['String']['output'];
  id: Scalars['String']['output'];
  publication: GqlPublication;
  title: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export enum CoverageMedium {
  Broadcast = 'BROADCAST',
  Online = 'ONLINE',
  Other = 'OTHER',
  Print = 'PRINT'
}

export type CoveragePiece = {
  __typename?: 'CoveragePiece';
  _count?: Maybe<CoveragePieceCount>;
  activities: Array<Activity>;
  article?: Maybe<ArticleUnion>;
  articleId?: Maybe<Scalars['String']['output']>;
  author: Scalars['String']['output'];
  category: Array<CoverageCategory>;
  clientMentions?: Maybe<Array<ClientMention>>;
  clients: Array<Client>;
  createdBy: User;
  createdById: Scalars['String']['output'];
  date: Scalars['DateTime']['output'];
  extract: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isFavourited: Scalars['Boolean']['output'];
  isLoadingSyndications: Scalars['Boolean']['output'];
  medium: Array<CoverageMedium>;
  notes: Scalars['String']['output'];
  page?: Maybe<Scalars['Int']['output']>;
  print?: Maybe<Scalars['String']['output']>;
  publication: Publication;
  publicationId: Scalars['String']['output'];
  screenshot?: Maybe<Scalars['String']['output']>;
  spokespeople: Array<Spokesperson>;
  syndications: Array<CoveragePieceSyndication>;
  title: Scalars['String']['output'];
  type: CoverageType;
  url?: Maybe<Scalars['String']['output']>;
};


export type CoveragePieceActivitiesArgs = {
  cursor?: InputMaybe<ActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActivityOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ActivityWhereInput>;
};


export type CoveragePieceClientMentionsArgs = {
  cursor?: InputMaybe<ClientMentionWhereUniqueInput>;
  distinct?: InputMaybe<Array<ClientMentionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ClientMentionOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ClientMentionWhereInput>;
};


export type CoveragePieceClientsArgs = {
  cursor?: InputMaybe<ClientWhereUniqueInput>;
  distinct?: InputMaybe<Array<ClientScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ClientOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ClientWhereInput>;
};


export type CoveragePieceSpokespeopleArgs = {
  cursor?: InputMaybe<SpokespersonWhereUniqueInput>;
  distinct?: InputMaybe<Array<SpokespersonScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SpokespersonOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SpokespersonWhereInput>;
};


export type CoveragePieceSyndicationsArgs = {
  cursor?: InputMaybe<CoveragePieceSyndicationWhereUniqueInput>;
  distinct?: InputMaybe<Array<CoveragePieceSyndicationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CoveragePieceSyndicationOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CoveragePieceSyndicationWhereInput>;
};

export type CoveragePieceCount = {
  __typename?: 'CoveragePieceCount';
  activities: Scalars['Int']['output'];
  clients: Scalars['Int']['output'];
  spokespeople: Scalars['Int']['output'];
  syndications: Scalars['Int']['output'];
};


export type CoveragePieceCountActivitiesArgs = {
  where?: InputMaybe<ActivityWhereInput>;
};


export type CoveragePieceCountClientsArgs = {
  where?: InputMaybe<ClientWhereInput>;
};


export type CoveragePieceCountSpokespeopleArgs = {
  where?: InputMaybe<SpokespersonWhereInput>;
};


export type CoveragePieceCountSyndicationsArgs = {
  where?: InputMaybe<CoveragePieceSyndicationWhereInput>;
};

export type CoveragePieceCreateManyArticleInput = {
  author: Scalars['String']['input'];
  category?: InputMaybe<CoveragePieceCreatecategoryInput>;
  createdById: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  extract: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isFavourited?: InputMaybe<Scalars['Boolean']['input']>;
  isLoadingSyndications?: InputMaybe<Scalars['Boolean']['input']>;
  medium?: InputMaybe<CoveragePieceCreatemediumInput>;
  notes: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  print?: InputMaybe<Scalars['String']['input']>;
  publicationId: Scalars['String']['input'];
  screenshot?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  type: CoverageType;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CoveragePieceCreateManyArticleInputEnvelope = {
  data: Array<CoveragePieceCreateManyArticleInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CoveragePieceCreateManyCreatedByInput = {
  articleId?: InputMaybe<Scalars['String']['input']>;
  author: Scalars['String']['input'];
  category?: InputMaybe<CoveragePieceCreatecategoryInput>;
  date: Scalars['DateTime']['input'];
  extract: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isFavourited?: InputMaybe<Scalars['Boolean']['input']>;
  isLoadingSyndications?: InputMaybe<Scalars['Boolean']['input']>;
  medium?: InputMaybe<CoveragePieceCreatemediumInput>;
  notes: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  print?: InputMaybe<Scalars['String']['input']>;
  publicationId: Scalars['String']['input'];
  screenshot?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  type: CoverageType;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CoveragePieceCreateManyCreatedByInputEnvelope = {
  data: Array<CoveragePieceCreateManyCreatedByInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CoveragePieceCreateManyPublicationInput = {
  articleId?: InputMaybe<Scalars['String']['input']>;
  author: Scalars['String']['input'];
  category?: InputMaybe<CoveragePieceCreatecategoryInput>;
  createdById: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  extract: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isFavourited?: InputMaybe<Scalars['Boolean']['input']>;
  isLoadingSyndications?: InputMaybe<Scalars['Boolean']['input']>;
  medium?: InputMaybe<CoveragePieceCreatemediumInput>;
  notes: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  print?: InputMaybe<Scalars['String']['input']>;
  screenshot?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  type: CoverageType;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CoveragePieceCreateManyPublicationInputEnvelope = {
  data: Array<CoveragePieceCreateManyPublicationInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CoveragePieceCreateNestedManyWithoutActivitiesInput = {
  connect?: InputMaybe<Array<CoveragePieceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoveragePieceCreateOrConnectWithoutActivitiesInput>>;
  create?: InputMaybe<Array<CoveragePieceCreateWithoutActivitiesInput>>;
};

export type CoveragePieceCreateNestedManyWithoutArticleInput = {
  connect?: InputMaybe<Array<CoveragePieceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoveragePieceCreateOrConnectWithoutArticleInput>>;
  create?: InputMaybe<Array<CoveragePieceCreateWithoutArticleInput>>;
  createMany?: InputMaybe<CoveragePieceCreateManyArticleInputEnvelope>;
};

export type CoveragePieceCreateNestedManyWithoutClientsInput = {
  connect?: InputMaybe<Array<CoveragePieceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoveragePieceCreateOrConnectWithoutClientsInput>>;
  create?: InputMaybe<Array<CoveragePieceCreateWithoutClientsInput>>;
};

export type CoveragePieceCreateNestedManyWithoutCreatedByInput = {
  connect?: InputMaybe<Array<CoveragePieceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoveragePieceCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<CoveragePieceCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<CoveragePieceCreateManyCreatedByInputEnvelope>;
};

export type CoveragePieceCreateNestedManyWithoutPublicationInput = {
  connect?: InputMaybe<Array<CoveragePieceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoveragePieceCreateOrConnectWithoutPublicationInput>>;
  create?: InputMaybe<Array<CoveragePieceCreateWithoutPublicationInput>>;
  createMany?: InputMaybe<CoveragePieceCreateManyPublicationInputEnvelope>;
};

export type CoveragePieceCreateNestedManyWithoutSpokespeopleInput = {
  connect?: InputMaybe<Array<CoveragePieceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoveragePieceCreateOrConnectWithoutSpokespeopleInput>>;
  create?: InputMaybe<Array<CoveragePieceCreateWithoutSpokespeopleInput>>;
};

export type CoveragePieceCreateNestedOneWithoutSyndicationsInput = {
  connect?: InputMaybe<CoveragePieceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoveragePieceCreateOrConnectWithoutSyndicationsInput>;
  create?: InputMaybe<CoveragePieceCreateWithoutSyndicationsInput>;
};

export type CoveragePieceCreateOrConnectWithoutActivitiesInput = {
  create: CoveragePieceCreateWithoutActivitiesInput;
  where: CoveragePieceWhereUniqueInput;
};

export type CoveragePieceCreateOrConnectWithoutArticleInput = {
  create: CoveragePieceCreateWithoutArticleInput;
  where: CoveragePieceWhereUniqueInput;
};

export type CoveragePieceCreateOrConnectWithoutClientsInput = {
  create: CoveragePieceCreateWithoutClientsInput;
  where: CoveragePieceWhereUniqueInput;
};

export type CoveragePieceCreateOrConnectWithoutCreatedByInput = {
  create: CoveragePieceCreateWithoutCreatedByInput;
  where: CoveragePieceWhereUniqueInput;
};

export type CoveragePieceCreateOrConnectWithoutPublicationInput = {
  create: CoveragePieceCreateWithoutPublicationInput;
  where: CoveragePieceWhereUniqueInput;
};

export type CoveragePieceCreateOrConnectWithoutSpokespeopleInput = {
  create: CoveragePieceCreateWithoutSpokespeopleInput;
  where: CoveragePieceWhereUniqueInput;
};

export type CoveragePieceCreateOrConnectWithoutSyndicationsInput = {
  create: CoveragePieceCreateWithoutSyndicationsInput;
  where: CoveragePieceWhereUniqueInput;
};

export type CoveragePieceCreateWithoutActivitiesInput = {
  article?: InputMaybe<ArticleCreateNestedOneWithoutCoveragePiecesInput>;
  author: Scalars['String']['input'];
  category?: InputMaybe<CoveragePieceCreatecategoryInput>;
  clients?: InputMaybe<ClientCreateNestedManyWithoutCoveragePiecesInput>;
  createdBy: UserCreateNestedOneWithoutCoveragePiecesInput;
  date: Scalars['DateTime']['input'];
  extract: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isFavourited?: InputMaybe<Scalars['Boolean']['input']>;
  isLoadingSyndications?: InputMaybe<Scalars['Boolean']['input']>;
  medium?: InputMaybe<CoveragePieceCreatemediumInput>;
  notes: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  print?: InputMaybe<Scalars['String']['input']>;
  publication: PublicationCreateNestedOneWithoutCoveragePiecesInput;
  screenshot?: InputMaybe<Scalars['String']['input']>;
  spokespeople?: InputMaybe<SpokespersonCreateNestedManyWithoutCoveragePiecesInput>;
  syndications?: InputMaybe<CoveragePieceSyndicationCreateNestedManyWithoutCoveragePieceInput>;
  title: Scalars['String']['input'];
  type: CoverageType;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CoveragePieceCreateWithoutArticleInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutCoverageInput>;
  author: Scalars['String']['input'];
  category?: InputMaybe<CoveragePieceCreatecategoryInput>;
  clients?: InputMaybe<ClientCreateNestedManyWithoutCoveragePiecesInput>;
  createdBy: UserCreateNestedOneWithoutCoveragePiecesInput;
  date: Scalars['DateTime']['input'];
  extract: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isFavourited?: InputMaybe<Scalars['Boolean']['input']>;
  isLoadingSyndications?: InputMaybe<Scalars['Boolean']['input']>;
  medium?: InputMaybe<CoveragePieceCreatemediumInput>;
  notes: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  print?: InputMaybe<Scalars['String']['input']>;
  publication: PublicationCreateNestedOneWithoutCoveragePiecesInput;
  screenshot?: InputMaybe<Scalars['String']['input']>;
  spokespeople?: InputMaybe<SpokespersonCreateNestedManyWithoutCoveragePiecesInput>;
  syndications?: InputMaybe<CoveragePieceSyndicationCreateNestedManyWithoutCoveragePieceInput>;
  title: Scalars['String']['input'];
  type: CoverageType;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CoveragePieceCreateWithoutClientsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutCoverageInput>;
  article?: InputMaybe<ArticleCreateNestedOneWithoutCoveragePiecesInput>;
  author: Scalars['String']['input'];
  category?: InputMaybe<CoveragePieceCreatecategoryInput>;
  createdBy: UserCreateNestedOneWithoutCoveragePiecesInput;
  date: Scalars['DateTime']['input'];
  extract: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isFavourited?: InputMaybe<Scalars['Boolean']['input']>;
  isLoadingSyndications?: InputMaybe<Scalars['Boolean']['input']>;
  medium?: InputMaybe<CoveragePieceCreatemediumInput>;
  notes: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  print?: InputMaybe<Scalars['String']['input']>;
  publication: PublicationCreateNestedOneWithoutCoveragePiecesInput;
  screenshot?: InputMaybe<Scalars['String']['input']>;
  spokespeople?: InputMaybe<SpokespersonCreateNestedManyWithoutCoveragePiecesInput>;
  syndications?: InputMaybe<CoveragePieceSyndicationCreateNestedManyWithoutCoveragePieceInput>;
  title: Scalars['String']['input'];
  type: CoverageType;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CoveragePieceCreateWithoutCreatedByInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutCoverageInput>;
  article?: InputMaybe<ArticleCreateNestedOneWithoutCoveragePiecesInput>;
  author: Scalars['String']['input'];
  category?: InputMaybe<CoveragePieceCreatecategoryInput>;
  clients?: InputMaybe<ClientCreateNestedManyWithoutCoveragePiecesInput>;
  date: Scalars['DateTime']['input'];
  extract: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isFavourited?: InputMaybe<Scalars['Boolean']['input']>;
  isLoadingSyndications?: InputMaybe<Scalars['Boolean']['input']>;
  medium?: InputMaybe<CoveragePieceCreatemediumInput>;
  notes: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  print?: InputMaybe<Scalars['String']['input']>;
  publication: PublicationCreateNestedOneWithoutCoveragePiecesInput;
  screenshot?: InputMaybe<Scalars['String']['input']>;
  spokespeople?: InputMaybe<SpokespersonCreateNestedManyWithoutCoveragePiecesInput>;
  syndications?: InputMaybe<CoveragePieceSyndicationCreateNestedManyWithoutCoveragePieceInput>;
  title: Scalars['String']['input'];
  type: CoverageType;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CoveragePieceCreateWithoutPublicationInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutCoverageInput>;
  article?: InputMaybe<ArticleCreateNestedOneWithoutCoveragePiecesInput>;
  author: Scalars['String']['input'];
  category?: InputMaybe<CoveragePieceCreatecategoryInput>;
  clients?: InputMaybe<ClientCreateNestedManyWithoutCoveragePiecesInput>;
  createdBy: UserCreateNestedOneWithoutCoveragePiecesInput;
  date: Scalars['DateTime']['input'];
  extract: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isFavourited?: InputMaybe<Scalars['Boolean']['input']>;
  isLoadingSyndications?: InputMaybe<Scalars['Boolean']['input']>;
  medium?: InputMaybe<CoveragePieceCreatemediumInput>;
  notes: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  print?: InputMaybe<Scalars['String']['input']>;
  screenshot?: InputMaybe<Scalars['String']['input']>;
  spokespeople?: InputMaybe<SpokespersonCreateNestedManyWithoutCoveragePiecesInput>;
  syndications?: InputMaybe<CoveragePieceSyndicationCreateNestedManyWithoutCoveragePieceInput>;
  title: Scalars['String']['input'];
  type: CoverageType;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CoveragePieceCreateWithoutSpokespeopleInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutCoverageInput>;
  article?: InputMaybe<ArticleCreateNestedOneWithoutCoveragePiecesInput>;
  author: Scalars['String']['input'];
  category?: InputMaybe<CoveragePieceCreatecategoryInput>;
  clients?: InputMaybe<ClientCreateNestedManyWithoutCoveragePiecesInput>;
  createdBy: UserCreateNestedOneWithoutCoveragePiecesInput;
  date: Scalars['DateTime']['input'];
  extract: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isFavourited?: InputMaybe<Scalars['Boolean']['input']>;
  isLoadingSyndications?: InputMaybe<Scalars['Boolean']['input']>;
  medium?: InputMaybe<CoveragePieceCreatemediumInput>;
  notes: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  print?: InputMaybe<Scalars['String']['input']>;
  publication: PublicationCreateNestedOneWithoutCoveragePiecesInput;
  screenshot?: InputMaybe<Scalars['String']['input']>;
  syndications?: InputMaybe<CoveragePieceSyndicationCreateNestedManyWithoutCoveragePieceInput>;
  title: Scalars['String']['input'];
  type: CoverageType;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CoveragePieceCreateWithoutSyndicationsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutCoverageInput>;
  article?: InputMaybe<ArticleCreateNestedOneWithoutCoveragePiecesInput>;
  author: Scalars['String']['input'];
  category?: InputMaybe<CoveragePieceCreatecategoryInput>;
  clients?: InputMaybe<ClientCreateNestedManyWithoutCoveragePiecesInput>;
  createdBy: UserCreateNestedOneWithoutCoveragePiecesInput;
  date: Scalars['DateTime']['input'];
  extract: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isFavourited?: InputMaybe<Scalars['Boolean']['input']>;
  isLoadingSyndications?: InputMaybe<Scalars['Boolean']['input']>;
  medium?: InputMaybe<CoveragePieceCreatemediumInput>;
  notes: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  print?: InputMaybe<Scalars['String']['input']>;
  publication: PublicationCreateNestedOneWithoutCoveragePiecesInput;
  screenshot?: InputMaybe<Scalars['String']['input']>;
  spokespeople?: InputMaybe<SpokespersonCreateNestedManyWithoutCoveragePiecesInput>;
  title: Scalars['String']['input'];
  type: CoverageType;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CoveragePieceCreatecategoryInput = {
  set: Array<CoverageCategory>;
};

export type CoveragePieceCreatemediumInput = {
  set: Array<CoverageMedium>;
};

export type CoveragePieceListRelationFilter = {
  every?: InputMaybe<CoveragePieceWhereInput>;
  none?: InputMaybe<CoveragePieceWhereInput>;
  some?: InputMaybe<CoveragePieceWhereInput>;
};

export type CoveragePieceOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum CoveragePieceOrderByRelevanceFieldEnum {
  ArticleId = 'articleId',
  Author = 'author',
  CreatedById = 'createdById',
  Extract = 'extract',
  Id = 'id',
  Notes = 'notes',
  Print = 'print',
  PublicationId = 'publicationId',
  Screenshot = 'screenshot',
  Title = 'title',
  Url = 'url'
}

export type CoveragePieceOrderByRelevanceInput = {
  fields: Array<CoveragePieceOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type CoveragePieceOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<CoveragePieceOrderByRelevanceInput>;
  activities?: InputMaybe<ActivityOrderByRelationAggregateInput>;
  article?: InputMaybe<ArticleOrderByWithRelationAndSearchRelevanceInput>;
  articleId?: InputMaybe<SortOrderInput>;
  author?: InputMaybe<SortOrder>;
  category?: InputMaybe<SortOrder>;
  clients?: InputMaybe<ClientOrderByRelationAggregateInput>;
  createdBy?: InputMaybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  createdById?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  extract?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isFavourited?: InputMaybe<SortOrder>;
  isLoadingSyndications?: InputMaybe<SortOrder>;
  medium?: InputMaybe<SortOrder>;
  notes?: InputMaybe<SortOrder>;
  page?: InputMaybe<SortOrderInput>;
  print?: InputMaybe<SortOrderInput>;
  publication?: InputMaybe<PublicationOrderByWithRelationAndSearchRelevanceInput>;
  publicationId?: InputMaybe<SortOrder>;
  screenshot?: InputMaybe<SortOrderInput>;
  spokespeople?: InputMaybe<SpokespersonOrderByRelationAggregateInput>;
  syndications?: InputMaybe<CoveragePieceSyndicationOrderByRelationAggregateInput>;
  title?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrderInput>;
};

export type CoveragePieceRelationFilter = {
  is?: InputMaybe<CoveragePieceWhereInput>;
  isNot?: InputMaybe<CoveragePieceWhereInput>;
};

export enum CoveragePieceScalarFieldEnum {
  ArticleId = 'articleId',
  Author = 'author',
  Category = 'category',
  CreatedById = 'createdById',
  Date = 'date',
  Extract = 'extract',
  Id = 'id',
  IsFavourited = 'isFavourited',
  IsLoadingSyndications = 'isLoadingSyndications',
  Medium = 'medium',
  Notes = 'notes',
  Page = 'page',
  Print = 'print',
  PublicationId = 'publicationId',
  Screenshot = 'screenshot',
  Title = 'title',
  Type = 'type',
  Url = 'url'
}

export type CoveragePieceScalarWhereInput = {
  AND?: InputMaybe<Array<CoveragePieceScalarWhereInput>>;
  NOT?: InputMaybe<Array<CoveragePieceScalarWhereInput>>;
  OR?: InputMaybe<Array<CoveragePieceScalarWhereInput>>;
  articleId?: InputMaybe<StringNullableFilter>;
  author?: InputMaybe<StringFilter>;
  category?: InputMaybe<EnumCoverageCategoryNullableListFilter>;
  createdById?: InputMaybe<StringFilter>;
  date?: InputMaybe<DateTimeFilter>;
  extract?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  isFavourited?: InputMaybe<BoolFilter>;
  isLoadingSyndications?: InputMaybe<BoolFilter>;
  medium?: InputMaybe<EnumCoverageMediumNullableListFilter>;
  notes?: InputMaybe<StringFilter>;
  page?: InputMaybe<IntNullableFilter>;
  print?: InputMaybe<StringNullableFilter>;
  publicationId?: InputMaybe<StringFilter>;
  screenshot?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumCoverageTypeFilter>;
  url?: InputMaybe<StringNullableFilter>;
};

export type CoveragePieceSyndication = {
  __typename?: 'CoveragePieceSyndication';
  coveragePiece: CoveragePiece;
  coveragePieceId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  publication: Publication;
  publicationId: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type CoveragePieceSyndicationCreateManyCoveragePieceInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  publicationId: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type CoveragePieceSyndicationCreateManyCoveragePieceInputEnvelope = {
  data: Array<CoveragePieceSyndicationCreateManyCoveragePieceInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CoveragePieceSyndicationCreateManyPublicationInput = {
  coveragePieceId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

export type CoveragePieceSyndicationCreateManyPublicationInputEnvelope = {
  data: Array<CoveragePieceSyndicationCreateManyPublicationInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CoveragePieceSyndicationCreateNestedManyWithoutCoveragePieceInput = {
  connect?: InputMaybe<Array<CoveragePieceSyndicationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoveragePieceSyndicationCreateOrConnectWithoutCoveragePieceInput>>;
  create?: InputMaybe<Array<CoveragePieceSyndicationCreateWithoutCoveragePieceInput>>;
  createMany?: InputMaybe<CoveragePieceSyndicationCreateManyCoveragePieceInputEnvelope>;
};

export type CoveragePieceSyndicationCreateNestedManyWithoutPublicationInput = {
  connect?: InputMaybe<Array<CoveragePieceSyndicationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoveragePieceSyndicationCreateOrConnectWithoutPublicationInput>>;
  create?: InputMaybe<Array<CoveragePieceSyndicationCreateWithoutPublicationInput>>;
  createMany?: InputMaybe<CoveragePieceSyndicationCreateManyPublicationInputEnvelope>;
};

export type CoveragePieceSyndicationCreateOrConnectWithoutCoveragePieceInput = {
  create: CoveragePieceSyndicationCreateWithoutCoveragePieceInput;
  where: CoveragePieceSyndicationWhereUniqueInput;
};

export type CoveragePieceSyndicationCreateOrConnectWithoutPublicationInput = {
  create: CoveragePieceSyndicationCreateWithoutPublicationInput;
  where: CoveragePieceSyndicationWhereUniqueInput;
};

export type CoveragePieceSyndicationCreateWithoutCoveragePieceInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  publication: PublicationCreateNestedOneWithoutSyndicationsInput;
  url: Scalars['String']['input'];
};

export type CoveragePieceSyndicationCreateWithoutPublicationInput = {
  coveragePiece: CoveragePieceCreateNestedOneWithoutSyndicationsInput;
  id?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

export type CoveragePieceSyndicationListRelationFilter = {
  every?: InputMaybe<CoveragePieceSyndicationWhereInput>;
  none?: InputMaybe<CoveragePieceSyndicationWhereInput>;
  some?: InputMaybe<CoveragePieceSyndicationWhereInput>;
};

export type CoveragePieceSyndicationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum CoveragePieceSyndicationOrderByRelevanceFieldEnum {
  CoveragePieceId = 'coveragePieceId',
  Id = 'id',
  PublicationId = 'publicationId',
  Url = 'url'
}

export type CoveragePieceSyndicationOrderByRelevanceInput = {
  fields: Array<CoveragePieceSyndicationOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type CoveragePieceSyndicationOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<CoveragePieceSyndicationOrderByRelevanceInput>;
  coveragePiece?: InputMaybe<CoveragePieceOrderByWithRelationAndSearchRelevanceInput>;
  coveragePieceId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  publication?: InputMaybe<PublicationOrderByWithRelationAndSearchRelevanceInput>;
  publicationId?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export enum CoveragePieceSyndicationScalarFieldEnum {
  CoveragePieceId = 'coveragePieceId',
  Id = 'id',
  PublicationId = 'publicationId',
  Url = 'url'
}

export type CoveragePieceSyndicationScalarWhereInput = {
  AND?: InputMaybe<Array<CoveragePieceSyndicationScalarWhereInput>>;
  NOT?: InputMaybe<Array<CoveragePieceSyndicationScalarWhereInput>>;
  OR?: InputMaybe<Array<CoveragePieceSyndicationScalarWhereInput>>;
  coveragePieceId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  publicationId?: InputMaybe<StringFilter>;
  url?: InputMaybe<StringFilter>;
};

export type CoveragePieceSyndicationUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CoveragePieceSyndicationUpdateManyWithWhereWithoutCoveragePieceInput = {
  data: CoveragePieceSyndicationUpdateManyMutationInput;
  where: CoveragePieceSyndicationScalarWhereInput;
};

export type CoveragePieceSyndicationUpdateManyWithWhereWithoutPublicationInput = {
  data: CoveragePieceSyndicationUpdateManyMutationInput;
  where: CoveragePieceSyndicationScalarWhereInput;
};

export type CoveragePieceSyndicationUpdateManyWithoutCoveragePieceNestedInput = {
  connect?: InputMaybe<Array<CoveragePieceSyndicationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoveragePieceSyndicationCreateOrConnectWithoutCoveragePieceInput>>;
  create?: InputMaybe<Array<CoveragePieceSyndicationCreateWithoutCoveragePieceInput>>;
  createMany?: InputMaybe<CoveragePieceSyndicationCreateManyCoveragePieceInputEnvelope>;
  delete?: InputMaybe<Array<CoveragePieceSyndicationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CoveragePieceSyndicationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CoveragePieceSyndicationWhereUniqueInput>>;
  set?: InputMaybe<Array<CoveragePieceSyndicationWhereUniqueInput>>;
  update?: InputMaybe<Array<CoveragePieceSyndicationUpdateWithWhereUniqueWithoutCoveragePieceInput>>;
  updateMany?: InputMaybe<Array<CoveragePieceSyndicationUpdateManyWithWhereWithoutCoveragePieceInput>>;
  upsert?: InputMaybe<Array<CoveragePieceSyndicationUpsertWithWhereUniqueWithoutCoveragePieceInput>>;
};

export type CoveragePieceSyndicationUpdateManyWithoutPublicationNestedInput = {
  connect?: InputMaybe<Array<CoveragePieceSyndicationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoveragePieceSyndicationCreateOrConnectWithoutPublicationInput>>;
  create?: InputMaybe<Array<CoveragePieceSyndicationCreateWithoutPublicationInput>>;
  createMany?: InputMaybe<CoveragePieceSyndicationCreateManyPublicationInputEnvelope>;
  delete?: InputMaybe<Array<CoveragePieceSyndicationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CoveragePieceSyndicationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CoveragePieceSyndicationWhereUniqueInput>>;
  set?: InputMaybe<Array<CoveragePieceSyndicationWhereUniqueInput>>;
  update?: InputMaybe<Array<CoveragePieceSyndicationUpdateWithWhereUniqueWithoutPublicationInput>>;
  updateMany?: InputMaybe<Array<CoveragePieceSyndicationUpdateManyWithWhereWithoutPublicationInput>>;
  upsert?: InputMaybe<Array<CoveragePieceSyndicationUpsertWithWhereUniqueWithoutPublicationInput>>;
};

export type CoveragePieceSyndicationUpdateWithWhereUniqueWithoutCoveragePieceInput = {
  data: CoveragePieceSyndicationUpdateWithoutCoveragePieceInput;
  where: CoveragePieceSyndicationWhereUniqueInput;
};

export type CoveragePieceSyndicationUpdateWithWhereUniqueWithoutPublicationInput = {
  data: CoveragePieceSyndicationUpdateWithoutPublicationInput;
  where: CoveragePieceSyndicationWhereUniqueInput;
};

export type CoveragePieceSyndicationUpdateWithoutCoveragePieceInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  publication?: InputMaybe<PublicationUpdateOneRequiredWithoutSyndicationsNestedInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CoveragePieceSyndicationUpdateWithoutPublicationInput = {
  coveragePiece?: InputMaybe<CoveragePieceUpdateOneRequiredWithoutSyndicationsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CoveragePieceSyndicationUpsertWithWhereUniqueWithoutCoveragePieceInput = {
  create: CoveragePieceSyndicationCreateWithoutCoveragePieceInput;
  update: CoveragePieceSyndicationUpdateWithoutCoveragePieceInput;
  where: CoveragePieceSyndicationWhereUniqueInput;
};

export type CoveragePieceSyndicationUpsertWithWhereUniqueWithoutPublicationInput = {
  create: CoveragePieceSyndicationCreateWithoutPublicationInput;
  update: CoveragePieceSyndicationUpdateWithoutPublicationInput;
  where: CoveragePieceSyndicationWhereUniqueInput;
};

export type CoveragePieceSyndicationWhereInput = {
  AND?: InputMaybe<Array<CoveragePieceSyndicationWhereInput>>;
  NOT?: InputMaybe<Array<CoveragePieceSyndicationWhereInput>>;
  OR?: InputMaybe<Array<CoveragePieceSyndicationWhereInput>>;
  coveragePiece?: InputMaybe<CoveragePieceRelationFilter>;
  coveragePieceId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  publication?: InputMaybe<PublicationRelationFilter>;
  publicationId?: InputMaybe<StringFilter>;
  url?: InputMaybe<StringFilter>;
};

export type CoveragePieceSyndicationWhereUniqueInput = {
  AND?: InputMaybe<Array<CoveragePieceSyndicationWhereInput>>;
  NOT?: InputMaybe<Array<CoveragePieceSyndicationWhereInput>>;
  OR?: InputMaybe<Array<CoveragePieceSyndicationWhereInput>>;
  coveragePiece?: InputMaybe<CoveragePieceRelationFilter>;
  coveragePieceId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  publication?: InputMaybe<PublicationRelationFilter>;
  publicationId?: InputMaybe<StringFilter>;
  url?: InputMaybe<StringFilter>;
};

export type CoveragePieceUpdateInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutCoverageNestedInput>;
  article?: InputMaybe<ArticleUpdateOneWithoutCoveragePiecesNestedInput>;
  author?: InputMaybe<StringFieldUpdateOperationsInput>;
  category?: InputMaybe<CoveragePieceUpdatecategoryInput>;
  clients?: InputMaybe<ClientUpdateManyWithoutCoveragePiecesNestedInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutCoveragePiecesNestedInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  extract?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isFavourited?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLoadingSyndications?: InputMaybe<BoolFieldUpdateOperationsInput>;
  medium?: InputMaybe<CoveragePieceUpdatemediumInput>;
  notes?: InputMaybe<StringFieldUpdateOperationsInput>;
  page?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  print?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  publication?: InputMaybe<PublicationUpdateOneRequiredWithoutCoveragePiecesNestedInput>;
  screenshot?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  spokespeople?: InputMaybe<SpokespersonUpdateManyWithoutCoveragePiecesNestedInput>;
  syndications?: InputMaybe<CoveragePieceSyndicationUpdateManyWithoutCoveragePieceNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumCoverageTypeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CoveragePieceUpdateManyMutationInput = {
  author?: InputMaybe<StringFieldUpdateOperationsInput>;
  category?: InputMaybe<CoveragePieceUpdatecategoryInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  extract?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isFavourited?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLoadingSyndications?: InputMaybe<BoolFieldUpdateOperationsInput>;
  medium?: InputMaybe<CoveragePieceUpdatemediumInput>;
  notes?: InputMaybe<StringFieldUpdateOperationsInput>;
  page?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  print?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  screenshot?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumCoverageTypeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CoveragePieceUpdateManyWithWhereWithoutActivitiesInput = {
  data: CoveragePieceUpdateManyMutationInput;
  where: CoveragePieceScalarWhereInput;
};

export type CoveragePieceUpdateManyWithWhereWithoutArticleInput = {
  data: CoveragePieceUpdateManyMutationInput;
  where: CoveragePieceScalarWhereInput;
};

export type CoveragePieceUpdateManyWithWhereWithoutClientsInput = {
  data: CoveragePieceUpdateManyMutationInput;
  where: CoveragePieceScalarWhereInput;
};

export type CoveragePieceUpdateManyWithWhereWithoutCreatedByInput = {
  data: CoveragePieceUpdateManyMutationInput;
  where: CoveragePieceScalarWhereInput;
};

export type CoveragePieceUpdateManyWithWhereWithoutPublicationInput = {
  data: CoveragePieceUpdateManyMutationInput;
  where: CoveragePieceScalarWhereInput;
};

export type CoveragePieceUpdateManyWithWhereWithoutSpokespeopleInput = {
  data: CoveragePieceUpdateManyMutationInput;
  where: CoveragePieceScalarWhereInput;
};

export type CoveragePieceUpdateManyWithoutActivitiesNestedInput = {
  connect?: InputMaybe<Array<CoveragePieceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoveragePieceCreateOrConnectWithoutActivitiesInput>>;
  create?: InputMaybe<Array<CoveragePieceCreateWithoutActivitiesInput>>;
  delete?: InputMaybe<Array<CoveragePieceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CoveragePieceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CoveragePieceWhereUniqueInput>>;
  set?: InputMaybe<Array<CoveragePieceWhereUniqueInput>>;
  update?: InputMaybe<Array<CoveragePieceUpdateWithWhereUniqueWithoutActivitiesInput>>;
  updateMany?: InputMaybe<Array<CoveragePieceUpdateManyWithWhereWithoutActivitiesInput>>;
  upsert?: InputMaybe<Array<CoveragePieceUpsertWithWhereUniqueWithoutActivitiesInput>>;
};

export type CoveragePieceUpdateManyWithoutArticleNestedInput = {
  connect?: InputMaybe<Array<CoveragePieceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoveragePieceCreateOrConnectWithoutArticleInput>>;
  create?: InputMaybe<Array<CoveragePieceCreateWithoutArticleInput>>;
  createMany?: InputMaybe<CoveragePieceCreateManyArticleInputEnvelope>;
  delete?: InputMaybe<Array<CoveragePieceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CoveragePieceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CoveragePieceWhereUniqueInput>>;
  set?: InputMaybe<Array<CoveragePieceWhereUniqueInput>>;
  update?: InputMaybe<Array<CoveragePieceUpdateWithWhereUniqueWithoutArticleInput>>;
  updateMany?: InputMaybe<Array<CoveragePieceUpdateManyWithWhereWithoutArticleInput>>;
  upsert?: InputMaybe<Array<CoveragePieceUpsertWithWhereUniqueWithoutArticleInput>>;
};

export type CoveragePieceUpdateManyWithoutClientsNestedInput = {
  connect?: InputMaybe<Array<CoveragePieceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoveragePieceCreateOrConnectWithoutClientsInput>>;
  create?: InputMaybe<Array<CoveragePieceCreateWithoutClientsInput>>;
  delete?: InputMaybe<Array<CoveragePieceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CoveragePieceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CoveragePieceWhereUniqueInput>>;
  set?: InputMaybe<Array<CoveragePieceWhereUniqueInput>>;
  update?: InputMaybe<Array<CoveragePieceUpdateWithWhereUniqueWithoutClientsInput>>;
  updateMany?: InputMaybe<Array<CoveragePieceUpdateManyWithWhereWithoutClientsInput>>;
  upsert?: InputMaybe<Array<CoveragePieceUpsertWithWhereUniqueWithoutClientsInput>>;
};

export type CoveragePieceUpdateManyWithoutCreatedByNestedInput = {
  connect?: InputMaybe<Array<CoveragePieceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoveragePieceCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<CoveragePieceCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<CoveragePieceCreateManyCreatedByInputEnvelope>;
  delete?: InputMaybe<Array<CoveragePieceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CoveragePieceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CoveragePieceWhereUniqueInput>>;
  set?: InputMaybe<Array<CoveragePieceWhereUniqueInput>>;
  update?: InputMaybe<Array<CoveragePieceUpdateWithWhereUniqueWithoutCreatedByInput>>;
  updateMany?: InputMaybe<Array<CoveragePieceUpdateManyWithWhereWithoutCreatedByInput>>;
  upsert?: InputMaybe<Array<CoveragePieceUpsertWithWhereUniqueWithoutCreatedByInput>>;
};

export type CoveragePieceUpdateManyWithoutPublicationNestedInput = {
  connect?: InputMaybe<Array<CoveragePieceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoveragePieceCreateOrConnectWithoutPublicationInput>>;
  create?: InputMaybe<Array<CoveragePieceCreateWithoutPublicationInput>>;
  createMany?: InputMaybe<CoveragePieceCreateManyPublicationInputEnvelope>;
  delete?: InputMaybe<Array<CoveragePieceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CoveragePieceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CoveragePieceWhereUniqueInput>>;
  set?: InputMaybe<Array<CoveragePieceWhereUniqueInput>>;
  update?: InputMaybe<Array<CoveragePieceUpdateWithWhereUniqueWithoutPublicationInput>>;
  updateMany?: InputMaybe<Array<CoveragePieceUpdateManyWithWhereWithoutPublicationInput>>;
  upsert?: InputMaybe<Array<CoveragePieceUpsertWithWhereUniqueWithoutPublicationInput>>;
};

export type CoveragePieceUpdateManyWithoutSpokespeopleNestedInput = {
  connect?: InputMaybe<Array<CoveragePieceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoveragePieceCreateOrConnectWithoutSpokespeopleInput>>;
  create?: InputMaybe<Array<CoveragePieceCreateWithoutSpokespeopleInput>>;
  delete?: InputMaybe<Array<CoveragePieceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CoveragePieceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CoveragePieceWhereUniqueInput>>;
  set?: InputMaybe<Array<CoveragePieceWhereUniqueInput>>;
  update?: InputMaybe<Array<CoveragePieceUpdateWithWhereUniqueWithoutSpokespeopleInput>>;
  updateMany?: InputMaybe<Array<CoveragePieceUpdateManyWithWhereWithoutSpokespeopleInput>>;
  upsert?: InputMaybe<Array<CoveragePieceUpsertWithWhereUniqueWithoutSpokespeopleInput>>;
};

export type CoveragePieceUpdateOneRequiredWithoutSyndicationsNestedInput = {
  connect?: InputMaybe<CoveragePieceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoveragePieceCreateOrConnectWithoutSyndicationsInput>;
  create?: InputMaybe<CoveragePieceCreateWithoutSyndicationsInput>;
  update?: InputMaybe<CoveragePieceUpdateToOneWithWhereWithoutSyndicationsInput>;
  upsert?: InputMaybe<CoveragePieceUpsertWithoutSyndicationsInput>;
};

export type CoveragePieceUpdateToOneWithWhereWithoutSyndicationsInput = {
  data: CoveragePieceUpdateWithoutSyndicationsInput;
  where?: InputMaybe<CoveragePieceWhereInput>;
};

export type CoveragePieceUpdateWithWhereUniqueWithoutActivitiesInput = {
  data: CoveragePieceUpdateWithoutActivitiesInput;
  where: CoveragePieceWhereUniqueInput;
};

export type CoveragePieceUpdateWithWhereUniqueWithoutArticleInput = {
  data: CoveragePieceUpdateWithoutArticleInput;
  where: CoveragePieceWhereUniqueInput;
};

export type CoveragePieceUpdateWithWhereUniqueWithoutClientsInput = {
  data: CoveragePieceUpdateWithoutClientsInput;
  where: CoveragePieceWhereUniqueInput;
};

export type CoveragePieceUpdateWithWhereUniqueWithoutCreatedByInput = {
  data: CoveragePieceUpdateWithoutCreatedByInput;
  where: CoveragePieceWhereUniqueInput;
};

export type CoveragePieceUpdateWithWhereUniqueWithoutPublicationInput = {
  data: CoveragePieceUpdateWithoutPublicationInput;
  where: CoveragePieceWhereUniqueInput;
};

export type CoveragePieceUpdateWithWhereUniqueWithoutSpokespeopleInput = {
  data: CoveragePieceUpdateWithoutSpokespeopleInput;
  where: CoveragePieceWhereUniqueInput;
};

export type CoveragePieceUpdateWithoutActivitiesInput = {
  article?: InputMaybe<ArticleUpdateOneWithoutCoveragePiecesNestedInput>;
  author?: InputMaybe<StringFieldUpdateOperationsInput>;
  category?: InputMaybe<CoveragePieceUpdatecategoryInput>;
  clients?: InputMaybe<ClientUpdateManyWithoutCoveragePiecesNestedInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutCoveragePiecesNestedInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  extract?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isFavourited?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLoadingSyndications?: InputMaybe<BoolFieldUpdateOperationsInput>;
  medium?: InputMaybe<CoveragePieceUpdatemediumInput>;
  notes?: InputMaybe<StringFieldUpdateOperationsInput>;
  page?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  print?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  publication?: InputMaybe<PublicationUpdateOneRequiredWithoutCoveragePiecesNestedInput>;
  screenshot?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  spokespeople?: InputMaybe<SpokespersonUpdateManyWithoutCoveragePiecesNestedInput>;
  syndications?: InputMaybe<CoveragePieceSyndicationUpdateManyWithoutCoveragePieceNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumCoverageTypeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CoveragePieceUpdateWithoutArticleInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutCoverageNestedInput>;
  author?: InputMaybe<StringFieldUpdateOperationsInput>;
  category?: InputMaybe<CoveragePieceUpdatecategoryInput>;
  clients?: InputMaybe<ClientUpdateManyWithoutCoveragePiecesNestedInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutCoveragePiecesNestedInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  extract?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isFavourited?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLoadingSyndications?: InputMaybe<BoolFieldUpdateOperationsInput>;
  medium?: InputMaybe<CoveragePieceUpdatemediumInput>;
  notes?: InputMaybe<StringFieldUpdateOperationsInput>;
  page?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  print?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  publication?: InputMaybe<PublicationUpdateOneRequiredWithoutCoveragePiecesNestedInput>;
  screenshot?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  spokespeople?: InputMaybe<SpokespersonUpdateManyWithoutCoveragePiecesNestedInput>;
  syndications?: InputMaybe<CoveragePieceSyndicationUpdateManyWithoutCoveragePieceNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumCoverageTypeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CoveragePieceUpdateWithoutClientsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutCoverageNestedInput>;
  article?: InputMaybe<ArticleUpdateOneWithoutCoveragePiecesNestedInput>;
  author?: InputMaybe<StringFieldUpdateOperationsInput>;
  category?: InputMaybe<CoveragePieceUpdatecategoryInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutCoveragePiecesNestedInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  extract?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isFavourited?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLoadingSyndications?: InputMaybe<BoolFieldUpdateOperationsInput>;
  medium?: InputMaybe<CoveragePieceUpdatemediumInput>;
  notes?: InputMaybe<StringFieldUpdateOperationsInput>;
  page?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  print?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  publication?: InputMaybe<PublicationUpdateOneRequiredWithoutCoveragePiecesNestedInput>;
  screenshot?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  spokespeople?: InputMaybe<SpokespersonUpdateManyWithoutCoveragePiecesNestedInput>;
  syndications?: InputMaybe<CoveragePieceSyndicationUpdateManyWithoutCoveragePieceNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumCoverageTypeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CoveragePieceUpdateWithoutCreatedByInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutCoverageNestedInput>;
  article?: InputMaybe<ArticleUpdateOneWithoutCoveragePiecesNestedInput>;
  author?: InputMaybe<StringFieldUpdateOperationsInput>;
  category?: InputMaybe<CoveragePieceUpdatecategoryInput>;
  clients?: InputMaybe<ClientUpdateManyWithoutCoveragePiecesNestedInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  extract?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isFavourited?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLoadingSyndications?: InputMaybe<BoolFieldUpdateOperationsInput>;
  medium?: InputMaybe<CoveragePieceUpdatemediumInput>;
  notes?: InputMaybe<StringFieldUpdateOperationsInput>;
  page?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  print?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  publication?: InputMaybe<PublicationUpdateOneRequiredWithoutCoveragePiecesNestedInput>;
  screenshot?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  spokespeople?: InputMaybe<SpokespersonUpdateManyWithoutCoveragePiecesNestedInput>;
  syndications?: InputMaybe<CoveragePieceSyndicationUpdateManyWithoutCoveragePieceNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumCoverageTypeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CoveragePieceUpdateWithoutPublicationInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutCoverageNestedInput>;
  article?: InputMaybe<ArticleUpdateOneWithoutCoveragePiecesNestedInput>;
  author?: InputMaybe<StringFieldUpdateOperationsInput>;
  category?: InputMaybe<CoveragePieceUpdatecategoryInput>;
  clients?: InputMaybe<ClientUpdateManyWithoutCoveragePiecesNestedInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutCoveragePiecesNestedInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  extract?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isFavourited?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLoadingSyndications?: InputMaybe<BoolFieldUpdateOperationsInput>;
  medium?: InputMaybe<CoveragePieceUpdatemediumInput>;
  notes?: InputMaybe<StringFieldUpdateOperationsInput>;
  page?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  print?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  screenshot?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  spokespeople?: InputMaybe<SpokespersonUpdateManyWithoutCoveragePiecesNestedInput>;
  syndications?: InputMaybe<CoveragePieceSyndicationUpdateManyWithoutCoveragePieceNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumCoverageTypeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CoveragePieceUpdateWithoutSpokespeopleInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutCoverageNestedInput>;
  article?: InputMaybe<ArticleUpdateOneWithoutCoveragePiecesNestedInput>;
  author?: InputMaybe<StringFieldUpdateOperationsInput>;
  category?: InputMaybe<CoveragePieceUpdatecategoryInput>;
  clients?: InputMaybe<ClientUpdateManyWithoutCoveragePiecesNestedInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutCoveragePiecesNestedInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  extract?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isFavourited?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLoadingSyndications?: InputMaybe<BoolFieldUpdateOperationsInput>;
  medium?: InputMaybe<CoveragePieceUpdatemediumInput>;
  notes?: InputMaybe<StringFieldUpdateOperationsInput>;
  page?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  print?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  publication?: InputMaybe<PublicationUpdateOneRequiredWithoutCoveragePiecesNestedInput>;
  screenshot?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  syndications?: InputMaybe<CoveragePieceSyndicationUpdateManyWithoutCoveragePieceNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumCoverageTypeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CoveragePieceUpdateWithoutSyndicationsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutCoverageNestedInput>;
  article?: InputMaybe<ArticleUpdateOneWithoutCoveragePiecesNestedInput>;
  author?: InputMaybe<StringFieldUpdateOperationsInput>;
  category?: InputMaybe<CoveragePieceUpdatecategoryInput>;
  clients?: InputMaybe<ClientUpdateManyWithoutCoveragePiecesNestedInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutCoveragePiecesNestedInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  extract?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isFavourited?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLoadingSyndications?: InputMaybe<BoolFieldUpdateOperationsInput>;
  medium?: InputMaybe<CoveragePieceUpdatemediumInput>;
  notes?: InputMaybe<StringFieldUpdateOperationsInput>;
  page?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  print?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  publication?: InputMaybe<PublicationUpdateOneRequiredWithoutCoveragePiecesNestedInput>;
  screenshot?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  spokespeople?: InputMaybe<SpokespersonUpdateManyWithoutCoveragePiecesNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumCoverageTypeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CoveragePieceUpdatecategoryInput = {
  push?: InputMaybe<Array<CoverageCategory>>;
  set?: InputMaybe<Array<CoverageCategory>>;
};

export type CoveragePieceUpdatemediumInput = {
  push?: InputMaybe<Array<CoverageMedium>>;
  set?: InputMaybe<Array<CoverageMedium>>;
};

export type CoveragePieceUpsertWithWhereUniqueWithoutActivitiesInput = {
  create: CoveragePieceCreateWithoutActivitiesInput;
  update: CoveragePieceUpdateWithoutActivitiesInput;
  where: CoveragePieceWhereUniqueInput;
};

export type CoveragePieceUpsertWithWhereUniqueWithoutArticleInput = {
  create: CoveragePieceCreateWithoutArticleInput;
  update: CoveragePieceUpdateWithoutArticleInput;
  where: CoveragePieceWhereUniqueInput;
};

export type CoveragePieceUpsertWithWhereUniqueWithoutClientsInput = {
  create: CoveragePieceCreateWithoutClientsInput;
  update: CoveragePieceUpdateWithoutClientsInput;
  where: CoveragePieceWhereUniqueInput;
};

export type CoveragePieceUpsertWithWhereUniqueWithoutCreatedByInput = {
  create: CoveragePieceCreateWithoutCreatedByInput;
  update: CoveragePieceUpdateWithoutCreatedByInput;
  where: CoveragePieceWhereUniqueInput;
};

export type CoveragePieceUpsertWithWhereUniqueWithoutPublicationInput = {
  create: CoveragePieceCreateWithoutPublicationInput;
  update: CoveragePieceUpdateWithoutPublicationInput;
  where: CoveragePieceWhereUniqueInput;
};

export type CoveragePieceUpsertWithWhereUniqueWithoutSpokespeopleInput = {
  create: CoveragePieceCreateWithoutSpokespeopleInput;
  update: CoveragePieceUpdateWithoutSpokespeopleInput;
  where: CoveragePieceWhereUniqueInput;
};

export type CoveragePieceUpsertWithoutSyndicationsInput = {
  create: CoveragePieceCreateWithoutSyndicationsInput;
  update: CoveragePieceUpdateWithoutSyndicationsInput;
  where?: InputMaybe<CoveragePieceWhereInput>;
};

export type CoveragePieceWhereInput = {
  AND?: InputMaybe<Array<CoveragePieceWhereInput>>;
  NOT?: InputMaybe<Array<CoveragePieceWhereInput>>;
  OR?: InputMaybe<Array<CoveragePieceWhereInput>>;
  activities?: InputMaybe<ActivityListRelationFilter>;
  article?: InputMaybe<ArticleNullableRelationFilter>;
  articleId?: InputMaybe<StringNullableFilter>;
  author?: InputMaybe<StringFilter>;
  category?: InputMaybe<EnumCoverageCategoryNullableListFilter>;
  clients?: InputMaybe<ClientListRelationFilter>;
  createdBy?: InputMaybe<UserRelationFilter>;
  createdById?: InputMaybe<StringFilter>;
  date?: InputMaybe<DateTimeFilter>;
  extract?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  isFavourited?: InputMaybe<BoolFilter>;
  isLoadingSyndications?: InputMaybe<BoolFilter>;
  medium?: InputMaybe<EnumCoverageMediumNullableListFilter>;
  notes?: InputMaybe<StringFilter>;
  page?: InputMaybe<IntNullableFilter>;
  print?: InputMaybe<StringNullableFilter>;
  publication?: InputMaybe<PublicationRelationFilter>;
  publicationId?: InputMaybe<StringFilter>;
  screenshot?: InputMaybe<StringNullableFilter>;
  spokespeople?: InputMaybe<SpokespersonListRelationFilter>;
  syndications?: InputMaybe<CoveragePieceSyndicationListRelationFilter>;
  title?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumCoverageTypeFilter>;
  url?: InputMaybe<StringNullableFilter>;
};

export type CoveragePieceWhereUniqueInput = {
  AND?: InputMaybe<Array<CoveragePieceWhereInput>>;
  NOT?: InputMaybe<Array<CoveragePieceWhereInput>>;
  OR?: InputMaybe<Array<CoveragePieceWhereInput>>;
  activities?: InputMaybe<ActivityListRelationFilter>;
  article?: InputMaybe<ArticleNullableRelationFilter>;
  articleId?: InputMaybe<StringNullableFilter>;
  author?: InputMaybe<StringFilter>;
  category?: InputMaybe<EnumCoverageCategoryNullableListFilter>;
  clients?: InputMaybe<ClientListRelationFilter>;
  createdBy?: InputMaybe<UserRelationFilter>;
  createdById?: InputMaybe<StringFilter>;
  date?: InputMaybe<DateTimeFilter>;
  extract?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  isFavourited?: InputMaybe<BoolFilter>;
  isLoadingSyndications?: InputMaybe<BoolFilter>;
  medium?: InputMaybe<EnumCoverageMediumNullableListFilter>;
  notes?: InputMaybe<StringFilter>;
  page?: InputMaybe<IntNullableFilter>;
  print?: InputMaybe<StringNullableFilter>;
  publication?: InputMaybe<PublicationRelationFilter>;
  publicationId?: InputMaybe<StringFilter>;
  screenshot?: InputMaybe<StringNullableFilter>;
  spokespeople?: InputMaybe<SpokespersonListRelationFilter>;
  syndications?: InputMaybe<CoveragePieceSyndicationListRelationFilter>;
  title?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumCoverageTypeFilter>;
  url?: InputMaybe<StringNullableFilter>;
};

export type CoveragePiecesByMonth = {
  __typename?: 'CoveragePiecesByMonth';
  coverageCount: Scalars['Int']['output'];
  month: Scalars['Int']['output'];
  totalHits: Scalars['Float']['output'];
  year: Scalars['Int']['output'];
};

export type CoveragePiecesByMonthResult = {
  __typename?: 'CoveragePiecesByMonthResult';
  averageSentiment: Scalars['Float']['output'];
  coveragePiecesByMonth: Array<CoveragePiecesByMonth>;
  predictedViews: Scalars['Float']['output'];
  totalAudience: Scalars['Float']['output'];
  totalPlacements: Scalars['Float']['output'];
};

export enum CoverageType {
  Earned = 'EARNED',
  Inbound = 'INBOUND',
  Organic = 'ORGANIC',
  Paid = 'PAID'
}

export type CreateActivityComment = {
  activityId: Scalars['String']['input'];
  content: Scalars['String']['input'];
};

export type CreateActivityType = {
  clientId: Scalars['String']['input'];
  date?: InputMaybe<Scalars['DateTime']['input']>;
  index: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  status: Scalars['String']['input'];
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CreateCoveragePieceExtendedInput = {
  author: Scalars['String']['input'];
  category?: InputMaybe<CoveragePieceCreatecategoryInput>;
  clients?: InputMaybe<ClientCreateNestedManyWithoutCoveragePiecesInput>;
  date: Scalars['DateTime']['input'];
  extract: Scalars['String']['input'];
  medium?: InputMaybe<CoveragePieceCreatemediumInput>;
  notes: Scalars['String']['input'];
  print?: InputMaybe<Scalars['String']['input']>;
  publication: PublicationCreateNestedOneWithoutCoveragePiecesInput;
  screenshot?: InputMaybe<Scalars['String']['input']>;
  spokespeople?: InputMaybe<SpokespersonCreateNestedManyWithoutCoveragePiecesInput>;
  syndications?: InputMaybe<CoveragePieceSyndicationCreateNestedManyWithoutCoveragePieceInput>;
  title: Scalars['String']['input'];
  type: CoverageType;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type DateRange = {
  __typename?: 'DateRange';
  endDate: Scalars['DateTime']['output'];
  startDate: Scalars['DateTime']['output'];
};

export type DateRangeInput = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DeleteFileInput = {
  id: Scalars['String']['input'];
};

export type EnumActivityPriorityFieldUpdateOperationsInput = {
  set?: InputMaybe<ActivityPriority>;
};

export type EnumActivityPriorityFilter = {
  equals?: InputMaybe<ActivityPriority>;
  in?: InputMaybe<Array<ActivityPriority>>;
  not?: InputMaybe<NestedEnumActivityPriorityFilter>;
  notIn?: InputMaybe<Array<ActivityPriority>>;
};

export type EnumActivityStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<ActivityStatus>;
};

export type EnumActivityStatusFilter = {
  equals?: InputMaybe<ActivityStatus>;
  in?: InputMaybe<Array<ActivityStatus>>;
  not?: InputMaybe<NestedEnumActivityStatusFilter>;
  notIn?: InputMaybe<Array<ActivityStatus>>;
};

export type EnumArticleTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<ArticleType>;
};

export type EnumArticleTypeFilter = {
  equals?: InputMaybe<ArticleType>;
  in?: InputMaybe<Array<ArticleType>>;
  not?: InputMaybe<NestedEnumArticleTypeFilter>;
  notIn?: InputMaybe<Array<ArticleType>>;
};

export type EnumClientStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<ClientStatus>;
};

export type EnumClientStatusFilter = {
  equals?: InputMaybe<ClientStatus>;
  in?: InputMaybe<Array<ClientStatus>>;
  not?: InputMaybe<NestedEnumClientStatusFilter>;
  notIn?: InputMaybe<Array<ClientStatus>>;
};

export type EnumCoverageCategoryNullableListFilter = {
  equals?: InputMaybe<Array<CoverageCategory>>;
  has?: InputMaybe<CoverageCategory>;
  hasEvery?: InputMaybe<Array<CoverageCategory>>;
  hasSome?: InputMaybe<Array<CoverageCategory>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EnumCoverageMediumNullableListFilter = {
  equals?: InputMaybe<Array<CoverageMedium>>;
  has?: InputMaybe<CoverageMedium>;
  hasEvery?: InputMaybe<Array<CoverageMedium>>;
  hasSome?: InputMaybe<Array<CoverageMedium>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EnumCoverageTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<CoverageType>;
};

export type EnumCoverageTypeFilter = {
  equals?: InputMaybe<CoverageType>;
  in?: InputMaybe<Array<CoverageType>>;
  not?: InputMaybe<NestedEnumCoverageTypeFilter>;
  notIn?: InputMaybe<Array<CoverageType>>;
};

export type EnumNameUniquenessFieldUpdateOperationsInput = {
  set?: InputMaybe<NameUniqueness>;
};

export type EnumNameUniquenessFilter = {
  equals?: InputMaybe<NameUniqueness>;
  in?: InputMaybe<Array<NameUniqueness>>;
  not?: InputMaybe<NestedEnumNameUniquenessFilter>;
  notIn?: InputMaybe<Array<NameUniqueness>>;
};

export type EnumNewsJackStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<NewsJackStatus>;
};

export type EnumNewsJackStatusFilter = {
  equals?: InputMaybe<NewsJackStatus>;
  in?: InputMaybe<Array<NewsJackStatus>>;
  not?: InputMaybe<NestedEnumNewsJackStatusFilter>;
  notIn?: InputMaybe<Array<NewsJackStatus>>;
};

export type EnumPermissionEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<PermissionEnum>;
};

export type EnumPermissionEnumFilter = {
  equals?: InputMaybe<PermissionEnum>;
  in?: InputMaybe<Array<PermissionEnum>>;
  not?: InputMaybe<NestedEnumPermissionEnumFilter>;
  notIn?: InputMaybe<Array<PermissionEnum>>;
};

export type EnumPrintScraperMethodFieldUpdateOperationsInput = {
  set?: InputMaybe<PrintScraperMethod>;
};

export type EnumPrintScraperMethodFilter = {
  equals?: InputMaybe<PrintScraperMethod>;
  in?: InputMaybe<Array<PrintScraperMethod>>;
  not?: InputMaybe<NestedEnumPrintScraperMethodFilter>;
  notIn?: InputMaybe<Array<PrintScraperMethod>>;
};

export type EnumPublicationTierFieldUpdateOperationsInput = {
  set?: InputMaybe<PublicationTier>;
};

export type EnumPublicationTierFilter = {
  equals?: InputMaybe<PublicationTier>;
  in?: InputMaybe<Array<PublicationTier>>;
  not?: InputMaybe<NestedEnumPublicationTierFilter>;
  notIn?: InputMaybe<Array<PublicationTier>>;
};

export type EnumPublicationTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<PublicationType>;
};

export type EnumPublicationTypeFilter = {
  equals?: InputMaybe<PublicationType>;
  in?: InputMaybe<Array<PublicationType>>;
  not?: InputMaybe<NestedEnumPublicationTypeFilter>;
  notIn?: InputMaybe<Array<PublicationType>>;
};

export type EnumScrapeMethodFieldUpdateOperationsInput = {
  set?: InputMaybe<ScrapeMethod>;
};

export type EnumScrapeMethodFilter = {
  equals?: InputMaybe<ScrapeMethod>;
  in?: InputMaybe<Array<ScrapeMethod>>;
  not?: InputMaybe<NestedEnumScrapeMethodFilter>;
  notIn?: InputMaybe<Array<ScrapeMethod>>;
};

export type EnumTokenTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<TokenType>;
};

export type EnumTokenTypeFilter = {
  equals?: InputMaybe<TokenType>;
  in?: InputMaybe<Array<TokenType>>;
  not?: InputMaybe<NestedEnumTokenTypeFilter>;
  notIn?: InputMaybe<Array<TokenType>>;
};

export type EnumWorkspacePermissionsNullableListFilter = {
  equals?: InputMaybe<Array<WorkspacePermissions>>;
  has?: InputMaybe<WorkspacePermissions>;
  hasEvery?: InputMaybe<Array<WorkspacePermissions>>;
  hasSome?: InputMaybe<Array<WorkspacePermissions>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ExpandInput = {
  context: Scalars['String']['input'];
  length: Scalars['Int']['input'];
  text: Scalars['String']['input'];
};

export type ExtendedActivity = {
  __typename?: 'ExtendedActivity';
  _count?: Maybe<ActivityCount>;
  client: Client;
  clientId: Scalars['String']['output'];
  comments: Array<ActivityComment>;
  content: Scalars['String']['output'];
  coverage: Array<CoveragePiece>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  docsId?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  files: Array<FileMetadata>;
  filesCount: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  index: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  priority: ActivityPriority;
  programPlannerItem?: Maybe<ProgramPlannerActivity>;
  status: ActivityStatus;
  users: Array<User>;
};


export type ExtendedActivityCommentsArgs = {
  cursor?: InputMaybe<ActivityCommentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityCommentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActivityCommentOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ActivityCommentWhereInput>;
};


export type ExtendedActivityCoverageArgs = {
  cursor?: InputMaybe<CoveragePieceWhereUniqueInput>;
  distinct?: InputMaybe<Array<CoveragePieceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CoveragePieceOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CoveragePieceWhereInput>;
};


export type ExtendedActivityFilesArgs = {
  cursor?: InputMaybe<FileMetadataWhereUniqueInput>;
  distinct?: InputMaybe<Array<FileMetadataScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FileMetadataOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FileMetadataWhereInput>;
};


export type ExtendedActivityUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};

export type Feed = {
  __typename?: 'Feed';
  createdAt: Scalars['DateTime']['output'];
  publicationId: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type FeedCreateManyPublicationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  url: Scalars['String']['input'];
};

export type FeedCreateManyPublicationInputEnvelope = {
  data: Array<FeedCreateManyPublicationInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FeedCreateNestedManyWithoutPublicationInput = {
  connect?: InputMaybe<Array<FeedWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FeedCreateOrConnectWithoutPublicationInput>>;
  create?: InputMaybe<Array<FeedCreateWithoutPublicationInput>>;
  createMany?: InputMaybe<FeedCreateManyPublicationInputEnvelope>;
};

export type FeedCreateOrConnectWithoutPublicationInput = {
  create: FeedCreateWithoutPublicationInput;
  where: FeedWhereUniqueInput;
};

export type FeedCreateWithoutPublicationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  url: Scalars['String']['input'];
};

export type FeedListRelationFilter = {
  every?: InputMaybe<FeedWhereInput>;
  none?: InputMaybe<FeedWhereInput>;
  some?: InputMaybe<FeedWhereInput>;
};

export type FeedOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type FeedScalarWhereInput = {
  AND?: InputMaybe<Array<FeedScalarWhereInput>>;
  NOT?: InputMaybe<Array<FeedScalarWhereInput>>;
  OR?: InputMaybe<Array<FeedScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  publicationId?: InputMaybe<StringFilter>;
  url?: InputMaybe<StringFilter>;
};

export type FeedUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type FeedUpdateManyWithWhereWithoutPublicationInput = {
  data: FeedUpdateManyMutationInput;
  where: FeedScalarWhereInput;
};

export type FeedUpdateManyWithoutPublicationNestedInput = {
  connect?: InputMaybe<Array<FeedWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FeedCreateOrConnectWithoutPublicationInput>>;
  create?: InputMaybe<Array<FeedCreateWithoutPublicationInput>>;
  createMany?: InputMaybe<FeedCreateManyPublicationInputEnvelope>;
  delete?: InputMaybe<Array<FeedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FeedScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FeedWhereUniqueInput>>;
  set?: InputMaybe<Array<FeedWhereUniqueInput>>;
  update?: InputMaybe<Array<FeedUpdateWithWhereUniqueWithoutPublicationInput>>;
  updateMany?: InputMaybe<Array<FeedUpdateManyWithWhereWithoutPublicationInput>>;
  upsert?: InputMaybe<Array<FeedUpsertWithWhereUniqueWithoutPublicationInput>>;
};

export type FeedUpdateWithWhereUniqueWithoutPublicationInput = {
  data: FeedUpdateWithoutPublicationInput;
  where: FeedWhereUniqueInput;
};

export type FeedUpdateWithoutPublicationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type FeedUpsertWithWhereUniqueWithoutPublicationInput = {
  create: FeedCreateWithoutPublicationInput;
  update: FeedUpdateWithoutPublicationInput;
  where: FeedWhereUniqueInput;
};

export type FeedWhereInput = {
  AND?: InputMaybe<Array<FeedWhereInput>>;
  NOT?: InputMaybe<Array<FeedWhereInput>>;
  OR?: InputMaybe<Array<FeedWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  publication?: InputMaybe<PublicationRelationFilter>;
  publicationId?: InputMaybe<StringFilter>;
  url?: InputMaybe<StringFilter>;
};

export type FeedWhereUniqueInput = {
  AND?: InputMaybe<Array<FeedWhereInput>>;
  NOT?: InputMaybe<Array<FeedWhereInput>>;
  OR?: InputMaybe<Array<FeedWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  publication?: InputMaybe<PublicationRelationFilter>;
  publicationId?: InputMaybe<StringFilter>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type FileListResult = {
  __typename?: 'FileListResult';
  id: Scalars['String']['output'];
  lastModified: Scalars['DateTime']['output'];
  mime: Scalars['String']['output'];
  name: Scalars['String']['output'];
  size: Scalars['Float']['output'];
  thumb?: Maybe<Scalars['String']['output']>;
  uploadedBy?: Maybe<User>;
};

export type FileMetadata = {
  __typename?: 'FileMetadata';
  _count?: Maybe<FileMetadataCount>;
  activityId?: Maybe<Scalars['String']['output']>;
  clientId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  mime: Scalars['String']['output'];
  name: Scalars['String']['output'];
  thumb?: Maybe<Scalars['String']['output']>;
  uploadedById?: Maybe<Scalars['String']['output']>;
};

export type FileMetadataCount = {
  __typename?: 'FileMetadataCount';
  content: Scalars['Int']['output'];
};


export type FileMetadataCountContentArgs = {
  where?: InputMaybe<ContentWhereInput>;
};

export type FileMetadataCreateManyActivityInput = {
  clientId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  mime: Scalars['String']['input'];
  name: Scalars['String']['input'];
  thumb?: InputMaybe<Scalars['String']['input']>;
  uploadedById?: InputMaybe<Scalars['String']['input']>;
};

export type FileMetadataCreateManyActivityInputEnvelope = {
  data: Array<FileMetadataCreateManyActivityInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FileMetadataCreateManyClientInput = {
  activityId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  mime: Scalars['String']['input'];
  name: Scalars['String']['input'];
  thumb?: InputMaybe<Scalars['String']['input']>;
  uploadedById?: InputMaybe<Scalars['String']['input']>;
};

export type FileMetadataCreateManyClientInputEnvelope = {
  data: Array<FileMetadataCreateManyClientInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FileMetadataCreateManyUploadedByInput = {
  activityId?: InputMaybe<Scalars['String']['input']>;
  clientId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  mime: Scalars['String']['input'];
  name: Scalars['String']['input'];
  thumb?: InputMaybe<Scalars['String']['input']>;
};

export type FileMetadataCreateManyUploadedByInputEnvelope = {
  data: Array<FileMetadataCreateManyUploadedByInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FileMetadataCreateNestedManyWithoutActivityInput = {
  connect?: InputMaybe<Array<FileMetadataWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FileMetadataCreateOrConnectWithoutActivityInput>>;
  create?: InputMaybe<Array<FileMetadataCreateWithoutActivityInput>>;
  createMany?: InputMaybe<FileMetadataCreateManyActivityInputEnvelope>;
};

export type FileMetadataCreateNestedManyWithoutClientInput = {
  connect?: InputMaybe<Array<FileMetadataWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FileMetadataCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<FileMetadataCreateWithoutClientInput>>;
  createMany?: InputMaybe<FileMetadataCreateManyClientInputEnvelope>;
};

export type FileMetadataCreateNestedManyWithoutContentInput = {
  connect?: InputMaybe<Array<FileMetadataWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FileMetadataCreateOrConnectWithoutContentInput>>;
  create?: InputMaybe<Array<FileMetadataCreateWithoutContentInput>>;
};

export type FileMetadataCreateNestedManyWithoutUploadedByInput = {
  connect?: InputMaybe<Array<FileMetadataWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FileMetadataCreateOrConnectWithoutUploadedByInput>>;
  create?: InputMaybe<Array<FileMetadataCreateWithoutUploadedByInput>>;
  createMany?: InputMaybe<FileMetadataCreateManyUploadedByInputEnvelope>;
};

export type FileMetadataCreateOrConnectWithoutActivityInput = {
  create: FileMetadataCreateWithoutActivityInput;
  where: FileMetadataWhereUniqueInput;
};

export type FileMetadataCreateOrConnectWithoutClientInput = {
  create: FileMetadataCreateWithoutClientInput;
  where: FileMetadataWhereUniqueInput;
};

export type FileMetadataCreateOrConnectWithoutContentInput = {
  create: FileMetadataCreateWithoutContentInput;
  where: FileMetadataWhereUniqueInput;
};

export type FileMetadataCreateOrConnectWithoutUploadedByInput = {
  create: FileMetadataCreateWithoutUploadedByInput;
  where: FileMetadataWhereUniqueInput;
};

export type FileMetadataCreateWithoutActivityInput = {
  client?: InputMaybe<ClientCreateNestedOneWithoutFilesInput>;
  content?: InputMaybe<ContentCreateNestedManyWithoutFilesInput>;
  id: Scalars['String']['input'];
  mime: Scalars['String']['input'];
  name: Scalars['String']['input'];
  thumb?: InputMaybe<Scalars['String']['input']>;
  uploadedBy?: InputMaybe<UserCreateNestedOneWithoutFilesInput>;
};

export type FileMetadataCreateWithoutClientInput = {
  activity?: InputMaybe<ActivityCreateNestedOneWithoutFilesInput>;
  content?: InputMaybe<ContentCreateNestedManyWithoutFilesInput>;
  id: Scalars['String']['input'];
  mime: Scalars['String']['input'];
  name: Scalars['String']['input'];
  thumb?: InputMaybe<Scalars['String']['input']>;
  uploadedBy?: InputMaybe<UserCreateNestedOneWithoutFilesInput>;
};

export type FileMetadataCreateWithoutContentInput = {
  activity?: InputMaybe<ActivityCreateNestedOneWithoutFilesInput>;
  client?: InputMaybe<ClientCreateNestedOneWithoutFilesInput>;
  id: Scalars['String']['input'];
  mime: Scalars['String']['input'];
  name: Scalars['String']['input'];
  thumb?: InputMaybe<Scalars['String']['input']>;
  uploadedBy?: InputMaybe<UserCreateNestedOneWithoutFilesInput>;
};

export type FileMetadataCreateWithoutUploadedByInput = {
  activity?: InputMaybe<ActivityCreateNestedOneWithoutFilesInput>;
  client?: InputMaybe<ClientCreateNestedOneWithoutFilesInput>;
  content?: InputMaybe<ContentCreateNestedManyWithoutFilesInput>;
  id: Scalars['String']['input'];
  mime: Scalars['String']['input'];
  name: Scalars['String']['input'];
  thumb?: InputMaybe<Scalars['String']['input']>;
};

export type FileMetadataListRelationFilter = {
  every?: InputMaybe<FileMetadataWhereInput>;
  none?: InputMaybe<FileMetadataWhereInput>;
  some?: InputMaybe<FileMetadataWhereInput>;
};

export type FileMetadataOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum FileMetadataOrderByRelevanceFieldEnum {
  ActivityId = 'activityId',
  ClientId = 'clientId',
  Id = 'id',
  Mime = 'mime',
  Name = 'name',
  Thumb = 'thumb',
  UploadedById = 'uploadedById'
}

export type FileMetadataOrderByRelevanceInput = {
  fields: Array<FileMetadataOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type FileMetadataOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<FileMetadataOrderByRelevanceInput>;
  activity?: InputMaybe<ActivityOrderByWithRelationAndSearchRelevanceInput>;
  activityId?: InputMaybe<SortOrderInput>;
  client?: InputMaybe<ClientOrderByWithRelationAndSearchRelevanceInput>;
  clientId?: InputMaybe<SortOrderInput>;
  content?: InputMaybe<ContentOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  mime?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  thumb?: InputMaybe<SortOrderInput>;
  uploadedBy?: InputMaybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  uploadedById?: InputMaybe<SortOrderInput>;
};

export enum FileMetadataScalarFieldEnum {
  ActivityId = 'activityId',
  ClientId = 'clientId',
  Id = 'id',
  Mime = 'mime',
  Name = 'name',
  Thumb = 'thumb',
  UploadedById = 'uploadedById'
}

export type FileMetadataScalarWhereInput = {
  AND?: InputMaybe<Array<FileMetadataScalarWhereInput>>;
  NOT?: InputMaybe<Array<FileMetadataScalarWhereInput>>;
  OR?: InputMaybe<Array<FileMetadataScalarWhereInput>>;
  activityId?: InputMaybe<StringNullableFilter>;
  clientId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  mime?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  thumb?: InputMaybe<StringNullableFilter>;
  uploadedById?: InputMaybe<StringNullableFilter>;
};

export type FileMetadataUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mime?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  thumb?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type FileMetadataUpdateManyWithWhereWithoutActivityInput = {
  data: FileMetadataUpdateManyMutationInput;
  where: FileMetadataScalarWhereInput;
};

export type FileMetadataUpdateManyWithWhereWithoutClientInput = {
  data: FileMetadataUpdateManyMutationInput;
  where: FileMetadataScalarWhereInput;
};

export type FileMetadataUpdateManyWithWhereWithoutContentInput = {
  data: FileMetadataUpdateManyMutationInput;
  where: FileMetadataScalarWhereInput;
};

export type FileMetadataUpdateManyWithWhereWithoutUploadedByInput = {
  data: FileMetadataUpdateManyMutationInput;
  where: FileMetadataScalarWhereInput;
};

export type FileMetadataUpdateManyWithoutActivityNestedInput = {
  connect?: InputMaybe<Array<FileMetadataWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FileMetadataCreateOrConnectWithoutActivityInput>>;
  create?: InputMaybe<Array<FileMetadataCreateWithoutActivityInput>>;
  createMany?: InputMaybe<FileMetadataCreateManyActivityInputEnvelope>;
  delete?: InputMaybe<Array<FileMetadataWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FileMetadataScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FileMetadataWhereUniqueInput>>;
  set?: InputMaybe<Array<FileMetadataWhereUniqueInput>>;
  update?: InputMaybe<Array<FileMetadataUpdateWithWhereUniqueWithoutActivityInput>>;
  updateMany?: InputMaybe<Array<FileMetadataUpdateManyWithWhereWithoutActivityInput>>;
  upsert?: InputMaybe<Array<FileMetadataUpsertWithWhereUniqueWithoutActivityInput>>;
};

export type FileMetadataUpdateManyWithoutClientNestedInput = {
  connect?: InputMaybe<Array<FileMetadataWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FileMetadataCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<FileMetadataCreateWithoutClientInput>>;
  createMany?: InputMaybe<FileMetadataCreateManyClientInputEnvelope>;
  delete?: InputMaybe<Array<FileMetadataWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FileMetadataScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FileMetadataWhereUniqueInput>>;
  set?: InputMaybe<Array<FileMetadataWhereUniqueInput>>;
  update?: InputMaybe<Array<FileMetadataUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: InputMaybe<Array<FileMetadataUpdateManyWithWhereWithoutClientInput>>;
  upsert?: InputMaybe<Array<FileMetadataUpsertWithWhereUniqueWithoutClientInput>>;
};

export type FileMetadataUpdateManyWithoutContentNestedInput = {
  connect?: InputMaybe<Array<FileMetadataWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FileMetadataCreateOrConnectWithoutContentInput>>;
  create?: InputMaybe<Array<FileMetadataCreateWithoutContentInput>>;
  delete?: InputMaybe<Array<FileMetadataWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FileMetadataScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FileMetadataWhereUniqueInput>>;
  set?: InputMaybe<Array<FileMetadataWhereUniqueInput>>;
  update?: InputMaybe<Array<FileMetadataUpdateWithWhereUniqueWithoutContentInput>>;
  updateMany?: InputMaybe<Array<FileMetadataUpdateManyWithWhereWithoutContentInput>>;
  upsert?: InputMaybe<Array<FileMetadataUpsertWithWhereUniqueWithoutContentInput>>;
};

export type FileMetadataUpdateManyWithoutUploadedByNestedInput = {
  connect?: InputMaybe<Array<FileMetadataWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FileMetadataCreateOrConnectWithoutUploadedByInput>>;
  create?: InputMaybe<Array<FileMetadataCreateWithoutUploadedByInput>>;
  createMany?: InputMaybe<FileMetadataCreateManyUploadedByInputEnvelope>;
  delete?: InputMaybe<Array<FileMetadataWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FileMetadataScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FileMetadataWhereUniqueInput>>;
  set?: InputMaybe<Array<FileMetadataWhereUniqueInput>>;
  update?: InputMaybe<Array<FileMetadataUpdateWithWhereUniqueWithoutUploadedByInput>>;
  updateMany?: InputMaybe<Array<FileMetadataUpdateManyWithWhereWithoutUploadedByInput>>;
  upsert?: InputMaybe<Array<FileMetadataUpsertWithWhereUniqueWithoutUploadedByInput>>;
};

export type FileMetadataUpdateWithWhereUniqueWithoutActivityInput = {
  data: FileMetadataUpdateWithoutActivityInput;
  where: FileMetadataWhereUniqueInput;
};

export type FileMetadataUpdateWithWhereUniqueWithoutClientInput = {
  data: FileMetadataUpdateWithoutClientInput;
  where: FileMetadataWhereUniqueInput;
};

export type FileMetadataUpdateWithWhereUniqueWithoutContentInput = {
  data: FileMetadataUpdateWithoutContentInput;
  where: FileMetadataWhereUniqueInput;
};

export type FileMetadataUpdateWithWhereUniqueWithoutUploadedByInput = {
  data: FileMetadataUpdateWithoutUploadedByInput;
  where: FileMetadataWhereUniqueInput;
};

export type FileMetadataUpdateWithoutActivityInput = {
  client?: InputMaybe<ClientUpdateOneWithoutFilesNestedInput>;
  content?: InputMaybe<ContentUpdateManyWithoutFilesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mime?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  thumb?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uploadedBy?: InputMaybe<UserUpdateOneWithoutFilesNestedInput>;
};

export type FileMetadataUpdateWithoutClientInput = {
  activity?: InputMaybe<ActivityUpdateOneWithoutFilesNestedInput>;
  content?: InputMaybe<ContentUpdateManyWithoutFilesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mime?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  thumb?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uploadedBy?: InputMaybe<UserUpdateOneWithoutFilesNestedInput>;
};

export type FileMetadataUpdateWithoutContentInput = {
  activity?: InputMaybe<ActivityUpdateOneWithoutFilesNestedInput>;
  client?: InputMaybe<ClientUpdateOneWithoutFilesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mime?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  thumb?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uploadedBy?: InputMaybe<UserUpdateOneWithoutFilesNestedInput>;
};

export type FileMetadataUpdateWithoutUploadedByInput = {
  activity?: InputMaybe<ActivityUpdateOneWithoutFilesNestedInput>;
  client?: InputMaybe<ClientUpdateOneWithoutFilesNestedInput>;
  content?: InputMaybe<ContentUpdateManyWithoutFilesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mime?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  thumb?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type FileMetadataUpsertWithWhereUniqueWithoutActivityInput = {
  create: FileMetadataCreateWithoutActivityInput;
  update: FileMetadataUpdateWithoutActivityInput;
  where: FileMetadataWhereUniqueInput;
};

export type FileMetadataUpsertWithWhereUniqueWithoutClientInput = {
  create: FileMetadataCreateWithoutClientInput;
  update: FileMetadataUpdateWithoutClientInput;
  where: FileMetadataWhereUniqueInput;
};

export type FileMetadataUpsertWithWhereUniqueWithoutContentInput = {
  create: FileMetadataCreateWithoutContentInput;
  update: FileMetadataUpdateWithoutContentInput;
  where: FileMetadataWhereUniqueInput;
};

export type FileMetadataUpsertWithWhereUniqueWithoutUploadedByInput = {
  create: FileMetadataCreateWithoutUploadedByInput;
  update: FileMetadataUpdateWithoutUploadedByInput;
  where: FileMetadataWhereUniqueInput;
};

export type FileMetadataWhereInput = {
  AND?: InputMaybe<Array<FileMetadataWhereInput>>;
  NOT?: InputMaybe<Array<FileMetadataWhereInput>>;
  OR?: InputMaybe<Array<FileMetadataWhereInput>>;
  activity?: InputMaybe<ActivityNullableRelationFilter>;
  activityId?: InputMaybe<StringNullableFilter>;
  client?: InputMaybe<ClientNullableRelationFilter>;
  clientId?: InputMaybe<StringNullableFilter>;
  content?: InputMaybe<ContentListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  mime?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  thumb?: InputMaybe<StringNullableFilter>;
  uploadedBy?: InputMaybe<UserNullableRelationFilter>;
  uploadedById?: InputMaybe<StringNullableFilter>;
};

export type FileMetadataWhereUniqueInput = {
  AND?: InputMaybe<Array<FileMetadataWhereInput>>;
  NOT?: InputMaybe<Array<FileMetadataWhereInput>>;
  OR?: InputMaybe<Array<FileMetadataWhereInput>>;
  activity?: InputMaybe<ActivityNullableRelationFilter>;
  activityId?: InputMaybe<StringNullableFilter>;
  client?: InputMaybe<ClientNullableRelationFilter>;
  clientId?: InputMaybe<StringNullableFilter>;
  content?: InputMaybe<ContentListRelationFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  mime?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  thumb?: InputMaybe<StringNullableFilter>;
  uploadedBy?: InputMaybe<UserNullableRelationFilter>;
  uploadedById?: InputMaybe<StringNullableFilter>;
};

/** Date range to filter articles by */
export enum FilterDateRange {
  AllTime = 'ALL_TIME',
  Last_5Years = 'LAST_5_YEARS',
  Last_7Days = 'LAST_7_DAYS',
  Last_12Months = 'LAST_12_MONTHS',
  Last_24Hours = 'LAST_24_HOURS',
  Last_30Days = 'LAST_30_DAYS',
  Last_90Days = 'LAST_90_DAYS'
}

export type FindPublicationInput = {
  url: Scalars['String']['input'];
};

export type FloatFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Float']['input']>;
  divide?: InputMaybe<Scalars['Float']['input']>;
  increment?: InputMaybe<Scalars['Float']['input']>;
  multiply?: InputMaybe<Scalars['Float']['input']>;
  set?: InputMaybe<Scalars['Float']['input']>;
};

export type FloatFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type GetClientFilesInput = {
  clientId: Scalars['String']['input'];
};

export type GetNotificationsWhere = {
  createdAt: DateTimeFilter;
};

export type GqlPublication = {
  __typename?: 'GqlPublication';
  id: Scalars['String']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type GraphDataResult = {
  __typename?: 'GraphDataResult';
  arrayOfTuples: Array<StringAndNumberTuple>;
  clientName: Scalars['String']['output'];
};

/** Group by days, weeks, months, or years */
export enum GroupBy {
  Days = 'DAYS',
  Months = 'MONTHS',
  Weeks = 'WEEKS',
  Years = 'YEARS'
}

export type IgnoredAuthorCreateManyPublicationInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type IgnoredAuthorCreateManyPublicationInputEnvelope = {
  data: Array<IgnoredAuthorCreateManyPublicationInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IgnoredAuthorCreateNestedManyWithoutPublicationInput = {
  connect?: InputMaybe<Array<IgnoredAuthorWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IgnoredAuthorCreateOrConnectWithoutPublicationInput>>;
  create?: InputMaybe<Array<IgnoredAuthorCreateWithoutPublicationInput>>;
  createMany?: InputMaybe<IgnoredAuthorCreateManyPublicationInputEnvelope>;
};

export type IgnoredAuthorCreateOrConnectWithoutPublicationInput = {
  create: IgnoredAuthorCreateWithoutPublicationInput;
  where: IgnoredAuthorWhereUniqueInput;
};

export type IgnoredAuthorCreateWithoutPublicationInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type IgnoredAuthorListRelationFilter = {
  every?: InputMaybe<IgnoredAuthorWhereInput>;
  none?: InputMaybe<IgnoredAuthorWhereInput>;
  some?: InputMaybe<IgnoredAuthorWhereInput>;
};

export type IgnoredAuthorNamePublicationIdCompoundUniqueInput = {
  name: Scalars['String']['input'];
  publicationId: Scalars['String']['input'];
};

export type IgnoredAuthorOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type IgnoredAuthorScalarWhereInput = {
  AND?: InputMaybe<Array<IgnoredAuthorScalarWhereInput>>;
  NOT?: InputMaybe<Array<IgnoredAuthorScalarWhereInput>>;
  OR?: InputMaybe<Array<IgnoredAuthorScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  publicationId?: InputMaybe<StringFilter>;
};

export type IgnoredAuthorUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type IgnoredAuthorUpdateManyWithWhereWithoutPublicationInput = {
  data: IgnoredAuthorUpdateManyMutationInput;
  where: IgnoredAuthorScalarWhereInput;
};

export type IgnoredAuthorUpdateManyWithoutPublicationNestedInput = {
  connect?: InputMaybe<Array<IgnoredAuthorWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IgnoredAuthorCreateOrConnectWithoutPublicationInput>>;
  create?: InputMaybe<Array<IgnoredAuthorCreateWithoutPublicationInput>>;
  createMany?: InputMaybe<IgnoredAuthorCreateManyPublicationInputEnvelope>;
  delete?: InputMaybe<Array<IgnoredAuthorWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<IgnoredAuthorScalarWhereInput>>;
  disconnect?: InputMaybe<Array<IgnoredAuthorWhereUniqueInput>>;
  set?: InputMaybe<Array<IgnoredAuthorWhereUniqueInput>>;
  update?: InputMaybe<Array<IgnoredAuthorUpdateWithWhereUniqueWithoutPublicationInput>>;
  updateMany?: InputMaybe<Array<IgnoredAuthorUpdateManyWithWhereWithoutPublicationInput>>;
  upsert?: InputMaybe<Array<IgnoredAuthorUpsertWithWhereUniqueWithoutPublicationInput>>;
};

export type IgnoredAuthorUpdateWithWhereUniqueWithoutPublicationInput = {
  data: IgnoredAuthorUpdateWithoutPublicationInput;
  where: IgnoredAuthorWhereUniqueInput;
};

export type IgnoredAuthorUpdateWithoutPublicationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type IgnoredAuthorUpsertWithWhereUniqueWithoutPublicationInput = {
  create: IgnoredAuthorCreateWithoutPublicationInput;
  update: IgnoredAuthorUpdateWithoutPublicationInput;
  where: IgnoredAuthorWhereUniqueInput;
};

export type IgnoredAuthorWhereInput = {
  AND?: InputMaybe<Array<IgnoredAuthorWhereInput>>;
  NOT?: InputMaybe<Array<IgnoredAuthorWhereInput>>;
  OR?: InputMaybe<Array<IgnoredAuthorWhereInput>>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  publication?: InputMaybe<PublicationRelationFilter>;
  publicationId?: InputMaybe<StringFilter>;
};

export type IgnoredAuthorWhereUniqueInput = {
  AND?: InputMaybe<Array<IgnoredAuthorWhereInput>>;
  NOT?: InputMaybe<Array<IgnoredAuthorWhereInput>>;
  OR?: InputMaybe<Array<IgnoredAuthorWhereInput>>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<StringFilter>;
  name_publicationId?: InputMaybe<IgnoredAuthorNamePublicationIdCompoundUniqueInput>;
  publication?: InputMaybe<PublicationRelationFilter>;
  publicationId?: InputMaybe<StringFilter>;
};

export type IgnoredSuggestedCoverage = {
  __typename?: 'IgnoredSuggestedCoverage';
  articleId: Scalars['String']['output'];
  clientId: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type IgnoredSuggestedCoverageCreateManyArticleInput = {
  clientId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
};

export type IgnoredSuggestedCoverageCreateManyArticleInputEnvelope = {
  data: Array<IgnoredSuggestedCoverageCreateManyArticleInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IgnoredSuggestedCoverageCreateManyClientInput = {
  articleId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
};

export type IgnoredSuggestedCoverageCreateManyClientInputEnvelope = {
  data: Array<IgnoredSuggestedCoverageCreateManyClientInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IgnoredSuggestedCoverageCreateNestedManyWithoutArticleInput = {
  connect?: InputMaybe<Array<IgnoredSuggestedCoverageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IgnoredSuggestedCoverageCreateOrConnectWithoutArticleInput>>;
  create?: InputMaybe<Array<IgnoredSuggestedCoverageCreateWithoutArticleInput>>;
  createMany?: InputMaybe<IgnoredSuggestedCoverageCreateManyArticleInputEnvelope>;
};

export type IgnoredSuggestedCoverageCreateNestedManyWithoutClientInput = {
  connect?: InputMaybe<Array<IgnoredSuggestedCoverageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IgnoredSuggestedCoverageCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<IgnoredSuggestedCoverageCreateWithoutClientInput>>;
  createMany?: InputMaybe<IgnoredSuggestedCoverageCreateManyClientInputEnvelope>;
};

export type IgnoredSuggestedCoverageCreateOrConnectWithoutArticleInput = {
  create: IgnoredSuggestedCoverageCreateWithoutArticleInput;
  where: IgnoredSuggestedCoverageWhereUniqueInput;
};

export type IgnoredSuggestedCoverageCreateOrConnectWithoutClientInput = {
  create: IgnoredSuggestedCoverageCreateWithoutClientInput;
  where: IgnoredSuggestedCoverageWhereUniqueInput;
};

export type IgnoredSuggestedCoverageCreateWithoutArticleInput = {
  client: ClientCreateNestedOneWithoutIgnoredSuggestedCoverageInput;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type IgnoredSuggestedCoverageCreateWithoutClientInput = {
  article?: InputMaybe<ArticleCreateNestedOneWithoutIgnoredSuggestedCoverageInput>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type IgnoredSuggestedCoverageListRelationFilter = {
  every?: InputMaybe<IgnoredSuggestedCoverageWhereInput>;
  none?: InputMaybe<IgnoredSuggestedCoverageWhereInput>;
  some?: InputMaybe<IgnoredSuggestedCoverageWhereInput>;
};

export type IgnoredSuggestedCoverageOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum IgnoredSuggestedCoverageOrderByRelevanceFieldEnum {
  ArticleId = 'articleId',
  ClientId = 'clientId',
  Id = 'id'
}

export type IgnoredSuggestedCoverageOrderByRelevanceInput = {
  fields: Array<IgnoredSuggestedCoverageOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type IgnoredSuggestedCoverageOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<IgnoredSuggestedCoverageOrderByRelevanceInput>;
  article?: InputMaybe<ArticleOrderByWithRelationAndSearchRelevanceInput>;
  articleId?: InputMaybe<SortOrder>;
  client?: InputMaybe<ClientOrderByWithRelationAndSearchRelevanceInput>;
  clientId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export enum IgnoredSuggestedCoverageScalarFieldEnum {
  ArticleId = 'articleId',
  ClientId = 'clientId',
  Id = 'id'
}

export type IgnoredSuggestedCoverageScalarWhereInput = {
  AND?: InputMaybe<Array<IgnoredSuggestedCoverageScalarWhereInput>>;
  NOT?: InputMaybe<Array<IgnoredSuggestedCoverageScalarWhereInput>>;
  OR?: InputMaybe<Array<IgnoredSuggestedCoverageScalarWhereInput>>;
  articleId?: InputMaybe<StringFilter>;
  clientId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
};

export type IgnoredSuggestedCoverageUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type IgnoredSuggestedCoverageUpdateManyWithWhereWithoutArticleInput = {
  data: IgnoredSuggestedCoverageUpdateManyMutationInput;
  where: IgnoredSuggestedCoverageScalarWhereInput;
};

export type IgnoredSuggestedCoverageUpdateManyWithWhereWithoutClientInput = {
  data: IgnoredSuggestedCoverageUpdateManyMutationInput;
  where: IgnoredSuggestedCoverageScalarWhereInput;
};

export type IgnoredSuggestedCoverageUpdateManyWithoutArticleNestedInput = {
  connect?: InputMaybe<Array<IgnoredSuggestedCoverageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IgnoredSuggestedCoverageCreateOrConnectWithoutArticleInput>>;
  create?: InputMaybe<Array<IgnoredSuggestedCoverageCreateWithoutArticleInput>>;
  createMany?: InputMaybe<IgnoredSuggestedCoverageCreateManyArticleInputEnvelope>;
  delete?: InputMaybe<Array<IgnoredSuggestedCoverageWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<IgnoredSuggestedCoverageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<IgnoredSuggestedCoverageWhereUniqueInput>>;
  set?: InputMaybe<Array<IgnoredSuggestedCoverageWhereUniqueInput>>;
  update?: InputMaybe<Array<IgnoredSuggestedCoverageUpdateWithWhereUniqueWithoutArticleInput>>;
  updateMany?: InputMaybe<Array<IgnoredSuggestedCoverageUpdateManyWithWhereWithoutArticleInput>>;
  upsert?: InputMaybe<Array<IgnoredSuggestedCoverageUpsertWithWhereUniqueWithoutArticleInput>>;
};

export type IgnoredSuggestedCoverageUpdateManyWithoutClientNestedInput = {
  connect?: InputMaybe<Array<IgnoredSuggestedCoverageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IgnoredSuggestedCoverageCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<IgnoredSuggestedCoverageCreateWithoutClientInput>>;
  createMany?: InputMaybe<IgnoredSuggestedCoverageCreateManyClientInputEnvelope>;
  delete?: InputMaybe<Array<IgnoredSuggestedCoverageWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<IgnoredSuggestedCoverageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<IgnoredSuggestedCoverageWhereUniqueInput>>;
  set?: InputMaybe<Array<IgnoredSuggestedCoverageWhereUniqueInput>>;
  update?: InputMaybe<Array<IgnoredSuggestedCoverageUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: InputMaybe<Array<IgnoredSuggestedCoverageUpdateManyWithWhereWithoutClientInput>>;
  upsert?: InputMaybe<Array<IgnoredSuggestedCoverageUpsertWithWhereUniqueWithoutClientInput>>;
};

export type IgnoredSuggestedCoverageUpdateWithWhereUniqueWithoutArticleInput = {
  data: IgnoredSuggestedCoverageUpdateWithoutArticleInput;
  where: IgnoredSuggestedCoverageWhereUniqueInput;
};

export type IgnoredSuggestedCoverageUpdateWithWhereUniqueWithoutClientInput = {
  data: IgnoredSuggestedCoverageUpdateWithoutClientInput;
  where: IgnoredSuggestedCoverageWhereUniqueInput;
};

export type IgnoredSuggestedCoverageUpdateWithoutArticleInput = {
  client?: InputMaybe<ClientUpdateOneRequiredWithoutIgnoredSuggestedCoverageNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type IgnoredSuggestedCoverageUpdateWithoutClientInput = {
  article?: InputMaybe<ArticleUpdateOneWithoutIgnoredSuggestedCoverageNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type IgnoredSuggestedCoverageUpsertWithWhereUniqueWithoutArticleInput = {
  create: IgnoredSuggestedCoverageCreateWithoutArticleInput;
  update: IgnoredSuggestedCoverageUpdateWithoutArticleInput;
  where: IgnoredSuggestedCoverageWhereUniqueInput;
};

export type IgnoredSuggestedCoverageUpsertWithWhereUniqueWithoutClientInput = {
  create: IgnoredSuggestedCoverageCreateWithoutClientInput;
  update: IgnoredSuggestedCoverageUpdateWithoutClientInput;
  where: IgnoredSuggestedCoverageWhereUniqueInput;
};

export type IgnoredSuggestedCoverageWhereInput = {
  AND?: InputMaybe<Array<IgnoredSuggestedCoverageWhereInput>>;
  NOT?: InputMaybe<Array<IgnoredSuggestedCoverageWhereInput>>;
  OR?: InputMaybe<Array<IgnoredSuggestedCoverageWhereInput>>;
  article?: InputMaybe<ArticleNullableRelationFilter>;
  articleId?: InputMaybe<StringFilter>;
  client?: InputMaybe<ClientRelationFilter>;
  clientId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
};

export type IgnoredSuggestedCoverageWhereUniqueInput = {
  AND?: InputMaybe<Array<IgnoredSuggestedCoverageWhereInput>>;
  NOT?: InputMaybe<Array<IgnoredSuggestedCoverageWhereInput>>;
  OR?: InputMaybe<Array<IgnoredSuggestedCoverageWhereInput>>;
  article?: InputMaybe<ArticleNullableRelationFilter>;
  articleId?: InputMaybe<StringFilter>;
  client?: InputMaybe<ClientRelationFilter>;
  clientId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type IgnoredUrlCreateManyPublicationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

export type IgnoredUrlCreateManyPublicationInputEnvelope = {
  data: Array<IgnoredUrlCreateManyPublicationInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IgnoredUrlCreateNestedManyWithoutPublicationInput = {
  connect?: InputMaybe<Array<IgnoredUrlWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IgnoredUrlCreateOrConnectWithoutPublicationInput>>;
  create?: InputMaybe<Array<IgnoredUrlCreateWithoutPublicationInput>>;
  createMany?: InputMaybe<IgnoredUrlCreateManyPublicationInputEnvelope>;
};

export type IgnoredUrlCreateOrConnectWithoutPublicationInput = {
  create: IgnoredUrlCreateWithoutPublicationInput;
  where: IgnoredUrlWhereUniqueInput;
};

export type IgnoredUrlCreateWithoutPublicationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

export type IgnoredUrlListRelationFilter = {
  every?: InputMaybe<IgnoredUrlWhereInput>;
  none?: InputMaybe<IgnoredUrlWhereInput>;
  some?: InputMaybe<IgnoredUrlWhereInput>;
};

export type IgnoredUrlOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type IgnoredUrlScalarWhereInput = {
  AND?: InputMaybe<Array<IgnoredUrlScalarWhereInput>>;
  NOT?: InputMaybe<Array<IgnoredUrlScalarWhereInput>>;
  OR?: InputMaybe<Array<IgnoredUrlScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  publicationId?: InputMaybe<StringFilter>;
  url?: InputMaybe<StringFilter>;
};

export type IgnoredUrlUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type IgnoredUrlUpdateManyWithWhereWithoutPublicationInput = {
  data: IgnoredUrlUpdateManyMutationInput;
  where: IgnoredUrlScalarWhereInput;
};

export type IgnoredUrlUpdateManyWithoutPublicationNestedInput = {
  connect?: InputMaybe<Array<IgnoredUrlWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IgnoredUrlCreateOrConnectWithoutPublicationInput>>;
  create?: InputMaybe<Array<IgnoredUrlCreateWithoutPublicationInput>>;
  createMany?: InputMaybe<IgnoredUrlCreateManyPublicationInputEnvelope>;
  delete?: InputMaybe<Array<IgnoredUrlWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<IgnoredUrlScalarWhereInput>>;
  disconnect?: InputMaybe<Array<IgnoredUrlWhereUniqueInput>>;
  set?: InputMaybe<Array<IgnoredUrlWhereUniqueInput>>;
  update?: InputMaybe<Array<IgnoredUrlUpdateWithWhereUniqueWithoutPublicationInput>>;
  updateMany?: InputMaybe<Array<IgnoredUrlUpdateManyWithWhereWithoutPublicationInput>>;
  upsert?: InputMaybe<Array<IgnoredUrlUpsertWithWhereUniqueWithoutPublicationInput>>;
};

export type IgnoredUrlUpdateWithWhereUniqueWithoutPublicationInput = {
  data: IgnoredUrlUpdateWithoutPublicationInput;
  where: IgnoredUrlWhereUniqueInput;
};

export type IgnoredUrlUpdateWithoutPublicationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type IgnoredUrlUpsertWithWhereUniqueWithoutPublicationInput = {
  create: IgnoredUrlCreateWithoutPublicationInput;
  update: IgnoredUrlUpdateWithoutPublicationInput;
  where: IgnoredUrlWhereUniqueInput;
};

export type IgnoredUrlWhereInput = {
  AND?: InputMaybe<Array<IgnoredUrlWhereInput>>;
  NOT?: InputMaybe<Array<IgnoredUrlWhereInput>>;
  OR?: InputMaybe<Array<IgnoredUrlWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  publication?: InputMaybe<PublicationRelationFilter>;
  publicationId?: InputMaybe<StringFilter>;
  url?: InputMaybe<StringFilter>;
};

export type IgnoredUrlWhereUniqueInput = {
  AND?: InputMaybe<Array<IgnoredUrlWhereInput>>;
  NOT?: InputMaybe<Array<IgnoredUrlWhereInput>>;
  OR?: InputMaybe<Array<IgnoredUrlWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  publication?: InputMaybe<PublicationRelationFilter>;
  publicationId?: InputMaybe<StringFilter>;
  url?: InputMaybe<StringFilter>;
};

export type IntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']['input']>;
  divide?: InputMaybe<Scalars['Int']['input']>;
  increment?: InputMaybe<Scalars['Int']['input']>;
  multiply?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type InviteCoworkerType = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type InviteUserType = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  roleId: Scalars['String']['input'];
};

export type Location = {
  __typename?: 'Location';
  _count?: Maybe<LocationCount>;
  country: Scalars['String']['output'];
  id: Scalars['String']['output'];
  latitude: Scalars['Float']['output'];
  locality: Scalars['String']['output'];
  longitude: Scalars['Float']['output'];
  streetAddress: Scalars['String']['output'];
  timezone: Scalars['String']['output'];
};

export type LocationCount = {
  __typename?: 'LocationCount';
  clients: Scalars['Int']['output'];
};


export type LocationCountClientsArgs = {
  where?: InputMaybe<ClientWhereInput>;
};

export type LocationCreateNestedOneWithoutClientsInput = {
  connect?: InputMaybe<LocationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LocationCreateOrConnectWithoutClientsInput>;
  create?: InputMaybe<LocationCreateWithoutClientsInput>;
};

export type LocationCreateOrConnectWithoutClientsInput = {
  create: LocationCreateWithoutClientsInput;
  where: LocationWhereUniqueInput;
};

export type LocationCreateWithoutClientsInput = {
  country: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Float']['input'];
  locality: Scalars['String']['input'];
  longitude: Scalars['Float']['input'];
  streetAddress: Scalars['String']['input'];
  timezone: Scalars['String']['input'];
};

export enum LocationOrderByRelevanceFieldEnum {
  Country = 'country',
  Id = 'id',
  Locality = 'locality',
  StreetAddress = 'streetAddress',
  Timezone = 'timezone'
}

export type LocationOrderByRelevanceInput = {
  fields: Array<LocationOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type LocationOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<LocationOrderByRelevanceInput>;
  clients?: InputMaybe<ClientOrderByRelationAggregateInput>;
  country?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  latitude?: InputMaybe<SortOrder>;
  locality?: InputMaybe<SortOrder>;
  longitude?: InputMaybe<SortOrder>;
  streetAddress?: InputMaybe<SortOrder>;
  timezone?: InputMaybe<SortOrder>;
};

export type LocationRelationFilter = {
  is?: InputMaybe<LocationWhereInput>;
  isNot?: InputMaybe<LocationWhereInput>;
};

export type LocationUpdateInput = {
  clients?: InputMaybe<ClientUpdateManyWithoutLocationNestedInput>;
  country?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  locality?: InputMaybe<StringFieldUpdateOperationsInput>;
  longitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  streetAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type LocationUpdateOneRequiredWithoutClientsNestedInput = {
  connect?: InputMaybe<LocationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LocationCreateOrConnectWithoutClientsInput>;
  create?: InputMaybe<LocationCreateWithoutClientsInput>;
  update?: InputMaybe<LocationUpdateToOneWithWhereWithoutClientsInput>;
  upsert?: InputMaybe<LocationUpsertWithoutClientsInput>;
};

export type LocationUpdateToOneWithWhereWithoutClientsInput = {
  data: LocationUpdateWithoutClientsInput;
  where?: InputMaybe<LocationWhereInput>;
};

export type LocationUpdateWithoutClientsInput = {
  country?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  locality?: InputMaybe<StringFieldUpdateOperationsInput>;
  longitude?: InputMaybe<FloatFieldUpdateOperationsInput>;
  streetAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type LocationUpsertWithoutClientsInput = {
  create: LocationCreateWithoutClientsInput;
  update: LocationUpdateWithoutClientsInput;
  where?: InputMaybe<LocationWhereInput>;
};

export type LocationWhereInput = {
  AND?: InputMaybe<Array<LocationWhereInput>>;
  NOT?: InputMaybe<Array<LocationWhereInput>>;
  OR?: InputMaybe<Array<LocationWhereInput>>;
  clients?: InputMaybe<ClientListRelationFilter>;
  country?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  latitude?: InputMaybe<FloatFilter>;
  locality?: InputMaybe<StringFilter>;
  longitude?: InputMaybe<FloatFilter>;
  streetAddress?: InputMaybe<StringFilter>;
  timezone?: InputMaybe<StringFilter>;
};

export type LocationWhereUniqueInput = {
  AND?: InputMaybe<Array<LocationWhereInput>>;
  NOT?: InputMaybe<Array<LocationWhereInput>>;
  OR?: InputMaybe<Array<LocationWhereInput>>;
  clients?: InputMaybe<ClientListRelationFilter>;
  country?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<FloatFilter>;
  locality?: InputMaybe<StringFilter>;
  longitude?: InputMaybe<FloatFilter>;
  streetAddress?: InputMaybe<StringFilter>;
  timezone?: InputMaybe<StringFilter>;
};

export type MeetingNotes = {
  __typename?: 'MeetingNotes';
  client: Client;
  clientId: Scalars['String']['output'];
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  createdById: Scalars['String']['output'];
  id: Scalars['String']['output'];
  tags: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type MeetingNotesCreateManyClientInput = {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdById: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<MeetingNotesCreatetagsInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type MeetingNotesCreateManyClientInputEnvelope = {
  data: Array<MeetingNotesCreateManyClientInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MeetingNotesCreateManyCreatedByInput = {
  clientId: Scalars['String']['input'];
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<MeetingNotesCreatetagsInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type MeetingNotesCreateManyCreatedByInputEnvelope = {
  data: Array<MeetingNotesCreateManyCreatedByInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MeetingNotesCreateNestedManyWithoutClientInput = {
  connect?: InputMaybe<Array<MeetingNotesWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MeetingNotesCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<MeetingNotesCreateWithoutClientInput>>;
  createMany?: InputMaybe<MeetingNotesCreateManyClientInputEnvelope>;
};

export type MeetingNotesCreateNestedManyWithoutCreatedByInput = {
  connect?: InputMaybe<Array<MeetingNotesWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MeetingNotesCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<MeetingNotesCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<MeetingNotesCreateManyCreatedByInputEnvelope>;
};

export type MeetingNotesCreateOrConnectWithoutClientInput = {
  create: MeetingNotesCreateWithoutClientInput;
  where: MeetingNotesWhereUniqueInput;
};

export type MeetingNotesCreateOrConnectWithoutCreatedByInput = {
  create: MeetingNotesCreateWithoutCreatedByInput;
  where: MeetingNotesWhereUniqueInput;
};

export type MeetingNotesCreateWithoutClientInput = {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy: UserCreateNestedOneWithoutMeetingNotesInput;
  id?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<MeetingNotesCreatetagsInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type MeetingNotesCreateWithoutCreatedByInput = {
  client: ClientCreateNestedOneWithoutMeetingNotesInput;
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<MeetingNotesCreatetagsInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type MeetingNotesCreatetagsInput = {
  set: Array<Scalars['String']['input']>;
};

export type MeetingNotesListRelationFilter = {
  every?: InputMaybe<MeetingNotesWhereInput>;
  none?: InputMaybe<MeetingNotesWhereInput>;
  some?: InputMaybe<MeetingNotesWhereInput>;
};

export type MeetingNotesOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum MeetingNotesOrderByRelevanceFieldEnum {
  ClientId = 'clientId',
  Content = 'content',
  CreatedById = 'createdById',
  Id = 'id',
  Tags = 'tags',
  Title = 'title'
}

export type MeetingNotesOrderByRelevanceInput = {
  fields: Array<MeetingNotesOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type MeetingNotesOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<MeetingNotesOrderByRelevanceInput>;
  client?: InputMaybe<ClientOrderByWithRelationAndSearchRelevanceInput>;
  clientId?: InputMaybe<SortOrder>;
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  createdById?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  tags?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export enum MeetingNotesScalarFieldEnum {
  ClientId = 'clientId',
  Content = 'content',
  CreatedAt = 'createdAt',
  CreatedById = 'createdById',
  Id = 'id',
  Tags = 'tags',
  Title = 'title'
}

export type MeetingNotesScalarWhereInput = {
  AND?: InputMaybe<Array<MeetingNotesScalarWhereInput>>;
  NOT?: InputMaybe<Array<MeetingNotesScalarWhereInput>>;
  OR?: InputMaybe<Array<MeetingNotesScalarWhereInput>>;
  clientId?: InputMaybe<StringFilter>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdById?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  tags?: InputMaybe<StringNullableListFilter>;
  title?: InputMaybe<StringFilter>;
};

export type MeetingNotesUpdateManyMutationInput = {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  tags?: InputMaybe<MeetingNotesUpdatetagsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type MeetingNotesUpdateManyWithWhereWithoutClientInput = {
  data: MeetingNotesUpdateManyMutationInput;
  where: MeetingNotesScalarWhereInput;
};

export type MeetingNotesUpdateManyWithWhereWithoutCreatedByInput = {
  data: MeetingNotesUpdateManyMutationInput;
  where: MeetingNotesScalarWhereInput;
};

export type MeetingNotesUpdateManyWithoutClientNestedInput = {
  connect?: InputMaybe<Array<MeetingNotesWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MeetingNotesCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<MeetingNotesCreateWithoutClientInput>>;
  createMany?: InputMaybe<MeetingNotesCreateManyClientInputEnvelope>;
  delete?: InputMaybe<Array<MeetingNotesWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MeetingNotesScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MeetingNotesWhereUniqueInput>>;
  set?: InputMaybe<Array<MeetingNotesWhereUniqueInput>>;
  update?: InputMaybe<Array<MeetingNotesUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: InputMaybe<Array<MeetingNotesUpdateManyWithWhereWithoutClientInput>>;
  upsert?: InputMaybe<Array<MeetingNotesUpsertWithWhereUniqueWithoutClientInput>>;
};

export type MeetingNotesUpdateManyWithoutCreatedByNestedInput = {
  connect?: InputMaybe<Array<MeetingNotesWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MeetingNotesCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<MeetingNotesCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<MeetingNotesCreateManyCreatedByInputEnvelope>;
  delete?: InputMaybe<Array<MeetingNotesWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MeetingNotesScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MeetingNotesWhereUniqueInput>>;
  set?: InputMaybe<Array<MeetingNotesWhereUniqueInput>>;
  update?: InputMaybe<Array<MeetingNotesUpdateWithWhereUniqueWithoutCreatedByInput>>;
  updateMany?: InputMaybe<Array<MeetingNotesUpdateManyWithWhereWithoutCreatedByInput>>;
  upsert?: InputMaybe<Array<MeetingNotesUpsertWithWhereUniqueWithoutCreatedByInput>>;
};

export type MeetingNotesUpdateWithWhereUniqueWithoutClientInput = {
  data: MeetingNotesUpdateWithoutClientInput;
  where: MeetingNotesWhereUniqueInput;
};

export type MeetingNotesUpdateWithWhereUniqueWithoutCreatedByInput = {
  data: MeetingNotesUpdateWithoutCreatedByInput;
  where: MeetingNotesWhereUniqueInput;
};

export type MeetingNotesUpdateWithoutClientInput = {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutMeetingNotesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  tags?: InputMaybe<MeetingNotesUpdatetagsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type MeetingNotesUpdateWithoutCreatedByInput = {
  client?: InputMaybe<ClientUpdateOneRequiredWithoutMeetingNotesNestedInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  tags?: InputMaybe<MeetingNotesUpdatetagsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type MeetingNotesUpdatetagsInput = {
  push?: InputMaybe<Array<Scalars['String']['input']>>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type MeetingNotesUpsertWithWhereUniqueWithoutClientInput = {
  create: MeetingNotesCreateWithoutClientInput;
  update: MeetingNotesUpdateWithoutClientInput;
  where: MeetingNotesWhereUniqueInput;
};

export type MeetingNotesUpsertWithWhereUniqueWithoutCreatedByInput = {
  create: MeetingNotesCreateWithoutCreatedByInput;
  update: MeetingNotesUpdateWithoutCreatedByInput;
  where: MeetingNotesWhereUniqueInput;
};

export type MeetingNotesWhereInput = {
  AND?: InputMaybe<Array<MeetingNotesWhereInput>>;
  NOT?: InputMaybe<Array<MeetingNotesWhereInput>>;
  OR?: InputMaybe<Array<MeetingNotesWhereInput>>;
  client?: InputMaybe<ClientRelationFilter>;
  clientId?: InputMaybe<StringFilter>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<UserRelationFilter>;
  createdById?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  tags?: InputMaybe<StringNullableListFilter>;
  title?: InputMaybe<StringFilter>;
};

export type MeetingNotesWhereUniqueInput = {
  AND?: InputMaybe<Array<MeetingNotesWhereInput>>;
  NOT?: InputMaybe<Array<MeetingNotesWhereInput>>;
  OR?: InputMaybe<Array<MeetingNotesWhereInput>>;
  client?: InputMaybe<ClientRelationFilter>;
  clientId?: InputMaybe<StringFilter>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<UserRelationFilter>;
  createdById?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<StringNullableListFilter>;
  title?: InputMaybe<StringFilter>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addNewsJackRevision: NewsJackRevision;
  addSuggestedCoverage: CoveragePiece;
  addSuggestedNewsWrapContent: NewsWrapContent;
  arbitraryTextInput: Scalars['String']['output'];
  bulkUploadCoveragePieces: BulkUploadResult;
  clearNotifications: Scalars['Float']['output'];
  continueOn: Scalars['String']['output'];
  createActivity?: Maybe<Activity>;
  createActivityComment?: Maybe<Activity>;
  createAuthorPublicationHistory: AuthorPublicationHistory;
  createClient?: Maybe<Client>;
  createClientComment: ClientComment;
  createClientMention: ClientMention;
  createCompetitor: Client;
  createContent: Content;
  createContentFromMarkdown: Content;
  createCoveragePiece: CoveragePiece;
  createMeetingNotes: MeetingNotes;
  createNewsWrapContent: NewsWrapContent;
  createOneNewsWrap: NewsWrap;
  createOneRole: Role;
  createOnlineArticle: OnlineArticle;
  createPrintArticle: PrintArticle;
  createProgramPlannerActivity: ProgramPlannerActivity;
  createProgramPlannerItem: ProgramPlannerItem;
  createProgramPlannerMetric: ProgramPlannerMetric;
  createPublication: Publication;
  createPublications: AffectedRowsOutput;
  createSpokesperson: Spokesperson;
  deleteActivities: AffectedRowsOutput;
  deleteActivityComment: ActivityComment;
  deleteClientComment: Scalars['Boolean']['output'];
  deleteContent: Content;
  deleteCoveragePiece: CoveragePiece;
  deleteFile: Scalars['Boolean']['output'];
  deleteManyProgramPlannerActivity: Array<AffectedRowsOutput>;
  deleteMeetingNotes: Scalars['Boolean']['output'];
  deleteNewsJackItem: NewsJackItem;
  deleteNewsWrap: NewsWrap;
  deleteNewsWrapContent: NewsWrapContent;
  deleteNotification: Notification;
  deleteProgramPlannerItem: ProgramPlannerItem;
  deleteProgramPlannerItems: AffectedRowsOutput;
  deleteProgramPlannerMetric: ProgramPlannerMetric;
  deletePublication: Publication;
  deleteSpokesperson: Spokesperson;
  deleteUser: User;
  duplicateActivity: ExtendedActivity;
  duplicateProgramPlannerItem: ProgramPlannerItem;
  expandText: Scalars['String']['output'];
  findPublicationForURL: PublicationData;
  getArticle?: Maybe<ArticleUnion>;
  getSuggestedPrompts: Array<Scalars['String']['output']>;
  ignoreSuggestedCoverage: IgnoredSuggestedCoverage;
  invite?: Maybe<User>;
  inviteCoworker?: Maybe<User>;
  isNewsWrapContentSubmitted: Scalars['Boolean']['output'];
  isNewsWrapOnDate: Scalars['Boolean']['output'];
  logOutUsers: Scalars['Boolean']['output'];
  markContentAsRecentlyViewed: RecentlyViewedContent;
  markNotificationsRead: Scalars['Float']['output'];
  reinvite?: Maybe<User>;
  removeNewsJackItem: NewsJackItem;
  renameFile: Scalars['Boolean']['output'];
  rephraseText: Scalars['String']['output'];
  saveAsMarkdown: Scalars['String']['output'];
  saveAsPdf: Scalars['String']['output'];
  saveAsWordDoc: Scalars['String']['output'];
  saveScratchpad: Scalars['String']['output'];
  scrapeArticle?: Maybe<OnlineArticle>;
  sendCoveragePieceToClient: Scalars['String']['output'];
  sendNewsWrap?: Maybe<Scalars['String']['output']>;
  sendNewsWrapToClient: Scalars['String']['output'];
  setNewsJackItemStatus: NewsJackItem;
  softDeleteUser: User;
  suggestTitle: Scalars['String']['output'];
  summariseText: Scalars['String']['output'];
  unignoreSuggestedCoverage: AffectedRowsOutput;
  updateActivity?: Maybe<Activity>;
  updateActivityComment?: Maybe<Activity>;
  updateActivityIndexes: Array<Activity>;
  updateActivityMetric: AffectedRowsOutput;
  updateAuthor: Author;
  updateAuthorPublicationHistory: AuthorPublicationHistory;
  updateClient?: Maybe<Client>;
  updateClientMention: ClientMention;
  updateContent: Content;
  updateCoveragePiece: CoveragePiece;
  updateManyActivities: AffectedRowsOutput;
  updateNewsJackItem: NewsJackItem;
  updateNewsJackItems: Array<NewsJackItem>;
  updateNewsWrap: Scalars['Boolean']['output'];
  updateNewsWrapContent: NewsWrapContent;
  updateProgramPlannerItem: ProgramPlannerItem;
  updatePublication: Publication;
  updateRecentlyViewedContent: RecentlyViewedContent;
  updateRole: Role;
  updateSpokesperson: Spokesperson;
  updateUser: User;
  updateWorkspace: Workspace;
};


export type MutationAddNewsJackRevisionArgs = {
  content: Scalars['String']['input'];
  newsJackItemId: Scalars['String']['input'];
};


export type MutationAddSuggestedCoverageArgs = {
  articleId: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
};


export type MutationAddSuggestedNewsWrapContentArgs = {
  articleId: Scalars['String']['input'];
  newsWrapId: Scalars['String']['input'];
};


export type MutationArbitraryTextInputArgs = {
  data: ArbitraryTextInput;
};


export type MutationBulkUploadCoveragePiecesArgs = {
  data: Array<BulkUploadCoveragePieceInput>;
};


export type MutationContinueOnArgs = {
  data: ContinueOnInput;
};


export type MutationCreateActivityArgs = {
  data: CreateActivityType;
};


export type MutationCreateActivityCommentArgs = {
  data: CreateActivityComment;
};


export type MutationCreateAuthorPublicationHistoryArgs = {
  input: AuthorPublicationHistoryCreateInput;
};


export type MutationCreateClientArgs = {
  data: ClientCreateInput;
};


export type MutationCreateClientCommentArgs = {
  clientId: Scalars['String']['input'];
  content: Scalars['String']['input'];
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  title: Scalars['String']['input'];
};


export type MutationCreateClientMentionArgs = {
  input: ClientMentionCreateInput;
};


export type MutationCreateCompetitorArgs = {
  competitorOf: Scalars['String']['input'];
  data: ClientCreateInput;
};


export type MutationCreateContentArgs = {
  data: ContentCreateInput;
};


export type MutationCreateContentFromMarkdownArgs = {
  data: ContentCreateInput;
};


export type MutationCreateCoveragePieceArgs = {
  data: CreateCoveragePieceExtendedInput;
};


export type MutationCreateMeetingNotesArgs = {
  clientId: Scalars['String']['input'];
  content: Scalars['String']['input'];
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  title: Scalars['String']['input'];
};


export type MutationCreateNewsWrapContentArgs = {
  data: NewsWrapContentCreateType;
};


export type MutationCreateOneNewsWrapArgs = {
  data: NewsWrapCreate;
};


export type MutationCreateOneRoleArgs = {
  data: RoleCreate;
};


export type MutationCreateOnlineArticleArgs = {
  data: OnlineArticleCreateInput;
};


export type MutationCreatePrintArticleArgs = {
  data: PrintArticleCreateInput;
};


export type MutationCreateProgramPlannerActivityArgs = {
  data: ProgramPlannerActivityCreateInput;
};


export type MutationCreateProgramPlannerItemArgs = {
  data: ProgramPlannerItemCreateInput;
};


export type MutationCreateProgramPlannerMetricArgs = {
  data: ProgramPlannerMetricCreateInput;
};


export type MutationCreatePublicationArgs = {
  data: PublicationCreateInput;
};


export type MutationCreatePublicationsArgs = {
  data: Array<PublicationCreateManyInput>;
};


export type MutationCreateSpokespersonArgs = {
  data: SpokespersonCreateInput;
};


export type MutationDeleteActivitiesArgs = {
  ids: Array<Scalars['String']['input']>;
};


export type MutationDeleteActivityCommentArgs = {
  where: ActivityCommentWhereUniqueInput;
};


export type MutationDeleteClientCommentArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteContentArgs = {
  where: ContentWhereUniqueInput;
};


export type MutationDeleteCoveragePieceArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteFileArgs = {
  params: DeleteFileInput;
};


export type MutationDeleteManyProgramPlannerActivityArgs = {
  where: ProgramPlannerActivityWhereInput;
};


export type MutationDeleteMeetingNotesArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteNewsJackItemArgs = {
  newsJackItemId: Scalars['String']['input'];
};


export type MutationDeleteNewsWrapArgs = {
  where: NewsWrapResolverQuery;
};


export type MutationDeleteNewsWrapContentArgs = {
  where: NewsWrapWhereUniqueInput;
};


export type MutationDeleteNotificationArgs = {
  where: NotificationWhereUniqueInput;
};


export type MutationDeleteProgramPlannerItemArgs = {
  where: ProgramPlannerItemWhereUniqueInput;
};


export type MutationDeleteProgramPlannerItemsArgs = {
  where: ProgramPlannerItemWhereInput;
};


export type MutationDeleteProgramPlannerMetricArgs = {
  where: ProgramPlannerMetricWhereUniqueInput;
};


export type MutationDeletePublicationArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteSpokespersonArgs = {
  where: SpokespersonWhereUniqueInput;
};


export type MutationDeleteUserArgs = {
  where: UserWhereUniqueInput;
};


export type MutationDuplicateActivityArgs = {
  where: ActivityWhere;
};


export type MutationDuplicateProgramPlannerItemArgs = {
  where: ProgramPlannerItemWhereUniqueInput;
};


export type MutationExpandTextArgs = {
  data: ExpandInput;
};


export type MutationFindPublicationForUrlArgs = {
  data: FindPublicationInput;
};


export type MutationGetArticleArgs = {
  id: Scalars['String']['input'];
};


export type MutationGetSuggestedPromptsArgs = {
  input: Scalars['String']['input'];
};


export type MutationIgnoreSuggestedCoverageArgs = {
  articleId: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
};


export type MutationInviteArgs = {
  data: InviteUserType;
};


export type MutationInviteCoworkerArgs = {
  data: InviteCoworkerType;
};


export type MutationIsNewsWrapContentSubmittedArgs = {
  articleId: Scalars['String']['input'];
};


export type MutationIsNewsWrapOnDateArgs = {
  date: DateTimeFilter;
};


export type MutationMarkContentAsRecentlyViewedArgs = {
  where: ContentWhereUniqueInput;
};


export type MutationReinviteArgs = {
  id: Scalars['String']['input'];
};


export type MutationRemoveNewsJackItemArgs = {
  newsJackItemId: Scalars['String']['input'];
};


export type MutationRenameFileArgs = {
  params: RenameFileInput;
};


export type MutationRephraseTextArgs = {
  data: RephraseTextInput;
};


export type MutationSaveAsMarkdownArgs = {
  where: ContentWhereUniqueInput;
};


export type MutationSaveAsPdfArgs = {
  where: ContentWhereUniqueInput;
};


export type MutationSaveAsWordDocArgs = {
  where: ContentWhereUniqueInput;
};


export type MutationSaveScratchpadArgs = {
  content: Scalars['String']['input'];
};


export type MutationScrapeArticleArgs = {
  url: Scalars['String']['input'];
};


export type MutationSendCoveragePieceToClientArgs = {
  data: SendCoveragePieceInput;
};


export type MutationSendNewsWrapArgs = {
  where: NewsWrapResolverQuery;
};


export type MutationSendNewsWrapToClientArgs = {
  clientId: Scalars['String']['input'];
  newsWrapId: Scalars['String']['input'];
};


export type MutationSetNewsJackItemStatusArgs = {
  newsJackItemId: Scalars['String']['input'];
  status: NewsJackStatus;
};


export type MutationSoftDeleteUserArgs = {
  where: UserWhereUniqueInput;
};


export type MutationSuggestTitleArgs = {
  data: SuggestTitleInput;
};


export type MutationSummariseTextArgs = {
  data: SummariseInput;
};


export type MutationUnignoreSuggestedCoverageArgs = {
  articleId: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
};


export type MutationUpdateActivityArgs = {
  data: ActivityUpdateInput;
  where: ActivityWhere;
};


export type MutationUpdateActivityCommentArgs = {
  data: UpdateActivityComment;
};


export type MutationUpdateActivityIndexesArgs = {
  data: Array<ActivityIndexInput>;
};


export type MutationUpdateActivityMetricArgs = {
  activityId: Scalars['String']['input'];
  metric: Scalars['Int']['input'];
};


export type MutationUpdateAuthorArgs = {
  data: AuthorUpdateInput;
  id: Scalars['String']['input'];
};


export type MutationUpdateAuthorPublicationHistoryArgs = {
  id: Scalars['String']['input'];
  input: AuthorPublicationHistoryUpdateInput;
};


export type MutationUpdateClientArgs = {
  data: UpdateClientType;
  where: ClientWhereUniqueInput;
};


export type MutationUpdateClientMentionArgs = {
  id: Scalars['String']['input'];
  input: ClientMentionUpdateInput;
};


export type MutationUpdateContentArgs = {
  data: ContentUpdateInput;
  where: ContentWhereUniqueInput;
};


export type MutationUpdateCoveragePieceArgs = {
  data: CoveragePieceUpdateInput;
  where: CoveragePieceWhereUniqueInput;
};


export type MutationUpdateManyActivitiesArgs = {
  data: ActivityUpdateManyMutationInput;
  where: ActivityWhereInput;
};


export type MutationUpdateNewsJackItemArgs = {
  data: NewsJackItemUpdateInput;
  newsJackItemId: Scalars['String']['input'];
};


export type MutationUpdateNewsJackItemsArgs = {
  clientId: Scalars['String']['input'];
  items: Array<UpdateManyNewsJackItemsInput>;
};


export type MutationUpdateNewsWrapArgs = {
  data: NewsWrapUpdate;
  id: Scalars['String']['input'];
};


export type MutationUpdateNewsWrapContentArgs = {
  data: NewsWrapContentUpdateInput;
  where: NewsWrapWhereUniqueInput;
};


export type MutationUpdateProgramPlannerItemArgs = {
  data: ProgramPlannerItemUpdateInput;
  where: ProgramPlannerItemWhereUniqueInput;
};


export type MutationUpdatePublicationArgs = {
  data: PublicationUpdateInput;
  where: PublicationWhereUniqueInput;
};


export type MutationUpdateRecentlyViewedContentArgs = {
  contentId: Scalars['String']['input'];
  isFavourited: Scalars['Boolean']['input'];
};


export type MutationUpdateRoleArgs = {
  data: RoleUpdateInput;
  where: RoleWhereUniqueInput;
};


export type MutationUpdateSpokespersonArgs = {
  data: SpokespersonUpdateInput;
  where: SpokespersonWhereUniqueInput;
};


export type MutationUpdateUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationUpdateWorkspaceArgs = {
  data: WorkspaceUpdateInput;
};

export enum NameUniqueness {
  EnglishWord = 'ENGLISH_WORD',
  UniqueWord = 'UNIQUE_WORD'
}

export type NestedBigIntFilter = {
  equals?: InputMaybe<Scalars['BigInt']['input']>;
  gt?: InputMaybe<Scalars['BigInt']['input']>;
  gte?: InputMaybe<Scalars['BigInt']['input']>;
  in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  lt?: InputMaybe<Scalars['BigInt']['input']>;
  lte?: InputMaybe<Scalars['BigInt']['input']>;
  not?: InputMaybe<NestedBigIntFilter>;
  notIn?: InputMaybe<Array<Scalars['BigInt']['input']>>;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type NestedEnumActivityPriorityFilter = {
  equals?: InputMaybe<ActivityPriority>;
  in?: InputMaybe<Array<ActivityPriority>>;
  not?: InputMaybe<NestedEnumActivityPriorityFilter>;
  notIn?: InputMaybe<Array<ActivityPriority>>;
};

export type NestedEnumActivityStatusFilter = {
  equals?: InputMaybe<ActivityStatus>;
  in?: InputMaybe<Array<ActivityStatus>>;
  not?: InputMaybe<NestedEnumActivityStatusFilter>;
  notIn?: InputMaybe<Array<ActivityStatus>>;
};

export type NestedEnumArticleTypeFilter = {
  equals?: InputMaybe<ArticleType>;
  in?: InputMaybe<Array<ArticleType>>;
  not?: InputMaybe<NestedEnumArticleTypeFilter>;
  notIn?: InputMaybe<Array<ArticleType>>;
};

export type NestedEnumClientStatusFilter = {
  equals?: InputMaybe<ClientStatus>;
  in?: InputMaybe<Array<ClientStatus>>;
  not?: InputMaybe<NestedEnumClientStatusFilter>;
  notIn?: InputMaybe<Array<ClientStatus>>;
};

export type NestedEnumCoverageTypeFilter = {
  equals?: InputMaybe<CoverageType>;
  in?: InputMaybe<Array<CoverageType>>;
  not?: InputMaybe<NestedEnumCoverageTypeFilter>;
  notIn?: InputMaybe<Array<CoverageType>>;
};

export type NestedEnumNameUniquenessFilter = {
  equals?: InputMaybe<NameUniqueness>;
  in?: InputMaybe<Array<NameUniqueness>>;
  not?: InputMaybe<NestedEnumNameUniquenessFilter>;
  notIn?: InputMaybe<Array<NameUniqueness>>;
};

export type NestedEnumNewsJackStatusFilter = {
  equals?: InputMaybe<NewsJackStatus>;
  in?: InputMaybe<Array<NewsJackStatus>>;
  not?: InputMaybe<NestedEnumNewsJackStatusFilter>;
  notIn?: InputMaybe<Array<NewsJackStatus>>;
};

export type NestedEnumPermissionEnumFilter = {
  equals?: InputMaybe<PermissionEnum>;
  in?: InputMaybe<Array<PermissionEnum>>;
  not?: InputMaybe<NestedEnumPermissionEnumFilter>;
  notIn?: InputMaybe<Array<PermissionEnum>>;
};

export type NestedEnumPrintScraperMethodFilter = {
  equals?: InputMaybe<PrintScraperMethod>;
  in?: InputMaybe<Array<PrintScraperMethod>>;
  not?: InputMaybe<NestedEnumPrintScraperMethodFilter>;
  notIn?: InputMaybe<Array<PrintScraperMethod>>;
};

export type NestedEnumPublicationTierFilter = {
  equals?: InputMaybe<PublicationTier>;
  in?: InputMaybe<Array<PublicationTier>>;
  not?: InputMaybe<NestedEnumPublicationTierFilter>;
  notIn?: InputMaybe<Array<PublicationTier>>;
};

export type NestedEnumPublicationTypeFilter = {
  equals?: InputMaybe<PublicationType>;
  in?: InputMaybe<Array<PublicationType>>;
  not?: InputMaybe<NestedEnumPublicationTypeFilter>;
  notIn?: InputMaybe<Array<PublicationType>>;
};

export type NestedEnumScrapeMethodFilter = {
  equals?: InputMaybe<ScrapeMethod>;
  in?: InputMaybe<Array<ScrapeMethod>>;
  not?: InputMaybe<NestedEnumScrapeMethodFilter>;
  notIn?: InputMaybe<Array<ScrapeMethod>>;
};

export type NestedEnumTokenTypeFilter = {
  equals?: InputMaybe<TokenType>;
  in?: InputMaybe<Array<TokenType>>;
  not?: InputMaybe<NestedEnumTokenTypeFilter>;
  notIn?: InputMaybe<Array<TokenType>>;
};

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NewsJackItem = {
  __typename?: 'NewsJackItem';
  _count?: Maybe<NewsJackItemCount>;
  client: Client;
  clientId: Scalars['String']['output'];
  content: NewsWrapContent;
  contentId: Scalars['String']['output'];
  deadline?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  revisions: Array<NewsJackRevision>;
  status: NewsJackStatus;
  statusChangedBy?: Maybe<User>;
  statusChangedById?: Maybe<Scalars['String']['output']>;
};


export type NewsJackItemRevisionsArgs = {
  cursor?: InputMaybe<NewsJackRevisionWhereUniqueInput>;
  distinct?: InputMaybe<Array<NewsJackRevisionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<NewsJackRevisionOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NewsJackRevisionWhereInput>;
};


export type NewsJackItemStatusChangedByArgs = {
  where?: InputMaybe<UserWhereInput>;
};

export type NewsJackItemContentIdClientIdCompoundUniqueInput = {
  clientId: Scalars['String']['input'];
  contentId: Scalars['String']['input'];
};

export type NewsJackItemCount = {
  __typename?: 'NewsJackItemCount';
  revisions: Scalars['Int']['output'];
};


export type NewsJackItemCountRevisionsArgs = {
  where?: InputMaybe<NewsJackRevisionWhereInput>;
};

export type NewsJackItemCreateManyClientInput = {
  contentId: Scalars['String']['input'];
  deadline?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<NewsJackStatus>;
  statusChangedById?: InputMaybe<Scalars['String']['input']>;
};

export type NewsJackItemCreateManyClientInputEnvelope = {
  data: Array<NewsJackItemCreateManyClientInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NewsJackItemCreateManyContentInput = {
  clientId: Scalars['String']['input'];
  deadline?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<NewsJackStatus>;
  statusChangedById?: InputMaybe<Scalars['String']['input']>;
};

export type NewsJackItemCreateManyContentInputEnvelope = {
  data: Array<NewsJackItemCreateManyContentInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NewsJackItemCreateManyStatusChangedByInput = {
  clientId: Scalars['String']['input'];
  contentId: Scalars['String']['input'];
  deadline?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<NewsJackStatus>;
};

export type NewsJackItemCreateManyStatusChangedByInputEnvelope = {
  data: Array<NewsJackItemCreateManyStatusChangedByInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NewsJackItemCreateNestedManyWithoutClientInput = {
  connect?: InputMaybe<Array<NewsJackItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NewsJackItemCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<NewsJackItemCreateWithoutClientInput>>;
  createMany?: InputMaybe<NewsJackItemCreateManyClientInputEnvelope>;
};

export type NewsJackItemCreateNestedManyWithoutContentInput = {
  connect?: InputMaybe<Array<NewsJackItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NewsJackItemCreateOrConnectWithoutContentInput>>;
  create?: InputMaybe<Array<NewsJackItemCreateWithoutContentInput>>;
  createMany?: InputMaybe<NewsJackItemCreateManyContentInputEnvelope>;
};

export type NewsJackItemCreateNestedManyWithoutStatusChangedByInput = {
  connect?: InputMaybe<Array<NewsJackItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NewsJackItemCreateOrConnectWithoutStatusChangedByInput>>;
  create?: InputMaybe<Array<NewsJackItemCreateWithoutStatusChangedByInput>>;
  createMany?: InputMaybe<NewsJackItemCreateManyStatusChangedByInputEnvelope>;
};

export type NewsJackItemCreateNestedOneWithoutRevisionsInput = {
  connect?: InputMaybe<NewsJackItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<NewsJackItemCreateOrConnectWithoutRevisionsInput>;
  create?: InputMaybe<NewsJackItemCreateWithoutRevisionsInput>;
};

export type NewsJackItemCreateOrConnectWithoutClientInput = {
  create: NewsJackItemCreateWithoutClientInput;
  where: NewsJackItemWhereUniqueInput;
};

export type NewsJackItemCreateOrConnectWithoutContentInput = {
  create: NewsJackItemCreateWithoutContentInput;
  where: NewsJackItemWhereUniqueInput;
};

export type NewsJackItemCreateOrConnectWithoutRevisionsInput = {
  create: NewsJackItemCreateWithoutRevisionsInput;
  where: NewsJackItemWhereUniqueInput;
};

export type NewsJackItemCreateOrConnectWithoutStatusChangedByInput = {
  create: NewsJackItemCreateWithoutStatusChangedByInput;
  where: NewsJackItemWhereUniqueInput;
};

export type NewsJackItemCreateWithoutClientInput = {
  content: NewsWrapContentCreateNestedOneWithoutNewsJackItemsInput;
  deadline?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  revisions?: InputMaybe<NewsJackRevisionCreateNestedManyWithoutNewsJackItemInput>;
  status?: InputMaybe<NewsJackStatus>;
  statusChangedBy?: InputMaybe<UserCreateNestedOneWithoutNewsJackItemsInput>;
};

export type NewsJackItemCreateWithoutContentInput = {
  client: ClientCreateNestedOneWithoutNewsJackItemsInput;
  deadline?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  revisions?: InputMaybe<NewsJackRevisionCreateNestedManyWithoutNewsJackItemInput>;
  status?: InputMaybe<NewsJackStatus>;
  statusChangedBy?: InputMaybe<UserCreateNestedOneWithoutNewsJackItemsInput>;
};

export type NewsJackItemCreateWithoutRevisionsInput = {
  client: ClientCreateNestedOneWithoutNewsJackItemsInput;
  content: NewsWrapContentCreateNestedOneWithoutNewsJackItemsInput;
  deadline?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<NewsJackStatus>;
  statusChangedBy?: InputMaybe<UserCreateNestedOneWithoutNewsJackItemsInput>;
};

export type NewsJackItemCreateWithoutStatusChangedByInput = {
  client: ClientCreateNestedOneWithoutNewsJackItemsInput;
  content: NewsWrapContentCreateNestedOneWithoutNewsJackItemsInput;
  deadline?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  revisions?: InputMaybe<NewsJackRevisionCreateNestedManyWithoutNewsJackItemInput>;
  status?: InputMaybe<NewsJackStatus>;
};

export type NewsJackItemListRelationFilter = {
  every?: InputMaybe<NewsJackItemWhereInput>;
  none?: InputMaybe<NewsJackItemWhereInput>;
  some?: InputMaybe<NewsJackItemWhereInput>;
};

export type NewsJackItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum NewsJackItemOrderByRelevanceFieldEnum {
  ClientId = 'clientId',
  ContentId = 'contentId',
  Id = 'id',
  StatusChangedById = 'statusChangedById'
}

export type NewsJackItemOrderByRelevanceInput = {
  fields: Array<NewsJackItemOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type NewsJackItemOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<NewsJackItemOrderByRelevanceInput>;
  client?: InputMaybe<ClientOrderByWithRelationAndSearchRelevanceInput>;
  clientId?: InputMaybe<SortOrder>;
  content?: InputMaybe<NewsWrapContentOrderByWithRelationAndSearchRelevanceInput>;
  contentId?: InputMaybe<SortOrder>;
  deadline?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  revisions?: InputMaybe<NewsJackRevisionOrderByRelationAggregateInput>;
  status?: InputMaybe<SortOrder>;
  statusChangedBy?: InputMaybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  statusChangedById?: InputMaybe<SortOrderInput>;
};

export type NewsJackItemRelationFilter = {
  is?: InputMaybe<NewsJackItemWhereInput>;
  isNot?: InputMaybe<NewsJackItemWhereInput>;
};

export enum NewsJackItemScalarFieldEnum {
  ClientId = 'clientId',
  ContentId = 'contentId',
  Deadline = 'deadline',
  Id = 'id',
  Status = 'status',
  StatusChangedById = 'statusChangedById'
}

export type NewsJackItemScalarWhereInput = {
  AND?: InputMaybe<Array<NewsJackItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<NewsJackItemScalarWhereInput>>;
  OR?: InputMaybe<Array<NewsJackItemScalarWhereInput>>;
  clientId?: InputMaybe<StringFilter>;
  contentId?: InputMaybe<StringFilter>;
  deadline?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumNewsJackStatusFilter>;
  statusChangedById?: InputMaybe<StringNullableFilter>;
};

export type NewsJackItemUpdateInput = {
  client?: InputMaybe<ClientUpdateOneRequiredWithoutNewsJackItemsNestedInput>;
  content?: InputMaybe<NewsWrapContentUpdateOneRequiredWithoutNewsJackItemsNestedInput>;
  deadline?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  revisions?: InputMaybe<NewsJackRevisionUpdateManyWithoutNewsJackItemNestedInput>;
  status?: InputMaybe<EnumNewsJackStatusFieldUpdateOperationsInput>;
  statusChangedBy?: InputMaybe<UserUpdateOneWithoutNewsJackItemsNestedInput>;
};

export type NewsJackItemUpdateManyMutationInput = {
  deadline?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumNewsJackStatusFieldUpdateOperationsInput>;
};

export type NewsJackItemUpdateManyWithWhereWithoutClientInput = {
  data: NewsJackItemUpdateManyMutationInput;
  where: NewsJackItemScalarWhereInput;
};

export type NewsJackItemUpdateManyWithWhereWithoutContentInput = {
  data: NewsJackItemUpdateManyMutationInput;
  where: NewsJackItemScalarWhereInput;
};

export type NewsJackItemUpdateManyWithWhereWithoutStatusChangedByInput = {
  data: NewsJackItemUpdateManyMutationInput;
  where: NewsJackItemScalarWhereInput;
};

export type NewsJackItemUpdateManyWithoutClientNestedInput = {
  connect?: InputMaybe<Array<NewsJackItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NewsJackItemCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<NewsJackItemCreateWithoutClientInput>>;
  createMany?: InputMaybe<NewsJackItemCreateManyClientInputEnvelope>;
  delete?: InputMaybe<Array<NewsJackItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<NewsJackItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<NewsJackItemWhereUniqueInput>>;
  set?: InputMaybe<Array<NewsJackItemWhereUniqueInput>>;
  update?: InputMaybe<Array<NewsJackItemUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: InputMaybe<Array<NewsJackItemUpdateManyWithWhereWithoutClientInput>>;
  upsert?: InputMaybe<Array<NewsJackItemUpsertWithWhereUniqueWithoutClientInput>>;
};

export type NewsJackItemUpdateManyWithoutContentNestedInput = {
  connect?: InputMaybe<Array<NewsJackItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NewsJackItemCreateOrConnectWithoutContentInput>>;
  create?: InputMaybe<Array<NewsJackItemCreateWithoutContentInput>>;
  createMany?: InputMaybe<NewsJackItemCreateManyContentInputEnvelope>;
  delete?: InputMaybe<Array<NewsJackItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<NewsJackItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<NewsJackItemWhereUniqueInput>>;
  set?: InputMaybe<Array<NewsJackItemWhereUniqueInput>>;
  update?: InputMaybe<Array<NewsJackItemUpdateWithWhereUniqueWithoutContentInput>>;
  updateMany?: InputMaybe<Array<NewsJackItemUpdateManyWithWhereWithoutContentInput>>;
  upsert?: InputMaybe<Array<NewsJackItemUpsertWithWhereUniqueWithoutContentInput>>;
};

export type NewsJackItemUpdateManyWithoutStatusChangedByNestedInput = {
  connect?: InputMaybe<Array<NewsJackItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NewsJackItemCreateOrConnectWithoutStatusChangedByInput>>;
  create?: InputMaybe<Array<NewsJackItemCreateWithoutStatusChangedByInput>>;
  createMany?: InputMaybe<NewsJackItemCreateManyStatusChangedByInputEnvelope>;
  delete?: InputMaybe<Array<NewsJackItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<NewsJackItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<NewsJackItemWhereUniqueInput>>;
  set?: InputMaybe<Array<NewsJackItemWhereUniqueInput>>;
  update?: InputMaybe<Array<NewsJackItemUpdateWithWhereUniqueWithoutStatusChangedByInput>>;
  updateMany?: InputMaybe<Array<NewsJackItemUpdateManyWithWhereWithoutStatusChangedByInput>>;
  upsert?: InputMaybe<Array<NewsJackItemUpsertWithWhereUniqueWithoutStatusChangedByInput>>;
};

export type NewsJackItemUpdateOneRequiredWithoutRevisionsNestedInput = {
  connect?: InputMaybe<NewsJackItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<NewsJackItemCreateOrConnectWithoutRevisionsInput>;
  create?: InputMaybe<NewsJackItemCreateWithoutRevisionsInput>;
  update?: InputMaybe<NewsJackItemUpdateToOneWithWhereWithoutRevisionsInput>;
  upsert?: InputMaybe<NewsJackItemUpsertWithoutRevisionsInput>;
};

export type NewsJackItemUpdateToOneWithWhereWithoutRevisionsInput = {
  data: NewsJackItemUpdateWithoutRevisionsInput;
  where?: InputMaybe<NewsJackItemWhereInput>;
};

export type NewsJackItemUpdateWithWhereUniqueWithoutClientInput = {
  data: NewsJackItemUpdateWithoutClientInput;
  where: NewsJackItemWhereUniqueInput;
};

export type NewsJackItemUpdateWithWhereUniqueWithoutContentInput = {
  data: NewsJackItemUpdateWithoutContentInput;
  where: NewsJackItemWhereUniqueInput;
};

export type NewsJackItemUpdateWithWhereUniqueWithoutStatusChangedByInput = {
  data: NewsJackItemUpdateWithoutStatusChangedByInput;
  where: NewsJackItemWhereUniqueInput;
};

export type NewsJackItemUpdateWithoutClientInput = {
  content?: InputMaybe<NewsWrapContentUpdateOneRequiredWithoutNewsJackItemsNestedInput>;
  deadline?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  revisions?: InputMaybe<NewsJackRevisionUpdateManyWithoutNewsJackItemNestedInput>;
  status?: InputMaybe<EnumNewsJackStatusFieldUpdateOperationsInput>;
  statusChangedBy?: InputMaybe<UserUpdateOneWithoutNewsJackItemsNestedInput>;
};

export type NewsJackItemUpdateWithoutContentInput = {
  client?: InputMaybe<ClientUpdateOneRequiredWithoutNewsJackItemsNestedInput>;
  deadline?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  revisions?: InputMaybe<NewsJackRevisionUpdateManyWithoutNewsJackItemNestedInput>;
  status?: InputMaybe<EnumNewsJackStatusFieldUpdateOperationsInput>;
  statusChangedBy?: InputMaybe<UserUpdateOneWithoutNewsJackItemsNestedInput>;
};

export type NewsJackItemUpdateWithoutRevisionsInput = {
  client?: InputMaybe<ClientUpdateOneRequiredWithoutNewsJackItemsNestedInput>;
  content?: InputMaybe<NewsWrapContentUpdateOneRequiredWithoutNewsJackItemsNestedInput>;
  deadline?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumNewsJackStatusFieldUpdateOperationsInput>;
  statusChangedBy?: InputMaybe<UserUpdateOneWithoutNewsJackItemsNestedInput>;
};

export type NewsJackItemUpdateWithoutStatusChangedByInput = {
  client?: InputMaybe<ClientUpdateOneRequiredWithoutNewsJackItemsNestedInput>;
  content?: InputMaybe<NewsWrapContentUpdateOneRequiredWithoutNewsJackItemsNestedInput>;
  deadline?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  revisions?: InputMaybe<NewsJackRevisionUpdateManyWithoutNewsJackItemNestedInput>;
  status?: InputMaybe<EnumNewsJackStatusFieldUpdateOperationsInput>;
};

export type NewsJackItemUpsertWithWhereUniqueWithoutClientInput = {
  create: NewsJackItemCreateWithoutClientInput;
  update: NewsJackItemUpdateWithoutClientInput;
  where: NewsJackItemWhereUniqueInput;
};

export type NewsJackItemUpsertWithWhereUniqueWithoutContentInput = {
  create: NewsJackItemCreateWithoutContentInput;
  update: NewsJackItemUpdateWithoutContentInput;
  where: NewsJackItemWhereUniqueInput;
};

export type NewsJackItemUpsertWithWhereUniqueWithoutStatusChangedByInput = {
  create: NewsJackItemCreateWithoutStatusChangedByInput;
  update: NewsJackItemUpdateWithoutStatusChangedByInput;
  where: NewsJackItemWhereUniqueInput;
};

export type NewsJackItemUpsertWithoutRevisionsInput = {
  create: NewsJackItemCreateWithoutRevisionsInput;
  update: NewsJackItemUpdateWithoutRevisionsInput;
  where?: InputMaybe<NewsJackItemWhereInput>;
};

export type NewsJackItemWhereInput = {
  AND?: InputMaybe<Array<NewsJackItemWhereInput>>;
  NOT?: InputMaybe<Array<NewsJackItemWhereInput>>;
  OR?: InputMaybe<Array<NewsJackItemWhereInput>>;
  client?: InputMaybe<ClientRelationFilter>;
  clientId?: InputMaybe<StringFilter>;
  content?: InputMaybe<NewsWrapContentRelationFilter>;
  contentId?: InputMaybe<StringFilter>;
  deadline?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  revisions?: InputMaybe<NewsJackRevisionListRelationFilter>;
  status?: InputMaybe<EnumNewsJackStatusFilter>;
  statusChangedBy?: InputMaybe<UserNullableRelationFilter>;
  statusChangedById?: InputMaybe<StringNullableFilter>;
};

export type NewsJackItemWhereUniqueInput = {
  AND?: InputMaybe<Array<NewsJackItemWhereInput>>;
  NOT?: InputMaybe<Array<NewsJackItemWhereInput>>;
  OR?: InputMaybe<Array<NewsJackItemWhereInput>>;
  client?: InputMaybe<ClientRelationFilter>;
  clientId?: InputMaybe<StringFilter>;
  content?: InputMaybe<NewsWrapContentRelationFilter>;
  contentId?: InputMaybe<StringFilter>;
  contentId_clientId?: InputMaybe<NewsJackItemContentIdClientIdCompoundUniqueInput>;
  deadline?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  revisions?: InputMaybe<NewsJackRevisionListRelationFilter>;
  status?: InputMaybe<EnumNewsJackStatusFilter>;
  statusChangedBy?: InputMaybe<UserNullableRelationFilter>;
  statusChangedById?: InputMaybe<StringNullableFilter>;
};

export type NewsJackRevision = {
  __typename?: 'NewsJackRevision';
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  createdById: Scalars['String']['output'];
  id: Scalars['String']['output'];
  newsJackItem: NewsJackItem;
  newsJackItemId: Scalars['String']['output'];
};

export type NewsJackRevisionCreateManyCreatedByInput = {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  newsJackItemId: Scalars['String']['input'];
};

export type NewsJackRevisionCreateManyCreatedByInputEnvelope = {
  data: Array<NewsJackRevisionCreateManyCreatedByInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NewsJackRevisionCreateManyNewsJackItemInput = {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdById: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
};

export type NewsJackRevisionCreateManyNewsJackItemInputEnvelope = {
  data: Array<NewsJackRevisionCreateManyNewsJackItemInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NewsJackRevisionCreateNestedManyWithoutCreatedByInput = {
  connect?: InputMaybe<Array<NewsJackRevisionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NewsJackRevisionCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<NewsJackRevisionCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<NewsJackRevisionCreateManyCreatedByInputEnvelope>;
};

export type NewsJackRevisionCreateNestedManyWithoutNewsJackItemInput = {
  connect?: InputMaybe<Array<NewsJackRevisionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NewsJackRevisionCreateOrConnectWithoutNewsJackItemInput>>;
  create?: InputMaybe<Array<NewsJackRevisionCreateWithoutNewsJackItemInput>>;
  createMany?: InputMaybe<NewsJackRevisionCreateManyNewsJackItemInputEnvelope>;
};

export type NewsJackRevisionCreateOrConnectWithoutCreatedByInput = {
  create: NewsJackRevisionCreateWithoutCreatedByInput;
  where: NewsJackRevisionWhereUniqueInput;
};

export type NewsJackRevisionCreateOrConnectWithoutNewsJackItemInput = {
  create: NewsJackRevisionCreateWithoutNewsJackItemInput;
  where: NewsJackRevisionWhereUniqueInput;
};

export type NewsJackRevisionCreateWithoutCreatedByInput = {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  newsJackItem: NewsJackItemCreateNestedOneWithoutRevisionsInput;
};

export type NewsJackRevisionCreateWithoutNewsJackItemInput = {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy: UserCreateNestedOneWithoutNewsJackRevisionsInput;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type NewsJackRevisionListRelationFilter = {
  every?: InputMaybe<NewsJackRevisionWhereInput>;
  none?: InputMaybe<NewsJackRevisionWhereInput>;
  some?: InputMaybe<NewsJackRevisionWhereInput>;
};

export type NewsJackRevisionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum NewsJackRevisionOrderByRelevanceFieldEnum {
  Content = 'content',
  CreatedById = 'createdById',
  Id = 'id',
  NewsJackItemId = 'newsJackItemId'
}

export type NewsJackRevisionOrderByRelevanceInput = {
  fields: Array<NewsJackRevisionOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type NewsJackRevisionOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<NewsJackRevisionOrderByRelevanceInput>;
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  createdById?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  newsJackItem?: InputMaybe<NewsJackItemOrderByWithRelationAndSearchRelevanceInput>;
  newsJackItemId?: InputMaybe<SortOrder>;
};

export enum NewsJackRevisionScalarFieldEnum {
  Content = 'content',
  CreatedAt = 'createdAt',
  CreatedById = 'createdById',
  Id = 'id',
  NewsJackItemId = 'newsJackItemId'
}

export type NewsJackRevisionScalarWhereInput = {
  AND?: InputMaybe<Array<NewsJackRevisionScalarWhereInput>>;
  NOT?: InputMaybe<Array<NewsJackRevisionScalarWhereInput>>;
  OR?: InputMaybe<Array<NewsJackRevisionScalarWhereInput>>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdById?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  newsJackItemId?: InputMaybe<StringFilter>;
};

export type NewsJackRevisionUpdateManyMutationInput = {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type NewsJackRevisionUpdateManyWithWhereWithoutCreatedByInput = {
  data: NewsJackRevisionUpdateManyMutationInput;
  where: NewsJackRevisionScalarWhereInput;
};

export type NewsJackRevisionUpdateManyWithWhereWithoutNewsJackItemInput = {
  data: NewsJackRevisionUpdateManyMutationInput;
  where: NewsJackRevisionScalarWhereInput;
};

export type NewsJackRevisionUpdateManyWithoutCreatedByNestedInput = {
  connect?: InputMaybe<Array<NewsJackRevisionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NewsJackRevisionCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<NewsJackRevisionCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<NewsJackRevisionCreateManyCreatedByInputEnvelope>;
  delete?: InputMaybe<Array<NewsJackRevisionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<NewsJackRevisionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<NewsJackRevisionWhereUniqueInput>>;
  set?: InputMaybe<Array<NewsJackRevisionWhereUniqueInput>>;
  update?: InputMaybe<Array<NewsJackRevisionUpdateWithWhereUniqueWithoutCreatedByInput>>;
  updateMany?: InputMaybe<Array<NewsJackRevisionUpdateManyWithWhereWithoutCreatedByInput>>;
  upsert?: InputMaybe<Array<NewsJackRevisionUpsertWithWhereUniqueWithoutCreatedByInput>>;
};

export type NewsJackRevisionUpdateManyWithoutNewsJackItemNestedInput = {
  connect?: InputMaybe<Array<NewsJackRevisionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NewsJackRevisionCreateOrConnectWithoutNewsJackItemInput>>;
  create?: InputMaybe<Array<NewsJackRevisionCreateWithoutNewsJackItemInput>>;
  createMany?: InputMaybe<NewsJackRevisionCreateManyNewsJackItemInputEnvelope>;
  delete?: InputMaybe<Array<NewsJackRevisionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<NewsJackRevisionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<NewsJackRevisionWhereUniqueInput>>;
  set?: InputMaybe<Array<NewsJackRevisionWhereUniqueInput>>;
  update?: InputMaybe<Array<NewsJackRevisionUpdateWithWhereUniqueWithoutNewsJackItemInput>>;
  updateMany?: InputMaybe<Array<NewsJackRevisionUpdateManyWithWhereWithoutNewsJackItemInput>>;
  upsert?: InputMaybe<Array<NewsJackRevisionUpsertWithWhereUniqueWithoutNewsJackItemInput>>;
};

export type NewsJackRevisionUpdateWithWhereUniqueWithoutCreatedByInput = {
  data: NewsJackRevisionUpdateWithoutCreatedByInput;
  where: NewsJackRevisionWhereUniqueInput;
};

export type NewsJackRevisionUpdateWithWhereUniqueWithoutNewsJackItemInput = {
  data: NewsJackRevisionUpdateWithoutNewsJackItemInput;
  where: NewsJackRevisionWhereUniqueInput;
};

export type NewsJackRevisionUpdateWithoutCreatedByInput = {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  newsJackItem?: InputMaybe<NewsJackItemUpdateOneRequiredWithoutRevisionsNestedInput>;
};

export type NewsJackRevisionUpdateWithoutNewsJackItemInput = {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutNewsJackRevisionsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type NewsJackRevisionUpsertWithWhereUniqueWithoutCreatedByInput = {
  create: NewsJackRevisionCreateWithoutCreatedByInput;
  update: NewsJackRevisionUpdateWithoutCreatedByInput;
  where: NewsJackRevisionWhereUniqueInput;
};

export type NewsJackRevisionUpsertWithWhereUniqueWithoutNewsJackItemInput = {
  create: NewsJackRevisionCreateWithoutNewsJackItemInput;
  update: NewsJackRevisionUpdateWithoutNewsJackItemInput;
  where: NewsJackRevisionWhereUniqueInput;
};

export type NewsJackRevisionWhereInput = {
  AND?: InputMaybe<Array<NewsJackRevisionWhereInput>>;
  NOT?: InputMaybe<Array<NewsJackRevisionWhereInput>>;
  OR?: InputMaybe<Array<NewsJackRevisionWhereInput>>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<UserRelationFilter>;
  createdById?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  newsJackItem?: InputMaybe<NewsJackItemRelationFilter>;
  newsJackItemId?: InputMaybe<StringFilter>;
};

export type NewsJackRevisionWhereUniqueInput = {
  AND?: InputMaybe<Array<NewsJackRevisionWhereInput>>;
  NOT?: InputMaybe<Array<NewsJackRevisionWhereInput>>;
  OR?: InputMaybe<Array<NewsJackRevisionWhereInput>>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<UserRelationFilter>;
  createdById?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  newsJackItem?: InputMaybe<NewsJackItemRelationFilter>;
  newsJackItemId?: InputMaybe<StringFilter>;
};

export enum NewsJackStatus {
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  PendingAgencyApproval = 'PENDING_AGENCY_APPROVAL',
  PendingClientApproval = 'PENDING_CLIENT_APPROVAL'
}

export type NewsWrap = {
  __typename?: 'NewsWrap';
  _count?: Maybe<NewsWrapCount>;
  content: Array<NewsWrapContent>;
  date: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  repeatScheduledSend: Scalars['Boolean']['output'];
  scheduledSendAt?: Maybe<Scalars['DateTime']['output']>;
  sentAt?: Maybe<Scalars['DateTime']['output']>;
  sentTo: Array<Client>;
  title: Scalars['String']['output'];
  workspaceId: Scalars['String']['output'];
};


export type NewsWrapContentArgs = {
  cursor?: InputMaybe<NewsWrapContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<NewsWrapContentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<NewsWrapContentOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NewsWrapContentWhereInput>;
};


export type NewsWrapSentToArgs = {
  cursor?: InputMaybe<NewsWrapWhereUniqueInput>;
  distinct?: InputMaybe<Array<NewsWrapScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<NewsWrapOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NewsWrapWhereInput>;
};

export type NewsWrapAggregate = {
  __typename?: 'NewsWrapAggregate';
  _count: Scalars['Float']['output'];
};

export type NewsWrapContent = {
  __typename?: 'NewsWrapContent';
  _count?: Maybe<NewsWrapContentCount>;
  article: ArticleUnion;
  articleId: Scalars['String']['output'];
  createdBy: User;
  createdById: Scalars['String']['output'];
  id: Scalars['String']['output'];
  newsJackItems: Array<NewsJackItem>;
  newsWrap: NewsWrap;
  newsWrapId: Scalars['String']['output'];
  notes: Scalars['String']['output'];
  referencedUsers: Array<User>;
};


export type NewsWrapContentNewsJackItemsArgs = {
  cursor?: InputMaybe<NewsJackItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<NewsJackItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<NewsJackItemOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NewsJackItemWhereInput>;
};


export type NewsWrapContentReferencedUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};

export type NewsWrapContentArticleIdNewsWrapIdCompoundUniqueInput = {
  articleId: Scalars['String']['input'];
  newsWrapId: Scalars['String']['input'];
};

export type NewsWrapContentCount = {
  __typename?: 'NewsWrapContentCount';
  newsJackItems: Scalars['Int']['output'];
  referencedUsers: Scalars['Int']['output'];
};


export type NewsWrapContentCountNewsJackItemsArgs = {
  where?: InputMaybe<NewsJackItemWhereInput>;
};


export type NewsWrapContentCountReferencedUsersArgs = {
  where?: InputMaybe<UserWhereInput>;
};

export type NewsWrapContentCreateManyArticleInput = {
  createdById: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  newsWrapId: Scalars['String']['input'];
  notes: Scalars['String']['input'];
};

export type NewsWrapContentCreateManyArticleInputEnvelope = {
  data: Array<NewsWrapContentCreateManyArticleInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NewsWrapContentCreateManyCreatedByInput = {
  articleId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  newsWrapId: Scalars['String']['input'];
  notes: Scalars['String']['input'];
};

export type NewsWrapContentCreateManyCreatedByInputEnvelope = {
  data: Array<NewsWrapContentCreateManyCreatedByInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NewsWrapContentCreateManyNewsWrapInput = {
  articleId: Scalars['String']['input'];
  createdById: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  notes: Scalars['String']['input'];
};

export type NewsWrapContentCreateManyNewsWrapInputEnvelope = {
  data: Array<NewsWrapContentCreateManyNewsWrapInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NewsWrapContentCreateNestedManyWithoutArticleInput = {
  connect?: InputMaybe<Array<NewsWrapContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NewsWrapContentCreateOrConnectWithoutArticleInput>>;
  create?: InputMaybe<Array<NewsWrapContentCreateWithoutArticleInput>>;
  createMany?: InputMaybe<NewsWrapContentCreateManyArticleInputEnvelope>;
};

export type NewsWrapContentCreateNestedManyWithoutCreatedByInput = {
  connect?: InputMaybe<Array<NewsWrapContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NewsWrapContentCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<NewsWrapContentCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<NewsWrapContentCreateManyCreatedByInputEnvelope>;
};

export type NewsWrapContentCreateNestedManyWithoutNewsWrapInput = {
  connect?: InputMaybe<Array<NewsWrapContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NewsWrapContentCreateOrConnectWithoutNewsWrapInput>>;
  create?: InputMaybe<Array<NewsWrapContentCreateWithoutNewsWrapInput>>;
  createMany?: InputMaybe<NewsWrapContentCreateManyNewsWrapInputEnvelope>;
};

export type NewsWrapContentCreateNestedManyWithoutReferencedUsersInput = {
  connect?: InputMaybe<Array<NewsWrapContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NewsWrapContentCreateOrConnectWithoutReferencedUsersInput>>;
  create?: InputMaybe<Array<NewsWrapContentCreateWithoutReferencedUsersInput>>;
};

export type NewsWrapContentCreateNestedOneWithoutNewsJackItemsInput = {
  connect?: InputMaybe<NewsWrapContentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<NewsWrapContentCreateOrConnectWithoutNewsJackItemsInput>;
  create?: InputMaybe<NewsWrapContentCreateWithoutNewsJackItemsInput>;
};

export type NewsWrapContentCreateOrConnectWithoutArticleInput = {
  create: NewsWrapContentCreateWithoutArticleInput;
  where: NewsWrapContentWhereUniqueInput;
};

export type NewsWrapContentCreateOrConnectWithoutCreatedByInput = {
  create: NewsWrapContentCreateWithoutCreatedByInput;
  where: NewsWrapContentWhereUniqueInput;
};

export type NewsWrapContentCreateOrConnectWithoutNewsJackItemsInput = {
  create: NewsWrapContentCreateWithoutNewsJackItemsInput;
  where: NewsWrapContentWhereUniqueInput;
};

export type NewsWrapContentCreateOrConnectWithoutNewsWrapInput = {
  create: NewsWrapContentCreateWithoutNewsWrapInput;
  where: NewsWrapContentWhereUniqueInput;
};

export type NewsWrapContentCreateOrConnectWithoutReferencedUsersInput = {
  create: NewsWrapContentCreateWithoutReferencedUsersInput;
  where: NewsWrapContentWhereUniqueInput;
};

export type NewsWrapContentCreateType = {
  articleId: Scalars['String']['input'];
  newsWrapId: Scalars['String']['input'];
  notes: Scalars['String']['input'];
};

export type NewsWrapContentCreateWithoutArticleInput = {
  createdBy: UserCreateNestedOneWithoutNewsWrapContentInput;
  id?: InputMaybe<Scalars['String']['input']>;
  newsJackItems?: InputMaybe<NewsJackItemCreateNestedManyWithoutContentInput>;
  newsWrap: NewsWrapCreateNestedOneWithoutContentInput;
  notes: Scalars['String']['input'];
  referencedUsers?: InputMaybe<UserCreateNestedManyWithoutReferencedUsersInput>;
};

export type NewsWrapContentCreateWithoutCreatedByInput = {
  article: ArticleCreateNestedOneWithoutNewsWrapContentsInput;
  id?: InputMaybe<Scalars['String']['input']>;
  newsJackItems?: InputMaybe<NewsJackItemCreateNestedManyWithoutContentInput>;
  newsWrap: NewsWrapCreateNestedOneWithoutContentInput;
  notes: Scalars['String']['input'];
  referencedUsers?: InputMaybe<UserCreateNestedManyWithoutReferencedUsersInput>;
};

export type NewsWrapContentCreateWithoutNewsJackItemsInput = {
  article: ArticleCreateNestedOneWithoutNewsWrapContentsInput;
  createdBy: UserCreateNestedOneWithoutNewsWrapContentInput;
  id?: InputMaybe<Scalars['String']['input']>;
  newsWrap: NewsWrapCreateNestedOneWithoutContentInput;
  notes: Scalars['String']['input'];
  referencedUsers?: InputMaybe<UserCreateNestedManyWithoutReferencedUsersInput>;
};

export type NewsWrapContentCreateWithoutNewsWrapInput = {
  article: ArticleCreateNestedOneWithoutNewsWrapContentsInput;
  createdBy: UserCreateNestedOneWithoutNewsWrapContentInput;
  id?: InputMaybe<Scalars['String']['input']>;
  newsJackItems?: InputMaybe<NewsJackItemCreateNestedManyWithoutContentInput>;
  notes: Scalars['String']['input'];
  referencedUsers?: InputMaybe<UserCreateNestedManyWithoutReferencedUsersInput>;
};

export type NewsWrapContentCreateWithoutReferencedUsersInput = {
  article: ArticleCreateNestedOneWithoutNewsWrapContentsInput;
  createdBy: UserCreateNestedOneWithoutNewsWrapContentInput;
  id?: InputMaybe<Scalars['String']['input']>;
  newsJackItems?: InputMaybe<NewsJackItemCreateNestedManyWithoutContentInput>;
  newsWrap: NewsWrapCreateNestedOneWithoutContentInput;
  notes: Scalars['String']['input'];
};

export type NewsWrapContentListRelationFilter = {
  every?: InputMaybe<NewsWrapContentWhereInput>;
  none?: InputMaybe<NewsWrapContentWhereInput>;
  some?: InputMaybe<NewsWrapContentWhereInput>;
};

export type NewsWrapContentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum NewsWrapContentOrderByRelevanceFieldEnum {
  ArticleId = 'articleId',
  CreatedById = 'createdById',
  Id = 'id',
  NewsWrapId = 'newsWrapId',
  Notes = 'notes'
}

export type NewsWrapContentOrderByRelevanceInput = {
  fields: Array<NewsWrapContentOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type NewsWrapContentOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<NewsWrapContentOrderByRelevanceInput>;
  article?: InputMaybe<ArticleOrderByWithRelationAndSearchRelevanceInput>;
  articleId?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  createdById?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  newsJackItems?: InputMaybe<NewsJackItemOrderByRelationAggregateInput>;
  newsWrap?: InputMaybe<NewsWrapOrderByWithRelationAndSearchRelevanceInput>;
  newsWrapId?: InputMaybe<SortOrder>;
  notes?: InputMaybe<SortOrder>;
  referencedUsers?: InputMaybe<UserOrderByRelationAggregateInput>;
};

export type NewsWrapContentRelationFilter = {
  is?: InputMaybe<NewsWrapContentWhereInput>;
  isNot?: InputMaybe<NewsWrapContentWhereInput>;
};

export enum NewsWrapContentScalarFieldEnum {
  ArticleId = 'articleId',
  CreatedById = 'createdById',
  Id = 'id',
  NewsWrapId = 'newsWrapId',
  Notes = 'notes'
}

export type NewsWrapContentScalarWhereInput = {
  AND?: InputMaybe<Array<NewsWrapContentScalarWhereInput>>;
  NOT?: InputMaybe<Array<NewsWrapContentScalarWhereInput>>;
  OR?: InputMaybe<Array<NewsWrapContentScalarWhereInput>>;
  articleId?: InputMaybe<StringFilter>;
  createdById?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  newsWrapId?: InputMaybe<StringFilter>;
  notes?: InputMaybe<StringFilter>;
};

export type NewsWrapContentUpdateInput = {
  article?: InputMaybe<ArticleUpdateOneRequiredWithoutNewsWrapContentsNestedInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutNewsWrapContentNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  newsJackItems?: InputMaybe<NewsJackItemUpdateManyWithoutContentNestedInput>;
  newsWrap?: InputMaybe<NewsWrapUpdateOneRequiredWithoutContentNestedInput>;
  notes?: InputMaybe<StringFieldUpdateOperationsInput>;
  referencedUsers?: InputMaybe<UserUpdateManyWithoutReferencedUsersNestedInput>;
};

export type NewsWrapContentUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  notes?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type NewsWrapContentUpdateManyWithWhereWithoutArticleInput = {
  data: NewsWrapContentUpdateManyMutationInput;
  where: NewsWrapContentScalarWhereInput;
};

export type NewsWrapContentUpdateManyWithWhereWithoutCreatedByInput = {
  data: NewsWrapContentUpdateManyMutationInput;
  where: NewsWrapContentScalarWhereInput;
};

export type NewsWrapContentUpdateManyWithWhereWithoutNewsWrapInput = {
  data: NewsWrapContentUpdateManyMutationInput;
  where: NewsWrapContentScalarWhereInput;
};

export type NewsWrapContentUpdateManyWithWhereWithoutReferencedUsersInput = {
  data: NewsWrapContentUpdateManyMutationInput;
  where: NewsWrapContentScalarWhereInput;
};

export type NewsWrapContentUpdateManyWithoutArticleNestedInput = {
  connect?: InputMaybe<Array<NewsWrapContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NewsWrapContentCreateOrConnectWithoutArticleInput>>;
  create?: InputMaybe<Array<NewsWrapContentCreateWithoutArticleInput>>;
  createMany?: InputMaybe<NewsWrapContentCreateManyArticleInputEnvelope>;
  delete?: InputMaybe<Array<NewsWrapContentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<NewsWrapContentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<NewsWrapContentWhereUniqueInput>>;
  set?: InputMaybe<Array<NewsWrapContentWhereUniqueInput>>;
  update?: InputMaybe<Array<NewsWrapContentUpdateWithWhereUniqueWithoutArticleInput>>;
  updateMany?: InputMaybe<Array<NewsWrapContentUpdateManyWithWhereWithoutArticleInput>>;
  upsert?: InputMaybe<Array<NewsWrapContentUpsertWithWhereUniqueWithoutArticleInput>>;
};

export type NewsWrapContentUpdateManyWithoutCreatedByNestedInput = {
  connect?: InputMaybe<Array<NewsWrapContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NewsWrapContentCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<NewsWrapContentCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<NewsWrapContentCreateManyCreatedByInputEnvelope>;
  delete?: InputMaybe<Array<NewsWrapContentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<NewsWrapContentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<NewsWrapContentWhereUniqueInput>>;
  set?: InputMaybe<Array<NewsWrapContentWhereUniqueInput>>;
  update?: InputMaybe<Array<NewsWrapContentUpdateWithWhereUniqueWithoutCreatedByInput>>;
  updateMany?: InputMaybe<Array<NewsWrapContentUpdateManyWithWhereWithoutCreatedByInput>>;
  upsert?: InputMaybe<Array<NewsWrapContentUpsertWithWhereUniqueWithoutCreatedByInput>>;
};

export type NewsWrapContentUpdateManyWithoutNewsWrapNestedInput = {
  connect?: InputMaybe<Array<NewsWrapContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NewsWrapContentCreateOrConnectWithoutNewsWrapInput>>;
  create?: InputMaybe<Array<NewsWrapContentCreateWithoutNewsWrapInput>>;
  createMany?: InputMaybe<NewsWrapContentCreateManyNewsWrapInputEnvelope>;
  delete?: InputMaybe<Array<NewsWrapContentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<NewsWrapContentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<NewsWrapContentWhereUniqueInput>>;
  set?: InputMaybe<Array<NewsWrapContentWhereUniqueInput>>;
  update?: InputMaybe<Array<NewsWrapContentUpdateWithWhereUniqueWithoutNewsWrapInput>>;
  updateMany?: InputMaybe<Array<NewsWrapContentUpdateManyWithWhereWithoutNewsWrapInput>>;
  upsert?: InputMaybe<Array<NewsWrapContentUpsertWithWhereUniqueWithoutNewsWrapInput>>;
};

export type NewsWrapContentUpdateManyWithoutReferencedUsersNestedInput = {
  connect?: InputMaybe<Array<NewsWrapContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NewsWrapContentCreateOrConnectWithoutReferencedUsersInput>>;
  create?: InputMaybe<Array<NewsWrapContentCreateWithoutReferencedUsersInput>>;
  delete?: InputMaybe<Array<NewsWrapContentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<NewsWrapContentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<NewsWrapContentWhereUniqueInput>>;
  set?: InputMaybe<Array<NewsWrapContentWhereUniqueInput>>;
  update?: InputMaybe<Array<NewsWrapContentUpdateWithWhereUniqueWithoutReferencedUsersInput>>;
  updateMany?: InputMaybe<Array<NewsWrapContentUpdateManyWithWhereWithoutReferencedUsersInput>>;
  upsert?: InputMaybe<Array<NewsWrapContentUpsertWithWhereUniqueWithoutReferencedUsersInput>>;
};

export type NewsWrapContentUpdateOneRequiredWithoutNewsJackItemsNestedInput = {
  connect?: InputMaybe<NewsWrapContentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<NewsWrapContentCreateOrConnectWithoutNewsJackItemsInput>;
  create?: InputMaybe<NewsWrapContentCreateWithoutNewsJackItemsInput>;
  update?: InputMaybe<NewsWrapContentUpdateToOneWithWhereWithoutNewsJackItemsInput>;
  upsert?: InputMaybe<NewsWrapContentUpsertWithoutNewsJackItemsInput>;
};

export type NewsWrapContentUpdateToOneWithWhereWithoutNewsJackItemsInput = {
  data: NewsWrapContentUpdateWithoutNewsJackItemsInput;
  where?: InputMaybe<NewsWrapContentWhereInput>;
};

export type NewsWrapContentUpdateWithWhereUniqueWithoutArticleInput = {
  data: NewsWrapContentUpdateWithoutArticleInput;
  where: NewsWrapContentWhereUniqueInput;
};

export type NewsWrapContentUpdateWithWhereUniqueWithoutCreatedByInput = {
  data: NewsWrapContentUpdateWithoutCreatedByInput;
  where: NewsWrapContentWhereUniqueInput;
};

export type NewsWrapContentUpdateWithWhereUniqueWithoutNewsWrapInput = {
  data: NewsWrapContentUpdateWithoutNewsWrapInput;
  where: NewsWrapContentWhereUniqueInput;
};

export type NewsWrapContentUpdateWithWhereUniqueWithoutReferencedUsersInput = {
  data: NewsWrapContentUpdateWithoutReferencedUsersInput;
  where: NewsWrapContentWhereUniqueInput;
};

export type NewsWrapContentUpdateWithoutArticleInput = {
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutNewsWrapContentNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  newsJackItems?: InputMaybe<NewsJackItemUpdateManyWithoutContentNestedInput>;
  newsWrap?: InputMaybe<NewsWrapUpdateOneRequiredWithoutContentNestedInput>;
  notes?: InputMaybe<StringFieldUpdateOperationsInput>;
  referencedUsers?: InputMaybe<UserUpdateManyWithoutReferencedUsersNestedInput>;
};

export type NewsWrapContentUpdateWithoutCreatedByInput = {
  article?: InputMaybe<ArticleUpdateOneRequiredWithoutNewsWrapContentsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  newsJackItems?: InputMaybe<NewsJackItemUpdateManyWithoutContentNestedInput>;
  newsWrap?: InputMaybe<NewsWrapUpdateOneRequiredWithoutContentNestedInput>;
  notes?: InputMaybe<StringFieldUpdateOperationsInput>;
  referencedUsers?: InputMaybe<UserUpdateManyWithoutReferencedUsersNestedInput>;
};

export type NewsWrapContentUpdateWithoutNewsJackItemsInput = {
  article?: InputMaybe<ArticleUpdateOneRequiredWithoutNewsWrapContentsNestedInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutNewsWrapContentNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  newsWrap?: InputMaybe<NewsWrapUpdateOneRequiredWithoutContentNestedInput>;
  notes?: InputMaybe<StringFieldUpdateOperationsInput>;
  referencedUsers?: InputMaybe<UserUpdateManyWithoutReferencedUsersNestedInput>;
};

export type NewsWrapContentUpdateWithoutNewsWrapInput = {
  article?: InputMaybe<ArticleUpdateOneRequiredWithoutNewsWrapContentsNestedInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutNewsWrapContentNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  newsJackItems?: InputMaybe<NewsJackItemUpdateManyWithoutContentNestedInput>;
  notes?: InputMaybe<StringFieldUpdateOperationsInput>;
  referencedUsers?: InputMaybe<UserUpdateManyWithoutReferencedUsersNestedInput>;
};

export type NewsWrapContentUpdateWithoutReferencedUsersInput = {
  article?: InputMaybe<ArticleUpdateOneRequiredWithoutNewsWrapContentsNestedInput>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutNewsWrapContentNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  newsJackItems?: InputMaybe<NewsJackItemUpdateManyWithoutContentNestedInput>;
  newsWrap?: InputMaybe<NewsWrapUpdateOneRequiredWithoutContentNestedInput>;
  notes?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type NewsWrapContentUpsertWithWhereUniqueWithoutArticleInput = {
  create: NewsWrapContentCreateWithoutArticleInput;
  update: NewsWrapContentUpdateWithoutArticleInput;
  where: NewsWrapContentWhereUniqueInput;
};

export type NewsWrapContentUpsertWithWhereUniqueWithoutCreatedByInput = {
  create: NewsWrapContentCreateWithoutCreatedByInput;
  update: NewsWrapContentUpdateWithoutCreatedByInput;
  where: NewsWrapContentWhereUniqueInput;
};

export type NewsWrapContentUpsertWithWhereUniqueWithoutNewsWrapInput = {
  create: NewsWrapContentCreateWithoutNewsWrapInput;
  update: NewsWrapContentUpdateWithoutNewsWrapInput;
  where: NewsWrapContentWhereUniqueInput;
};

export type NewsWrapContentUpsertWithWhereUniqueWithoutReferencedUsersInput = {
  create: NewsWrapContentCreateWithoutReferencedUsersInput;
  update: NewsWrapContentUpdateWithoutReferencedUsersInput;
  where: NewsWrapContentWhereUniqueInput;
};

export type NewsWrapContentUpsertWithoutNewsJackItemsInput = {
  create: NewsWrapContentCreateWithoutNewsJackItemsInput;
  update: NewsWrapContentUpdateWithoutNewsJackItemsInput;
  where?: InputMaybe<NewsWrapContentWhereInput>;
};

export type NewsWrapContentWhereInput = {
  AND?: InputMaybe<Array<NewsWrapContentWhereInput>>;
  NOT?: InputMaybe<Array<NewsWrapContentWhereInput>>;
  OR?: InputMaybe<Array<NewsWrapContentWhereInput>>;
  article?: InputMaybe<ArticleRelationFilter>;
  articleId?: InputMaybe<StringFilter>;
  createdBy?: InputMaybe<UserRelationFilter>;
  createdById?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  newsJackItems?: InputMaybe<NewsJackItemListRelationFilter>;
  newsWrap?: InputMaybe<NewsWrapRelationFilter>;
  newsWrapId?: InputMaybe<StringFilter>;
  notes?: InputMaybe<StringFilter>;
  referencedUsers?: InputMaybe<UserListRelationFilter>;
};

export type NewsWrapContentWhereUniqueInput = {
  AND?: InputMaybe<Array<NewsWrapContentWhereInput>>;
  NOT?: InputMaybe<Array<NewsWrapContentWhereInput>>;
  OR?: InputMaybe<Array<NewsWrapContentWhereInput>>;
  article?: InputMaybe<ArticleRelationFilter>;
  articleId?: InputMaybe<StringFilter>;
  articleId_newsWrapId?: InputMaybe<NewsWrapContentArticleIdNewsWrapIdCompoundUniqueInput>;
  createdBy?: InputMaybe<UserRelationFilter>;
  createdById?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  newsJackItems?: InputMaybe<NewsJackItemListRelationFilter>;
  newsWrap?: InputMaybe<NewsWrapRelationFilter>;
  newsWrapId?: InputMaybe<StringFilter>;
  notes?: InputMaybe<StringFilter>;
  referencedUsers?: InputMaybe<UserListRelationFilter>;
};

export type NewsWrapCount = {
  __typename?: 'NewsWrapCount';
  content: Scalars['Int']['output'];
  sentTo: Scalars['Int']['output'];
};


export type NewsWrapCountContentArgs = {
  where?: InputMaybe<NewsWrapContentWhereInput>;
};


export type NewsWrapCountSentToArgs = {
  where?: InputMaybe<ClientWhereInput>;
};

export type NewsWrapCreate = {
  date: Scalars['DateTime']['input'];
};

export type NewsWrapCreateManyWorkspaceInput = {
  date: Scalars['DateTime']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  repeatScheduledSend?: InputMaybe<Scalars['Boolean']['input']>;
  scheduledSendAt?: InputMaybe<Scalars['DateTime']['input']>;
  sentAt?: InputMaybe<Scalars['DateTime']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type NewsWrapCreateManyWorkspaceInputEnvelope = {
  data: Array<NewsWrapCreateManyWorkspaceInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NewsWrapCreateNestedManyWithoutSentToInput = {
  connect?: InputMaybe<Array<NewsWrapWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NewsWrapCreateOrConnectWithoutSentToInput>>;
  create?: InputMaybe<Array<NewsWrapCreateWithoutSentToInput>>;
};

export type NewsWrapCreateNestedManyWithoutWorkspaceInput = {
  connect?: InputMaybe<Array<NewsWrapWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NewsWrapCreateOrConnectWithoutWorkspaceInput>>;
  create?: InputMaybe<Array<NewsWrapCreateWithoutWorkspaceInput>>;
  createMany?: InputMaybe<NewsWrapCreateManyWorkspaceInputEnvelope>;
};

export type NewsWrapCreateNestedOneWithoutContentInput = {
  connect?: InputMaybe<NewsWrapWhereUniqueInput>;
  connectOrCreate?: InputMaybe<NewsWrapCreateOrConnectWithoutContentInput>;
  create?: InputMaybe<NewsWrapCreateWithoutContentInput>;
};

export type NewsWrapCreateOrConnectWithoutContentInput = {
  create: NewsWrapCreateWithoutContentInput;
  where: NewsWrapWhereUniqueInput;
};

export type NewsWrapCreateOrConnectWithoutSentToInput = {
  create: NewsWrapCreateWithoutSentToInput;
  where: NewsWrapWhereUniqueInput;
};

export type NewsWrapCreateOrConnectWithoutWorkspaceInput = {
  create: NewsWrapCreateWithoutWorkspaceInput;
  where: NewsWrapWhereUniqueInput;
};

export type NewsWrapCreateWithoutContentInput = {
  date: Scalars['DateTime']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  repeatScheduledSend?: InputMaybe<Scalars['Boolean']['input']>;
  scheduledSendAt?: InputMaybe<Scalars['DateTime']['input']>;
  sentAt?: InputMaybe<Scalars['DateTime']['input']>;
  sentTo?: InputMaybe<ClientCreateNestedManyWithoutReceivedNewsWrapsInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  workspace: WorkspaceCreateNestedOneWithoutNewsWrapsInput;
};

export type NewsWrapCreateWithoutSentToInput = {
  content?: InputMaybe<NewsWrapContentCreateNestedManyWithoutNewsWrapInput>;
  date: Scalars['DateTime']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  repeatScheduledSend?: InputMaybe<Scalars['Boolean']['input']>;
  scheduledSendAt?: InputMaybe<Scalars['DateTime']['input']>;
  sentAt?: InputMaybe<Scalars['DateTime']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  workspace: WorkspaceCreateNestedOneWithoutNewsWrapsInput;
};

export type NewsWrapCreateWithoutWorkspaceInput = {
  content?: InputMaybe<NewsWrapContentCreateNestedManyWithoutNewsWrapInput>;
  date: Scalars['DateTime']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  repeatScheduledSend?: InputMaybe<Scalars['Boolean']['input']>;
  scheduledSendAt?: InputMaybe<Scalars['DateTime']['input']>;
  sentAt?: InputMaybe<Scalars['DateTime']['input']>;
  sentTo?: InputMaybe<ClientCreateNestedManyWithoutReceivedNewsWrapsInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type NewsWrapListRelationFilter = {
  every?: InputMaybe<NewsWrapWhereInput>;
  none?: InputMaybe<NewsWrapWhereInput>;
  some?: InputMaybe<NewsWrapWhereInput>;
};

export type NewsWrapOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum NewsWrapOrderByRelevanceFieldEnum {
  Id = 'id',
  Title = 'title',
  WorkspaceId = 'workspaceId'
}

export type NewsWrapOrderByRelevanceInput = {
  fields: Array<NewsWrapOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type NewsWrapOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<NewsWrapOrderByRelevanceInput>;
  content?: InputMaybe<NewsWrapContentOrderByRelationAggregateInput>;
  date?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  repeatScheduledSend?: InputMaybe<SortOrder>;
  scheduledSendAt?: InputMaybe<SortOrderInput>;
  sentAt?: InputMaybe<SortOrderInput>;
  sentTo?: InputMaybe<ClientOrderByRelationAggregateInput>;
  title?: InputMaybe<SortOrder>;
  workspace?: InputMaybe<WorkspaceOrderByWithRelationAndSearchRelevanceInput>;
  workspaceId?: InputMaybe<SortOrder>;
};

export type NewsWrapRelationFilter = {
  is?: InputMaybe<NewsWrapWhereInput>;
  isNot?: InputMaybe<NewsWrapWhereInput>;
};

export type NewsWrapResolverQuery = {
  id: Scalars['String']['input'];
};

export enum NewsWrapScalarFieldEnum {
  Date = 'date',
  Id = 'id',
  RepeatScheduledSend = 'repeatScheduledSend',
  ScheduledSendAt = 'scheduledSendAt',
  SentAt = 'sentAt',
  Title = 'title',
  WorkspaceId = 'workspaceId'
}

export type NewsWrapScalarWhereInput = {
  AND?: InputMaybe<Array<NewsWrapScalarWhereInput>>;
  NOT?: InputMaybe<Array<NewsWrapScalarWhereInput>>;
  OR?: InputMaybe<Array<NewsWrapScalarWhereInput>>;
  date?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  repeatScheduledSend?: InputMaybe<BoolFilter>;
  scheduledSendAt?: InputMaybe<DateTimeNullableFilter>;
  sentAt?: InputMaybe<DateTimeNullableFilter>;
  title?: InputMaybe<StringFilter>;
  workspaceId?: InputMaybe<StringFilter>;
};

export type NewsWrapUpdate = {
  repeat?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type NewsWrapUpdateManyMutationInput = {
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  repeatScheduledSend?: InputMaybe<BoolFieldUpdateOperationsInput>;
  scheduledSendAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sentAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type NewsWrapUpdateManyWithWhereWithoutSentToInput = {
  data: NewsWrapUpdateManyMutationInput;
  where: NewsWrapScalarWhereInput;
};

export type NewsWrapUpdateManyWithWhereWithoutWorkspaceInput = {
  data: NewsWrapUpdateManyMutationInput;
  where: NewsWrapScalarWhereInput;
};

export type NewsWrapUpdateManyWithoutSentToNestedInput = {
  connect?: InputMaybe<Array<NewsWrapWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NewsWrapCreateOrConnectWithoutSentToInput>>;
  create?: InputMaybe<Array<NewsWrapCreateWithoutSentToInput>>;
  delete?: InputMaybe<Array<NewsWrapWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<NewsWrapScalarWhereInput>>;
  disconnect?: InputMaybe<Array<NewsWrapWhereUniqueInput>>;
  set?: InputMaybe<Array<NewsWrapWhereUniqueInput>>;
  update?: InputMaybe<Array<NewsWrapUpdateWithWhereUniqueWithoutSentToInput>>;
  updateMany?: InputMaybe<Array<NewsWrapUpdateManyWithWhereWithoutSentToInput>>;
  upsert?: InputMaybe<Array<NewsWrapUpsertWithWhereUniqueWithoutSentToInput>>;
};

export type NewsWrapUpdateManyWithoutWorkspaceNestedInput = {
  connect?: InputMaybe<Array<NewsWrapWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NewsWrapCreateOrConnectWithoutWorkspaceInput>>;
  create?: InputMaybe<Array<NewsWrapCreateWithoutWorkspaceInput>>;
  createMany?: InputMaybe<NewsWrapCreateManyWorkspaceInputEnvelope>;
  delete?: InputMaybe<Array<NewsWrapWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<NewsWrapScalarWhereInput>>;
  disconnect?: InputMaybe<Array<NewsWrapWhereUniqueInput>>;
  set?: InputMaybe<Array<NewsWrapWhereUniqueInput>>;
  update?: InputMaybe<Array<NewsWrapUpdateWithWhereUniqueWithoutWorkspaceInput>>;
  updateMany?: InputMaybe<Array<NewsWrapUpdateManyWithWhereWithoutWorkspaceInput>>;
  upsert?: InputMaybe<Array<NewsWrapUpsertWithWhereUniqueWithoutWorkspaceInput>>;
};

export type NewsWrapUpdateOneRequiredWithoutContentNestedInput = {
  connect?: InputMaybe<NewsWrapWhereUniqueInput>;
  connectOrCreate?: InputMaybe<NewsWrapCreateOrConnectWithoutContentInput>;
  create?: InputMaybe<NewsWrapCreateWithoutContentInput>;
  update?: InputMaybe<NewsWrapUpdateToOneWithWhereWithoutContentInput>;
  upsert?: InputMaybe<NewsWrapUpsertWithoutContentInput>;
};

export type NewsWrapUpdateToOneWithWhereWithoutContentInput = {
  data: NewsWrapUpdateWithoutContentInput;
  where?: InputMaybe<NewsWrapWhereInput>;
};

export type NewsWrapUpdateWithWhereUniqueWithoutSentToInput = {
  data: NewsWrapUpdateWithoutSentToInput;
  where: NewsWrapWhereUniqueInput;
};

export type NewsWrapUpdateWithWhereUniqueWithoutWorkspaceInput = {
  data: NewsWrapUpdateWithoutWorkspaceInput;
  where: NewsWrapWhereUniqueInput;
};

export type NewsWrapUpdateWithoutContentInput = {
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  repeatScheduledSend?: InputMaybe<BoolFieldUpdateOperationsInput>;
  scheduledSendAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sentAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sentTo?: InputMaybe<ClientUpdateManyWithoutReceivedNewsWrapsNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  workspace?: InputMaybe<WorkspaceUpdateOneRequiredWithoutNewsWrapsNestedInput>;
};

export type NewsWrapUpdateWithoutSentToInput = {
  content?: InputMaybe<NewsWrapContentUpdateManyWithoutNewsWrapNestedInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  repeatScheduledSend?: InputMaybe<BoolFieldUpdateOperationsInput>;
  scheduledSendAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sentAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  workspace?: InputMaybe<WorkspaceUpdateOneRequiredWithoutNewsWrapsNestedInput>;
};

export type NewsWrapUpdateWithoutWorkspaceInput = {
  content?: InputMaybe<NewsWrapContentUpdateManyWithoutNewsWrapNestedInput>;
  date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  repeatScheduledSend?: InputMaybe<BoolFieldUpdateOperationsInput>;
  scheduledSendAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sentAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sentTo?: InputMaybe<ClientUpdateManyWithoutReceivedNewsWrapsNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type NewsWrapUpsertWithWhereUniqueWithoutSentToInput = {
  create: NewsWrapCreateWithoutSentToInput;
  update: NewsWrapUpdateWithoutSentToInput;
  where: NewsWrapWhereUniqueInput;
};

export type NewsWrapUpsertWithWhereUniqueWithoutWorkspaceInput = {
  create: NewsWrapCreateWithoutWorkspaceInput;
  update: NewsWrapUpdateWithoutWorkspaceInput;
  where: NewsWrapWhereUniqueInput;
};

export type NewsWrapUpsertWithoutContentInput = {
  create: NewsWrapCreateWithoutContentInput;
  update: NewsWrapUpdateWithoutContentInput;
  where?: InputMaybe<NewsWrapWhereInput>;
};

export type NewsWrapWhereInput = {
  AND?: InputMaybe<Array<NewsWrapWhereInput>>;
  NOT?: InputMaybe<Array<NewsWrapWhereInput>>;
  OR?: InputMaybe<Array<NewsWrapWhereInput>>;
  content?: InputMaybe<NewsWrapContentListRelationFilter>;
  date?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  repeatScheduledSend?: InputMaybe<BoolFilter>;
  scheduledSendAt?: InputMaybe<DateTimeNullableFilter>;
  sentAt?: InputMaybe<DateTimeNullableFilter>;
  sentTo?: InputMaybe<ClientListRelationFilter>;
  title?: InputMaybe<StringFilter>;
  workspace?: InputMaybe<WorkspaceRelationFilter>;
  workspaceId?: InputMaybe<StringFilter>;
};

export type NewsWrapWhereUniqueInput = {
  AND?: InputMaybe<Array<NewsWrapWhereInput>>;
  NOT?: InputMaybe<Array<NewsWrapWhereInput>>;
  OR?: InputMaybe<Array<NewsWrapWhereInput>>;
  content?: InputMaybe<NewsWrapContentListRelationFilter>;
  date?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  repeatScheduledSend?: InputMaybe<BoolFilter>;
  scheduledSendAt?: InputMaybe<DateTimeNullableFilter>;
  sentAt?: InputMaybe<DateTimeNullableFilter>;
  sentTo?: InputMaybe<ClientListRelationFilter>;
  title?: InputMaybe<StringFilter>;
  workspace?: InputMaybe<WorkspaceRelationFilter>;
  workspaceId?: InputMaybe<StringFilter>;
};

export type Notification = {
  __typename?: 'Notification';
  body: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  link: Scalars['String']['output'];
  read: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export type NotificationCreateManyUserInput = {
  body: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  link: Scalars['String']['input'];
  read?: InputMaybe<Scalars['Boolean']['input']>;
  title: Scalars['String']['input'];
};

export type NotificationCreateManyUserInputEnvelope = {
  data: Array<NotificationCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NotificationCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NotificationCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<NotificationCreateWithoutUserInput>>;
  createMany?: InputMaybe<NotificationCreateManyUserInputEnvelope>;
};

export type NotificationCreateOrConnectWithoutUserInput = {
  create: NotificationCreateWithoutUserInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationCreateWithoutUserInput = {
  body: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  link: Scalars['String']['input'];
  read?: InputMaybe<Scalars['Boolean']['input']>;
  title: Scalars['String']['input'];
};

export type NotificationListRelationFilter = {
  every?: InputMaybe<NotificationWhereInput>;
  none?: InputMaybe<NotificationWhereInput>;
  some?: InputMaybe<NotificationWhereInput>;
};

export type NotificationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type NotificationScalarWhereInput = {
  AND?: InputMaybe<Array<NotificationScalarWhereInput>>;
  NOT?: InputMaybe<Array<NotificationScalarWhereInput>>;
  OR?: InputMaybe<Array<NotificationScalarWhereInput>>;
  body?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  imageUrl?: InputMaybe<StringNullableFilter>;
  link?: InputMaybe<StringFilter>;
  read?: InputMaybe<BoolFilter>;
  title?: InputMaybe<StringFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type NotificationUpdateManyMutationInput = {
  body?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  link?: InputMaybe<StringFieldUpdateOperationsInput>;
  read?: InputMaybe<BoolFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type NotificationUpdateManyWithWhereWithoutUserInput = {
  data: NotificationUpdateManyMutationInput;
  where: NotificationScalarWhereInput;
};

export type NotificationUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NotificationCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<NotificationCreateWithoutUserInput>>;
  createMany?: InputMaybe<NotificationCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<NotificationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  set?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  update?: InputMaybe<Array<NotificationUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<NotificationUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<NotificationUpsertWithWhereUniqueWithoutUserInput>>;
};

export type NotificationUpdateWithWhereUniqueWithoutUserInput = {
  data: NotificationUpdateWithoutUserInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationUpdateWithoutUserInput = {
  body?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  link?: InputMaybe<StringFieldUpdateOperationsInput>;
  read?: InputMaybe<BoolFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type NotificationUpsertWithWhereUniqueWithoutUserInput = {
  create: NotificationCreateWithoutUserInput;
  update: NotificationUpdateWithoutUserInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationWhereInput = {
  AND?: InputMaybe<Array<NotificationWhereInput>>;
  NOT?: InputMaybe<Array<NotificationWhereInput>>;
  OR?: InputMaybe<Array<NotificationWhereInput>>;
  body?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  imageUrl?: InputMaybe<StringNullableFilter>;
  link?: InputMaybe<StringFilter>;
  read?: InputMaybe<BoolFilter>;
  title?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type NotificationWhereUniqueInput = {
  AND?: InputMaybe<Array<NotificationWhereInput>>;
  NOT?: InputMaybe<Array<NotificationWhereInput>>;
  OR?: InputMaybe<Array<NotificationWhereInput>>;
  body?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<StringNullableFilter>;
  link?: InputMaybe<StringFilter>;
  read?: InputMaybe<BoolFilter>;
  title?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']['input']>;
};

export type NullableIntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']['input']>;
  divide?: InputMaybe<Scalars['Int']['input']>;
  increment?: InputMaybe<Scalars['Int']['input']>;
  multiply?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']['input']>;
};

export enum NullsOrder {
  First = 'first',
  Last = 'last'
}

export type OnlineArticle = {
  __typename?: 'OnlineArticle';
  author: Scalars['String']['output'];
  authors: Array<Author>;
  clientMentions: Array<ClientMention>;
  clients: Array<Client>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  publication: Publication;
  publicationId: Scalars['String']['output'];
  screenshot?: Maybe<Scalars['String']['output']>;
  syndicatedFromId?: Maybe<Scalars['String']['output']>;
  synthExtract: Scalars['String']['output'];
  tags: Array<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};


export type OnlineArticleAuthorsArgs = {
  cursor?: InputMaybe<AuthorWhereUniqueInput>;
  distinct?: InputMaybe<Array<AuthorScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AuthorOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AuthorWhereInput>;
};


export type OnlineArticleClientMentionsArgs = {
  cursor?: InputMaybe<ClientMentionWhereUniqueInput>;
  distinct?: InputMaybe<Array<ClientMentionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ClientMentionOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ClientMentionWhereInput>;
};

export type OnlineArticleCreateInput = {
  article: ArticleCreateNestedOneWithoutOnlineArticleInput;
  author: Scalars['String']['input'];
  createdAt: Scalars['DateTime']['input'];
  publication: PublicationCreateNestedOneWithoutOnlineArticlesInput;
  screenshot?: InputMaybe<Scalars['String']['input']>;
  syndicatedFrom?: InputMaybe<PublicationCreateNestedOneWithoutSyndicatedArticlesInput>;
  synthExtract: Scalars['String']['input'];
  tags?: InputMaybe<OnlineArticleCreatetagsInput>;
  text?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type OnlineArticleCreateManyPublicationInput = {
  author: Scalars['String']['input'];
  createdAt: Scalars['DateTime']['input'];
  id: Scalars['String']['input'];
  screenshot?: InputMaybe<Scalars['String']['input']>;
  syndicatedFromId?: InputMaybe<Scalars['String']['input']>;
  synthExtract: Scalars['String']['input'];
  tags?: InputMaybe<OnlineArticleCreatetagsInput>;
  text?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type OnlineArticleCreateManyPublicationInputEnvelope = {
  data: Array<OnlineArticleCreateManyPublicationInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OnlineArticleCreateManySyndicatedFromInput = {
  author: Scalars['String']['input'];
  createdAt: Scalars['DateTime']['input'];
  id: Scalars['String']['input'];
  publicationId: Scalars['String']['input'];
  screenshot?: InputMaybe<Scalars['String']['input']>;
  synthExtract: Scalars['String']['input'];
  tags?: InputMaybe<OnlineArticleCreatetagsInput>;
  text?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type OnlineArticleCreateManySyndicatedFromInputEnvelope = {
  data: Array<OnlineArticleCreateManySyndicatedFromInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OnlineArticleCreateNestedManyWithoutPublicationInput = {
  connect?: InputMaybe<Array<OnlineArticleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OnlineArticleCreateOrConnectWithoutPublicationInput>>;
  create?: InputMaybe<Array<OnlineArticleCreateWithoutPublicationInput>>;
  createMany?: InputMaybe<OnlineArticleCreateManyPublicationInputEnvelope>;
};

export type OnlineArticleCreateNestedManyWithoutSyndicatedFromInput = {
  connect?: InputMaybe<Array<OnlineArticleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OnlineArticleCreateOrConnectWithoutSyndicatedFromInput>>;
  create?: InputMaybe<Array<OnlineArticleCreateWithoutSyndicatedFromInput>>;
  createMany?: InputMaybe<OnlineArticleCreateManySyndicatedFromInputEnvelope>;
};

export type OnlineArticleCreateNestedOneWithoutArticleInput = {
  connect?: InputMaybe<OnlineArticleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OnlineArticleCreateOrConnectWithoutArticleInput>;
  create?: InputMaybe<OnlineArticleCreateWithoutArticleInput>;
};

export type OnlineArticleCreateOrConnectWithoutArticleInput = {
  create: OnlineArticleCreateWithoutArticleInput;
  where: OnlineArticleWhereUniqueInput;
};

export type OnlineArticleCreateOrConnectWithoutPublicationInput = {
  create: OnlineArticleCreateWithoutPublicationInput;
  where: OnlineArticleWhereUniqueInput;
};

export type OnlineArticleCreateOrConnectWithoutSyndicatedFromInput = {
  create: OnlineArticleCreateWithoutSyndicatedFromInput;
  where: OnlineArticleWhereUniqueInput;
};

export type OnlineArticleCreateWithoutArticleInput = {
  author: Scalars['String']['input'];
  createdAt: Scalars['DateTime']['input'];
  publication: PublicationCreateNestedOneWithoutOnlineArticlesInput;
  screenshot?: InputMaybe<Scalars['String']['input']>;
  syndicatedFrom?: InputMaybe<PublicationCreateNestedOneWithoutSyndicatedArticlesInput>;
  synthExtract: Scalars['String']['input'];
  tags?: InputMaybe<OnlineArticleCreatetagsInput>;
  text?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type OnlineArticleCreateWithoutPublicationInput = {
  article: ArticleCreateNestedOneWithoutOnlineArticleInput;
  author: Scalars['String']['input'];
  createdAt: Scalars['DateTime']['input'];
  screenshot?: InputMaybe<Scalars['String']['input']>;
  syndicatedFrom?: InputMaybe<PublicationCreateNestedOneWithoutSyndicatedArticlesInput>;
  synthExtract: Scalars['String']['input'];
  tags?: InputMaybe<OnlineArticleCreatetagsInput>;
  text?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type OnlineArticleCreateWithoutSyndicatedFromInput = {
  article: ArticleCreateNestedOneWithoutOnlineArticleInput;
  author: Scalars['String']['input'];
  createdAt: Scalars['DateTime']['input'];
  publication: PublicationCreateNestedOneWithoutOnlineArticlesInput;
  screenshot?: InputMaybe<Scalars['String']['input']>;
  synthExtract: Scalars['String']['input'];
  tags?: InputMaybe<OnlineArticleCreatetagsInput>;
  text?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type OnlineArticleCreatetagsInput = {
  set: Array<Scalars['String']['input']>;
};

export type OnlineArticleListRelationFilter = {
  every?: InputMaybe<OnlineArticleWhereInput>;
  none?: InputMaybe<OnlineArticleWhereInput>;
  some?: InputMaybe<OnlineArticleWhereInput>;
};

export type OnlineArticleNullableRelationFilter = {
  is?: InputMaybe<OnlineArticleWhereInput>;
  isNot?: InputMaybe<OnlineArticleWhereInput>;
};

export type OnlineArticleOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum OnlineArticleOrderByRelevanceFieldEnum {
  Author = 'author',
  Id = 'id',
  PublicationId = 'publicationId',
  Screenshot = 'screenshot',
  SyndicatedFromId = 'syndicatedFromId',
  SynthExtract = 'synthExtract',
  Tags = 'tags',
  Text = 'text',
  Title = 'title',
  Url = 'url'
}

export type OnlineArticleOrderByRelevanceInput = {
  fields: Array<OnlineArticleOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type OnlineArticleOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<OnlineArticleOrderByRelevanceInput>;
  article?: InputMaybe<ArticleOrderByWithRelationAndSearchRelevanceInput>;
  author?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  publication?: InputMaybe<PublicationOrderByWithRelationAndSearchRelevanceInput>;
  publicationId?: InputMaybe<SortOrder>;
  screenshot?: InputMaybe<SortOrderInput>;
  syndicatedFrom?: InputMaybe<PublicationOrderByWithRelationAndSearchRelevanceInput>;
  syndicatedFromId?: InputMaybe<SortOrderInput>;
  synthExtract?: InputMaybe<SortOrder>;
  tags?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type OnlineArticleScalarWhereInput = {
  AND?: InputMaybe<Array<OnlineArticleScalarWhereInput>>;
  NOT?: InputMaybe<Array<OnlineArticleScalarWhereInput>>;
  OR?: InputMaybe<Array<OnlineArticleScalarWhereInput>>;
  author?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  publicationId?: InputMaybe<StringFilter>;
  screenshot?: InputMaybe<StringNullableFilter>;
  syndicatedFromId?: InputMaybe<StringNullableFilter>;
  synthExtract?: InputMaybe<StringFilter>;
  tags?: InputMaybe<StringNullableListFilter>;
  text?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  url?: InputMaybe<StringFilter>;
};

export type OnlineArticleUpdateManyMutationInput = {
  author?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  screenshot?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  synthExtract?: InputMaybe<StringFieldUpdateOperationsInput>;
  tags?: InputMaybe<OnlineArticleUpdatetagsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type OnlineArticleUpdateManyWithWhereWithoutPublicationInput = {
  data: OnlineArticleUpdateManyMutationInput;
  where: OnlineArticleScalarWhereInput;
};

export type OnlineArticleUpdateManyWithWhereWithoutSyndicatedFromInput = {
  data: OnlineArticleUpdateManyMutationInput;
  where: OnlineArticleScalarWhereInput;
};

export type OnlineArticleUpdateManyWithoutPublicationNestedInput = {
  connect?: InputMaybe<Array<OnlineArticleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OnlineArticleCreateOrConnectWithoutPublicationInput>>;
  create?: InputMaybe<Array<OnlineArticleCreateWithoutPublicationInput>>;
  createMany?: InputMaybe<OnlineArticleCreateManyPublicationInputEnvelope>;
  delete?: InputMaybe<Array<OnlineArticleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OnlineArticleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OnlineArticleWhereUniqueInput>>;
  set?: InputMaybe<Array<OnlineArticleWhereUniqueInput>>;
  update?: InputMaybe<Array<OnlineArticleUpdateWithWhereUniqueWithoutPublicationInput>>;
  updateMany?: InputMaybe<Array<OnlineArticleUpdateManyWithWhereWithoutPublicationInput>>;
  upsert?: InputMaybe<Array<OnlineArticleUpsertWithWhereUniqueWithoutPublicationInput>>;
};

export type OnlineArticleUpdateManyWithoutSyndicatedFromNestedInput = {
  connect?: InputMaybe<Array<OnlineArticleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OnlineArticleCreateOrConnectWithoutSyndicatedFromInput>>;
  create?: InputMaybe<Array<OnlineArticleCreateWithoutSyndicatedFromInput>>;
  createMany?: InputMaybe<OnlineArticleCreateManySyndicatedFromInputEnvelope>;
  delete?: InputMaybe<Array<OnlineArticleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OnlineArticleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OnlineArticleWhereUniqueInput>>;
  set?: InputMaybe<Array<OnlineArticleWhereUniqueInput>>;
  update?: InputMaybe<Array<OnlineArticleUpdateWithWhereUniqueWithoutSyndicatedFromInput>>;
  updateMany?: InputMaybe<Array<OnlineArticleUpdateManyWithWhereWithoutSyndicatedFromInput>>;
  upsert?: InputMaybe<Array<OnlineArticleUpsertWithWhereUniqueWithoutSyndicatedFromInput>>;
};

export type OnlineArticleUpdateOneWithoutArticleNestedInput = {
  connect?: InputMaybe<OnlineArticleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OnlineArticleCreateOrConnectWithoutArticleInput>;
  create?: InputMaybe<OnlineArticleCreateWithoutArticleInput>;
  delete?: InputMaybe<OnlineArticleWhereInput>;
  disconnect?: InputMaybe<OnlineArticleWhereInput>;
  update?: InputMaybe<OnlineArticleUpdateToOneWithWhereWithoutArticleInput>;
  upsert?: InputMaybe<OnlineArticleUpsertWithoutArticleInput>;
};

export type OnlineArticleUpdateToOneWithWhereWithoutArticleInput = {
  data: OnlineArticleUpdateWithoutArticleInput;
  where?: InputMaybe<OnlineArticleWhereInput>;
};

export type OnlineArticleUpdateWithWhereUniqueWithoutPublicationInput = {
  data: OnlineArticleUpdateWithoutPublicationInput;
  where: OnlineArticleWhereUniqueInput;
};

export type OnlineArticleUpdateWithWhereUniqueWithoutSyndicatedFromInput = {
  data: OnlineArticleUpdateWithoutSyndicatedFromInput;
  where: OnlineArticleWhereUniqueInput;
};

export type OnlineArticleUpdateWithoutArticleInput = {
  author?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  publication?: InputMaybe<PublicationUpdateOneRequiredWithoutOnlineArticlesNestedInput>;
  screenshot?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  syndicatedFrom?: InputMaybe<PublicationUpdateOneWithoutSyndicatedArticlesNestedInput>;
  synthExtract?: InputMaybe<StringFieldUpdateOperationsInput>;
  tags?: InputMaybe<OnlineArticleUpdatetagsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type OnlineArticleUpdateWithoutPublicationInput = {
  article?: InputMaybe<ArticleUpdateOneRequiredWithoutOnlineArticleNestedInput>;
  author?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  screenshot?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  syndicatedFrom?: InputMaybe<PublicationUpdateOneWithoutSyndicatedArticlesNestedInput>;
  synthExtract?: InputMaybe<StringFieldUpdateOperationsInput>;
  tags?: InputMaybe<OnlineArticleUpdatetagsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type OnlineArticleUpdateWithoutSyndicatedFromInput = {
  article?: InputMaybe<ArticleUpdateOneRequiredWithoutOnlineArticleNestedInput>;
  author?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  publication?: InputMaybe<PublicationUpdateOneRequiredWithoutOnlineArticlesNestedInput>;
  screenshot?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  synthExtract?: InputMaybe<StringFieldUpdateOperationsInput>;
  tags?: InputMaybe<OnlineArticleUpdatetagsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type OnlineArticleUpdatetagsInput = {
  push?: InputMaybe<Array<Scalars['String']['input']>>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OnlineArticleUpsertWithWhereUniqueWithoutPublicationInput = {
  create: OnlineArticleCreateWithoutPublicationInput;
  update: OnlineArticleUpdateWithoutPublicationInput;
  where: OnlineArticleWhereUniqueInput;
};

export type OnlineArticleUpsertWithWhereUniqueWithoutSyndicatedFromInput = {
  create: OnlineArticleCreateWithoutSyndicatedFromInput;
  update: OnlineArticleUpdateWithoutSyndicatedFromInput;
  where: OnlineArticleWhereUniqueInput;
};

export type OnlineArticleUpsertWithoutArticleInput = {
  create: OnlineArticleCreateWithoutArticleInput;
  update: OnlineArticleUpdateWithoutArticleInput;
  where?: InputMaybe<OnlineArticleWhereInput>;
};

export type OnlineArticleWhereInput = {
  AND?: InputMaybe<Array<OnlineArticleWhereInput>>;
  NOT?: InputMaybe<Array<OnlineArticleWhereInput>>;
  OR?: InputMaybe<Array<OnlineArticleWhereInput>>;
  article?: InputMaybe<ArticleRelationFilter>;
  author?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  publication?: InputMaybe<PublicationRelationFilter>;
  publicationId?: InputMaybe<StringFilter>;
  screenshot?: InputMaybe<StringNullableFilter>;
  syndicatedFrom?: InputMaybe<PublicationNullableRelationFilter>;
  syndicatedFromId?: InputMaybe<StringNullableFilter>;
  synthExtract?: InputMaybe<StringFilter>;
  tags?: InputMaybe<StringNullableListFilter>;
  text?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  url?: InputMaybe<StringFilter>;
};

export type OnlineArticleWhereUniqueInput = {
  AND?: InputMaybe<Array<OnlineArticleWhereInput>>;
  NOT?: InputMaybe<Array<OnlineArticleWhereInput>>;
  OR?: InputMaybe<Array<OnlineArticleWhereInput>>;
  article?: InputMaybe<ArticleRelationFilter>;
  author?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  publication?: InputMaybe<PublicationRelationFilter>;
  publicationId?: InputMaybe<StringFilter>;
  screenshot?: InputMaybe<StringNullableFilter>;
  syndicatedFrom?: InputMaybe<PublicationNullableRelationFilter>;
  syndicatedFromId?: InputMaybe<StringNullableFilter>;
  synthExtract?: InputMaybe<StringFilter>;
  tags?: InputMaybe<StringNullableListFilter>;
  text?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export enum PermissionEnum {
  CanAddClients = 'CAN_ADD_CLIENTS',
  CanAddCoverage = 'CAN_ADD_COVERAGE',
  CanEditClients = 'CAN_EDIT_CLIENTS',
  CanEditUsers = 'CAN_EDIT_USERS',
  CanEditWorkspace = 'CAN_EDIT_WORKSPACE',
  CanInviteAgencyAdmin = 'CAN_INVITE_AGENCY_ADMIN',
  CanInviteAgencyEmployee = 'CAN_INVITE_AGENCY_EMPLOYEE',
  CanInviteClientCoworker = 'CAN_INVITE_CLIENT_COWORKER',
  CanInviteClientEmployee = 'CAN_INVITE_CLIENT_EMPLOYEE',
  CanPerformAllActions = 'CAN_PERFORM_ALL_ACTIONS',
  CanViewAgencyDashboard = 'CAN_VIEW_AGENCY_DASHBOARD',
  CanViewAllClients = 'CAN_VIEW_ALL_CLIENTS',
  CanViewAllUsers = 'CAN_VIEW_ALL_USERS',
  CanViewChatPage = 'CAN_VIEW_CHAT_PAGE',
  CanViewClientDashboard = 'CAN_VIEW_CLIENT_DASHBOARD',
  CanViewClientSentiment = 'CAN_VIEW_CLIENT_SENTIMENT',
  CanViewCompetitorAnalysis = 'CAN_VIEW_COMPETITOR_ANALYSIS',
  CanViewNewsWraps = 'CAN_VIEW_NEWS_WRAPS',
  CanViewPublications = 'CAN_VIEW_PUBLICATIONS',
  CanViewSettings = 'CAN_VIEW_SETTINGS',
  CanViewSyndicationLists = 'CAN_VIEW_SYNDICATION_LISTS'
}

export type PrintArticle = {
  __typename?: 'PrintArticle';
  author: Scalars['String']['output'];
  authors: Array<Author>;
  clientMentions: Array<ClientMention>;
  clients: Array<Client>;
  id: Scalars['String']['output'];
  page: Scalars['Int']['output'];
  printIssue: PrintIssue;
  printIssueId: Scalars['String']['output'];
  synthExtract: Scalars['String']['output'];
  tags: Array<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
};


export type PrintArticleAuthorsArgs = {
  cursor?: InputMaybe<AuthorWhereUniqueInput>;
  distinct?: InputMaybe<Array<AuthorScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AuthorOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AuthorWhereInput>;
};


export type PrintArticleClientMentionsArgs = {
  cursor?: InputMaybe<ClientMentionWhereUniqueInput>;
  distinct?: InputMaybe<Array<ClientMentionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ClientMentionOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ClientMentionWhereInput>;
};

export type PrintArticleCreateInput = {
  article: ArticleCreateNestedOneWithoutPrintArticleInput;
  author: Scalars['String']['input'];
  page: Scalars['Int']['input'];
  printIssue: PrintIssueCreateNestedOneWithoutArticlesInput;
  synthExtract: Scalars['String']['input'];
  tags?: InputMaybe<PrintArticleCreatetagsInput>;
  text: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type PrintArticleCreateManyPrintIssueInput = {
  author: Scalars['String']['input'];
  id: Scalars['String']['input'];
  page: Scalars['Int']['input'];
  synthExtract: Scalars['String']['input'];
  tags?: InputMaybe<PrintArticleCreatetagsInput>;
  text: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type PrintArticleCreateManyPrintIssueInputEnvelope = {
  data: Array<PrintArticleCreateManyPrintIssueInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PrintArticleCreateNestedManyWithoutPrintIssueInput = {
  connect?: InputMaybe<Array<PrintArticleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PrintArticleCreateOrConnectWithoutPrintIssueInput>>;
  create?: InputMaybe<Array<PrintArticleCreateWithoutPrintIssueInput>>;
  createMany?: InputMaybe<PrintArticleCreateManyPrintIssueInputEnvelope>;
};

export type PrintArticleCreateNestedOneWithoutArticleInput = {
  connect?: InputMaybe<PrintArticleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PrintArticleCreateOrConnectWithoutArticleInput>;
  create?: InputMaybe<PrintArticleCreateWithoutArticleInput>;
};

export type PrintArticleCreateOrConnectWithoutArticleInput = {
  create: PrintArticleCreateWithoutArticleInput;
  where: PrintArticleWhereUniqueInput;
};

export type PrintArticleCreateOrConnectWithoutPrintIssueInput = {
  create: PrintArticleCreateWithoutPrintIssueInput;
  where: PrintArticleWhereUniqueInput;
};

export type PrintArticleCreateWithoutArticleInput = {
  author: Scalars['String']['input'];
  page: Scalars['Int']['input'];
  printIssue: PrintIssueCreateNestedOneWithoutArticlesInput;
  synthExtract: Scalars['String']['input'];
  tags?: InputMaybe<PrintArticleCreatetagsInput>;
  text: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type PrintArticleCreateWithoutPrintIssueInput = {
  article: ArticleCreateNestedOneWithoutPrintArticleInput;
  author: Scalars['String']['input'];
  page: Scalars['Int']['input'];
  synthExtract: Scalars['String']['input'];
  tags?: InputMaybe<PrintArticleCreatetagsInput>;
  text: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type PrintArticleCreatetagsInput = {
  set: Array<Scalars['String']['input']>;
};

export type PrintArticleListRelationFilter = {
  every?: InputMaybe<PrintArticleWhereInput>;
  none?: InputMaybe<PrintArticleWhereInput>;
  some?: InputMaybe<PrintArticleWhereInput>;
};

export type PrintArticleNullableRelationFilter = {
  is?: InputMaybe<PrintArticleWhereInput>;
  isNot?: InputMaybe<PrintArticleWhereInput>;
};

export type PrintArticleOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum PrintArticleOrderByRelevanceFieldEnum {
  Author = 'author',
  Id = 'id',
  PrintIssueId = 'printIssueId',
  SynthExtract = 'synthExtract',
  Tags = 'tags',
  Text = 'text',
  Title = 'title'
}

export type PrintArticleOrderByRelevanceInput = {
  fields: Array<PrintArticleOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type PrintArticleOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<PrintArticleOrderByRelevanceInput>;
  article?: InputMaybe<ArticleOrderByWithRelationAndSearchRelevanceInput>;
  author?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  page?: InputMaybe<SortOrder>;
  printIssue?: InputMaybe<PrintIssueOrderByWithRelationAndSearchRelevanceInput>;
  printIssueId?: InputMaybe<SortOrder>;
  synthExtract?: InputMaybe<SortOrder>;
  tags?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export enum PrintArticleScalarFieldEnum {
  Author = 'author',
  Id = 'id',
  Page = 'page',
  PrintIssueId = 'printIssueId',
  SynthExtract = 'synthExtract',
  Tags = 'tags',
  Text = 'text',
  Title = 'title'
}

export type PrintArticleScalarWhereInput = {
  AND?: InputMaybe<Array<PrintArticleScalarWhereInput>>;
  NOT?: InputMaybe<Array<PrintArticleScalarWhereInput>>;
  OR?: InputMaybe<Array<PrintArticleScalarWhereInput>>;
  author?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  page?: InputMaybe<IntFilter>;
  printIssueId?: InputMaybe<StringFilter>;
  synthExtract?: InputMaybe<StringFilter>;
  tags?: InputMaybe<StringNullableListFilter>;
  text?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
};

export type PrintArticleUpdateManyMutationInput = {
  author?: InputMaybe<StringFieldUpdateOperationsInput>;
  page?: InputMaybe<IntFieldUpdateOperationsInput>;
  synthExtract?: InputMaybe<StringFieldUpdateOperationsInput>;
  tags?: InputMaybe<PrintArticleUpdatetagsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type PrintArticleUpdateManyWithWhereWithoutPrintIssueInput = {
  data: PrintArticleUpdateManyMutationInput;
  where: PrintArticleScalarWhereInput;
};

export type PrintArticleUpdateManyWithoutPrintIssueNestedInput = {
  connect?: InputMaybe<Array<PrintArticleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PrintArticleCreateOrConnectWithoutPrintIssueInput>>;
  create?: InputMaybe<Array<PrintArticleCreateWithoutPrintIssueInput>>;
  createMany?: InputMaybe<PrintArticleCreateManyPrintIssueInputEnvelope>;
  delete?: InputMaybe<Array<PrintArticleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PrintArticleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PrintArticleWhereUniqueInput>>;
  set?: InputMaybe<Array<PrintArticleWhereUniqueInput>>;
  update?: InputMaybe<Array<PrintArticleUpdateWithWhereUniqueWithoutPrintIssueInput>>;
  updateMany?: InputMaybe<Array<PrintArticleUpdateManyWithWhereWithoutPrintIssueInput>>;
  upsert?: InputMaybe<Array<PrintArticleUpsertWithWhereUniqueWithoutPrintIssueInput>>;
};

export type PrintArticleUpdateOneWithoutArticleNestedInput = {
  connect?: InputMaybe<PrintArticleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PrintArticleCreateOrConnectWithoutArticleInput>;
  create?: InputMaybe<PrintArticleCreateWithoutArticleInput>;
  delete?: InputMaybe<PrintArticleWhereInput>;
  disconnect?: InputMaybe<PrintArticleWhereInput>;
  update?: InputMaybe<PrintArticleUpdateToOneWithWhereWithoutArticleInput>;
  upsert?: InputMaybe<PrintArticleUpsertWithoutArticleInput>;
};

export type PrintArticleUpdateToOneWithWhereWithoutArticleInput = {
  data: PrintArticleUpdateWithoutArticleInput;
  where?: InputMaybe<PrintArticleWhereInput>;
};

export type PrintArticleUpdateWithWhereUniqueWithoutPrintIssueInput = {
  data: PrintArticleUpdateWithoutPrintIssueInput;
  where: PrintArticleWhereUniqueInput;
};

export type PrintArticleUpdateWithoutArticleInput = {
  author?: InputMaybe<StringFieldUpdateOperationsInput>;
  page?: InputMaybe<IntFieldUpdateOperationsInput>;
  printIssue?: InputMaybe<PrintIssueUpdateOneRequiredWithoutArticlesNestedInput>;
  synthExtract?: InputMaybe<StringFieldUpdateOperationsInput>;
  tags?: InputMaybe<PrintArticleUpdatetagsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type PrintArticleUpdateWithoutPrintIssueInput = {
  article?: InputMaybe<ArticleUpdateOneRequiredWithoutPrintArticleNestedInput>;
  author?: InputMaybe<StringFieldUpdateOperationsInput>;
  page?: InputMaybe<IntFieldUpdateOperationsInput>;
  synthExtract?: InputMaybe<StringFieldUpdateOperationsInput>;
  tags?: InputMaybe<PrintArticleUpdatetagsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type PrintArticleUpdatetagsInput = {
  push?: InputMaybe<Array<Scalars['String']['input']>>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type PrintArticleUpsertWithWhereUniqueWithoutPrintIssueInput = {
  create: PrintArticleCreateWithoutPrintIssueInput;
  update: PrintArticleUpdateWithoutPrintIssueInput;
  where: PrintArticleWhereUniqueInput;
};

export type PrintArticleUpsertWithoutArticleInput = {
  create: PrintArticleCreateWithoutArticleInput;
  update: PrintArticleUpdateWithoutArticleInput;
  where?: InputMaybe<PrintArticleWhereInput>;
};

export type PrintArticleWhereInput = {
  AND?: InputMaybe<Array<PrintArticleWhereInput>>;
  NOT?: InputMaybe<Array<PrintArticleWhereInput>>;
  OR?: InputMaybe<Array<PrintArticleWhereInput>>;
  article?: InputMaybe<ArticleRelationFilter>;
  author?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  page?: InputMaybe<IntFilter>;
  printIssue?: InputMaybe<PrintIssueRelationFilter>;
  printIssueId?: InputMaybe<StringFilter>;
  synthExtract?: InputMaybe<StringFilter>;
  tags?: InputMaybe<StringNullableListFilter>;
  text?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
};

export type PrintArticleWhereUniqueInput = {
  AND?: InputMaybe<Array<PrintArticleWhereInput>>;
  NOT?: InputMaybe<Array<PrintArticleWhereInput>>;
  OR?: InputMaybe<Array<PrintArticleWhereInput>>;
  article?: InputMaybe<ArticleRelationFilter>;
  author?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<IntFilter>;
  printIssue?: InputMaybe<PrintIssueRelationFilter>;
  printIssueId?: InputMaybe<StringFilter>;
  synthExtract?: InputMaybe<StringFilter>;
  tags?: InputMaybe<StringNullableListFilter>;
  text?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
};

export type PrintIssue = {
  __typename?: 'PrintIssue';
  _count?: Maybe<PrintIssueCount>;
  articles: Array<PrintArticle>;
  id: Scalars['String']['output'];
  issueDate: Scalars['DateTime']['output'];
  publication: Publication;
  publicationId: Scalars['String']['output'];
};


export type PrintIssueArticlesArgs = {
  cursor?: InputMaybe<PrintArticleWhereUniqueInput>;
  distinct?: InputMaybe<Array<PrintArticleScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PrintArticleOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PrintArticleWhereInput>;
};

export type PrintIssueCount = {
  __typename?: 'PrintIssueCount';
  articles: Scalars['Int']['output'];
};


export type PrintIssueCountArticlesArgs = {
  where?: InputMaybe<PrintArticleWhereInput>;
};

export type PrintIssueCreateManyPublicationInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  issueDate: Scalars['DateTime']['input'];
};

export type PrintIssueCreateManyPublicationInputEnvelope = {
  data: Array<PrintIssueCreateManyPublicationInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PrintIssueCreateNestedManyWithoutPublicationInput = {
  connect?: InputMaybe<Array<PrintIssueWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PrintIssueCreateOrConnectWithoutPublicationInput>>;
  create?: InputMaybe<Array<PrintIssueCreateWithoutPublicationInput>>;
  createMany?: InputMaybe<PrintIssueCreateManyPublicationInputEnvelope>;
};

export type PrintIssueCreateNestedOneWithoutArticlesInput = {
  connect?: InputMaybe<PrintIssueWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PrintIssueCreateOrConnectWithoutArticlesInput>;
  create?: InputMaybe<PrintIssueCreateWithoutArticlesInput>;
};

export type PrintIssueCreateOrConnectWithoutArticlesInput = {
  create: PrintIssueCreateWithoutArticlesInput;
  where: PrintIssueWhereUniqueInput;
};

export type PrintIssueCreateOrConnectWithoutPublicationInput = {
  create: PrintIssueCreateWithoutPublicationInput;
  where: PrintIssueWhereUniqueInput;
};

export type PrintIssueCreateWithoutArticlesInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  issueDate: Scalars['DateTime']['input'];
  publication: PublicationCreateNestedOneWithoutPrintIssuesInput;
};

export type PrintIssueCreateWithoutPublicationInput = {
  articles?: InputMaybe<PrintArticleCreateNestedManyWithoutPrintIssueInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  issueDate: Scalars['DateTime']['input'];
};

export type PrintIssueListRelationFilter = {
  every?: InputMaybe<PrintIssueWhereInput>;
  none?: InputMaybe<PrintIssueWhereInput>;
  some?: InputMaybe<PrintIssueWhereInput>;
};

export type PrintIssueOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum PrintIssueOrderByRelevanceFieldEnum {
  Id = 'id',
  PublicationId = 'publicationId'
}

export type PrintIssueOrderByRelevanceInput = {
  fields: Array<PrintIssueOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type PrintIssueOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<PrintIssueOrderByRelevanceInput>;
  articles?: InputMaybe<PrintArticleOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  issueDate?: InputMaybe<SortOrder>;
  publication?: InputMaybe<PublicationOrderByWithRelationAndSearchRelevanceInput>;
  publicationId?: InputMaybe<SortOrder>;
};

export type PrintIssuePublicationIdIssueDateCompoundUniqueInput = {
  issueDate: Scalars['DateTime']['input'];
  publicationId: Scalars['String']['input'];
};

export type PrintIssueRelationFilter = {
  is?: InputMaybe<PrintIssueWhereInput>;
  isNot?: InputMaybe<PrintIssueWhereInput>;
};

export type PrintIssueScalarWhereInput = {
  AND?: InputMaybe<Array<PrintIssueScalarWhereInput>>;
  NOT?: InputMaybe<Array<PrintIssueScalarWhereInput>>;
  OR?: InputMaybe<Array<PrintIssueScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  issueDate?: InputMaybe<DateTimeFilter>;
  publicationId?: InputMaybe<StringFilter>;
};

export type PrintIssueUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  issueDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PrintIssueUpdateManyWithWhereWithoutPublicationInput = {
  data: PrintIssueUpdateManyMutationInput;
  where: PrintIssueScalarWhereInput;
};

export type PrintIssueUpdateManyWithoutPublicationNestedInput = {
  connect?: InputMaybe<Array<PrintIssueWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PrintIssueCreateOrConnectWithoutPublicationInput>>;
  create?: InputMaybe<Array<PrintIssueCreateWithoutPublicationInput>>;
  createMany?: InputMaybe<PrintIssueCreateManyPublicationInputEnvelope>;
  delete?: InputMaybe<Array<PrintIssueWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PrintIssueScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PrintIssueWhereUniqueInput>>;
  set?: InputMaybe<Array<PrintIssueWhereUniqueInput>>;
  update?: InputMaybe<Array<PrintIssueUpdateWithWhereUniqueWithoutPublicationInput>>;
  updateMany?: InputMaybe<Array<PrintIssueUpdateManyWithWhereWithoutPublicationInput>>;
  upsert?: InputMaybe<Array<PrintIssueUpsertWithWhereUniqueWithoutPublicationInput>>;
};

export type PrintIssueUpdateOneRequiredWithoutArticlesNestedInput = {
  connect?: InputMaybe<PrintIssueWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PrintIssueCreateOrConnectWithoutArticlesInput>;
  create?: InputMaybe<PrintIssueCreateWithoutArticlesInput>;
  update?: InputMaybe<PrintIssueUpdateToOneWithWhereWithoutArticlesInput>;
  upsert?: InputMaybe<PrintIssueUpsertWithoutArticlesInput>;
};

export type PrintIssueUpdateToOneWithWhereWithoutArticlesInput = {
  data: PrintIssueUpdateWithoutArticlesInput;
  where?: InputMaybe<PrintIssueWhereInput>;
};

export type PrintIssueUpdateWithWhereUniqueWithoutPublicationInput = {
  data: PrintIssueUpdateWithoutPublicationInput;
  where: PrintIssueWhereUniqueInput;
};

export type PrintIssueUpdateWithoutArticlesInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  issueDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  publication?: InputMaybe<PublicationUpdateOneRequiredWithoutPrintIssuesNestedInput>;
};

export type PrintIssueUpdateWithoutPublicationInput = {
  articles?: InputMaybe<PrintArticleUpdateManyWithoutPrintIssueNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  issueDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PrintIssueUpsertWithWhereUniqueWithoutPublicationInput = {
  create: PrintIssueCreateWithoutPublicationInput;
  update: PrintIssueUpdateWithoutPublicationInput;
  where: PrintIssueWhereUniqueInput;
};

export type PrintIssueUpsertWithoutArticlesInput = {
  create: PrintIssueCreateWithoutArticlesInput;
  update: PrintIssueUpdateWithoutArticlesInput;
  where?: InputMaybe<PrintIssueWhereInput>;
};

export type PrintIssueWhereInput = {
  AND?: InputMaybe<Array<PrintIssueWhereInput>>;
  NOT?: InputMaybe<Array<PrintIssueWhereInput>>;
  OR?: InputMaybe<Array<PrintIssueWhereInput>>;
  articles?: InputMaybe<PrintArticleListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  issueDate?: InputMaybe<DateTimeFilter>;
  publication?: InputMaybe<PublicationRelationFilter>;
  publicationId?: InputMaybe<StringFilter>;
};

export type PrintIssueWhereUniqueInput = {
  AND?: InputMaybe<Array<PrintIssueWhereInput>>;
  NOT?: InputMaybe<Array<PrintIssueWhereInput>>;
  OR?: InputMaybe<Array<PrintIssueWhereInput>>;
  articles?: InputMaybe<PrintArticleListRelationFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  issueDate?: InputMaybe<DateTimeFilter>;
  publication?: InputMaybe<PublicationRelationFilter>;
  publicationId?: InputMaybe<StringFilter>;
  publicationId_issueDate?: InputMaybe<PrintIssuePublicationIdIssueDateCompoundUniqueInput>;
};

export enum PrintScraperMethod {
  Fairfax = 'FAIRFAX',
  NewsCorp = 'NEWS_CORP',
  SevenWest = 'SEVEN_WEST'
}

export type PrintScraperSettingsCreateNestedOneWithoutPublicationInput = {
  connect?: InputMaybe<PrintScraperSettingsWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PrintScraperSettingsCreateOrConnectWithoutPublicationInput>;
  create?: InputMaybe<PrintScraperSettingsCreateWithoutPublicationInput>;
};

export type PrintScraperSettingsCreateOrConnectWithoutPublicationInput = {
  create: PrintScraperSettingsCreateWithoutPublicationInput;
  where: PrintScraperSettingsWhereUniqueInput;
};

export type PrintScraperSettingsCreateWithoutPublicationInput = {
  apiUrl: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  method: PrintScraperMethod;
  newsCorpPublicationGuid?: InputMaybe<Scalars['String']['input']>;
};

export type PrintScraperSettingsNullableRelationFilter = {
  is?: InputMaybe<PrintScraperSettingsWhereInput>;
  isNot?: InputMaybe<PrintScraperSettingsWhereInput>;
};

export enum PrintScraperSettingsOrderByRelevanceFieldEnum {
  ApiUrl = 'apiUrl',
  Id = 'id',
  NewsCorpPublicationGuid = 'newsCorpPublicationGuid',
  PublicationId = 'publicationId'
}

export type PrintScraperSettingsOrderByRelevanceInput = {
  fields: Array<PrintScraperSettingsOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type PrintScraperSettingsOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<PrintScraperSettingsOrderByRelevanceInput>;
  apiUrl?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  method?: InputMaybe<SortOrder>;
  newsCorpPublicationGuid?: InputMaybe<SortOrderInput>;
  publication?: InputMaybe<PublicationOrderByWithRelationAndSearchRelevanceInput>;
  publicationId?: InputMaybe<SortOrder>;
};

export type PrintScraperSettingsUpdateOneWithoutPublicationNestedInput = {
  connect?: InputMaybe<PrintScraperSettingsWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PrintScraperSettingsCreateOrConnectWithoutPublicationInput>;
  create?: InputMaybe<PrintScraperSettingsCreateWithoutPublicationInput>;
  delete?: InputMaybe<PrintScraperSettingsWhereInput>;
  disconnect?: InputMaybe<PrintScraperSettingsWhereInput>;
  update?: InputMaybe<PrintScraperSettingsUpdateToOneWithWhereWithoutPublicationInput>;
  upsert?: InputMaybe<PrintScraperSettingsUpsertWithoutPublicationInput>;
};

export type PrintScraperSettingsUpdateToOneWithWhereWithoutPublicationInput = {
  data: PrintScraperSettingsUpdateWithoutPublicationInput;
  where?: InputMaybe<PrintScraperSettingsWhereInput>;
};

export type PrintScraperSettingsUpdateWithoutPublicationInput = {
  apiUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  method?: InputMaybe<EnumPrintScraperMethodFieldUpdateOperationsInput>;
  newsCorpPublicationGuid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type PrintScraperSettingsUpsertWithoutPublicationInput = {
  create: PrintScraperSettingsCreateWithoutPublicationInput;
  update: PrintScraperSettingsUpdateWithoutPublicationInput;
  where?: InputMaybe<PrintScraperSettingsWhereInput>;
};

export type PrintScraperSettingsWhereInput = {
  AND?: InputMaybe<Array<PrintScraperSettingsWhereInput>>;
  NOT?: InputMaybe<Array<PrintScraperSettingsWhereInput>>;
  OR?: InputMaybe<Array<PrintScraperSettingsWhereInput>>;
  apiUrl?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  method?: InputMaybe<EnumPrintScraperMethodFilter>;
  newsCorpPublicationGuid?: InputMaybe<StringNullableFilter>;
  publication?: InputMaybe<PublicationRelationFilter>;
  publicationId?: InputMaybe<StringFilter>;
};

export type PrintScraperSettingsWhereUniqueInput = {
  AND?: InputMaybe<Array<PrintScraperSettingsWhereInput>>;
  NOT?: InputMaybe<Array<PrintScraperSettingsWhereInput>>;
  OR?: InputMaybe<Array<PrintScraperSettingsWhereInput>>;
  apiUrl?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  method?: InputMaybe<EnumPrintScraperMethodFilter>;
  newsCorpPublicationGuid?: InputMaybe<StringNullableFilter>;
  publication?: InputMaybe<PublicationRelationFilter>;
  publicationId?: InputMaybe<Scalars['String']['input']>;
};

export type ProgramPlannerActivity = {
  __typename?: 'ProgramPlannerActivity';
  activity: Activity;
  activityId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  programPlannerItem: ProgramPlannerItem;
  programPlannerItemId: Scalars['String']['output'];
  value: Scalars['Int']['output'];
};

export type ProgramPlannerActivityCreateInput = {
  activity: ActivityCreateNestedOneWithoutProgramPlannerItemInput;
  id?: InputMaybe<Scalars['String']['input']>;
  programPlannerItem: ProgramPlannerItemCreateNestedOneWithoutActivitiesInput;
  value?: InputMaybe<Scalars['Int']['input']>;
};

export type ProgramPlannerActivityCreateManyProgramPlannerItemInput = {
  activityId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

export type ProgramPlannerActivityCreateManyProgramPlannerItemInputEnvelope = {
  data: Array<ProgramPlannerActivityCreateManyProgramPlannerItemInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProgramPlannerActivityCreateNestedManyWithoutProgramPlannerItemInput = {
  connect?: InputMaybe<Array<ProgramPlannerActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProgramPlannerActivityCreateOrConnectWithoutProgramPlannerItemInput>>;
  create?: InputMaybe<Array<ProgramPlannerActivityCreateWithoutProgramPlannerItemInput>>;
  createMany?: InputMaybe<ProgramPlannerActivityCreateManyProgramPlannerItemInputEnvelope>;
};

export type ProgramPlannerActivityCreateNestedOneWithoutActivityInput = {
  connect?: InputMaybe<ProgramPlannerActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProgramPlannerActivityCreateOrConnectWithoutActivityInput>;
  create?: InputMaybe<ProgramPlannerActivityCreateWithoutActivityInput>;
};

export type ProgramPlannerActivityCreateOrConnectWithoutActivityInput = {
  create: ProgramPlannerActivityCreateWithoutActivityInput;
  where: ProgramPlannerActivityWhereUniqueInput;
};

export type ProgramPlannerActivityCreateOrConnectWithoutProgramPlannerItemInput = {
  create: ProgramPlannerActivityCreateWithoutProgramPlannerItemInput;
  where: ProgramPlannerActivityWhereUniqueInput;
};

export type ProgramPlannerActivityCreateWithoutActivityInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  programPlannerItem: ProgramPlannerItemCreateNestedOneWithoutActivitiesInput;
  value?: InputMaybe<Scalars['Int']['input']>;
};

export type ProgramPlannerActivityCreateWithoutProgramPlannerItemInput = {
  activity: ActivityCreateNestedOneWithoutProgramPlannerItemInput;
  id?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

export type ProgramPlannerActivityListRelationFilter = {
  every?: InputMaybe<ProgramPlannerActivityWhereInput>;
  none?: InputMaybe<ProgramPlannerActivityWhereInput>;
  some?: InputMaybe<ProgramPlannerActivityWhereInput>;
};

export type ProgramPlannerActivityNullableRelationFilter = {
  is?: InputMaybe<ProgramPlannerActivityWhereInput>;
  isNot?: InputMaybe<ProgramPlannerActivityWhereInput>;
};

export type ProgramPlannerActivityOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum ProgramPlannerActivityOrderByRelevanceFieldEnum {
  ActivityId = 'activityId',
  Id = 'id',
  ProgramPlannerItemId = 'programPlannerItemId'
}

export type ProgramPlannerActivityOrderByRelevanceInput = {
  fields: Array<ProgramPlannerActivityOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type ProgramPlannerActivityOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<ProgramPlannerActivityOrderByRelevanceInput>;
  activity?: InputMaybe<ActivityOrderByWithRelationAndSearchRelevanceInput>;
  activityId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  programPlannerItem?: InputMaybe<ProgramPlannerItemOrderByWithRelationAndSearchRelevanceInput>;
  programPlannerItemId?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export enum ProgramPlannerActivityScalarFieldEnum {
  ActivityId = 'activityId',
  Id = 'id',
  ProgramPlannerItemId = 'programPlannerItemId',
  Value = 'value'
}

export type ProgramPlannerActivityScalarWhereInput = {
  AND?: InputMaybe<Array<ProgramPlannerActivityScalarWhereInput>>;
  NOT?: InputMaybe<Array<ProgramPlannerActivityScalarWhereInput>>;
  OR?: InputMaybe<Array<ProgramPlannerActivityScalarWhereInput>>;
  activityId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  programPlannerItemId?: InputMaybe<StringFilter>;
  value?: InputMaybe<IntFilter>;
};

export type ProgramPlannerActivityUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  value?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ProgramPlannerActivityUpdateManyWithWhereWithoutProgramPlannerItemInput = {
  data: ProgramPlannerActivityUpdateManyMutationInput;
  where: ProgramPlannerActivityScalarWhereInput;
};

export type ProgramPlannerActivityUpdateManyWithoutProgramPlannerItemNestedInput = {
  connect?: InputMaybe<Array<ProgramPlannerActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProgramPlannerActivityCreateOrConnectWithoutProgramPlannerItemInput>>;
  create?: InputMaybe<Array<ProgramPlannerActivityCreateWithoutProgramPlannerItemInput>>;
  createMany?: InputMaybe<ProgramPlannerActivityCreateManyProgramPlannerItemInputEnvelope>;
  delete?: InputMaybe<Array<ProgramPlannerActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProgramPlannerActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProgramPlannerActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<ProgramPlannerActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<ProgramPlannerActivityUpdateWithWhereUniqueWithoutProgramPlannerItemInput>>;
  updateMany?: InputMaybe<Array<ProgramPlannerActivityUpdateManyWithWhereWithoutProgramPlannerItemInput>>;
  upsert?: InputMaybe<Array<ProgramPlannerActivityUpsertWithWhereUniqueWithoutProgramPlannerItemInput>>;
};

export type ProgramPlannerActivityUpdateOneWithoutActivityNestedInput = {
  connect?: InputMaybe<ProgramPlannerActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProgramPlannerActivityCreateOrConnectWithoutActivityInput>;
  create?: InputMaybe<ProgramPlannerActivityCreateWithoutActivityInput>;
  delete?: InputMaybe<ProgramPlannerActivityWhereInput>;
  disconnect?: InputMaybe<ProgramPlannerActivityWhereInput>;
  update?: InputMaybe<ProgramPlannerActivityUpdateToOneWithWhereWithoutActivityInput>;
  upsert?: InputMaybe<ProgramPlannerActivityUpsertWithoutActivityInput>;
};

export type ProgramPlannerActivityUpdateToOneWithWhereWithoutActivityInput = {
  data: ProgramPlannerActivityUpdateWithoutActivityInput;
  where?: InputMaybe<ProgramPlannerActivityWhereInput>;
};

export type ProgramPlannerActivityUpdateWithWhereUniqueWithoutProgramPlannerItemInput = {
  data: ProgramPlannerActivityUpdateWithoutProgramPlannerItemInput;
  where: ProgramPlannerActivityWhereUniqueInput;
};

export type ProgramPlannerActivityUpdateWithoutActivityInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  programPlannerItem?: InputMaybe<ProgramPlannerItemUpdateOneRequiredWithoutActivitiesNestedInput>;
  value?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ProgramPlannerActivityUpdateWithoutProgramPlannerItemInput = {
  activity?: InputMaybe<ActivityUpdateOneRequiredWithoutProgramPlannerItemNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  value?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ProgramPlannerActivityUpsertWithWhereUniqueWithoutProgramPlannerItemInput = {
  create: ProgramPlannerActivityCreateWithoutProgramPlannerItemInput;
  update: ProgramPlannerActivityUpdateWithoutProgramPlannerItemInput;
  where: ProgramPlannerActivityWhereUniqueInput;
};

export type ProgramPlannerActivityUpsertWithoutActivityInput = {
  create: ProgramPlannerActivityCreateWithoutActivityInput;
  update: ProgramPlannerActivityUpdateWithoutActivityInput;
  where?: InputMaybe<ProgramPlannerActivityWhereInput>;
};

export type ProgramPlannerActivityWhereInput = {
  AND?: InputMaybe<Array<ProgramPlannerActivityWhereInput>>;
  NOT?: InputMaybe<Array<ProgramPlannerActivityWhereInput>>;
  OR?: InputMaybe<Array<ProgramPlannerActivityWhereInput>>;
  activity?: InputMaybe<ActivityRelationFilter>;
  activityId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  programPlannerItem?: InputMaybe<ProgramPlannerItemRelationFilter>;
  programPlannerItemId?: InputMaybe<StringFilter>;
  value?: InputMaybe<IntFilter>;
};

export type ProgramPlannerActivityWhereUniqueInput = {
  AND?: InputMaybe<Array<ProgramPlannerActivityWhereInput>>;
  NOT?: InputMaybe<Array<ProgramPlannerActivityWhereInput>>;
  OR?: InputMaybe<Array<ProgramPlannerActivityWhereInput>>;
  activity?: InputMaybe<ActivityRelationFilter>;
  activityId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  programPlannerItem?: InputMaybe<ProgramPlannerItemRelationFilter>;
  programPlannerItemId?: InputMaybe<StringFilter>;
  value?: InputMaybe<IntFilter>;
};

export type ProgramPlannerItem = {
  __typename?: 'ProgramPlannerItem';
  _count?: Maybe<ProgramPlannerItemCount>;
  activities: Array<ProgramPlannerActivity>;
  client: Client;
  clientId: Scalars['String']['output'];
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  metric: Array<ProgramPlannerMetric>;
  month: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
  year: Scalars['Int']['output'];
};


export type ProgramPlannerItemActivitiesArgs = {
  cursor?: InputMaybe<ProgramPlannerActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProgramPlannerActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProgramPlannerActivityOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProgramPlannerActivityWhereInput>;
};


export type ProgramPlannerItemMetricArgs = {
  cursor?: InputMaybe<ProgramPlannerMetricWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProgramPlannerMetricScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProgramPlannerMetricOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProgramPlannerMetricWhereInput>;
};

export type ProgramPlannerItemCount = {
  __typename?: 'ProgramPlannerItemCount';
  activities: Scalars['Int']['output'];
  metric: Scalars['Int']['output'];
};


export type ProgramPlannerItemCountActivitiesArgs = {
  where?: InputMaybe<ProgramPlannerActivityWhereInput>;
};


export type ProgramPlannerItemCountMetricArgs = {
  where?: InputMaybe<ProgramPlannerMetricWhereInput>;
};

export type ProgramPlannerItemCreateInput = {
  activities?: InputMaybe<ProgramPlannerActivityCreateNestedManyWithoutProgramPlannerItemInput>;
  client: ClientCreateNestedOneWithoutProgramPlannerItemsInput;
  content?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  metric?: InputMaybe<ProgramPlannerMetricCreateNestedManyWithoutProgramPlannerItemInput>;
  month: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  type: Scalars['String']['input'];
  year: Scalars['Int']['input'];
};

export type ProgramPlannerItemCreateManyClientInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  month: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  type: Scalars['String']['input'];
  year: Scalars['Int']['input'];
};

export type ProgramPlannerItemCreateManyClientInputEnvelope = {
  data: Array<ProgramPlannerItemCreateManyClientInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProgramPlannerItemCreateNestedManyWithoutClientInput = {
  connect?: InputMaybe<Array<ProgramPlannerItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProgramPlannerItemCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<ProgramPlannerItemCreateWithoutClientInput>>;
  createMany?: InputMaybe<ProgramPlannerItemCreateManyClientInputEnvelope>;
};

export type ProgramPlannerItemCreateNestedOneWithoutActivitiesInput = {
  connect?: InputMaybe<ProgramPlannerItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProgramPlannerItemCreateOrConnectWithoutActivitiesInput>;
  create?: InputMaybe<ProgramPlannerItemCreateWithoutActivitiesInput>;
};

export type ProgramPlannerItemCreateNestedOneWithoutMetricInput = {
  connect?: InputMaybe<ProgramPlannerItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProgramPlannerItemCreateOrConnectWithoutMetricInput>;
  create?: InputMaybe<ProgramPlannerItemCreateWithoutMetricInput>;
};

export type ProgramPlannerItemCreateOrConnectWithoutActivitiesInput = {
  create: ProgramPlannerItemCreateWithoutActivitiesInput;
  where: ProgramPlannerItemWhereUniqueInput;
};

export type ProgramPlannerItemCreateOrConnectWithoutClientInput = {
  create: ProgramPlannerItemCreateWithoutClientInput;
  where: ProgramPlannerItemWhereUniqueInput;
};

export type ProgramPlannerItemCreateOrConnectWithoutMetricInput = {
  create: ProgramPlannerItemCreateWithoutMetricInput;
  where: ProgramPlannerItemWhereUniqueInput;
};

export type ProgramPlannerItemCreateWithoutActivitiesInput = {
  client: ClientCreateNestedOneWithoutProgramPlannerItemsInput;
  content?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  metric?: InputMaybe<ProgramPlannerMetricCreateNestedManyWithoutProgramPlannerItemInput>;
  month: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  type: Scalars['String']['input'];
  year: Scalars['Int']['input'];
};

export type ProgramPlannerItemCreateWithoutClientInput = {
  activities?: InputMaybe<ProgramPlannerActivityCreateNestedManyWithoutProgramPlannerItemInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  metric?: InputMaybe<ProgramPlannerMetricCreateNestedManyWithoutProgramPlannerItemInput>;
  month: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  type: Scalars['String']['input'];
  year: Scalars['Int']['input'];
};

export type ProgramPlannerItemCreateWithoutMetricInput = {
  activities?: InputMaybe<ProgramPlannerActivityCreateNestedManyWithoutProgramPlannerItemInput>;
  client: ClientCreateNestedOneWithoutProgramPlannerItemsInput;
  content?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  month: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  type: Scalars['String']['input'];
  year: Scalars['Int']['input'];
};

export type ProgramPlannerItemListRelationFilter = {
  every?: InputMaybe<ProgramPlannerItemWhereInput>;
  none?: InputMaybe<ProgramPlannerItemWhereInput>;
  some?: InputMaybe<ProgramPlannerItemWhereInput>;
};

export type ProgramPlannerItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum ProgramPlannerItemOrderByRelevanceFieldEnum {
  ClientId = 'clientId',
  Content = 'content',
  Id = 'id',
  Name = 'name',
  Type = 'type'
}

export type ProgramPlannerItemOrderByRelevanceInput = {
  fields: Array<ProgramPlannerItemOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type ProgramPlannerItemOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<ProgramPlannerItemOrderByRelevanceInput>;
  activities?: InputMaybe<ProgramPlannerActivityOrderByRelationAggregateInput>;
  client?: InputMaybe<ClientOrderByWithRelationAndSearchRelevanceInput>;
  clientId?: InputMaybe<SortOrder>;
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  metric?: InputMaybe<ProgramPlannerMetricOrderByRelationAggregateInput>;
  month?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  year?: InputMaybe<SortOrder>;
};

export type ProgramPlannerItemRelationFilter = {
  is?: InputMaybe<ProgramPlannerItemWhereInput>;
  isNot?: InputMaybe<ProgramPlannerItemWhereInput>;
};

export enum ProgramPlannerItemScalarFieldEnum {
  ClientId = 'clientId',
  Content = 'content',
  CreatedAt = 'createdAt',
  Id = 'id',
  Month = 'month',
  Name = 'name',
  Type = 'type',
  Year = 'year'
}

export type ProgramPlannerItemScalarWhereInput = {
  AND?: InputMaybe<Array<ProgramPlannerItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<ProgramPlannerItemScalarWhereInput>>;
  OR?: InputMaybe<Array<ProgramPlannerItemScalarWhereInput>>;
  clientId?: InputMaybe<StringFilter>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  month?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  type?: InputMaybe<StringFilter>;
  year?: InputMaybe<IntFilter>;
};

export type ProgramPlannerItemUpdateInput = {
  activities?: InputMaybe<ProgramPlannerActivityUpdateManyWithoutProgramPlannerItemNestedInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutProgramPlannerItemsNestedInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  metric?: InputMaybe<ProgramPlannerMetricUpdateManyWithoutProgramPlannerItemNestedInput>;
  month?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  year?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ProgramPlannerItemUpdateManyMutationInput = {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  month?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  year?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ProgramPlannerItemUpdateManyWithWhereWithoutClientInput = {
  data: ProgramPlannerItemUpdateManyMutationInput;
  where: ProgramPlannerItemScalarWhereInput;
};

export type ProgramPlannerItemUpdateManyWithoutClientNestedInput = {
  connect?: InputMaybe<Array<ProgramPlannerItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProgramPlannerItemCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<ProgramPlannerItemCreateWithoutClientInput>>;
  createMany?: InputMaybe<ProgramPlannerItemCreateManyClientInputEnvelope>;
  delete?: InputMaybe<Array<ProgramPlannerItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProgramPlannerItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProgramPlannerItemWhereUniqueInput>>;
  set?: InputMaybe<Array<ProgramPlannerItemWhereUniqueInput>>;
  update?: InputMaybe<Array<ProgramPlannerItemUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: InputMaybe<Array<ProgramPlannerItemUpdateManyWithWhereWithoutClientInput>>;
  upsert?: InputMaybe<Array<ProgramPlannerItemUpsertWithWhereUniqueWithoutClientInput>>;
};

export type ProgramPlannerItemUpdateOneRequiredWithoutActivitiesNestedInput = {
  connect?: InputMaybe<ProgramPlannerItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProgramPlannerItemCreateOrConnectWithoutActivitiesInput>;
  create?: InputMaybe<ProgramPlannerItemCreateWithoutActivitiesInput>;
  update?: InputMaybe<ProgramPlannerItemUpdateToOneWithWhereWithoutActivitiesInput>;
  upsert?: InputMaybe<ProgramPlannerItemUpsertWithoutActivitiesInput>;
};

export type ProgramPlannerItemUpdateToOneWithWhereWithoutActivitiesInput = {
  data: ProgramPlannerItemUpdateWithoutActivitiesInput;
  where?: InputMaybe<ProgramPlannerItemWhereInput>;
};

export type ProgramPlannerItemUpdateWithWhereUniqueWithoutClientInput = {
  data: ProgramPlannerItemUpdateWithoutClientInput;
  where: ProgramPlannerItemWhereUniqueInput;
};

export type ProgramPlannerItemUpdateWithoutActivitiesInput = {
  client?: InputMaybe<ClientUpdateOneRequiredWithoutProgramPlannerItemsNestedInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  metric?: InputMaybe<ProgramPlannerMetricUpdateManyWithoutProgramPlannerItemNestedInput>;
  month?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  year?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ProgramPlannerItemUpdateWithoutClientInput = {
  activities?: InputMaybe<ProgramPlannerActivityUpdateManyWithoutProgramPlannerItemNestedInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  metric?: InputMaybe<ProgramPlannerMetricUpdateManyWithoutProgramPlannerItemNestedInput>;
  month?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  year?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ProgramPlannerItemUpsertWithWhereUniqueWithoutClientInput = {
  create: ProgramPlannerItemCreateWithoutClientInput;
  update: ProgramPlannerItemUpdateWithoutClientInput;
  where: ProgramPlannerItemWhereUniqueInput;
};

export type ProgramPlannerItemUpsertWithoutActivitiesInput = {
  create: ProgramPlannerItemCreateWithoutActivitiesInput;
  update: ProgramPlannerItemUpdateWithoutActivitiesInput;
  where?: InputMaybe<ProgramPlannerItemWhereInput>;
};

export type ProgramPlannerItemWhereInput = {
  AND?: InputMaybe<Array<ProgramPlannerItemWhereInput>>;
  NOT?: InputMaybe<Array<ProgramPlannerItemWhereInput>>;
  OR?: InputMaybe<Array<ProgramPlannerItemWhereInput>>;
  activities?: InputMaybe<ProgramPlannerActivityListRelationFilter>;
  client?: InputMaybe<ClientRelationFilter>;
  clientId?: InputMaybe<StringFilter>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  metric?: InputMaybe<ProgramPlannerMetricListRelationFilter>;
  month?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  type?: InputMaybe<StringFilter>;
  year?: InputMaybe<IntFilter>;
};

export type ProgramPlannerItemWhereUniqueInput = {
  AND?: InputMaybe<Array<ProgramPlannerItemWhereInput>>;
  NOT?: InputMaybe<Array<ProgramPlannerItemWhereInput>>;
  OR?: InputMaybe<Array<ProgramPlannerItemWhereInput>>;
  activities?: InputMaybe<ProgramPlannerActivityListRelationFilter>;
  client?: InputMaybe<ClientRelationFilter>;
  clientId?: InputMaybe<StringFilter>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  metric?: InputMaybe<ProgramPlannerMetricListRelationFilter>;
  month?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  type?: InputMaybe<StringFilter>;
  year?: InputMaybe<IntFilter>;
};

export type ProgramPlannerMetric = {
  __typename?: 'ProgramPlannerMetric';
  goal: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  programPlannerItem: ProgramPlannerItem;
  programPlannerItemId: Scalars['String']['output'];
};

export type ProgramPlannerMetricCreateInput = {
  goal: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  programPlannerItem: ProgramPlannerItemCreateNestedOneWithoutMetricInput;
};

export type ProgramPlannerMetricCreateManyProgramPlannerItemInput = {
  goal: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type ProgramPlannerMetricCreateManyProgramPlannerItemInputEnvelope = {
  data: Array<ProgramPlannerMetricCreateManyProgramPlannerItemInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProgramPlannerMetricCreateNestedManyWithoutProgramPlannerItemInput = {
  connect?: InputMaybe<Array<ProgramPlannerMetricWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProgramPlannerMetricCreateOrConnectWithoutProgramPlannerItemInput>>;
  create?: InputMaybe<Array<ProgramPlannerMetricCreateWithoutProgramPlannerItemInput>>;
  createMany?: InputMaybe<ProgramPlannerMetricCreateManyProgramPlannerItemInputEnvelope>;
};

export type ProgramPlannerMetricCreateOrConnectWithoutProgramPlannerItemInput = {
  create: ProgramPlannerMetricCreateWithoutProgramPlannerItemInput;
  where: ProgramPlannerMetricWhereUniqueInput;
};

export type ProgramPlannerMetricCreateWithoutProgramPlannerItemInput = {
  goal: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type ProgramPlannerMetricListRelationFilter = {
  every?: InputMaybe<ProgramPlannerMetricWhereInput>;
  none?: InputMaybe<ProgramPlannerMetricWhereInput>;
  some?: InputMaybe<ProgramPlannerMetricWhereInput>;
};

export type ProgramPlannerMetricOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum ProgramPlannerMetricOrderByRelevanceFieldEnum {
  Id = 'id',
  Name = 'name',
  ProgramPlannerItemId = 'programPlannerItemId'
}

export type ProgramPlannerMetricOrderByRelevanceInput = {
  fields: Array<ProgramPlannerMetricOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type ProgramPlannerMetricOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<ProgramPlannerMetricOrderByRelevanceInput>;
  goal?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  programPlannerItem?: InputMaybe<ProgramPlannerItemOrderByWithRelationAndSearchRelevanceInput>;
  programPlannerItemId?: InputMaybe<SortOrder>;
};

export enum ProgramPlannerMetricScalarFieldEnum {
  Goal = 'goal',
  Id = 'id',
  Name = 'name',
  ProgramPlannerItemId = 'programPlannerItemId'
}

export type ProgramPlannerMetricScalarWhereInput = {
  AND?: InputMaybe<Array<ProgramPlannerMetricScalarWhereInput>>;
  NOT?: InputMaybe<Array<ProgramPlannerMetricScalarWhereInput>>;
  OR?: InputMaybe<Array<ProgramPlannerMetricScalarWhereInput>>;
  goal?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  programPlannerItemId?: InputMaybe<StringFilter>;
};

export type ProgramPlannerMetricUpdateManyMutationInput = {
  goal?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ProgramPlannerMetricUpdateManyWithWhereWithoutProgramPlannerItemInput = {
  data: ProgramPlannerMetricUpdateManyMutationInput;
  where: ProgramPlannerMetricScalarWhereInput;
};

export type ProgramPlannerMetricUpdateManyWithoutProgramPlannerItemNestedInput = {
  connect?: InputMaybe<Array<ProgramPlannerMetricWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProgramPlannerMetricCreateOrConnectWithoutProgramPlannerItemInput>>;
  create?: InputMaybe<Array<ProgramPlannerMetricCreateWithoutProgramPlannerItemInput>>;
  createMany?: InputMaybe<ProgramPlannerMetricCreateManyProgramPlannerItemInputEnvelope>;
  delete?: InputMaybe<Array<ProgramPlannerMetricWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProgramPlannerMetricScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProgramPlannerMetricWhereUniqueInput>>;
  set?: InputMaybe<Array<ProgramPlannerMetricWhereUniqueInput>>;
  update?: InputMaybe<Array<ProgramPlannerMetricUpdateWithWhereUniqueWithoutProgramPlannerItemInput>>;
  updateMany?: InputMaybe<Array<ProgramPlannerMetricUpdateManyWithWhereWithoutProgramPlannerItemInput>>;
  upsert?: InputMaybe<Array<ProgramPlannerMetricUpsertWithWhereUniqueWithoutProgramPlannerItemInput>>;
};

export type ProgramPlannerMetricUpdateWithWhereUniqueWithoutProgramPlannerItemInput = {
  data: ProgramPlannerMetricUpdateWithoutProgramPlannerItemInput;
  where: ProgramPlannerMetricWhereUniqueInput;
};

export type ProgramPlannerMetricUpdateWithoutProgramPlannerItemInput = {
  goal?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ProgramPlannerMetricUpsertWithWhereUniqueWithoutProgramPlannerItemInput = {
  create: ProgramPlannerMetricCreateWithoutProgramPlannerItemInput;
  update: ProgramPlannerMetricUpdateWithoutProgramPlannerItemInput;
  where: ProgramPlannerMetricWhereUniqueInput;
};

export type ProgramPlannerMetricWhereInput = {
  AND?: InputMaybe<Array<ProgramPlannerMetricWhereInput>>;
  NOT?: InputMaybe<Array<ProgramPlannerMetricWhereInput>>;
  OR?: InputMaybe<Array<ProgramPlannerMetricWhereInput>>;
  goal?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  programPlannerItem?: InputMaybe<ProgramPlannerItemRelationFilter>;
  programPlannerItemId?: InputMaybe<StringFilter>;
};

export type ProgramPlannerMetricWhereUniqueInput = {
  AND?: InputMaybe<Array<ProgramPlannerMetricWhereInput>>;
  NOT?: InputMaybe<Array<ProgramPlannerMetricWhereInput>>;
  OR?: InputMaybe<Array<ProgramPlannerMetricWhereInput>>;
  goal?: InputMaybe<IntFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<StringFilter>;
  programPlannerItem?: InputMaybe<ProgramPlannerItemRelationFilter>;
  programPlannerItemId?: InputMaybe<StringFilter>;
};

export type Publication = {
  __typename?: 'Publication';
  _count?: Maybe<PublicationCount>;
  country?: Maybe<Country>;
  countryId?: Maybe<Scalars['String']['output']>;
  coveragePieces: Array<CoveragePiece>;
  description: Scalars['String']['output'];
  domain: Scalars['String']['output'];
  feeds: Array<Feed>;
  id: Scalars['String']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  monthlyReadership: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  publicationType: PublicationType;
  scrapeMethod: ScrapeMethod;
  syndications: Array<CoveragePieceSyndication>;
  tier: PublicationTier;
};


export type PublicationCoveragePiecesArgs = {
  cursor?: InputMaybe<CoveragePieceWhereUniqueInput>;
  distinct?: InputMaybe<Array<CoveragePieceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CoveragePieceOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CoveragePieceWhereInput>;
};


export type PublicationSyndicationsArgs = {
  cursor?: InputMaybe<CoveragePieceSyndicationWhereUniqueInput>;
  distinct?: InputMaybe<Array<CoveragePieceSyndicationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CoveragePieceSyndicationOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CoveragePieceSyndicationWhereInput>;
};

export type PublicationCount = {
  __typename?: 'PublicationCount';
  authors: Scalars['Int']['output'];
  coveragePieces: Scalars['Int']['output'];
  feeds: Scalars['Int']['output'];
  ignoredAuthors: Scalars['Int']['output'];
  ignoredUrls: Scalars['Int']['output'];
  onlineArticles: Scalars['Int']['output'];
  printIssues: Scalars['Int']['output'];
  syndicatedArticles: Scalars['Int']['output'];
  syndications: Scalars['Int']['output'];
};


export type PublicationCountAuthorsArgs = {
  where?: InputMaybe<AuthorPublicationHistoryWhereInput>;
};


export type PublicationCountCoveragePiecesArgs = {
  where?: InputMaybe<CoveragePieceWhereInput>;
};


export type PublicationCountFeedsArgs = {
  where?: InputMaybe<FeedWhereInput>;
};


export type PublicationCountIgnoredAuthorsArgs = {
  where?: InputMaybe<IgnoredAuthorWhereInput>;
};


export type PublicationCountIgnoredUrlsArgs = {
  where?: InputMaybe<IgnoredUrlWhereInput>;
};


export type PublicationCountOnlineArticlesArgs = {
  where?: InputMaybe<OnlineArticleWhereInput>;
};


export type PublicationCountPrintIssuesArgs = {
  where?: InputMaybe<PrintIssueWhereInput>;
};


export type PublicationCountSyndicatedArticlesArgs = {
  where?: InputMaybe<OnlineArticleWhereInput>;
};


export type PublicationCountSyndicationsArgs = {
  where?: InputMaybe<CoveragePieceSyndicationWhereInput>;
};

export type PublicationCreateInput = {
  authors?: InputMaybe<AuthorPublicationHistoryCreateNestedManyWithoutPublicationInput>;
  country?: InputMaybe<CountryCreateNestedOneWithoutPublicationsInput>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutPublicationInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  domain: Scalars['String']['input'];
  feeds?: InputMaybe<FeedCreateNestedManyWithoutPublicationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignoredAuthors?: InputMaybe<IgnoredAuthorCreateNestedManyWithoutPublicationInput>;
  ignoredUrls?: InputMaybe<IgnoredUrlCreateNestedManyWithoutPublicationInput>;
  logo?: InputMaybe<Scalars['String']['input']>;
  monthlyReadership?: InputMaybe<Scalars['BigInt']['input']>;
  name: Scalars['String']['input'];
  onlineArticles?: InputMaybe<OnlineArticleCreateNestedManyWithoutPublicationInput>;
  printIssues?: InputMaybe<PrintIssueCreateNestedManyWithoutPublicationInput>;
  printScraperSettings?: InputMaybe<PrintScraperSettingsCreateNestedOneWithoutPublicationInput>;
  publicationType?: InputMaybe<PublicationType>;
  scrapeMethod?: InputMaybe<ScrapeMethod>;
  syndicatedArticles?: InputMaybe<OnlineArticleCreateNestedManyWithoutSyndicatedFromInput>;
  syndications?: InputMaybe<CoveragePieceSyndicationCreateNestedManyWithoutPublicationInput>;
  tier?: InputMaybe<PublicationTier>;
};

export type PublicationCreateManyCountryInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  domain: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  monthlyReadership?: InputMaybe<Scalars['BigInt']['input']>;
  name: Scalars['String']['input'];
  publicationType?: InputMaybe<PublicationType>;
  scrapeMethod?: InputMaybe<ScrapeMethod>;
  tier?: InputMaybe<PublicationTier>;
};

export type PublicationCreateManyCountryInputEnvelope = {
  data: Array<PublicationCreateManyCountryInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PublicationCreateManyInput = {
  countryId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  domain: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  monthlyReadership?: InputMaybe<Scalars['BigInt']['input']>;
  name: Scalars['String']['input'];
  publicationType?: InputMaybe<PublicationType>;
  scrapeMethod?: InputMaybe<ScrapeMethod>;
  tier?: InputMaybe<PublicationTier>;
};

export type PublicationCreateNestedManyWithoutCountryInput = {
  connect?: InputMaybe<Array<PublicationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PublicationCreateOrConnectWithoutCountryInput>>;
  create?: InputMaybe<Array<PublicationCreateWithoutCountryInput>>;
  createMany?: InputMaybe<PublicationCreateManyCountryInputEnvelope>;
};

export type PublicationCreateNestedOneWithoutAuthorsInput = {
  connect?: InputMaybe<PublicationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PublicationCreateOrConnectWithoutAuthorsInput>;
  create?: InputMaybe<PublicationCreateWithoutAuthorsInput>;
};

export type PublicationCreateNestedOneWithoutCoveragePiecesInput = {
  connect?: InputMaybe<PublicationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PublicationCreateOrConnectWithoutCoveragePiecesInput>;
  create?: InputMaybe<PublicationCreateWithoutCoveragePiecesInput>;
};

export type PublicationCreateNestedOneWithoutOnlineArticlesInput = {
  connect?: InputMaybe<PublicationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PublicationCreateOrConnectWithoutOnlineArticlesInput>;
  create?: InputMaybe<PublicationCreateWithoutOnlineArticlesInput>;
};

export type PublicationCreateNestedOneWithoutPrintIssuesInput = {
  connect?: InputMaybe<PublicationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PublicationCreateOrConnectWithoutPrintIssuesInput>;
  create?: InputMaybe<PublicationCreateWithoutPrintIssuesInput>;
};

export type PublicationCreateNestedOneWithoutSyndicatedArticlesInput = {
  connect?: InputMaybe<PublicationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PublicationCreateOrConnectWithoutSyndicatedArticlesInput>;
  create?: InputMaybe<PublicationCreateWithoutSyndicatedArticlesInput>;
};

export type PublicationCreateNestedOneWithoutSyndicationsInput = {
  connect?: InputMaybe<PublicationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PublicationCreateOrConnectWithoutSyndicationsInput>;
  create?: InputMaybe<PublicationCreateWithoutSyndicationsInput>;
};

export type PublicationCreateOrConnectWithoutAuthorsInput = {
  create: PublicationCreateWithoutAuthorsInput;
  where: PublicationWhereUniqueInput;
};

export type PublicationCreateOrConnectWithoutCountryInput = {
  create: PublicationCreateWithoutCountryInput;
  where: PublicationWhereUniqueInput;
};

export type PublicationCreateOrConnectWithoutCoveragePiecesInput = {
  create: PublicationCreateWithoutCoveragePiecesInput;
  where: PublicationWhereUniqueInput;
};

export type PublicationCreateOrConnectWithoutOnlineArticlesInput = {
  create: PublicationCreateWithoutOnlineArticlesInput;
  where: PublicationWhereUniqueInput;
};

export type PublicationCreateOrConnectWithoutPrintIssuesInput = {
  create: PublicationCreateWithoutPrintIssuesInput;
  where: PublicationWhereUniqueInput;
};

export type PublicationCreateOrConnectWithoutSyndicatedArticlesInput = {
  create: PublicationCreateWithoutSyndicatedArticlesInput;
  where: PublicationWhereUniqueInput;
};

export type PublicationCreateOrConnectWithoutSyndicationsInput = {
  create: PublicationCreateWithoutSyndicationsInput;
  where: PublicationWhereUniqueInput;
};

export type PublicationCreateWithoutAuthorsInput = {
  country?: InputMaybe<CountryCreateNestedOneWithoutPublicationsInput>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutPublicationInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  domain: Scalars['String']['input'];
  feeds?: InputMaybe<FeedCreateNestedManyWithoutPublicationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignoredAuthors?: InputMaybe<IgnoredAuthorCreateNestedManyWithoutPublicationInput>;
  ignoredUrls?: InputMaybe<IgnoredUrlCreateNestedManyWithoutPublicationInput>;
  logo?: InputMaybe<Scalars['String']['input']>;
  monthlyReadership?: InputMaybe<Scalars['BigInt']['input']>;
  name: Scalars['String']['input'];
  onlineArticles?: InputMaybe<OnlineArticleCreateNestedManyWithoutPublicationInput>;
  printIssues?: InputMaybe<PrintIssueCreateNestedManyWithoutPublicationInput>;
  printScraperSettings?: InputMaybe<PrintScraperSettingsCreateNestedOneWithoutPublicationInput>;
  publicationType?: InputMaybe<PublicationType>;
  scrapeMethod?: InputMaybe<ScrapeMethod>;
  syndicatedArticles?: InputMaybe<OnlineArticleCreateNestedManyWithoutSyndicatedFromInput>;
  syndications?: InputMaybe<CoveragePieceSyndicationCreateNestedManyWithoutPublicationInput>;
  tier?: InputMaybe<PublicationTier>;
};

export type PublicationCreateWithoutCountryInput = {
  authors?: InputMaybe<AuthorPublicationHistoryCreateNestedManyWithoutPublicationInput>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutPublicationInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  domain: Scalars['String']['input'];
  feeds?: InputMaybe<FeedCreateNestedManyWithoutPublicationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignoredAuthors?: InputMaybe<IgnoredAuthorCreateNestedManyWithoutPublicationInput>;
  ignoredUrls?: InputMaybe<IgnoredUrlCreateNestedManyWithoutPublicationInput>;
  logo?: InputMaybe<Scalars['String']['input']>;
  monthlyReadership?: InputMaybe<Scalars['BigInt']['input']>;
  name: Scalars['String']['input'];
  onlineArticles?: InputMaybe<OnlineArticleCreateNestedManyWithoutPublicationInput>;
  printIssues?: InputMaybe<PrintIssueCreateNestedManyWithoutPublicationInput>;
  printScraperSettings?: InputMaybe<PrintScraperSettingsCreateNestedOneWithoutPublicationInput>;
  publicationType?: InputMaybe<PublicationType>;
  scrapeMethod?: InputMaybe<ScrapeMethod>;
  syndicatedArticles?: InputMaybe<OnlineArticleCreateNestedManyWithoutSyndicatedFromInput>;
  syndications?: InputMaybe<CoveragePieceSyndicationCreateNestedManyWithoutPublicationInput>;
  tier?: InputMaybe<PublicationTier>;
};

export type PublicationCreateWithoutCoveragePiecesInput = {
  authors?: InputMaybe<AuthorPublicationHistoryCreateNestedManyWithoutPublicationInput>;
  country?: InputMaybe<CountryCreateNestedOneWithoutPublicationsInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  domain: Scalars['String']['input'];
  feeds?: InputMaybe<FeedCreateNestedManyWithoutPublicationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignoredAuthors?: InputMaybe<IgnoredAuthorCreateNestedManyWithoutPublicationInput>;
  ignoredUrls?: InputMaybe<IgnoredUrlCreateNestedManyWithoutPublicationInput>;
  logo?: InputMaybe<Scalars['String']['input']>;
  monthlyReadership?: InputMaybe<Scalars['BigInt']['input']>;
  name: Scalars['String']['input'];
  onlineArticles?: InputMaybe<OnlineArticleCreateNestedManyWithoutPublicationInput>;
  printIssues?: InputMaybe<PrintIssueCreateNestedManyWithoutPublicationInput>;
  printScraperSettings?: InputMaybe<PrintScraperSettingsCreateNestedOneWithoutPublicationInput>;
  publicationType?: InputMaybe<PublicationType>;
  scrapeMethod?: InputMaybe<ScrapeMethod>;
  syndicatedArticles?: InputMaybe<OnlineArticleCreateNestedManyWithoutSyndicatedFromInput>;
  syndications?: InputMaybe<CoveragePieceSyndicationCreateNestedManyWithoutPublicationInput>;
  tier?: InputMaybe<PublicationTier>;
};

export type PublicationCreateWithoutOnlineArticlesInput = {
  authors?: InputMaybe<AuthorPublicationHistoryCreateNestedManyWithoutPublicationInput>;
  country?: InputMaybe<CountryCreateNestedOneWithoutPublicationsInput>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutPublicationInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  domain: Scalars['String']['input'];
  feeds?: InputMaybe<FeedCreateNestedManyWithoutPublicationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignoredAuthors?: InputMaybe<IgnoredAuthorCreateNestedManyWithoutPublicationInput>;
  ignoredUrls?: InputMaybe<IgnoredUrlCreateNestedManyWithoutPublicationInput>;
  logo?: InputMaybe<Scalars['String']['input']>;
  monthlyReadership?: InputMaybe<Scalars['BigInt']['input']>;
  name: Scalars['String']['input'];
  printIssues?: InputMaybe<PrintIssueCreateNestedManyWithoutPublicationInput>;
  printScraperSettings?: InputMaybe<PrintScraperSettingsCreateNestedOneWithoutPublicationInput>;
  publicationType?: InputMaybe<PublicationType>;
  scrapeMethod?: InputMaybe<ScrapeMethod>;
  syndicatedArticles?: InputMaybe<OnlineArticleCreateNestedManyWithoutSyndicatedFromInput>;
  syndications?: InputMaybe<CoveragePieceSyndicationCreateNestedManyWithoutPublicationInput>;
  tier?: InputMaybe<PublicationTier>;
};

export type PublicationCreateWithoutPrintIssuesInput = {
  authors?: InputMaybe<AuthorPublicationHistoryCreateNestedManyWithoutPublicationInput>;
  country?: InputMaybe<CountryCreateNestedOneWithoutPublicationsInput>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutPublicationInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  domain: Scalars['String']['input'];
  feeds?: InputMaybe<FeedCreateNestedManyWithoutPublicationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignoredAuthors?: InputMaybe<IgnoredAuthorCreateNestedManyWithoutPublicationInput>;
  ignoredUrls?: InputMaybe<IgnoredUrlCreateNestedManyWithoutPublicationInput>;
  logo?: InputMaybe<Scalars['String']['input']>;
  monthlyReadership?: InputMaybe<Scalars['BigInt']['input']>;
  name: Scalars['String']['input'];
  onlineArticles?: InputMaybe<OnlineArticleCreateNestedManyWithoutPublicationInput>;
  printScraperSettings?: InputMaybe<PrintScraperSettingsCreateNestedOneWithoutPublicationInput>;
  publicationType?: InputMaybe<PublicationType>;
  scrapeMethod?: InputMaybe<ScrapeMethod>;
  syndicatedArticles?: InputMaybe<OnlineArticleCreateNestedManyWithoutSyndicatedFromInput>;
  syndications?: InputMaybe<CoveragePieceSyndicationCreateNestedManyWithoutPublicationInput>;
  tier?: InputMaybe<PublicationTier>;
};

export type PublicationCreateWithoutSyndicatedArticlesInput = {
  authors?: InputMaybe<AuthorPublicationHistoryCreateNestedManyWithoutPublicationInput>;
  country?: InputMaybe<CountryCreateNestedOneWithoutPublicationsInput>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutPublicationInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  domain: Scalars['String']['input'];
  feeds?: InputMaybe<FeedCreateNestedManyWithoutPublicationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignoredAuthors?: InputMaybe<IgnoredAuthorCreateNestedManyWithoutPublicationInput>;
  ignoredUrls?: InputMaybe<IgnoredUrlCreateNestedManyWithoutPublicationInput>;
  logo?: InputMaybe<Scalars['String']['input']>;
  monthlyReadership?: InputMaybe<Scalars['BigInt']['input']>;
  name: Scalars['String']['input'];
  onlineArticles?: InputMaybe<OnlineArticleCreateNestedManyWithoutPublicationInput>;
  printIssues?: InputMaybe<PrintIssueCreateNestedManyWithoutPublicationInput>;
  printScraperSettings?: InputMaybe<PrintScraperSettingsCreateNestedOneWithoutPublicationInput>;
  publicationType?: InputMaybe<PublicationType>;
  scrapeMethod?: InputMaybe<ScrapeMethod>;
  syndications?: InputMaybe<CoveragePieceSyndicationCreateNestedManyWithoutPublicationInput>;
  tier?: InputMaybe<PublicationTier>;
};

export type PublicationCreateWithoutSyndicationsInput = {
  authors?: InputMaybe<AuthorPublicationHistoryCreateNestedManyWithoutPublicationInput>;
  country?: InputMaybe<CountryCreateNestedOneWithoutPublicationsInput>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutPublicationInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  domain: Scalars['String']['input'];
  feeds?: InputMaybe<FeedCreateNestedManyWithoutPublicationInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignoredAuthors?: InputMaybe<IgnoredAuthorCreateNestedManyWithoutPublicationInput>;
  ignoredUrls?: InputMaybe<IgnoredUrlCreateNestedManyWithoutPublicationInput>;
  logo?: InputMaybe<Scalars['String']['input']>;
  monthlyReadership?: InputMaybe<Scalars['BigInt']['input']>;
  name: Scalars['String']['input'];
  onlineArticles?: InputMaybe<OnlineArticleCreateNestedManyWithoutPublicationInput>;
  printIssues?: InputMaybe<PrintIssueCreateNestedManyWithoutPublicationInput>;
  printScraperSettings?: InputMaybe<PrintScraperSettingsCreateNestedOneWithoutPublicationInput>;
  publicationType?: InputMaybe<PublicationType>;
  scrapeMethod?: InputMaybe<ScrapeMethod>;
  syndicatedArticles?: InputMaybe<OnlineArticleCreateNestedManyWithoutSyndicatedFromInput>;
  tier?: InputMaybe<PublicationTier>;
};

export type PublicationData = {
  __typename?: 'PublicationData';
  iconLink: Scalars['String']['output'];
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type PublicationListRelationFilter = {
  every?: InputMaybe<PublicationWhereInput>;
  none?: InputMaybe<PublicationWhereInput>;
  some?: InputMaybe<PublicationWhereInput>;
};

export type PublicationNullableRelationFilter = {
  is?: InputMaybe<PublicationWhereInput>;
  isNot?: InputMaybe<PublicationWhereInput>;
};

export type PublicationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum PublicationOrderByRelevanceFieldEnum {
  CountryId = 'countryId',
  Description = 'description',
  Domain = 'domain',
  Id = 'id',
  Logo = 'logo',
  Name = 'name'
}

export type PublicationOrderByRelevanceInput = {
  fields: Array<PublicationOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type PublicationOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<PublicationOrderByRelevanceInput>;
  authors?: InputMaybe<AuthorPublicationHistoryOrderByRelationAggregateInput>;
  country?: InputMaybe<CountryOrderByWithRelationAndSearchRelevanceInput>;
  countryId?: InputMaybe<SortOrderInput>;
  coveragePieces?: InputMaybe<CoveragePieceOrderByRelationAggregateInput>;
  description?: InputMaybe<SortOrder>;
  domain?: InputMaybe<SortOrder>;
  feeds?: InputMaybe<FeedOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  ignoredAuthors?: InputMaybe<IgnoredAuthorOrderByRelationAggregateInput>;
  ignoredUrls?: InputMaybe<IgnoredUrlOrderByRelationAggregateInput>;
  logo?: InputMaybe<SortOrderInput>;
  monthlyReadership?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  onlineArticles?: InputMaybe<OnlineArticleOrderByRelationAggregateInput>;
  printIssues?: InputMaybe<PrintIssueOrderByRelationAggregateInput>;
  printScraperSettings?: InputMaybe<PrintScraperSettingsOrderByWithRelationAndSearchRelevanceInput>;
  publicationType?: InputMaybe<SortOrder>;
  scrapeMethod?: InputMaybe<SortOrder>;
  syndicatedArticles?: InputMaybe<OnlineArticleOrderByRelationAggregateInput>;
  syndications?: InputMaybe<CoveragePieceSyndicationOrderByRelationAggregateInput>;
  tier?: InputMaybe<SortOrder>;
};

export type PublicationRelationFilter = {
  is?: InputMaybe<PublicationWhereInput>;
  isNot?: InputMaybe<PublicationWhereInput>;
};

export enum PublicationScalarFieldEnum {
  CountryId = 'countryId',
  Description = 'description',
  Domain = 'domain',
  Id = 'id',
  Logo = 'logo',
  MonthlyReadership = 'monthlyReadership',
  Name = 'name',
  PublicationType = 'publicationType',
  ScrapeMethod = 'scrapeMethod',
  Tier = 'tier'
}

export type PublicationScalarWhereInput = {
  AND?: InputMaybe<Array<PublicationScalarWhereInput>>;
  NOT?: InputMaybe<Array<PublicationScalarWhereInput>>;
  OR?: InputMaybe<Array<PublicationScalarWhereInput>>;
  countryId?: InputMaybe<StringNullableFilter>;
  description?: InputMaybe<StringFilter>;
  domain?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  logo?: InputMaybe<StringNullableFilter>;
  monthlyReadership?: InputMaybe<BigIntFilter>;
  name?: InputMaybe<StringFilter>;
  publicationType?: InputMaybe<EnumPublicationTypeFilter>;
  scrapeMethod?: InputMaybe<EnumScrapeMethodFilter>;
  tier?: InputMaybe<EnumPublicationTierFilter>;
};

export enum PublicationTier {
  Tier1 = 'TIER1',
  Tier2 = 'TIER2',
  Tier3 = 'TIER3'
}

export enum PublicationType {
  Blog = 'BLOG',
  Government = 'GOVERNMENT',
  News = 'NEWS',
  Other = 'OTHER'
}

export type PublicationUpdateInput = {
  authors?: InputMaybe<AuthorPublicationHistoryUpdateManyWithoutPublicationNestedInput>;
  country?: InputMaybe<CountryUpdateOneWithoutPublicationsNestedInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutPublicationNestedInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  domain?: InputMaybe<StringFieldUpdateOperationsInput>;
  feeds?: InputMaybe<FeedUpdateManyWithoutPublicationNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignoredAuthors?: InputMaybe<IgnoredAuthorUpdateManyWithoutPublicationNestedInput>;
  ignoredUrls?: InputMaybe<IgnoredUrlUpdateManyWithoutPublicationNestedInput>;
  logo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  monthlyReadership?: InputMaybe<BigIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  onlineArticles?: InputMaybe<OnlineArticleUpdateManyWithoutPublicationNestedInput>;
  printIssues?: InputMaybe<PrintIssueUpdateManyWithoutPublicationNestedInput>;
  printScraperSettings?: InputMaybe<PrintScraperSettingsUpdateOneWithoutPublicationNestedInput>;
  publicationType?: InputMaybe<EnumPublicationTypeFieldUpdateOperationsInput>;
  scrapeMethod?: InputMaybe<EnumScrapeMethodFieldUpdateOperationsInput>;
  syndicatedArticles?: InputMaybe<OnlineArticleUpdateManyWithoutSyndicatedFromNestedInput>;
  syndications?: InputMaybe<CoveragePieceSyndicationUpdateManyWithoutPublicationNestedInput>;
  tier?: InputMaybe<EnumPublicationTierFieldUpdateOperationsInput>;
};

export type PublicationUpdateManyMutationInput = {
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  domain?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  logo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  monthlyReadership?: InputMaybe<BigIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  publicationType?: InputMaybe<EnumPublicationTypeFieldUpdateOperationsInput>;
  scrapeMethod?: InputMaybe<EnumScrapeMethodFieldUpdateOperationsInput>;
  tier?: InputMaybe<EnumPublicationTierFieldUpdateOperationsInput>;
};

export type PublicationUpdateManyWithWhereWithoutCountryInput = {
  data: PublicationUpdateManyMutationInput;
  where: PublicationScalarWhereInput;
};

export type PublicationUpdateManyWithoutCountryNestedInput = {
  connect?: InputMaybe<Array<PublicationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PublicationCreateOrConnectWithoutCountryInput>>;
  create?: InputMaybe<Array<PublicationCreateWithoutCountryInput>>;
  createMany?: InputMaybe<PublicationCreateManyCountryInputEnvelope>;
  delete?: InputMaybe<Array<PublicationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PublicationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PublicationWhereUniqueInput>>;
  set?: InputMaybe<Array<PublicationWhereUniqueInput>>;
  update?: InputMaybe<Array<PublicationUpdateWithWhereUniqueWithoutCountryInput>>;
  updateMany?: InputMaybe<Array<PublicationUpdateManyWithWhereWithoutCountryInput>>;
  upsert?: InputMaybe<Array<PublicationUpsertWithWhereUniqueWithoutCountryInput>>;
};

export type PublicationUpdateOneRequiredWithoutAuthorsNestedInput = {
  connect?: InputMaybe<PublicationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PublicationCreateOrConnectWithoutAuthorsInput>;
  create?: InputMaybe<PublicationCreateWithoutAuthorsInput>;
  update?: InputMaybe<PublicationUpdateToOneWithWhereWithoutAuthorsInput>;
  upsert?: InputMaybe<PublicationUpsertWithoutAuthorsInput>;
};

export type PublicationUpdateOneRequiredWithoutCoveragePiecesNestedInput = {
  connect?: InputMaybe<PublicationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PublicationCreateOrConnectWithoutCoveragePiecesInput>;
  create?: InputMaybe<PublicationCreateWithoutCoveragePiecesInput>;
  update?: InputMaybe<PublicationUpdateToOneWithWhereWithoutCoveragePiecesInput>;
  upsert?: InputMaybe<PublicationUpsertWithoutCoveragePiecesInput>;
};

export type PublicationUpdateOneRequiredWithoutOnlineArticlesNestedInput = {
  connect?: InputMaybe<PublicationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PublicationCreateOrConnectWithoutOnlineArticlesInput>;
  create?: InputMaybe<PublicationCreateWithoutOnlineArticlesInput>;
  update?: InputMaybe<PublicationUpdateToOneWithWhereWithoutOnlineArticlesInput>;
  upsert?: InputMaybe<PublicationUpsertWithoutOnlineArticlesInput>;
};

export type PublicationUpdateOneRequiredWithoutPrintIssuesNestedInput = {
  connect?: InputMaybe<PublicationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PublicationCreateOrConnectWithoutPrintIssuesInput>;
  create?: InputMaybe<PublicationCreateWithoutPrintIssuesInput>;
  update?: InputMaybe<PublicationUpdateToOneWithWhereWithoutPrintIssuesInput>;
  upsert?: InputMaybe<PublicationUpsertWithoutPrintIssuesInput>;
};

export type PublicationUpdateOneRequiredWithoutSyndicationsNestedInput = {
  connect?: InputMaybe<PublicationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PublicationCreateOrConnectWithoutSyndicationsInput>;
  create?: InputMaybe<PublicationCreateWithoutSyndicationsInput>;
  update?: InputMaybe<PublicationUpdateToOneWithWhereWithoutSyndicationsInput>;
  upsert?: InputMaybe<PublicationUpsertWithoutSyndicationsInput>;
};

export type PublicationUpdateOneWithoutSyndicatedArticlesNestedInput = {
  connect?: InputMaybe<PublicationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PublicationCreateOrConnectWithoutSyndicatedArticlesInput>;
  create?: InputMaybe<PublicationCreateWithoutSyndicatedArticlesInput>;
  delete?: InputMaybe<PublicationWhereInput>;
  disconnect?: InputMaybe<PublicationWhereInput>;
  update?: InputMaybe<PublicationUpdateToOneWithWhereWithoutSyndicatedArticlesInput>;
  upsert?: InputMaybe<PublicationUpsertWithoutSyndicatedArticlesInput>;
};

export type PublicationUpdateToOneWithWhereWithoutAuthorsInput = {
  data: PublicationUpdateWithoutAuthorsInput;
  where?: InputMaybe<PublicationWhereInput>;
};

export type PublicationUpdateToOneWithWhereWithoutCoveragePiecesInput = {
  data: PublicationUpdateWithoutCoveragePiecesInput;
  where?: InputMaybe<PublicationWhereInput>;
};

export type PublicationUpdateToOneWithWhereWithoutOnlineArticlesInput = {
  data: PublicationUpdateWithoutOnlineArticlesInput;
  where?: InputMaybe<PublicationWhereInput>;
};

export type PublicationUpdateToOneWithWhereWithoutPrintIssuesInput = {
  data: PublicationUpdateWithoutPrintIssuesInput;
  where?: InputMaybe<PublicationWhereInput>;
};

export type PublicationUpdateToOneWithWhereWithoutSyndicatedArticlesInput = {
  data: PublicationUpdateWithoutSyndicatedArticlesInput;
  where?: InputMaybe<PublicationWhereInput>;
};

export type PublicationUpdateToOneWithWhereWithoutSyndicationsInput = {
  data: PublicationUpdateWithoutSyndicationsInput;
  where?: InputMaybe<PublicationWhereInput>;
};

export type PublicationUpdateWithWhereUniqueWithoutCountryInput = {
  data: PublicationUpdateWithoutCountryInput;
  where: PublicationWhereUniqueInput;
};

export type PublicationUpdateWithoutAuthorsInput = {
  country?: InputMaybe<CountryUpdateOneWithoutPublicationsNestedInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutPublicationNestedInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  domain?: InputMaybe<StringFieldUpdateOperationsInput>;
  feeds?: InputMaybe<FeedUpdateManyWithoutPublicationNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignoredAuthors?: InputMaybe<IgnoredAuthorUpdateManyWithoutPublicationNestedInput>;
  ignoredUrls?: InputMaybe<IgnoredUrlUpdateManyWithoutPublicationNestedInput>;
  logo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  monthlyReadership?: InputMaybe<BigIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  onlineArticles?: InputMaybe<OnlineArticleUpdateManyWithoutPublicationNestedInput>;
  printIssues?: InputMaybe<PrintIssueUpdateManyWithoutPublicationNestedInput>;
  printScraperSettings?: InputMaybe<PrintScraperSettingsUpdateOneWithoutPublicationNestedInput>;
  publicationType?: InputMaybe<EnumPublicationTypeFieldUpdateOperationsInput>;
  scrapeMethod?: InputMaybe<EnumScrapeMethodFieldUpdateOperationsInput>;
  syndicatedArticles?: InputMaybe<OnlineArticleUpdateManyWithoutSyndicatedFromNestedInput>;
  syndications?: InputMaybe<CoveragePieceSyndicationUpdateManyWithoutPublicationNestedInput>;
  tier?: InputMaybe<EnumPublicationTierFieldUpdateOperationsInput>;
};

export type PublicationUpdateWithoutCountryInput = {
  authors?: InputMaybe<AuthorPublicationHistoryUpdateManyWithoutPublicationNestedInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutPublicationNestedInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  domain?: InputMaybe<StringFieldUpdateOperationsInput>;
  feeds?: InputMaybe<FeedUpdateManyWithoutPublicationNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignoredAuthors?: InputMaybe<IgnoredAuthorUpdateManyWithoutPublicationNestedInput>;
  ignoredUrls?: InputMaybe<IgnoredUrlUpdateManyWithoutPublicationNestedInput>;
  logo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  monthlyReadership?: InputMaybe<BigIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  onlineArticles?: InputMaybe<OnlineArticleUpdateManyWithoutPublicationNestedInput>;
  printIssues?: InputMaybe<PrintIssueUpdateManyWithoutPublicationNestedInput>;
  printScraperSettings?: InputMaybe<PrintScraperSettingsUpdateOneWithoutPublicationNestedInput>;
  publicationType?: InputMaybe<EnumPublicationTypeFieldUpdateOperationsInput>;
  scrapeMethod?: InputMaybe<EnumScrapeMethodFieldUpdateOperationsInput>;
  syndicatedArticles?: InputMaybe<OnlineArticleUpdateManyWithoutSyndicatedFromNestedInput>;
  syndications?: InputMaybe<CoveragePieceSyndicationUpdateManyWithoutPublicationNestedInput>;
  tier?: InputMaybe<EnumPublicationTierFieldUpdateOperationsInput>;
};

export type PublicationUpdateWithoutCoveragePiecesInput = {
  authors?: InputMaybe<AuthorPublicationHistoryUpdateManyWithoutPublicationNestedInput>;
  country?: InputMaybe<CountryUpdateOneWithoutPublicationsNestedInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  domain?: InputMaybe<StringFieldUpdateOperationsInput>;
  feeds?: InputMaybe<FeedUpdateManyWithoutPublicationNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignoredAuthors?: InputMaybe<IgnoredAuthorUpdateManyWithoutPublicationNestedInput>;
  ignoredUrls?: InputMaybe<IgnoredUrlUpdateManyWithoutPublicationNestedInput>;
  logo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  monthlyReadership?: InputMaybe<BigIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  onlineArticles?: InputMaybe<OnlineArticleUpdateManyWithoutPublicationNestedInput>;
  printIssues?: InputMaybe<PrintIssueUpdateManyWithoutPublicationNestedInput>;
  printScraperSettings?: InputMaybe<PrintScraperSettingsUpdateOneWithoutPublicationNestedInput>;
  publicationType?: InputMaybe<EnumPublicationTypeFieldUpdateOperationsInput>;
  scrapeMethod?: InputMaybe<EnumScrapeMethodFieldUpdateOperationsInput>;
  syndicatedArticles?: InputMaybe<OnlineArticleUpdateManyWithoutSyndicatedFromNestedInput>;
  syndications?: InputMaybe<CoveragePieceSyndicationUpdateManyWithoutPublicationNestedInput>;
  tier?: InputMaybe<EnumPublicationTierFieldUpdateOperationsInput>;
};

export type PublicationUpdateWithoutOnlineArticlesInput = {
  authors?: InputMaybe<AuthorPublicationHistoryUpdateManyWithoutPublicationNestedInput>;
  country?: InputMaybe<CountryUpdateOneWithoutPublicationsNestedInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutPublicationNestedInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  domain?: InputMaybe<StringFieldUpdateOperationsInput>;
  feeds?: InputMaybe<FeedUpdateManyWithoutPublicationNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignoredAuthors?: InputMaybe<IgnoredAuthorUpdateManyWithoutPublicationNestedInput>;
  ignoredUrls?: InputMaybe<IgnoredUrlUpdateManyWithoutPublicationNestedInput>;
  logo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  monthlyReadership?: InputMaybe<BigIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  printIssues?: InputMaybe<PrintIssueUpdateManyWithoutPublicationNestedInput>;
  printScraperSettings?: InputMaybe<PrintScraperSettingsUpdateOneWithoutPublicationNestedInput>;
  publicationType?: InputMaybe<EnumPublicationTypeFieldUpdateOperationsInput>;
  scrapeMethod?: InputMaybe<EnumScrapeMethodFieldUpdateOperationsInput>;
  syndicatedArticles?: InputMaybe<OnlineArticleUpdateManyWithoutSyndicatedFromNestedInput>;
  syndications?: InputMaybe<CoveragePieceSyndicationUpdateManyWithoutPublicationNestedInput>;
  tier?: InputMaybe<EnumPublicationTierFieldUpdateOperationsInput>;
};

export type PublicationUpdateWithoutPrintIssuesInput = {
  authors?: InputMaybe<AuthorPublicationHistoryUpdateManyWithoutPublicationNestedInput>;
  country?: InputMaybe<CountryUpdateOneWithoutPublicationsNestedInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutPublicationNestedInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  domain?: InputMaybe<StringFieldUpdateOperationsInput>;
  feeds?: InputMaybe<FeedUpdateManyWithoutPublicationNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignoredAuthors?: InputMaybe<IgnoredAuthorUpdateManyWithoutPublicationNestedInput>;
  ignoredUrls?: InputMaybe<IgnoredUrlUpdateManyWithoutPublicationNestedInput>;
  logo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  monthlyReadership?: InputMaybe<BigIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  onlineArticles?: InputMaybe<OnlineArticleUpdateManyWithoutPublicationNestedInput>;
  printScraperSettings?: InputMaybe<PrintScraperSettingsUpdateOneWithoutPublicationNestedInput>;
  publicationType?: InputMaybe<EnumPublicationTypeFieldUpdateOperationsInput>;
  scrapeMethod?: InputMaybe<EnumScrapeMethodFieldUpdateOperationsInput>;
  syndicatedArticles?: InputMaybe<OnlineArticleUpdateManyWithoutSyndicatedFromNestedInput>;
  syndications?: InputMaybe<CoveragePieceSyndicationUpdateManyWithoutPublicationNestedInput>;
  tier?: InputMaybe<EnumPublicationTierFieldUpdateOperationsInput>;
};

export type PublicationUpdateWithoutSyndicatedArticlesInput = {
  authors?: InputMaybe<AuthorPublicationHistoryUpdateManyWithoutPublicationNestedInput>;
  country?: InputMaybe<CountryUpdateOneWithoutPublicationsNestedInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutPublicationNestedInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  domain?: InputMaybe<StringFieldUpdateOperationsInput>;
  feeds?: InputMaybe<FeedUpdateManyWithoutPublicationNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignoredAuthors?: InputMaybe<IgnoredAuthorUpdateManyWithoutPublicationNestedInput>;
  ignoredUrls?: InputMaybe<IgnoredUrlUpdateManyWithoutPublicationNestedInput>;
  logo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  monthlyReadership?: InputMaybe<BigIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  onlineArticles?: InputMaybe<OnlineArticleUpdateManyWithoutPublicationNestedInput>;
  printIssues?: InputMaybe<PrintIssueUpdateManyWithoutPublicationNestedInput>;
  printScraperSettings?: InputMaybe<PrintScraperSettingsUpdateOneWithoutPublicationNestedInput>;
  publicationType?: InputMaybe<EnumPublicationTypeFieldUpdateOperationsInput>;
  scrapeMethod?: InputMaybe<EnumScrapeMethodFieldUpdateOperationsInput>;
  syndications?: InputMaybe<CoveragePieceSyndicationUpdateManyWithoutPublicationNestedInput>;
  tier?: InputMaybe<EnumPublicationTierFieldUpdateOperationsInput>;
};

export type PublicationUpdateWithoutSyndicationsInput = {
  authors?: InputMaybe<AuthorPublicationHistoryUpdateManyWithoutPublicationNestedInput>;
  country?: InputMaybe<CountryUpdateOneWithoutPublicationsNestedInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutPublicationNestedInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  domain?: InputMaybe<StringFieldUpdateOperationsInput>;
  feeds?: InputMaybe<FeedUpdateManyWithoutPublicationNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ignoredAuthors?: InputMaybe<IgnoredAuthorUpdateManyWithoutPublicationNestedInput>;
  ignoredUrls?: InputMaybe<IgnoredUrlUpdateManyWithoutPublicationNestedInput>;
  logo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  monthlyReadership?: InputMaybe<BigIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  onlineArticles?: InputMaybe<OnlineArticleUpdateManyWithoutPublicationNestedInput>;
  printIssues?: InputMaybe<PrintIssueUpdateManyWithoutPublicationNestedInput>;
  printScraperSettings?: InputMaybe<PrintScraperSettingsUpdateOneWithoutPublicationNestedInput>;
  publicationType?: InputMaybe<EnumPublicationTypeFieldUpdateOperationsInput>;
  scrapeMethod?: InputMaybe<EnumScrapeMethodFieldUpdateOperationsInput>;
  syndicatedArticles?: InputMaybe<OnlineArticleUpdateManyWithoutSyndicatedFromNestedInput>;
  tier?: InputMaybe<EnumPublicationTierFieldUpdateOperationsInput>;
};

export type PublicationUpsertWithWhereUniqueWithoutCountryInput = {
  create: PublicationCreateWithoutCountryInput;
  update: PublicationUpdateWithoutCountryInput;
  where: PublicationWhereUniqueInput;
};

export type PublicationUpsertWithoutAuthorsInput = {
  create: PublicationCreateWithoutAuthorsInput;
  update: PublicationUpdateWithoutAuthorsInput;
  where?: InputMaybe<PublicationWhereInput>;
};

export type PublicationUpsertWithoutCoveragePiecesInput = {
  create: PublicationCreateWithoutCoveragePiecesInput;
  update: PublicationUpdateWithoutCoveragePiecesInput;
  where?: InputMaybe<PublicationWhereInput>;
};

export type PublicationUpsertWithoutOnlineArticlesInput = {
  create: PublicationCreateWithoutOnlineArticlesInput;
  update: PublicationUpdateWithoutOnlineArticlesInput;
  where?: InputMaybe<PublicationWhereInput>;
};

export type PublicationUpsertWithoutPrintIssuesInput = {
  create: PublicationCreateWithoutPrintIssuesInput;
  update: PublicationUpdateWithoutPrintIssuesInput;
  where?: InputMaybe<PublicationWhereInput>;
};

export type PublicationUpsertWithoutSyndicatedArticlesInput = {
  create: PublicationCreateWithoutSyndicatedArticlesInput;
  update: PublicationUpdateWithoutSyndicatedArticlesInput;
  where?: InputMaybe<PublicationWhereInput>;
};

export type PublicationUpsertWithoutSyndicationsInput = {
  create: PublicationCreateWithoutSyndicationsInput;
  update: PublicationUpdateWithoutSyndicationsInput;
  where?: InputMaybe<PublicationWhereInput>;
};

export type PublicationWhereInput = {
  AND?: InputMaybe<Array<PublicationWhereInput>>;
  NOT?: InputMaybe<Array<PublicationWhereInput>>;
  OR?: InputMaybe<Array<PublicationWhereInput>>;
  authors?: InputMaybe<AuthorPublicationHistoryListRelationFilter>;
  country?: InputMaybe<CountryNullableRelationFilter>;
  countryId?: InputMaybe<StringNullableFilter>;
  coveragePieces?: InputMaybe<CoveragePieceListRelationFilter>;
  description?: InputMaybe<StringFilter>;
  domain?: InputMaybe<StringFilter>;
  feeds?: InputMaybe<FeedListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  ignoredAuthors?: InputMaybe<IgnoredAuthorListRelationFilter>;
  ignoredUrls?: InputMaybe<IgnoredUrlListRelationFilter>;
  logo?: InputMaybe<StringNullableFilter>;
  monthlyReadership?: InputMaybe<BigIntFilter>;
  name?: InputMaybe<StringFilter>;
  onlineArticles?: InputMaybe<OnlineArticleListRelationFilter>;
  printIssues?: InputMaybe<PrintIssueListRelationFilter>;
  printScraperSettings?: InputMaybe<PrintScraperSettingsNullableRelationFilter>;
  publicationType?: InputMaybe<EnumPublicationTypeFilter>;
  scrapeMethod?: InputMaybe<EnumScrapeMethodFilter>;
  syndicatedArticles?: InputMaybe<OnlineArticleListRelationFilter>;
  syndications?: InputMaybe<CoveragePieceSyndicationListRelationFilter>;
  tier?: InputMaybe<EnumPublicationTierFilter>;
};

export type PublicationWhereUniqueInput = {
  AND?: InputMaybe<Array<PublicationWhereInput>>;
  NOT?: InputMaybe<Array<PublicationWhereInput>>;
  OR?: InputMaybe<Array<PublicationWhereInput>>;
  authors?: InputMaybe<AuthorPublicationHistoryListRelationFilter>;
  country?: InputMaybe<CountryNullableRelationFilter>;
  countryId?: InputMaybe<StringNullableFilter>;
  coveragePieces?: InputMaybe<CoveragePieceListRelationFilter>;
  description?: InputMaybe<StringFilter>;
  domain?: InputMaybe<Scalars['String']['input']>;
  feeds?: InputMaybe<FeedListRelationFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  ignoredAuthors?: InputMaybe<IgnoredAuthorListRelationFilter>;
  ignoredUrls?: InputMaybe<IgnoredUrlListRelationFilter>;
  logo?: InputMaybe<StringNullableFilter>;
  monthlyReadership?: InputMaybe<BigIntFilter>;
  name?: InputMaybe<StringFilter>;
  onlineArticles?: InputMaybe<OnlineArticleListRelationFilter>;
  printIssues?: InputMaybe<PrintIssueListRelationFilter>;
  printScraperSettings?: InputMaybe<PrintScraperSettingsNullableRelationFilter>;
  publicationType?: InputMaybe<EnumPublicationTypeFilter>;
  scrapeMethod?: InputMaybe<EnumScrapeMethodFilter>;
  syndicatedArticles?: InputMaybe<OnlineArticleListRelationFilter>;
  syndications?: InputMaybe<CoveragePieceSyndicationListRelationFilter>;
  tier?: InputMaybe<EnumPublicationTierFilter>;
};

export type Query = {
  __typename?: 'Query';
  activities: Array<ExtendedActivity>;
  activityComments: Array<ActivityComment>;
  aggregateNewsWrap: NewsWrapAggregate;
  article?: Maybe<ArticleUnion>;
  articles: Array<ArticleUnion>;
  articlesByAuthor: Array<ArticleUnion>;
  articlesForPublication: Array<ArticleUnion>;
  articlesFromURL: Array<ArticleUnion>;
  author: Author;
  authorsForPublication: Array<Author>;
  client: Client;
  clientMentions: Array<ClientMention>;
  clients: Array<Client>;
  competitorAnalysisByMonth: Array<CompetitorAnalysisByMonth>;
  content: Content;
  contents: Array<Content>;
  countries: Array<Country>;
  country: Country;
  coverageForCompetitorAnalysis: Array<CoverageForCompetitorAnalysisResult>;
  coveragePieces: Array<CoveragePiece>;
  coveragePiecesByMonth: CoveragePiecesByMonthResult;
  findFileMetadata: FileMetadata;
  findSimilarArticles: Array<ArticleUnion>;
  getAgencyDashboard?: Maybe<AgencyDashboardResponse>;
  getAgencyDashboardStatistics: AgencyDashboardStatistics;
  getClientCommentsForClient: Array<ClientComment>;
  getClientDashboard?: Maybe<ClientDashboardResponse>;
  getClientFiles: Array<FileListResult>;
  getCompetitorAnalysisPlacementsGraphData: Array<GraphDataResult>;
  getCompetitorAnalysisPredictedViewsGraphData: Array<GraphDataResult>;
  getCompetitorAnalysisTotalAudienceGraphData: Array<GraphDataResult>;
  getCompetitorSentimentChart: Array<GraphDataResult>;
  getCoveragePlacementsGraphData: Array<GraphDataResult>;
  getCoveragePredictedViewsGraphData: Array<GraphDataResult>;
  getCoverageTotalAudienceGraphData: Array<GraphDataResult>;
  getCoverageTrackerDateRange: DateRange;
  getEmbeddings: Scalars['String']['output'];
  getFileMetadataForActivity: Array<FileListResult>;
  getMeetingNotesForClient: Array<MeetingNotes>;
  getNotifications?: Maybe<Array<Notification>>;
  getScratchpad: Scalars['String']['output'];
  getSentimentAnalysis: Array<GraphDataResult>;
  getSuggestedArticles: Array<ArticleUnion>;
  getSuggestedCoverageForClient: Array<ArticleUnion>;
  newsJackItems: Array<NewsJackItem>;
  newsWrap: NewsWrap;
  newsWrapContent: NewsWrapContent;
  newsWrapContents: Array<NewsWrapContent>;
  newsWraps: Array<NewsWrap>;
  programPlannerItem: ProgramPlannerItem;
  programPlannerItems: Array<ProgramPlannerItem>;
  publication: Publication;
  publications: Array<Publication>;
  publicationsByIds: Array<Publication>;
  recentlyViewedContents: Array<RecentlyViewedContent>;
  roles: Array<Role>;
  searchAuthors: Array<SearchAuthor>;
  searchPublications: Array<Publication>;
  searchTags: Array<Scalars['String']['output']>;
  spokespeople: Array<Spokesperson>;
  suggestInputPlaceholder: Scalars['Boolean']['output'];
  suggestedAuthors: Array<Author>;
  users: Array<User>;
  workspace: Workspace;
};


export type QueryActivitiesArgs = {
  where: ActivityWhereInput;
};


export type QueryActivityCommentsArgs = {
  cursor?: InputMaybe<ActivityCommentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityCommentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActivityCommentOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ActivityCommentWhereInput>;
};


export type QueryAggregateNewsWrapArgs = {
  cursor?: InputMaybe<NewsWrapWhereUniqueInput>;
  orderBy?: InputMaybe<Array<NewsWrapOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NewsWrapWhereInput>;
};


export type QueryArticleArgs = {
  id: Scalars['String']['input'];
};


export type QueryArticlesArgs = {
  dateRange?: InputMaybe<FilterDateRange>;
  query: Scalars['String']['input'];
  skip: Scalars['Int']['input'];
  take: Scalars['Int']['input'];
};


export type QueryArticlesByAuthorArgs = {
  authorId: Scalars['String']['input'];
};


export type QueryArticlesForPublicationArgs = {
  publicationId: Scalars['String']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryArticlesFromUrlArgs = {
  url: Scalars['String']['input'];
};


export type QueryAuthorArgs = {
  id: Scalars['String']['input'];
};


export type QueryAuthorsForPublicationArgs = {
  publicationId: Scalars['String']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  skip: Scalars['Int']['input'];
  take: Scalars['Int']['input'];
};


export type QueryClientArgs = {
  where: ClientWhereUniqueInput;
};


export type QueryClientMentionsArgs = {
  cursor?: InputMaybe<ClientMentionWhereUniqueInput>;
  distinct?: InputMaybe<Array<ClientMentionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ClientMentionOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ClientMentionWhereInput>;
};


export type QueryClientsArgs = {
  cursor?: InputMaybe<ClientWhereUniqueInput>;
  distinct?: InputMaybe<Array<ClientScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ClientOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ClientWhereInput>;
};


export type QueryCompetitorAnalysisByMonthArgs = {
  clientIds: Array<Scalars['String']['input']>;
  where: CompetitorCoverageWhere;
};


export type QueryContentArgs = {
  where: ContentWhereUniqueInput;
};


export type QueryContentsArgs = {
  where: ContentWhereInput;
};


export type QueryCountriesArgs = {
  query?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCountryArgs = {
  id: Scalars['String']['input'];
};


export type QueryCoverageForCompetitorAnalysisArgs = {
  clientIds: Array<Scalars['String']['input']>;
  where: CompetitorCoverageWhere;
};


export type QueryCoveragePiecesArgs = {
  clientIds: Array<Scalars['String']['input']>;
  cursor?: InputMaybe<CoveragePieceWhereUniqueInput>;
  distinct?: InputMaybe<Array<CoveragePieceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CoveragePieceOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CoveragePieceWhereInput>;
};


export type QueryCoveragePiecesByMonthArgs = {
  clientIds: Array<Scalars['String']['input']>;
  cursor?: InputMaybe<CoveragePieceWhereUniqueInput>;
  distinct?: InputMaybe<Array<CoveragePieceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CoveragePieceOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CoveragePieceWhereInput>;
};


export type QueryFindFileMetadataArgs = {
  where: FileMetadataWhereUniqueInput;
};


export type QueryFindSimilarArticlesArgs = {
  id: Scalars['String']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetClientCommentsForClientArgs = {
  clientId: Scalars['String']['input'];
  searchText?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetClientFilesArgs = {
  input: GetClientFilesInput;
};


export type QueryGetCompetitorAnalysisPlacementsGraphDataArgs = {
  clientIds: Array<Scalars['String']['input']>;
  groupBy: GroupBy;
  where: CompetitorCoverageWhere;
};


export type QueryGetCompetitorAnalysisPredictedViewsGraphDataArgs = {
  clientIds: Array<Scalars['String']['input']>;
  groupBy: GroupBy;
  where: CompetitorCoverageWhere;
};


export type QueryGetCompetitorAnalysisTotalAudienceGraphDataArgs = {
  clientIds: Array<Scalars['String']['input']>;
  groupBy: GroupBy;
  where: CompetitorCoverageWhere;
};


export type QueryGetCompetitorSentimentChartArgs = {
  clientIds: Array<Scalars['String']['input']>;
  groupBy: GroupBy;
  where: CompetitorCoverageWhere;
};


export type QueryGetCoveragePlacementsGraphDataArgs = {
  clientIds: Array<Scalars['String']['input']>;
  cursor?: InputMaybe<CoveragePieceWhereUniqueInput>;
  distinct?: InputMaybe<Array<CoveragePieceScalarFieldEnum>>;
  groupBy: GroupBy;
  orderBy?: InputMaybe<Array<CoveragePieceOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CoveragePieceWhereInput>;
};


export type QueryGetCoveragePredictedViewsGraphDataArgs = {
  clientIds: Array<Scalars['String']['input']>;
  cursor?: InputMaybe<CoveragePieceWhereUniqueInput>;
  distinct?: InputMaybe<Array<CoveragePieceScalarFieldEnum>>;
  groupBy: GroupBy;
  orderBy?: InputMaybe<Array<CoveragePieceOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CoveragePieceWhereInput>;
};


export type QueryGetCoverageTotalAudienceGraphDataArgs = {
  clientIds: Array<Scalars['String']['input']>;
  cursor?: InputMaybe<CoveragePieceWhereUniqueInput>;
  distinct?: InputMaybe<Array<CoveragePieceScalarFieldEnum>>;
  groupBy: GroupBy;
  orderBy?: InputMaybe<Array<CoveragePieceOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CoveragePieceWhereInput>;
};


export type QueryGetCoverageTrackerDateRangeArgs = {
  clientId: Scalars['String']['input'];
};


export type QueryGetEmbeddingsArgs = {
  count: Scalars['Float']['input'];
  skip: Scalars['Float']['input'];
};


export type QueryGetFileMetadataForActivityArgs = {
  activityId: Scalars['String']['input'];
};


export type QueryGetMeetingNotesForClientArgs = {
  clientId: Scalars['String']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetNotificationsArgs = {
  where: GetNotificationsWhere;
};


export type QueryGetSentimentAnalysisArgs = {
  clientIds: Array<Scalars['String']['input']>;
  cursor?: InputMaybe<CoveragePieceWhereUniqueInput>;
  distinct?: InputMaybe<Array<CoveragePieceScalarFieldEnum>>;
  groupBy: GroupBy;
  orderBy?: InputMaybe<Array<CoveragePieceOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CoveragePieceWhereInput>;
};


export type QueryGetSuggestedArticlesArgs = {
  newsWrapId: Scalars['String']['input'];
  skip: Scalars['Int']['input'];
  take: Scalars['Int']['input'];
};


export type QueryGetSuggestedCoverageForClientArgs = {
  clientId: Scalars['String']['input'];
  country?: InputMaybe<Scalars['String']['input']>;
  dateRange?: InputMaybe<DateRangeInput>;
  includeSponsored?: InputMaybe<Scalars['Boolean']['input']>;
  skip: Scalars['Int']['input'];
  spokesperson?: InputMaybe<Scalars['String']['input']>;
  take: Scalars['Int']['input'];
};


export type QueryNewsJackItemsArgs = {
  cursor?: InputMaybe<NewsJackItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<NewsJackItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<NewsJackItemOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NewsJackItemWhereInput>;
};


export type QueryNewsWrapArgs = {
  where: NewsWrapWhereUniqueInput;
};


export type QueryNewsWrapContentArgs = {
  where: NewsWrapWhereUniqueInput;
};


export type QueryNewsWrapContentsArgs = {
  cursor?: InputMaybe<NewsWrapContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<NewsWrapContentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<NewsWrapContentOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NewsWrapContentWhereInput>;
};


export type QueryNewsWrapsArgs = {
  cursor?: InputMaybe<NewsWrapWhereUniqueInput>;
  distinct?: InputMaybe<Array<NewsWrapScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<NewsWrapOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NewsWrapWhereInput>;
};


export type QueryProgramPlannerItemArgs = {
  id: Scalars['String']['input'];
};


export type QueryProgramPlannerItemsArgs = {
  cursor?: InputMaybe<ProgramPlannerItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProgramPlannerItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProgramPlannerItemOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProgramPlannerItemWhereInput>;
};


export type QueryPublicationArgs = {
  id: Scalars['String']['input'];
};


export type QueryPublicationsArgs = {
  cursor?: InputMaybe<PublicationWhereUniqueInput>;
  distinct?: InputMaybe<Array<PublicationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PublicationOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PublicationWhereInput>;
};


export type QueryPublicationsByIdsArgs = {
  ids: Array<Scalars['String']['input']>;
};


export type QueryRecentlyViewedContentsArgs = {
  cursor?: InputMaybe<RecentlyViewedContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<RecentlyViewedContentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RecentlyViewedContentOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RecentlyViewedContentWhereInput>;
};


export type QueryRolesArgs = {
  cursor?: InputMaybe<RoleWhereUniqueInput>;
  distinct?: InputMaybe<Array<RoleScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RoleOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RoleWhereInput>;
};


export type QuerySearchAuthorsArgs = {
  countryId?: InputMaybe<Scalars['String']['input']>;
  query: Scalars['String']['input'];
  skip: Scalars['Float']['input'];
  take: Scalars['Float']['input'];
};


export type QuerySearchPublicationsArgs = {
  countries?: InputMaybe<Array<Scalars['String']['input']>>;
  query?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  tier?: InputMaybe<PublicationTier>;
  types?: InputMaybe<Array<PublicationType>>;
};


export type QuerySearchTagsArgs = {
  query: Scalars['String']['input'];
};


export type QuerySpokespeopleArgs = {
  cursor?: InputMaybe<SpokespersonWhereUniqueInput>;
  distinct?: InputMaybe<Array<SpokespersonScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SpokespersonOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SpokespersonWhereInput>;
};


export type QuerySuggestInputPlaceholderArgs = {
  input: SuggestInput;
};


export type QuerySuggestedAuthorsArgs = {
  contentId: Scalars['String']['input'];
  skip: Scalars['Int']['input'];
  take: Scalars['Int']['input'];
};


export type QueryUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type RecentlyViewedContent = {
  __typename?: 'RecentlyViewedContent';
  content: Content;
  contentId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isFavourited: Scalars['Boolean']['output'];
  user: User;
  userId: Scalars['String']['output'];
  viewedAt: Scalars['DateTime']['output'];
};

export type RecentlyViewedContentContentIdUserIdCompoundUniqueInput = {
  contentId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type RecentlyViewedContentCreateManyContentInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  isFavourited?: InputMaybe<Scalars['Boolean']['input']>;
  userId: Scalars['String']['input'];
  viewedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type RecentlyViewedContentCreateManyContentInputEnvelope = {
  data: Array<RecentlyViewedContentCreateManyContentInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RecentlyViewedContentCreateManyUserInput = {
  contentId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isFavourited?: InputMaybe<Scalars['Boolean']['input']>;
  viewedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type RecentlyViewedContentCreateManyUserInputEnvelope = {
  data: Array<RecentlyViewedContentCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RecentlyViewedContentCreateNestedManyWithoutContentInput = {
  connect?: InputMaybe<Array<RecentlyViewedContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RecentlyViewedContentCreateOrConnectWithoutContentInput>>;
  create?: InputMaybe<Array<RecentlyViewedContentCreateWithoutContentInput>>;
  createMany?: InputMaybe<RecentlyViewedContentCreateManyContentInputEnvelope>;
};

export type RecentlyViewedContentCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<RecentlyViewedContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RecentlyViewedContentCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<RecentlyViewedContentCreateWithoutUserInput>>;
  createMany?: InputMaybe<RecentlyViewedContentCreateManyUserInputEnvelope>;
};

export type RecentlyViewedContentCreateOrConnectWithoutContentInput = {
  create: RecentlyViewedContentCreateWithoutContentInput;
  where: RecentlyViewedContentWhereUniqueInput;
};

export type RecentlyViewedContentCreateOrConnectWithoutUserInput = {
  create: RecentlyViewedContentCreateWithoutUserInput;
  where: RecentlyViewedContentWhereUniqueInput;
};

export type RecentlyViewedContentCreateWithoutContentInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  isFavourited?: InputMaybe<Scalars['Boolean']['input']>;
  user: UserCreateNestedOneWithoutRecentlyViewedContentInput;
  viewedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type RecentlyViewedContentCreateWithoutUserInput = {
  content: ContentCreateNestedOneWithoutRecentlyViewedInput;
  id?: InputMaybe<Scalars['String']['input']>;
  isFavourited?: InputMaybe<Scalars['Boolean']['input']>;
  viewedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type RecentlyViewedContentListRelationFilter = {
  every?: InputMaybe<RecentlyViewedContentWhereInput>;
  none?: InputMaybe<RecentlyViewedContentWhereInput>;
  some?: InputMaybe<RecentlyViewedContentWhereInput>;
};

export type RecentlyViewedContentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum RecentlyViewedContentOrderByRelevanceFieldEnum {
  ContentId = 'contentId',
  Id = 'id',
  UserId = 'userId'
}

export type RecentlyViewedContentOrderByRelevanceInput = {
  fields: Array<RecentlyViewedContentOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type RecentlyViewedContentOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<RecentlyViewedContentOrderByRelevanceInput>;
  content?: InputMaybe<ContentOrderByWithRelationAndSearchRelevanceInput>;
  contentId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isFavourited?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  userId?: InputMaybe<SortOrder>;
  viewedAt?: InputMaybe<SortOrder>;
};

export enum RecentlyViewedContentScalarFieldEnum {
  ContentId = 'contentId',
  Id = 'id',
  IsFavourited = 'isFavourited',
  UserId = 'userId',
  ViewedAt = 'viewedAt'
}

export type RecentlyViewedContentScalarWhereInput = {
  AND?: InputMaybe<Array<RecentlyViewedContentScalarWhereInput>>;
  NOT?: InputMaybe<Array<RecentlyViewedContentScalarWhereInput>>;
  OR?: InputMaybe<Array<RecentlyViewedContentScalarWhereInput>>;
  contentId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  isFavourited?: InputMaybe<BoolFilter>;
  userId?: InputMaybe<StringFilter>;
  viewedAt?: InputMaybe<DateTimeFilter>;
};

export type RecentlyViewedContentUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isFavourited?: InputMaybe<BoolFieldUpdateOperationsInput>;
  viewedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RecentlyViewedContentUpdateManyWithWhereWithoutContentInput = {
  data: RecentlyViewedContentUpdateManyMutationInput;
  where: RecentlyViewedContentScalarWhereInput;
};

export type RecentlyViewedContentUpdateManyWithWhereWithoutUserInput = {
  data: RecentlyViewedContentUpdateManyMutationInput;
  where: RecentlyViewedContentScalarWhereInput;
};

export type RecentlyViewedContentUpdateManyWithoutContentNestedInput = {
  connect?: InputMaybe<Array<RecentlyViewedContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RecentlyViewedContentCreateOrConnectWithoutContentInput>>;
  create?: InputMaybe<Array<RecentlyViewedContentCreateWithoutContentInput>>;
  createMany?: InputMaybe<RecentlyViewedContentCreateManyContentInputEnvelope>;
  delete?: InputMaybe<Array<RecentlyViewedContentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RecentlyViewedContentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RecentlyViewedContentWhereUniqueInput>>;
  set?: InputMaybe<Array<RecentlyViewedContentWhereUniqueInput>>;
  update?: InputMaybe<Array<RecentlyViewedContentUpdateWithWhereUniqueWithoutContentInput>>;
  updateMany?: InputMaybe<Array<RecentlyViewedContentUpdateManyWithWhereWithoutContentInput>>;
  upsert?: InputMaybe<Array<RecentlyViewedContentUpsertWithWhereUniqueWithoutContentInput>>;
};

export type RecentlyViewedContentUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<RecentlyViewedContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RecentlyViewedContentCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<RecentlyViewedContentCreateWithoutUserInput>>;
  createMany?: InputMaybe<RecentlyViewedContentCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<RecentlyViewedContentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RecentlyViewedContentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RecentlyViewedContentWhereUniqueInput>>;
  set?: InputMaybe<Array<RecentlyViewedContentWhereUniqueInput>>;
  update?: InputMaybe<Array<RecentlyViewedContentUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<RecentlyViewedContentUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<RecentlyViewedContentUpsertWithWhereUniqueWithoutUserInput>>;
};

export type RecentlyViewedContentUpdateWithWhereUniqueWithoutContentInput = {
  data: RecentlyViewedContentUpdateWithoutContentInput;
  where: RecentlyViewedContentWhereUniqueInput;
};

export type RecentlyViewedContentUpdateWithWhereUniqueWithoutUserInput = {
  data: RecentlyViewedContentUpdateWithoutUserInput;
  where: RecentlyViewedContentWhereUniqueInput;
};

export type RecentlyViewedContentUpdateWithoutContentInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isFavourited?: InputMaybe<BoolFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutRecentlyViewedContentNestedInput>;
  viewedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RecentlyViewedContentUpdateWithoutUserInput = {
  content?: InputMaybe<ContentUpdateOneRequiredWithoutRecentlyViewedNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isFavourited?: InputMaybe<BoolFieldUpdateOperationsInput>;
  viewedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RecentlyViewedContentUpsertWithWhereUniqueWithoutContentInput = {
  create: RecentlyViewedContentCreateWithoutContentInput;
  update: RecentlyViewedContentUpdateWithoutContentInput;
  where: RecentlyViewedContentWhereUniqueInput;
};

export type RecentlyViewedContentUpsertWithWhereUniqueWithoutUserInput = {
  create: RecentlyViewedContentCreateWithoutUserInput;
  update: RecentlyViewedContentUpdateWithoutUserInput;
  where: RecentlyViewedContentWhereUniqueInput;
};

export type RecentlyViewedContentWhereInput = {
  AND?: InputMaybe<Array<RecentlyViewedContentWhereInput>>;
  NOT?: InputMaybe<Array<RecentlyViewedContentWhereInput>>;
  OR?: InputMaybe<Array<RecentlyViewedContentWhereInput>>;
  content?: InputMaybe<ContentRelationFilter>;
  contentId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  isFavourited?: InputMaybe<BoolFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  viewedAt?: InputMaybe<DateTimeFilter>;
};

export type RecentlyViewedContentWhereUniqueInput = {
  AND?: InputMaybe<Array<RecentlyViewedContentWhereInput>>;
  NOT?: InputMaybe<Array<RecentlyViewedContentWhereInput>>;
  OR?: InputMaybe<Array<RecentlyViewedContentWhereInput>>;
  content?: InputMaybe<ContentRelationFilter>;
  contentId?: InputMaybe<StringFilter>;
  contentId_userId?: InputMaybe<RecentlyViewedContentContentIdUserIdCompoundUniqueInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  isFavourited?: InputMaybe<BoolFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  viewedAt?: InputMaybe<DateTimeFilter>;
};

export type RegionCreateNestedManyWithoutCountriesInput = {
  connect?: InputMaybe<Array<RegionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RegionCreateOrConnectWithoutCountriesInput>>;
  create?: InputMaybe<Array<RegionCreateWithoutCountriesInput>>;
};

export type RegionCreateOrConnectWithoutCountriesInput = {
  create: RegionCreateWithoutCountriesInput;
  where: RegionWhereUniqueInput;
};

export type RegionCreateWithoutCountriesInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type RegionListRelationFilter = {
  every?: InputMaybe<RegionWhereInput>;
  none?: InputMaybe<RegionWhereInput>;
  some?: InputMaybe<RegionWhereInput>;
};

export type RegionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type RegionScalarWhereInput = {
  AND?: InputMaybe<Array<RegionScalarWhereInput>>;
  NOT?: InputMaybe<Array<RegionScalarWhereInput>>;
  OR?: InputMaybe<Array<RegionScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
};

export type RegionUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type RegionUpdateManyWithWhereWithoutCountriesInput = {
  data: RegionUpdateManyMutationInput;
  where: RegionScalarWhereInput;
};

export type RegionUpdateManyWithoutCountriesNestedInput = {
  connect?: InputMaybe<Array<RegionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RegionCreateOrConnectWithoutCountriesInput>>;
  create?: InputMaybe<Array<RegionCreateWithoutCountriesInput>>;
  delete?: InputMaybe<Array<RegionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RegionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RegionWhereUniqueInput>>;
  set?: InputMaybe<Array<RegionWhereUniqueInput>>;
  update?: InputMaybe<Array<RegionUpdateWithWhereUniqueWithoutCountriesInput>>;
  updateMany?: InputMaybe<Array<RegionUpdateManyWithWhereWithoutCountriesInput>>;
  upsert?: InputMaybe<Array<RegionUpsertWithWhereUniqueWithoutCountriesInput>>;
};

export type RegionUpdateWithWhereUniqueWithoutCountriesInput = {
  data: RegionUpdateWithoutCountriesInput;
  where: RegionWhereUniqueInput;
};

export type RegionUpdateWithoutCountriesInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type RegionUpsertWithWhereUniqueWithoutCountriesInput = {
  create: RegionCreateWithoutCountriesInput;
  update: RegionUpdateWithoutCountriesInput;
  where: RegionWhereUniqueInput;
};

export type RegionWhereInput = {
  AND?: InputMaybe<Array<RegionWhereInput>>;
  NOT?: InputMaybe<Array<RegionWhereInput>>;
  OR?: InputMaybe<Array<RegionWhereInput>>;
  countries?: InputMaybe<CountryListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
};

export type RegionWhereUniqueInput = {
  AND?: InputMaybe<Array<RegionWhereInput>>;
  NOT?: InputMaybe<Array<RegionWhereInput>>;
  OR?: InputMaybe<Array<RegionWhereInput>>;
  countries?: InputMaybe<CountryListRelationFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<StringFilter>;
};

export type RelevantArticle = {
  __typename?: 'RelevantArticle';
  createdAt: Scalars['String']['output'];
  relevantSentences: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type RenameFileInput = {
  id: Scalars['String']['input'];
  newName: Scalars['String']['input'];
};

export type RephraseTextInput = {
  context: Scalars['String']['input'];
  text: Scalars['String']['input'];
};

export type Role = {
  __typename?: 'Role';
  _count?: Maybe<RoleCount>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  permissions: Array<RolePermissions>;
  users: Array<User>;
  workspace: Workspace;
  workspaceId: Scalars['String']['output'];
};


export type RolePermissionsArgs = {
  cursor?: InputMaybe<RolePermissionsWhereUniqueInput>;
  distinct?: InputMaybe<Array<RolePermissionsScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RolePermissionsOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RolePermissionsWhereInput>;
};


export type RoleUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};

export type RoleCount = {
  __typename?: 'RoleCount';
  permissions: Scalars['Int']['output'];
  users: Scalars['Int']['output'];
};


export type RoleCountPermissionsArgs = {
  where?: InputMaybe<RolePermissionsWhereInput>;
};


export type RoleCountUsersArgs = {
  where?: InputMaybe<UserWhereInput>;
};

export type RoleCreate = {
  name: Scalars['String']['input'];
  permissions: RolePermissionsCreateNestedManyWithoutRoleInput;
};

export type RoleCreateManyWorkspaceInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type RoleCreateManyWorkspaceInputEnvelope = {
  data: Array<RoleCreateManyWorkspaceInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RoleCreateNestedManyWithoutWorkspaceInput = {
  connect?: InputMaybe<Array<RoleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RoleCreateOrConnectWithoutWorkspaceInput>>;
  create?: InputMaybe<Array<RoleCreateWithoutWorkspaceInput>>;
  createMany?: InputMaybe<RoleCreateManyWorkspaceInputEnvelope>;
};

export type RoleCreateNestedOneWithoutUsersInput = {
  connect?: InputMaybe<RoleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RoleCreateOrConnectWithoutUsersInput>;
  create?: InputMaybe<RoleCreateWithoutUsersInput>;
};

export type RoleCreateOrConnectWithoutUsersInput = {
  create: RoleCreateWithoutUsersInput;
  where: RoleWhereUniqueInput;
};

export type RoleCreateOrConnectWithoutWorkspaceInput = {
  create: RoleCreateWithoutWorkspaceInput;
  where: RoleWhereUniqueInput;
};

export type RoleCreateWithoutUsersInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  permissions?: InputMaybe<RolePermissionsCreateNestedManyWithoutRoleInput>;
  workspace: WorkspaceCreateNestedOneWithoutRolesInput;
};

export type RoleCreateWithoutWorkspaceInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  permissions?: InputMaybe<RolePermissionsCreateNestedManyWithoutRoleInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutRoleInput>;
};

export type RoleListRelationFilter = {
  every?: InputMaybe<RoleWhereInput>;
  none?: InputMaybe<RoleWhereInput>;
  some?: InputMaybe<RoleWhereInput>;
};

export type RoleNameWorkspaceIdCompoundUniqueInput = {
  name: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};

export type RoleOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum RoleOrderByRelevanceFieldEnum {
  Id = 'id',
  Name = 'name',
  WorkspaceId = 'workspaceId'
}

export type RoleOrderByRelevanceInput = {
  fields: Array<RoleOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type RoleOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<RoleOrderByRelevanceInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  permissions?: InputMaybe<RolePermissionsOrderByRelationAggregateInput>;
  users?: InputMaybe<UserOrderByRelationAggregateInput>;
  workspace?: InputMaybe<WorkspaceOrderByWithRelationAndSearchRelevanceInput>;
  workspaceId?: InputMaybe<SortOrder>;
};

export type RolePermissions = {
  __typename?: 'RolePermissions';
  id: Scalars['String']['output'];
  permission: PermissionEnum;
  roleId: Scalars['String']['output'];
};

export type RolePermissionsCreateManyRoleInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  permission: PermissionEnum;
};

export type RolePermissionsCreateManyRoleInputEnvelope = {
  data: Array<RolePermissionsCreateManyRoleInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RolePermissionsCreateNestedManyWithoutRoleInput = {
  connect?: InputMaybe<Array<RolePermissionsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RolePermissionsCreateOrConnectWithoutRoleInput>>;
  create?: InputMaybe<Array<RolePermissionsCreateWithoutRoleInput>>;
  createMany?: InputMaybe<RolePermissionsCreateManyRoleInputEnvelope>;
};

export type RolePermissionsCreateOrConnectWithoutRoleInput = {
  create: RolePermissionsCreateWithoutRoleInput;
  where: RolePermissionsWhereUniqueInput;
};

export type RolePermissionsCreateWithoutRoleInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  permission: PermissionEnum;
};

export type RolePermissionsListRelationFilter = {
  every?: InputMaybe<RolePermissionsWhereInput>;
  none?: InputMaybe<RolePermissionsWhereInput>;
  some?: InputMaybe<RolePermissionsWhereInput>;
};

export type RolePermissionsOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum RolePermissionsOrderByRelevanceFieldEnum {
  Id = 'id',
  RoleId = 'roleId'
}

export type RolePermissionsOrderByRelevanceInput = {
  fields: Array<RolePermissionsOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type RolePermissionsOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<RolePermissionsOrderByRelevanceInput>;
  id?: InputMaybe<SortOrder>;
  permission?: InputMaybe<SortOrder>;
  role?: InputMaybe<RoleOrderByWithRelationAndSearchRelevanceInput>;
  roleId?: InputMaybe<SortOrder>;
};

export enum RolePermissionsScalarFieldEnum {
  Id = 'id',
  Permission = 'permission',
  RoleId = 'roleId'
}

export type RolePermissionsScalarWhereInput = {
  AND?: InputMaybe<Array<RolePermissionsScalarWhereInput>>;
  NOT?: InputMaybe<Array<RolePermissionsScalarWhereInput>>;
  OR?: InputMaybe<Array<RolePermissionsScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  permission?: InputMaybe<EnumPermissionEnumFilter>;
  roleId?: InputMaybe<StringFilter>;
};

export type RolePermissionsUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  permission?: InputMaybe<EnumPermissionEnumFieldUpdateOperationsInput>;
};

export type RolePermissionsUpdateManyWithWhereWithoutRoleInput = {
  data: RolePermissionsUpdateManyMutationInput;
  where: RolePermissionsScalarWhereInput;
};

export type RolePermissionsUpdateManyWithoutRoleNestedInput = {
  connect?: InputMaybe<Array<RolePermissionsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RolePermissionsCreateOrConnectWithoutRoleInput>>;
  create?: InputMaybe<Array<RolePermissionsCreateWithoutRoleInput>>;
  createMany?: InputMaybe<RolePermissionsCreateManyRoleInputEnvelope>;
  delete?: InputMaybe<Array<RolePermissionsWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RolePermissionsScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RolePermissionsWhereUniqueInput>>;
  set?: InputMaybe<Array<RolePermissionsWhereUniqueInput>>;
  update?: InputMaybe<Array<RolePermissionsUpdateWithWhereUniqueWithoutRoleInput>>;
  updateMany?: InputMaybe<Array<RolePermissionsUpdateManyWithWhereWithoutRoleInput>>;
  upsert?: InputMaybe<Array<RolePermissionsUpsertWithWhereUniqueWithoutRoleInput>>;
};

export type RolePermissionsUpdateWithWhereUniqueWithoutRoleInput = {
  data: RolePermissionsUpdateWithoutRoleInput;
  where: RolePermissionsWhereUniqueInput;
};

export type RolePermissionsUpdateWithoutRoleInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  permission?: InputMaybe<EnumPermissionEnumFieldUpdateOperationsInput>;
};

export type RolePermissionsUpsertWithWhereUniqueWithoutRoleInput = {
  create: RolePermissionsCreateWithoutRoleInput;
  update: RolePermissionsUpdateWithoutRoleInput;
  where: RolePermissionsWhereUniqueInput;
};

export type RolePermissionsWhereInput = {
  AND?: InputMaybe<Array<RolePermissionsWhereInput>>;
  NOT?: InputMaybe<Array<RolePermissionsWhereInput>>;
  OR?: InputMaybe<Array<RolePermissionsWhereInput>>;
  id?: InputMaybe<StringFilter>;
  permission?: InputMaybe<EnumPermissionEnumFilter>;
  role?: InputMaybe<RoleRelationFilter>;
  roleId?: InputMaybe<StringFilter>;
};

export type RolePermissionsWhereUniqueInput = {
  AND?: InputMaybe<Array<RolePermissionsWhereInput>>;
  NOT?: InputMaybe<Array<RolePermissionsWhereInput>>;
  OR?: InputMaybe<Array<RolePermissionsWhereInput>>;
  id?: InputMaybe<Scalars['String']['input']>;
  permission?: InputMaybe<EnumPermissionEnumFilter>;
  role?: InputMaybe<RoleRelationFilter>;
  roleId?: InputMaybe<StringFilter>;
};

export type RoleRelationFilter = {
  is?: InputMaybe<RoleWhereInput>;
  isNot?: InputMaybe<RoleWhereInput>;
};

export enum RoleScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  WorkspaceId = 'workspaceId'
}

export type RoleScalarWhereInput = {
  AND?: InputMaybe<Array<RoleScalarWhereInput>>;
  NOT?: InputMaybe<Array<RoleScalarWhereInput>>;
  OR?: InputMaybe<Array<RoleScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  workspaceId?: InputMaybe<StringFilter>;
};

export type RoleUpdateInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  permissions?: InputMaybe<RolePermissionsUpdateManyWithoutRoleNestedInput>;
  users?: InputMaybe<UserUpdateManyWithoutRoleNestedInput>;
  workspace?: InputMaybe<WorkspaceUpdateOneRequiredWithoutRolesNestedInput>;
};

export type RoleUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type RoleUpdateManyWithWhereWithoutWorkspaceInput = {
  data: RoleUpdateManyMutationInput;
  where: RoleScalarWhereInput;
};

export type RoleUpdateManyWithoutWorkspaceNestedInput = {
  connect?: InputMaybe<Array<RoleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RoleCreateOrConnectWithoutWorkspaceInput>>;
  create?: InputMaybe<Array<RoleCreateWithoutWorkspaceInput>>;
  createMany?: InputMaybe<RoleCreateManyWorkspaceInputEnvelope>;
  delete?: InputMaybe<Array<RoleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RoleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RoleWhereUniqueInput>>;
  set?: InputMaybe<Array<RoleWhereUniqueInput>>;
  update?: InputMaybe<Array<RoleUpdateWithWhereUniqueWithoutWorkspaceInput>>;
  updateMany?: InputMaybe<Array<RoleUpdateManyWithWhereWithoutWorkspaceInput>>;
  upsert?: InputMaybe<Array<RoleUpsertWithWhereUniqueWithoutWorkspaceInput>>;
};

export type RoleUpdateOneRequiredWithoutUsersNestedInput = {
  connect?: InputMaybe<RoleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RoleCreateOrConnectWithoutUsersInput>;
  create?: InputMaybe<RoleCreateWithoutUsersInput>;
  update?: InputMaybe<RoleUpdateToOneWithWhereWithoutUsersInput>;
  upsert?: InputMaybe<RoleUpsertWithoutUsersInput>;
};

export type RoleUpdateToOneWithWhereWithoutUsersInput = {
  data: RoleUpdateWithoutUsersInput;
  where?: InputMaybe<RoleWhereInput>;
};

export type RoleUpdateWithWhereUniqueWithoutWorkspaceInput = {
  data: RoleUpdateWithoutWorkspaceInput;
  where: RoleWhereUniqueInput;
};

export type RoleUpdateWithoutUsersInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  permissions?: InputMaybe<RolePermissionsUpdateManyWithoutRoleNestedInput>;
  workspace?: InputMaybe<WorkspaceUpdateOneRequiredWithoutRolesNestedInput>;
};

export type RoleUpdateWithoutWorkspaceInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  permissions?: InputMaybe<RolePermissionsUpdateManyWithoutRoleNestedInput>;
  users?: InputMaybe<UserUpdateManyWithoutRoleNestedInput>;
};

export type RoleUpsertWithWhereUniqueWithoutWorkspaceInput = {
  create: RoleCreateWithoutWorkspaceInput;
  update: RoleUpdateWithoutWorkspaceInput;
  where: RoleWhereUniqueInput;
};

export type RoleUpsertWithoutUsersInput = {
  create: RoleCreateWithoutUsersInput;
  update: RoleUpdateWithoutUsersInput;
  where?: InputMaybe<RoleWhereInput>;
};

export type RoleWhereInput = {
  AND?: InputMaybe<Array<RoleWhereInput>>;
  NOT?: InputMaybe<Array<RoleWhereInput>>;
  OR?: InputMaybe<Array<RoleWhereInput>>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  permissions?: InputMaybe<RolePermissionsListRelationFilter>;
  users?: InputMaybe<UserListRelationFilter>;
  workspace?: InputMaybe<WorkspaceRelationFilter>;
  workspaceId?: InputMaybe<StringFilter>;
};

export type RoleWhereUniqueInput = {
  AND?: InputMaybe<Array<RoleWhereInput>>;
  NOT?: InputMaybe<Array<RoleWhereInput>>;
  OR?: InputMaybe<Array<RoleWhereInput>>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<StringFilter>;
  name_workspaceId?: InputMaybe<RoleNameWorkspaceIdCompoundUniqueInput>;
  permissions?: InputMaybe<RolePermissionsListRelationFilter>;
  users?: InputMaybe<UserListRelationFilter>;
  workspace?: InputMaybe<WorkspaceRelationFilter>;
  workspaceId?: InputMaybe<StringFilter>;
};

export enum ScrapeMethod {
  Crawl = 'CRAWL',
  Rss = 'RSS'
}

export type ScratchpadCreateNestedOneWithoutUserInput = {
  connect?: InputMaybe<ScratchpadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScratchpadCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<ScratchpadCreateWithoutUserInput>;
};

export type ScratchpadCreateOrConnectWithoutUserInput = {
  create: ScratchpadCreateWithoutUserInput;
  where: ScratchpadWhereUniqueInput;
};

export type ScratchpadCreateWithoutUserInput = {
  content: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
};

export type ScratchpadNullableRelationFilter = {
  is?: InputMaybe<ScratchpadWhereInput>;
  isNot?: InputMaybe<ScratchpadWhereInput>;
};

export enum ScratchpadOrderByRelevanceFieldEnum {
  Content = 'content',
  Id = 'id',
  UserId = 'userId'
}

export type ScratchpadOrderByRelevanceInput = {
  fields: Array<ScratchpadOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type ScratchpadOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<ScratchpadOrderByRelevanceInput>;
  content?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  userId?: InputMaybe<SortOrder>;
};

export type ScratchpadUpdateOneWithoutUserNestedInput = {
  connect?: InputMaybe<ScratchpadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScratchpadCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<ScratchpadCreateWithoutUserInput>;
  delete?: InputMaybe<ScratchpadWhereInput>;
  disconnect?: InputMaybe<ScratchpadWhereInput>;
  update?: InputMaybe<ScratchpadUpdateToOneWithWhereWithoutUserInput>;
  upsert?: InputMaybe<ScratchpadUpsertWithoutUserInput>;
};

export type ScratchpadUpdateToOneWithWhereWithoutUserInput = {
  data: ScratchpadUpdateWithoutUserInput;
  where?: InputMaybe<ScratchpadWhereInput>;
};

export type ScratchpadUpdateWithoutUserInput = {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ScratchpadUpsertWithoutUserInput = {
  create: ScratchpadCreateWithoutUserInput;
  update: ScratchpadUpdateWithoutUserInput;
  where?: InputMaybe<ScratchpadWhereInput>;
};

export type ScratchpadWhereInput = {
  AND?: InputMaybe<Array<ScratchpadWhereInput>>;
  NOT?: InputMaybe<Array<ScratchpadWhereInput>>;
  OR?: InputMaybe<Array<ScratchpadWhereInput>>;
  content?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type ScratchpadWhereUniqueInput = {
  AND?: InputMaybe<Array<ScratchpadWhereInput>>;
  NOT?: InputMaybe<Array<ScratchpadWhereInput>>;
  OR?: InputMaybe<Array<ScratchpadWhereInput>>;
  content?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SearchAuthor = {
  __typename?: 'SearchAuthor';
  _count?: Maybe<AuthorCount>;
  bio: Scalars['String']['output'];
  id: Scalars['String']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  publicationHistory: Array<AuthorPublicationHistory>;
  relevantArticles: Array<RelevantArticle>;
};

export type SendCoveragePieceInput = {
  clientId: Scalars['String']['input'];
  dates: Array<Scalars['DateTime']['input']>;
  emails: Array<Scalars['String']['input']>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type SortOrderInput = {
  nulls?: InputMaybe<NullsOrder>;
  sort: SortOrder;
};

export type Spokesperson = {
  __typename?: 'Spokesperson';
  _count?: Maybe<SpokespersonCount>;
  client: Client;
  clientId: Scalars['String']['output'];
  content: Scalars['String']['output'];
  coveragePieces: Array<CoveragePiece>;
  id: Scalars['String']['output'];
  image?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  title: Scalars['String']['output'];
};


export type SpokespersonCoveragePiecesArgs = {
  cursor?: InputMaybe<CoveragePieceWhereUniqueInput>;
  distinct?: InputMaybe<Array<CoveragePieceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CoveragePieceOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CoveragePieceWhereInput>;
};

export type SpokespersonCount = {
  __typename?: 'SpokespersonCount';
  coveragePieces: Scalars['Int']['output'];
};


export type SpokespersonCountCoveragePiecesArgs = {
  where?: InputMaybe<CoveragePieceWhereInput>;
};

export type SpokespersonCreateInput = {
  client: ClientCreateNestedOneWithoutSpokespeopleInput;
  content: Scalars['String']['input'];
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutSpokespeopleInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type SpokespersonCreateManyClientInput = {
  content: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type SpokespersonCreateManyClientInputEnvelope = {
  data: Array<SpokespersonCreateManyClientInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SpokespersonCreateNestedManyWithoutClientInput = {
  connect?: InputMaybe<Array<SpokespersonWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SpokespersonCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<SpokespersonCreateWithoutClientInput>>;
  createMany?: InputMaybe<SpokespersonCreateManyClientInputEnvelope>;
};

export type SpokespersonCreateNestedManyWithoutCoveragePiecesInput = {
  connect?: InputMaybe<Array<SpokespersonWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SpokespersonCreateOrConnectWithoutCoveragePiecesInput>>;
  create?: InputMaybe<Array<SpokespersonCreateWithoutCoveragePiecesInput>>;
};

export type SpokespersonCreateOrConnectWithoutClientInput = {
  create: SpokespersonCreateWithoutClientInput;
  where: SpokespersonWhereUniqueInput;
};

export type SpokespersonCreateOrConnectWithoutCoveragePiecesInput = {
  create: SpokespersonCreateWithoutCoveragePiecesInput;
  where: SpokespersonWhereUniqueInput;
};

export type SpokespersonCreateWithoutClientInput = {
  content: Scalars['String']['input'];
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutSpokespeopleInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type SpokespersonCreateWithoutCoveragePiecesInput = {
  client: ClientCreateNestedOneWithoutSpokespeopleInput;
  content: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type SpokespersonListRelationFilter = {
  every?: InputMaybe<SpokespersonWhereInput>;
  none?: InputMaybe<SpokespersonWhereInput>;
  some?: InputMaybe<SpokespersonWhereInput>;
};

export type SpokespersonOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum SpokespersonOrderByRelevanceFieldEnum {
  ClientId = 'clientId',
  Content = 'content',
  Id = 'id',
  Image = 'image',
  Name = 'name',
  Title = 'title'
}

export type SpokespersonOrderByRelevanceInput = {
  fields: Array<SpokespersonOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type SpokespersonOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<SpokespersonOrderByRelevanceInput>;
  client?: InputMaybe<ClientOrderByWithRelationAndSearchRelevanceInput>;
  clientId?: InputMaybe<SortOrder>;
  content?: InputMaybe<SortOrder>;
  coveragePieces?: InputMaybe<CoveragePieceOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export enum SpokespersonScalarFieldEnum {
  ClientId = 'clientId',
  Content = 'content',
  Id = 'id',
  Image = 'image',
  Name = 'name',
  Title = 'title'
}

export type SpokespersonScalarWhereInput = {
  AND?: InputMaybe<Array<SpokespersonScalarWhereInput>>;
  NOT?: InputMaybe<Array<SpokespersonScalarWhereInput>>;
  OR?: InputMaybe<Array<SpokespersonScalarWhereInput>>;
  clientId?: InputMaybe<StringFilter>;
  content?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  image?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
};

export type SpokespersonUpdateInput = {
  client?: InputMaybe<ClientUpdateOneRequiredWithoutSpokespeopleNestedInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutSpokespeopleNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type SpokespersonUpdateManyMutationInput = {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type SpokespersonUpdateManyWithWhereWithoutClientInput = {
  data: SpokespersonUpdateManyMutationInput;
  where: SpokespersonScalarWhereInput;
};

export type SpokespersonUpdateManyWithWhereWithoutCoveragePiecesInput = {
  data: SpokespersonUpdateManyMutationInput;
  where: SpokespersonScalarWhereInput;
};

export type SpokespersonUpdateManyWithoutClientNestedInput = {
  connect?: InputMaybe<Array<SpokespersonWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SpokespersonCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<SpokespersonCreateWithoutClientInput>>;
  createMany?: InputMaybe<SpokespersonCreateManyClientInputEnvelope>;
  delete?: InputMaybe<Array<SpokespersonWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SpokespersonScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SpokespersonWhereUniqueInput>>;
  set?: InputMaybe<Array<SpokespersonWhereUniqueInput>>;
  update?: InputMaybe<Array<SpokespersonUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: InputMaybe<Array<SpokespersonUpdateManyWithWhereWithoutClientInput>>;
  upsert?: InputMaybe<Array<SpokespersonUpsertWithWhereUniqueWithoutClientInput>>;
};

export type SpokespersonUpdateManyWithoutCoveragePiecesNestedInput = {
  connect?: InputMaybe<Array<SpokespersonWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SpokespersonCreateOrConnectWithoutCoveragePiecesInput>>;
  create?: InputMaybe<Array<SpokespersonCreateWithoutCoveragePiecesInput>>;
  delete?: InputMaybe<Array<SpokespersonWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SpokespersonScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SpokespersonWhereUniqueInput>>;
  set?: InputMaybe<Array<SpokespersonWhereUniqueInput>>;
  update?: InputMaybe<Array<SpokespersonUpdateWithWhereUniqueWithoutCoveragePiecesInput>>;
  updateMany?: InputMaybe<Array<SpokespersonUpdateManyWithWhereWithoutCoveragePiecesInput>>;
  upsert?: InputMaybe<Array<SpokespersonUpsertWithWhereUniqueWithoutCoveragePiecesInput>>;
};

export type SpokespersonUpdateWithWhereUniqueWithoutClientInput = {
  data: SpokespersonUpdateWithoutClientInput;
  where: SpokespersonWhereUniqueInput;
};

export type SpokespersonUpdateWithWhereUniqueWithoutCoveragePiecesInput = {
  data: SpokespersonUpdateWithoutCoveragePiecesInput;
  where: SpokespersonWhereUniqueInput;
};

export type SpokespersonUpdateWithoutClientInput = {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutSpokespeopleNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type SpokespersonUpdateWithoutCoveragePiecesInput = {
  client?: InputMaybe<ClientUpdateOneRequiredWithoutSpokespeopleNestedInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type SpokespersonUpsertWithWhereUniqueWithoutClientInput = {
  create: SpokespersonCreateWithoutClientInput;
  update: SpokespersonUpdateWithoutClientInput;
  where: SpokespersonWhereUniqueInput;
};

export type SpokespersonUpsertWithWhereUniqueWithoutCoveragePiecesInput = {
  create: SpokespersonCreateWithoutCoveragePiecesInput;
  update: SpokespersonUpdateWithoutCoveragePiecesInput;
  where: SpokespersonWhereUniqueInput;
};

export type SpokespersonWhereInput = {
  AND?: InputMaybe<Array<SpokespersonWhereInput>>;
  NOT?: InputMaybe<Array<SpokespersonWhereInput>>;
  OR?: InputMaybe<Array<SpokespersonWhereInput>>;
  client?: InputMaybe<ClientRelationFilter>;
  clientId?: InputMaybe<StringFilter>;
  content?: InputMaybe<StringFilter>;
  coveragePieces?: InputMaybe<CoveragePieceListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  image?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
};

export type SpokespersonWhereUniqueInput = {
  AND?: InputMaybe<Array<SpokespersonWhereInput>>;
  NOT?: InputMaybe<Array<SpokespersonWhereInput>>;
  OR?: InputMaybe<Array<SpokespersonWhereInput>>;
  client?: InputMaybe<ClientRelationFilter>;
  clientId?: InputMaybe<StringFilter>;
  content?: InputMaybe<StringFilter>;
  coveragePieces?: InputMaybe<CoveragePieceListRelationFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
};

export type StringAndNumberTuple = {
  __typename?: 'StringAndNumberTuple';
  number: Scalars['Float']['output'];
  string: Scalars['String']['output'];
};

export type StringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']['input']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringNullableListFilter = {
  equals?: InputMaybe<Array<Scalars['String']['input']>>;
  has?: InputMaybe<Scalars['String']['input']>;
  hasEvery?: InputMaybe<Array<Scalars['String']['input']>>;
  hasSome?: InputMaybe<Array<Scalars['String']['input']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The tone of a suggested comment */
export enum SuggestCommentTone {
  Negative = 'NEGATIVE',
  Neutral = 'NEUTRAL',
  Positive = 'POSITIVE',
  StronglyNegative = 'STRONGLY_NEGATIVE',
  StronglyPositive = 'STRONGLY_POSITIVE'
}

export type SuggestInput = {
  sortBy: SuggestCommentTone;
};

export type SuggestTitleInput = {
  clientId: Scalars['String']['input'];
  topic: Scalars['String']['input'];
  type: ContentWriterType;
};

export type SummariseInput = {
  context: Scalars['String']['input'];
  length: Scalars['Int']['input'];
  text: Scalars['String']['input'];
};

export type TokenCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expiration: Scalars['DateTime']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  type: TokenType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  valid?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TokenCreateManyUserInputEnvelope = {
  data: Array<TokenCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TokenCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<TokenWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TokenCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<TokenCreateWithoutUserInput>>;
  createMany?: InputMaybe<TokenCreateManyUserInputEnvelope>;
};

export type TokenCreateOrConnectWithoutUserInput = {
  create: TokenCreateWithoutUserInput;
  where: TokenWhereUniqueInput;
};

export type TokenCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expiration: Scalars['DateTime']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  type: TokenType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  valid?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TokenListRelationFilter = {
  every?: InputMaybe<TokenWhereInput>;
  none?: InputMaybe<TokenWhereInput>;
  some?: InputMaybe<TokenWhereInput>;
};

export type TokenOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TokenScalarWhereInput = {
  AND?: InputMaybe<Array<TokenScalarWhereInput>>;
  NOT?: InputMaybe<Array<TokenScalarWhereInput>>;
  OR?: InputMaybe<Array<TokenScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  expiration?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumTokenTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
  valid?: InputMaybe<BoolFilter>;
};

export enum TokenType {
  Api = 'API',
  Email = 'EMAIL'
}

export type TokenUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  expiration?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumTokenTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type TokenUpdateManyWithWhereWithoutUserInput = {
  data: TokenUpdateManyMutationInput;
  where: TokenScalarWhereInput;
};

export type TokenUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<TokenWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TokenCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<TokenCreateWithoutUserInput>>;
  createMany?: InputMaybe<TokenCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<TokenWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TokenScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TokenWhereUniqueInput>>;
  set?: InputMaybe<Array<TokenWhereUniqueInput>>;
  update?: InputMaybe<Array<TokenUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<TokenUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<TokenUpsertWithWhereUniqueWithoutUserInput>>;
};

export type TokenUpdateWithWhereUniqueWithoutUserInput = {
  data: TokenUpdateWithoutUserInput;
  where: TokenWhereUniqueInput;
};

export type TokenUpdateWithoutUserInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  expiration?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumTokenTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  valid?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type TokenUpsertWithWhereUniqueWithoutUserInput = {
  create: TokenCreateWithoutUserInput;
  update: TokenUpdateWithoutUserInput;
  where: TokenWhereUniqueInput;
};

export type TokenWhereInput = {
  AND?: InputMaybe<Array<TokenWhereInput>>;
  NOT?: InputMaybe<Array<TokenWhereInput>>;
  OR?: InputMaybe<Array<TokenWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  expiration?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumTokenTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  valid?: InputMaybe<BoolFilter>;
};

export type TokenWhereUniqueInput = {
  AND?: InputMaybe<Array<TokenWhereInput>>;
  NOT?: InputMaybe<Array<TokenWhereInput>>;
  OR?: InputMaybe<Array<TokenWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  expiration?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<EnumTokenTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
  valid?: InputMaybe<BoolFilter>;
};

export type UpdateActivityComment = {
  content: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type UpdateClientType = {
  agencyManagersUpdate?: InputMaybe<Array<UserWhereUniqueInput>>;
  boilerplate?: InputMaybe<Scalars['String']['input']>;
  employeesUpdate?: InputMaybe<Array<UserWhereUniqueInput>>;
  entitlements?: InputMaybe<Scalars['String']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  locationInput?: InputMaybe<LocationUpdateInput>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  tagList?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateManyNewsJackItemsInput = {
  content: Scalars['String']['input'];
  deadline?: InputMaybe<Scalars['DateTime']['input']>;
  newsWrapContentId: Scalars['String']['input'];
};

export type User = {
  __typename?: 'User';
  _count?: Maybe<UserCount>;
  bio: Scalars['String']['output'];
  clientEmployer?: Maybe<Client>;
  clientId?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  isArchived: Scalars['Boolean']['output'];
  lastOnlineAt: Scalars['DateTime']['output'];
  managedClients: Array<Client>;
  name: Scalars['String']['output'];
  role: Role;
  roleId: Scalars['String']['output'];
  timezone: Scalars['String']['output'];
  workspaceId: Scalars['String']['output'];
};


export type UserManagedClientsArgs = {
  cursor?: InputMaybe<ClientWhereUniqueInput>;
  distinct?: InputMaybe<Array<ClientScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ClientOrderByWithRelationAndSearchRelevanceInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ClientWhereInput>;
};

export type UserCount = {
  __typename?: 'UserCount';
  activities: Scalars['Int']['output'];
  activityComments: Scalars['Int']['output'];
  clientComments: Scalars['Int']['output'];
  content: Scalars['Int']['output'];
  coveragePieces: Scalars['Int']['output'];
  files: Scalars['Int']['output'];
  managedClients: Scalars['Int']['output'];
  meetingNotes: Scalars['Int']['output'];
  newsJackItems: Scalars['Int']['output'];
  newsJackRevisions: Scalars['Int']['output'];
  newsWrapContent: Scalars['Int']['output'];
  notifications: Scalars['Int']['output'];
  recentlyViewedContent: Scalars['Int']['output'];
  referencedInActivityComments: Scalars['Int']['output'];
  referencedInClientComments: Scalars['Int']['output'];
  referencedUsers: Scalars['Int']['output'];
  tokens: Scalars['Int']['output'];
};


export type UserCountActivitiesArgs = {
  where?: InputMaybe<ActivityWhereInput>;
};


export type UserCountActivityCommentsArgs = {
  where?: InputMaybe<ActivityCommentWhereInput>;
};


export type UserCountClientCommentsArgs = {
  where?: InputMaybe<ClientCommentWhereInput>;
};


export type UserCountContentArgs = {
  where?: InputMaybe<ContentWhereInput>;
};


export type UserCountCoveragePiecesArgs = {
  where?: InputMaybe<CoveragePieceWhereInput>;
};


export type UserCountFilesArgs = {
  where?: InputMaybe<FileMetadataWhereInput>;
};


export type UserCountManagedClientsArgs = {
  where?: InputMaybe<ClientWhereInput>;
};


export type UserCountMeetingNotesArgs = {
  where?: InputMaybe<MeetingNotesWhereInput>;
};


export type UserCountNewsJackItemsArgs = {
  where?: InputMaybe<NewsJackItemWhereInput>;
};


export type UserCountNewsJackRevisionsArgs = {
  where?: InputMaybe<NewsJackRevisionWhereInput>;
};


export type UserCountNewsWrapContentArgs = {
  where?: InputMaybe<NewsWrapContentWhereInput>;
};


export type UserCountNotificationsArgs = {
  where?: InputMaybe<NotificationWhereInput>;
};


export type UserCountRecentlyViewedContentArgs = {
  where?: InputMaybe<RecentlyViewedContentWhereInput>;
};


export type UserCountReferencedInActivityCommentsArgs = {
  where?: InputMaybe<ActivityCommentWhereInput>;
};


export type UserCountReferencedInClientCommentsArgs = {
  where?: InputMaybe<ClientCommentWhereInput>;
};


export type UserCountReferencedUsersArgs = {
  where?: InputMaybe<NewsWrapContentWhereInput>;
};


export type UserCountTokensArgs = {
  where?: InputMaybe<TokenWhereInput>;
};

export type UserCreateManyClientEmployerInput = {
  bio?: InputMaybe<Scalars['String']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  lastOnlineAt?: InputMaybe<Scalars['DateTime']['input']>;
  name: Scalars['String']['input'];
  roleId: Scalars['String']['input'];
  timezone?: InputMaybe<Scalars['String']['input']>;
  workspaceId: Scalars['String']['input'];
};

export type UserCreateManyClientEmployerInputEnvelope = {
  data: Array<UserCreateManyClientEmployerInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserCreateManyRoleInput = {
  bio?: InputMaybe<Scalars['String']['input']>;
  clientId?: InputMaybe<Scalars['String']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  lastOnlineAt?: InputMaybe<Scalars['DateTime']['input']>;
  name: Scalars['String']['input'];
  timezone?: InputMaybe<Scalars['String']['input']>;
  workspaceId: Scalars['String']['input'];
};

export type UserCreateManyRoleInputEnvelope = {
  data: Array<UserCreateManyRoleInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserCreateManyWorkspaceInput = {
  bio?: InputMaybe<Scalars['String']['input']>;
  clientId?: InputMaybe<Scalars['String']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  lastOnlineAt?: InputMaybe<Scalars['DateTime']['input']>;
  name: Scalars['String']['input'];
  roleId: Scalars['String']['input'];
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateManyWorkspaceInputEnvelope = {
  data: Array<UserCreateManyWorkspaceInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserCreateNestedManyWithoutActivitiesInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutActivitiesInput>>;
  create?: InputMaybe<Array<UserCreateWithoutActivitiesInput>>;
};

export type UserCreateNestedManyWithoutClientEmployerInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutClientEmployerInput>>;
  create?: InputMaybe<Array<UserCreateWithoutClientEmployerInput>>;
  createMany?: InputMaybe<UserCreateManyClientEmployerInputEnvelope>;
};

export type UserCreateNestedManyWithoutManagedClientsInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutManagedClientsInput>>;
  create?: InputMaybe<Array<UserCreateWithoutManagedClientsInput>>;
};

export type UserCreateNestedManyWithoutReferencedInActivityCommentsInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutReferencedInActivityCommentsInput>>;
  create?: InputMaybe<Array<UserCreateWithoutReferencedInActivityCommentsInput>>;
};

export type UserCreateNestedManyWithoutReferencedInClientCommentsInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutReferencedInClientCommentsInput>>;
  create?: InputMaybe<Array<UserCreateWithoutReferencedInClientCommentsInput>>;
};

export type UserCreateNestedManyWithoutReferencedUsersInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutReferencedUsersInput>>;
  create?: InputMaybe<Array<UserCreateWithoutReferencedUsersInput>>;
};

export type UserCreateNestedManyWithoutRoleInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutRoleInput>>;
  create?: InputMaybe<Array<UserCreateWithoutRoleInput>>;
  createMany?: InputMaybe<UserCreateManyRoleInputEnvelope>;
};

export type UserCreateNestedManyWithoutWorkspaceInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutWorkspaceInput>>;
  create?: InputMaybe<Array<UserCreateWithoutWorkspaceInput>>;
  createMany?: InputMaybe<UserCreateManyWorkspaceInputEnvelope>;
};

export type UserCreateNestedOneWithoutActivityCommentsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutActivityCommentsInput>;
  create?: InputMaybe<UserCreateWithoutActivityCommentsInput>;
};

export type UserCreateNestedOneWithoutClientCommentsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutClientCommentsInput>;
  create?: InputMaybe<UserCreateWithoutClientCommentsInput>;
};

export type UserCreateNestedOneWithoutContentInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutContentInput>;
  create?: InputMaybe<UserCreateWithoutContentInput>;
};

export type UserCreateNestedOneWithoutCoveragePiecesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCoveragePiecesInput>;
  create?: InputMaybe<UserCreateWithoutCoveragePiecesInput>;
};

export type UserCreateNestedOneWithoutFilesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutFilesInput>;
  create?: InputMaybe<UserCreateWithoutFilesInput>;
};

export type UserCreateNestedOneWithoutMeetingNotesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutMeetingNotesInput>;
  create?: InputMaybe<UserCreateWithoutMeetingNotesInput>;
};

export type UserCreateNestedOneWithoutNewsJackItemsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutNewsJackItemsInput>;
  create?: InputMaybe<UserCreateWithoutNewsJackItemsInput>;
};

export type UserCreateNestedOneWithoutNewsJackRevisionsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutNewsJackRevisionsInput>;
  create?: InputMaybe<UserCreateWithoutNewsJackRevisionsInput>;
};

export type UserCreateNestedOneWithoutNewsWrapContentInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutNewsWrapContentInput>;
  create?: InputMaybe<UserCreateWithoutNewsWrapContentInput>;
};

export type UserCreateNestedOneWithoutRecentlyViewedContentInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutRecentlyViewedContentInput>;
  create?: InputMaybe<UserCreateWithoutRecentlyViewedContentInput>;
};

export type UserCreateOrConnectWithoutActivitiesInput = {
  create: UserCreateWithoutActivitiesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutActivityCommentsInput = {
  create: UserCreateWithoutActivityCommentsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutClientCommentsInput = {
  create: UserCreateWithoutClientCommentsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutClientEmployerInput = {
  create: UserCreateWithoutClientEmployerInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutContentInput = {
  create: UserCreateWithoutContentInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCoveragePiecesInput = {
  create: UserCreateWithoutCoveragePiecesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutFilesInput = {
  create: UserCreateWithoutFilesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutManagedClientsInput = {
  create: UserCreateWithoutManagedClientsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutMeetingNotesInput = {
  create: UserCreateWithoutMeetingNotesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutNewsJackItemsInput = {
  create: UserCreateWithoutNewsJackItemsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutNewsJackRevisionsInput = {
  create: UserCreateWithoutNewsJackRevisionsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutNewsWrapContentInput = {
  create: UserCreateWithoutNewsWrapContentInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutRecentlyViewedContentInput = {
  create: UserCreateWithoutRecentlyViewedContentInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutReferencedInActivityCommentsInput = {
  create: UserCreateWithoutReferencedInActivityCommentsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutReferencedInClientCommentsInput = {
  create: UserCreateWithoutReferencedInClientCommentsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutReferencedUsersInput = {
  create: UserCreateWithoutReferencedUsersInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutRoleInput = {
  create: UserCreateWithoutRoleInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutWorkspaceInput = {
  create: UserCreateWithoutWorkspaceInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutActivitiesInput = {
  activityComments?: InputMaybe<ActivityCommentCreateNestedManyWithoutUserInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  clientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutUserInput>;
  clientEmployer?: InputMaybe<ClientCreateNestedOneWithoutEmployeesInput>;
  content?: InputMaybe<ContentCreateNestedManyWithoutCreatedByInput>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutCreatedByInput>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  files?: InputMaybe<FileMetadataCreateNestedManyWithoutUploadedByInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  lastOnlineAt?: InputMaybe<Scalars['DateTime']['input']>;
  managedClients?: InputMaybe<ClientCreateNestedManyWithoutAgencyManagersInput>;
  meetingNotes?: InputMaybe<MeetingNotesCreateNestedManyWithoutCreatedByInput>;
  name: Scalars['String']['input'];
  newsJackItems?: InputMaybe<NewsJackItemCreateNestedManyWithoutStatusChangedByInput>;
  newsJackRevisions?: InputMaybe<NewsJackRevisionCreateNestedManyWithoutCreatedByInput>;
  newsWrapContent?: InputMaybe<NewsWrapContentCreateNestedManyWithoutCreatedByInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  recentlyViewedContent?: InputMaybe<RecentlyViewedContentCreateNestedManyWithoutUserInput>;
  referencedInActivityComments?: InputMaybe<ActivityCommentCreateNestedManyWithoutReferencedUsersInput>;
  referencedInClientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutReferencedUsersInput>;
  referencedUsers?: InputMaybe<NewsWrapContentCreateNestedManyWithoutReferencedUsersInput>;
  role: RoleCreateNestedOneWithoutUsersInput;
  scratchpad?: InputMaybe<ScratchpadCreateNestedOneWithoutUserInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  tokens?: InputMaybe<TokenCreateNestedManyWithoutUserInput>;
  workspace: WorkspaceCreateNestedOneWithoutUsersInput;
};

export type UserCreateWithoutActivityCommentsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutUsersInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  clientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutUserInput>;
  clientEmployer?: InputMaybe<ClientCreateNestedOneWithoutEmployeesInput>;
  content?: InputMaybe<ContentCreateNestedManyWithoutCreatedByInput>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutCreatedByInput>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  files?: InputMaybe<FileMetadataCreateNestedManyWithoutUploadedByInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  lastOnlineAt?: InputMaybe<Scalars['DateTime']['input']>;
  managedClients?: InputMaybe<ClientCreateNestedManyWithoutAgencyManagersInput>;
  meetingNotes?: InputMaybe<MeetingNotesCreateNestedManyWithoutCreatedByInput>;
  name: Scalars['String']['input'];
  newsJackItems?: InputMaybe<NewsJackItemCreateNestedManyWithoutStatusChangedByInput>;
  newsJackRevisions?: InputMaybe<NewsJackRevisionCreateNestedManyWithoutCreatedByInput>;
  newsWrapContent?: InputMaybe<NewsWrapContentCreateNestedManyWithoutCreatedByInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  recentlyViewedContent?: InputMaybe<RecentlyViewedContentCreateNestedManyWithoutUserInput>;
  referencedInActivityComments?: InputMaybe<ActivityCommentCreateNestedManyWithoutReferencedUsersInput>;
  referencedInClientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutReferencedUsersInput>;
  referencedUsers?: InputMaybe<NewsWrapContentCreateNestedManyWithoutReferencedUsersInput>;
  role: RoleCreateNestedOneWithoutUsersInput;
  scratchpad?: InputMaybe<ScratchpadCreateNestedOneWithoutUserInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  tokens?: InputMaybe<TokenCreateNestedManyWithoutUserInput>;
  workspace: WorkspaceCreateNestedOneWithoutUsersInput;
};

export type UserCreateWithoutClientCommentsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutUsersInput>;
  activityComments?: InputMaybe<ActivityCommentCreateNestedManyWithoutUserInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  clientEmployer?: InputMaybe<ClientCreateNestedOneWithoutEmployeesInput>;
  content?: InputMaybe<ContentCreateNestedManyWithoutCreatedByInput>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutCreatedByInput>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  files?: InputMaybe<FileMetadataCreateNestedManyWithoutUploadedByInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  lastOnlineAt?: InputMaybe<Scalars['DateTime']['input']>;
  managedClients?: InputMaybe<ClientCreateNestedManyWithoutAgencyManagersInput>;
  meetingNotes?: InputMaybe<MeetingNotesCreateNestedManyWithoutCreatedByInput>;
  name: Scalars['String']['input'];
  newsJackItems?: InputMaybe<NewsJackItemCreateNestedManyWithoutStatusChangedByInput>;
  newsJackRevisions?: InputMaybe<NewsJackRevisionCreateNestedManyWithoutCreatedByInput>;
  newsWrapContent?: InputMaybe<NewsWrapContentCreateNestedManyWithoutCreatedByInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  recentlyViewedContent?: InputMaybe<RecentlyViewedContentCreateNestedManyWithoutUserInput>;
  referencedInActivityComments?: InputMaybe<ActivityCommentCreateNestedManyWithoutReferencedUsersInput>;
  referencedInClientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutReferencedUsersInput>;
  referencedUsers?: InputMaybe<NewsWrapContentCreateNestedManyWithoutReferencedUsersInput>;
  role: RoleCreateNestedOneWithoutUsersInput;
  scratchpad?: InputMaybe<ScratchpadCreateNestedOneWithoutUserInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  tokens?: InputMaybe<TokenCreateNestedManyWithoutUserInput>;
  workspace: WorkspaceCreateNestedOneWithoutUsersInput;
};

export type UserCreateWithoutClientEmployerInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutUsersInput>;
  activityComments?: InputMaybe<ActivityCommentCreateNestedManyWithoutUserInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  clientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutUserInput>;
  content?: InputMaybe<ContentCreateNestedManyWithoutCreatedByInput>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutCreatedByInput>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  files?: InputMaybe<FileMetadataCreateNestedManyWithoutUploadedByInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  lastOnlineAt?: InputMaybe<Scalars['DateTime']['input']>;
  managedClients?: InputMaybe<ClientCreateNestedManyWithoutAgencyManagersInput>;
  meetingNotes?: InputMaybe<MeetingNotesCreateNestedManyWithoutCreatedByInput>;
  name: Scalars['String']['input'];
  newsJackItems?: InputMaybe<NewsJackItemCreateNestedManyWithoutStatusChangedByInput>;
  newsJackRevisions?: InputMaybe<NewsJackRevisionCreateNestedManyWithoutCreatedByInput>;
  newsWrapContent?: InputMaybe<NewsWrapContentCreateNestedManyWithoutCreatedByInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  recentlyViewedContent?: InputMaybe<RecentlyViewedContentCreateNestedManyWithoutUserInput>;
  referencedInActivityComments?: InputMaybe<ActivityCommentCreateNestedManyWithoutReferencedUsersInput>;
  referencedInClientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutReferencedUsersInput>;
  referencedUsers?: InputMaybe<NewsWrapContentCreateNestedManyWithoutReferencedUsersInput>;
  role: RoleCreateNestedOneWithoutUsersInput;
  scratchpad?: InputMaybe<ScratchpadCreateNestedOneWithoutUserInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  tokens?: InputMaybe<TokenCreateNestedManyWithoutUserInput>;
  workspace: WorkspaceCreateNestedOneWithoutUsersInput;
};

export type UserCreateWithoutContentInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutUsersInput>;
  activityComments?: InputMaybe<ActivityCommentCreateNestedManyWithoutUserInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  clientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutUserInput>;
  clientEmployer?: InputMaybe<ClientCreateNestedOneWithoutEmployeesInput>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutCreatedByInput>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  files?: InputMaybe<FileMetadataCreateNestedManyWithoutUploadedByInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  lastOnlineAt?: InputMaybe<Scalars['DateTime']['input']>;
  managedClients?: InputMaybe<ClientCreateNestedManyWithoutAgencyManagersInput>;
  meetingNotes?: InputMaybe<MeetingNotesCreateNestedManyWithoutCreatedByInput>;
  name: Scalars['String']['input'];
  newsJackItems?: InputMaybe<NewsJackItemCreateNestedManyWithoutStatusChangedByInput>;
  newsJackRevisions?: InputMaybe<NewsJackRevisionCreateNestedManyWithoutCreatedByInput>;
  newsWrapContent?: InputMaybe<NewsWrapContentCreateNestedManyWithoutCreatedByInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  recentlyViewedContent?: InputMaybe<RecentlyViewedContentCreateNestedManyWithoutUserInput>;
  referencedInActivityComments?: InputMaybe<ActivityCommentCreateNestedManyWithoutReferencedUsersInput>;
  referencedInClientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutReferencedUsersInput>;
  referencedUsers?: InputMaybe<NewsWrapContentCreateNestedManyWithoutReferencedUsersInput>;
  role: RoleCreateNestedOneWithoutUsersInput;
  scratchpad?: InputMaybe<ScratchpadCreateNestedOneWithoutUserInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  tokens?: InputMaybe<TokenCreateNestedManyWithoutUserInput>;
  workspace: WorkspaceCreateNestedOneWithoutUsersInput;
};

export type UserCreateWithoutCoveragePiecesInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutUsersInput>;
  activityComments?: InputMaybe<ActivityCommentCreateNestedManyWithoutUserInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  clientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutUserInput>;
  clientEmployer?: InputMaybe<ClientCreateNestedOneWithoutEmployeesInput>;
  content?: InputMaybe<ContentCreateNestedManyWithoutCreatedByInput>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  files?: InputMaybe<FileMetadataCreateNestedManyWithoutUploadedByInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  lastOnlineAt?: InputMaybe<Scalars['DateTime']['input']>;
  managedClients?: InputMaybe<ClientCreateNestedManyWithoutAgencyManagersInput>;
  meetingNotes?: InputMaybe<MeetingNotesCreateNestedManyWithoutCreatedByInput>;
  name: Scalars['String']['input'];
  newsJackItems?: InputMaybe<NewsJackItemCreateNestedManyWithoutStatusChangedByInput>;
  newsJackRevisions?: InputMaybe<NewsJackRevisionCreateNestedManyWithoutCreatedByInput>;
  newsWrapContent?: InputMaybe<NewsWrapContentCreateNestedManyWithoutCreatedByInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  recentlyViewedContent?: InputMaybe<RecentlyViewedContentCreateNestedManyWithoutUserInput>;
  referencedInActivityComments?: InputMaybe<ActivityCommentCreateNestedManyWithoutReferencedUsersInput>;
  referencedInClientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutReferencedUsersInput>;
  referencedUsers?: InputMaybe<NewsWrapContentCreateNestedManyWithoutReferencedUsersInput>;
  role: RoleCreateNestedOneWithoutUsersInput;
  scratchpad?: InputMaybe<ScratchpadCreateNestedOneWithoutUserInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  tokens?: InputMaybe<TokenCreateNestedManyWithoutUserInput>;
  workspace: WorkspaceCreateNestedOneWithoutUsersInput;
};

export type UserCreateWithoutFilesInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutUsersInput>;
  activityComments?: InputMaybe<ActivityCommentCreateNestedManyWithoutUserInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  clientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutUserInput>;
  clientEmployer?: InputMaybe<ClientCreateNestedOneWithoutEmployeesInput>;
  content?: InputMaybe<ContentCreateNestedManyWithoutCreatedByInput>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutCreatedByInput>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  lastOnlineAt?: InputMaybe<Scalars['DateTime']['input']>;
  managedClients?: InputMaybe<ClientCreateNestedManyWithoutAgencyManagersInput>;
  meetingNotes?: InputMaybe<MeetingNotesCreateNestedManyWithoutCreatedByInput>;
  name: Scalars['String']['input'];
  newsJackItems?: InputMaybe<NewsJackItemCreateNestedManyWithoutStatusChangedByInput>;
  newsJackRevisions?: InputMaybe<NewsJackRevisionCreateNestedManyWithoutCreatedByInput>;
  newsWrapContent?: InputMaybe<NewsWrapContentCreateNestedManyWithoutCreatedByInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  recentlyViewedContent?: InputMaybe<RecentlyViewedContentCreateNestedManyWithoutUserInput>;
  referencedInActivityComments?: InputMaybe<ActivityCommentCreateNestedManyWithoutReferencedUsersInput>;
  referencedInClientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutReferencedUsersInput>;
  referencedUsers?: InputMaybe<NewsWrapContentCreateNestedManyWithoutReferencedUsersInput>;
  role: RoleCreateNestedOneWithoutUsersInput;
  scratchpad?: InputMaybe<ScratchpadCreateNestedOneWithoutUserInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  tokens?: InputMaybe<TokenCreateNestedManyWithoutUserInput>;
  workspace: WorkspaceCreateNestedOneWithoutUsersInput;
};

export type UserCreateWithoutManagedClientsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutUsersInput>;
  activityComments?: InputMaybe<ActivityCommentCreateNestedManyWithoutUserInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  clientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutUserInput>;
  clientEmployer?: InputMaybe<ClientCreateNestedOneWithoutEmployeesInput>;
  content?: InputMaybe<ContentCreateNestedManyWithoutCreatedByInput>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutCreatedByInput>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  files?: InputMaybe<FileMetadataCreateNestedManyWithoutUploadedByInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  lastOnlineAt?: InputMaybe<Scalars['DateTime']['input']>;
  meetingNotes?: InputMaybe<MeetingNotesCreateNestedManyWithoutCreatedByInput>;
  name: Scalars['String']['input'];
  newsJackItems?: InputMaybe<NewsJackItemCreateNestedManyWithoutStatusChangedByInput>;
  newsJackRevisions?: InputMaybe<NewsJackRevisionCreateNestedManyWithoutCreatedByInput>;
  newsWrapContent?: InputMaybe<NewsWrapContentCreateNestedManyWithoutCreatedByInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  recentlyViewedContent?: InputMaybe<RecentlyViewedContentCreateNestedManyWithoutUserInput>;
  referencedInActivityComments?: InputMaybe<ActivityCommentCreateNestedManyWithoutReferencedUsersInput>;
  referencedInClientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutReferencedUsersInput>;
  referencedUsers?: InputMaybe<NewsWrapContentCreateNestedManyWithoutReferencedUsersInput>;
  role: RoleCreateNestedOneWithoutUsersInput;
  scratchpad?: InputMaybe<ScratchpadCreateNestedOneWithoutUserInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  tokens?: InputMaybe<TokenCreateNestedManyWithoutUserInput>;
  workspace: WorkspaceCreateNestedOneWithoutUsersInput;
};

export type UserCreateWithoutMeetingNotesInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutUsersInput>;
  activityComments?: InputMaybe<ActivityCommentCreateNestedManyWithoutUserInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  clientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutUserInput>;
  clientEmployer?: InputMaybe<ClientCreateNestedOneWithoutEmployeesInput>;
  content?: InputMaybe<ContentCreateNestedManyWithoutCreatedByInput>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutCreatedByInput>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  files?: InputMaybe<FileMetadataCreateNestedManyWithoutUploadedByInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  lastOnlineAt?: InputMaybe<Scalars['DateTime']['input']>;
  managedClients?: InputMaybe<ClientCreateNestedManyWithoutAgencyManagersInput>;
  name: Scalars['String']['input'];
  newsJackItems?: InputMaybe<NewsJackItemCreateNestedManyWithoutStatusChangedByInput>;
  newsJackRevisions?: InputMaybe<NewsJackRevisionCreateNestedManyWithoutCreatedByInput>;
  newsWrapContent?: InputMaybe<NewsWrapContentCreateNestedManyWithoutCreatedByInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  recentlyViewedContent?: InputMaybe<RecentlyViewedContentCreateNestedManyWithoutUserInput>;
  referencedInActivityComments?: InputMaybe<ActivityCommentCreateNestedManyWithoutReferencedUsersInput>;
  referencedInClientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutReferencedUsersInput>;
  referencedUsers?: InputMaybe<NewsWrapContentCreateNestedManyWithoutReferencedUsersInput>;
  role: RoleCreateNestedOneWithoutUsersInput;
  scratchpad?: InputMaybe<ScratchpadCreateNestedOneWithoutUserInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  tokens?: InputMaybe<TokenCreateNestedManyWithoutUserInput>;
  workspace: WorkspaceCreateNestedOneWithoutUsersInput;
};

export type UserCreateWithoutNewsJackItemsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutUsersInput>;
  activityComments?: InputMaybe<ActivityCommentCreateNestedManyWithoutUserInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  clientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutUserInput>;
  clientEmployer?: InputMaybe<ClientCreateNestedOneWithoutEmployeesInput>;
  content?: InputMaybe<ContentCreateNestedManyWithoutCreatedByInput>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutCreatedByInput>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  files?: InputMaybe<FileMetadataCreateNestedManyWithoutUploadedByInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  lastOnlineAt?: InputMaybe<Scalars['DateTime']['input']>;
  managedClients?: InputMaybe<ClientCreateNestedManyWithoutAgencyManagersInput>;
  meetingNotes?: InputMaybe<MeetingNotesCreateNestedManyWithoutCreatedByInput>;
  name: Scalars['String']['input'];
  newsJackRevisions?: InputMaybe<NewsJackRevisionCreateNestedManyWithoutCreatedByInput>;
  newsWrapContent?: InputMaybe<NewsWrapContentCreateNestedManyWithoutCreatedByInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  recentlyViewedContent?: InputMaybe<RecentlyViewedContentCreateNestedManyWithoutUserInput>;
  referencedInActivityComments?: InputMaybe<ActivityCommentCreateNestedManyWithoutReferencedUsersInput>;
  referencedInClientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutReferencedUsersInput>;
  referencedUsers?: InputMaybe<NewsWrapContentCreateNestedManyWithoutReferencedUsersInput>;
  role: RoleCreateNestedOneWithoutUsersInput;
  scratchpad?: InputMaybe<ScratchpadCreateNestedOneWithoutUserInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  tokens?: InputMaybe<TokenCreateNestedManyWithoutUserInput>;
  workspace: WorkspaceCreateNestedOneWithoutUsersInput;
};

export type UserCreateWithoutNewsJackRevisionsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutUsersInput>;
  activityComments?: InputMaybe<ActivityCommentCreateNestedManyWithoutUserInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  clientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutUserInput>;
  clientEmployer?: InputMaybe<ClientCreateNestedOneWithoutEmployeesInput>;
  content?: InputMaybe<ContentCreateNestedManyWithoutCreatedByInput>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutCreatedByInput>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  files?: InputMaybe<FileMetadataCreateNestedManyWithoutUploadedByInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  lastOnlineAt?: InputMaybe<Scalars['DateTime']['input']>;
  managedClients?: InputMaybe<ClientCreateNestedManyWithoutAgencyManagersInput>;
  meetingNotes?: InputMaybe<MeetingNotesCreateNestedManyWithoutCreatedByInput>;
  name: Scalars['String']['input'];
  newsJackItems?: InputMaybe<NewsJackItemCreateNestedManyWithoutStatusChangedByInput>;
  newsWrapContent?: InputMaybe<NewsWrapContentCreateNestedManyWithoutCreatedByInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  recentlyViewedContent?: InputMaybe<RecentlyViewedContentCreateNestedManyWithoutUserInput>;
  referencedInActivityComments?: InputMaybe<ActivityCommentCreateNestedManyWithoutReferencedUsersInput>;
  referencedInClientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutReferencedUsersInput>;
  referencedUsers?: InputMaybe<NewsWrapContentCreateNestedManyWithoutReferencedUsersInput>;
  role: RoleCreateNestedOneWithoutUsersInput;
  scratchpad?: InputMaybe<ScratchpadCreateNestedOneWithoutUserInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  tokens?: InputMaybe<TokenCreateNestedManyWithoutUserInput>;
  workspace: WorkspaceCreateNestedOneWithoutUsersInput;
};

export type UserCreateWithoutNewsWrapContentInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutUsersInput>;
  activityComments?: InputMaybe<ActivityCommentCreateNestedManyWithoutUserInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  clientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutUserInput>;
  clientEmployer?: InputMaybe<ClientCreateNestedOneWithoutEmployeesInput>;
  content?: InputMaybe<ContentCreateNestedManyWithoutCreatedByInput>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutCreatedByInput>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  files?: InputMaybe<FileMetadataCreateNestedManyWithoutUploadedByInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  lastOnlineAt?: InputMaybe<Scalars['DateTime']['input']>;
  managedClients?: InputMaybe<ClientCreateNestedManyWithoutAgencyManagersInput>;
  meetingNotes?: InputMaybe<MeetingNotesCreateNestedManyWithoutCreatedByInput>;
  name: Scalars['String']['input'];
  newsJackItems?: InputMaybe<NewsJackItemCreateNestedManyWithoutStatusChangedByInput>;
  newsJackRevisions?: InputMaybe<NewsJackRevisionCreateNestedManyWithoutCreatedByInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  recentlyViewedContent?: InputMaybe<RecentlyViewedContentCreateNestedManyWithoutUserInput>;
  referencedInActivityComments?: InputMaybe<ActivityCommentCreateNestedManyWithoutReferencedUsersInput>;
  referencedInClientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutReferencedUsersInput>;
  referencedUsers?: InputMaybe<NewsWrapContentCreateNestedManyWithoutReferencedUsersInput>;
  role: RoleCreateNestedOneWithoutUsersInput;
  scratchpad?: InputMaybe<ScratchpadCreateNestedOneWithoutUserInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  tokens?: InputMaybe<TokenCreateNestedManyWithoutUserInput>;
  workspace: WorkspaceCreateNestedOneWithoutUsersInput;
};

export type UserCreateWithoutRecentlyViewedContentInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutUsersInput>;
  activityComments?: InputMaybe<ActivityCommentCreateNestedManyWithoutUserInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  clientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutUserInput>;
  clientEmployer?: InputMaybe<ClientCreateNestedOneWithoutEmployeesInput>;
  content?: InputMaybe<ContentCreateNestedManyWithoutCreatedByInput>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutCreatedByInput>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  files?: InputMaybe<FileMetadataCreateNestedManyWithoutUploadedByInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  lastOnlineAt?: InputMaybe<Scalars['DateTime']['input']>;
  managedClients?: InputMaybe<ClientCreateNestedManyWithoutAgencyManagersInput>;
  meetingNotes?: InputMaybe<MeetingNotesCreateNestedManyWithoutCreatedByInput>;
  name: Scalars['String']['input'];
  newsJackItems?: InputMaybe<NewsJackItemCreateNestedManyWithoutStatusChangedByInput>;
  newsJackRevisions?: InputMaybe<NewsJackRevisionCreateNestedManyWithoutCreatedByInput>;
  newsWrapContent?: InputMaybe<NewsWrapContentCreateNestedManyWithoutCreatedByInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  referencedInActivityComments?: InputMaybe<ActivityCommentCreateNestedManyWithoutReferencedUsersInput>;
  referencedInClientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutReferencedUsersInput>;
  referencedUsers?: InputMaybe<NewsWrapContentCreateNestedManyWithoutReferencedUsersInput>;
  role: RoleCreateNestedOneWithoutUsersInput;
  scratchpad?: InputMaybe<ScratchpadCreateNestedOneWithoutUserInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  tokens?: InputMaybe<TokenCreateNestedManyWithoutUserInput>;
  workspace: WorkspaceCreateNestedOneWithoutUsersInput;
};

export type UserCreateWithoutReferencedInActivityCommentsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutUsersInput>;
  activityComments?: InputMaybe<ActivityCommentCreateNestedManyWithoutUserInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  clientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutUserInput>;
  clientEmployer?: InputMaybe<ClientCreateNestedOneWithoutEmployeesInput>;
  content?: InputMaybe<ContentCreateNestedManyWithoutCreatedByInput>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutCreatedByInput>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  files?: InputMaybe<FileMetadataCreateNestedManyWithoutUploadedByInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  lastOnlineAt?: InputMaybe<Scalars['DateTime']['input']>;
  managedClients?: InputMaybe<ClientCreateNestedManyWithoutAgencyManagersInput>;
  meetingNotes?: InputMaybe<MeetingNotesCreateNestedManyWithoutCreatedByInput>;
  name: Scalars['String']['input'];
  newsJackItems?: InputMaybe<NewsJackItemCreateNestedManyWithoutStatusChangedByInput>;
  newsJackRevisions?: InputMaybe<NewsJackRevisionCreateNestedManyWithoutCreatedByInput>;
  newsWrapContent?: InputMaybe<NewsWrapContentCreateNestedManyWithoutCreatedByInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  recentlyViewedContent?: InputMaybe<RecentlyViewedContentCreateNestedManyWithoutUserInput>;
  referencedInClientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutReferencedUsersInput>;
  referencedUsers?: InputMaybe<NewsWrapContentCreateNestedManyWithoutReferencedUsersInput>;
  role: RoleCreateNestedOneWithoutUsersInput;
  scratchpad?: InputMaybe<ScratchpadCreateNestedOneWithoutUserInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  tokens?: InputMaybe<TokenCreateNestedManyWithoutUserInput>;
  workspace: WorkspaceCreateNestedOneWithoutUsersInput;
};

export type UserCreateWithoutReferencedInClientCommentsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutUsersInput>;
  activityComments?: InputMaybe<ActivityCommentCreateNestedManyWithoutUserInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  clientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutUserInput>;
  clientEmployer?: InputMaybe<ClientCreateNestedOneWithoutEmployeesInput>;
  content?: InputMaybe<ContentCreateNestedManyWithoutCreatedByInput>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutCreatedByInput>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  files?: InputMaybe<FileMetadataCreateNestedManyWithoutUploadedByInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  lastOnlineAt?: InputMaybe<Scalars['DateTime']['input']>;
  managedClients?: InputMaybe<ClientCreateNestedManyWithoutAgencyManagersInput>;
  meetingNotes?: InputMaybe<MeetingNotesCreateNestedManyWithoutCreatedByInput>;
  name: Scalars['String']['input'];
  newsJackItems?: InputMaybe<NewsJackItemCreateNestedManyWithoutStatusChangedByInput>;
  newsJackRevisions?: InputMaybe<NewsJackRevisionCreateNestedManyWithoutCreatedByInput>;
  newsWrapContent?: InputMaybe<NewsWrapContentCreateNestedManyWithoutCreatedByInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  recentlyViewedContent?: InputMaybe<RecentlyViewedContentCreateNestedManyWithoutUserInput>;
  referencedInActivityComments?: InputMaybe<ActivityCommentCreateNestedManyWithoutReferencedUsersInput>;
  referencedUsers?: InputMaybe<NewsWrapContentCreateNestedManyWithoutReferencedUsersInput>;
  role: RoleCreateNestedOneWithoutUsersInput;
  scratchpad?: InputMaybe<ScratchpadCreateNestedOneWithoutUserInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  tokens?: InputMaybe<TokenCreateNestedManyWithoutUserInput>;
  workspace: WorkspaceCreateNestedOneWithoutUsersInput;
};

export type UserCreateWithoutReferencedUsersInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutUsersInput>;
  activityComments?: InputMaybe<ActivityCommentCreateNestedManyWithoutUserInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  clientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutUserInput>;
  clientEmployer?: InputMaybe<ClientCreateNestedOneWithoutEmployeesInput>;
  content?: InputMaybe<ContentCreateNestedManyWithoutCreatedByInput>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutCreatedByInput>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  files?: InputMaybe<FileMetadataCreateNestedManyWithoutUploadedByInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  lastOnlineAt?: InputMaybe<Scalars['DateTime']['input']>;
  managedClients?: InputMaybe<ClientCreateNestedManyWithoutAgencyManagersInput>;
  meetingNotes?: InputMaybe<MeetingNotesCreateNestedManyWithoutCreatedByInput>;
  name: Scalars['String']['input'];
  newsJackItems?: InputMaybe<NewsJackItemCreateNestedManyWithoutStatusChangedByInput>;
  newsJackRevisions?: InputMaybe<NewsJackRevisionCreateNestedManyWithoutCreatedByInput>;
  newsWrapContent?: InputMaybe<NewsWrapContentCreateNestedManyWithoutCreatedByInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  recentlyViewedContent?: InputMaybe<RecentlyViewedContentCreateNestedManyWithoutUserInput>;
  referencedInActivityComments?: InputMaybe<ActivityCommentCreateNestedManyWithoutReferencedUsersInput>;
  referencedInClientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutReferencedUsersInput>;
  role: RoleCreateNestedOneWithoutUsersInput;
  scratchpad?: InputMaybe<ScratchpadCreateNestedOneWithoutUserInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  tokens?: InputMaybe<TokenCreateNestedManyWithoutUserInput>;
  workspace: WorkspaceCreateNestedOneWithoutUsersInput;
};

export type UserCreateWithoutRoleInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutUsersInput>;
  activityComments?: InputMaybe<ActivityCommentCreateNestedManyWithoutUserInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  clientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutUserInput>;
  clientEmployer?: InputMaybe<ClientCreateNestedOneWithoutEmployeesInput>;
  content?: InputMaybe<ContentCreateNestedManyWithoutCreatedByInput>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutCreatedByInput>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  files?: InputMaybe<FileMetadataCreateNestedManyWithoutUploadedByInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  lastOnlineAt?: InputMaybe<Scalars['DateTime']['input']>;
  managedClients?: InputMaybe<ClientCreateNestedManyWithoutAgencyManagersInput>;
  meetingNotes?: InputMaybe<MeetingNotesCreateNestedManyWithoutCreatedByInput>;
  name: Scalars['String']['input'];
  newsJackItems?: InputMaybe<NewsJackItemCreateNestedManyWithoutStatusChangedByInput>;
  newsJackRevisions?: InputMaybe<NewsJackRevisionCreateNestedManyWithoutCreatedByInput>;
  newsWrapContent?: InputMaybe<NewsWrapContentCreateNestedManyWithoutCreatedByInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  recentlyViewedContent?: InputMaybe<RecentlyViewedContentCreateNestedManyWithoutUserInput>;
  referencedInActivityComments?: InputMaybe<ActivityCommentCreateNestedManyWithoutReferencedUsersInput>;
  referencedInClientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutReferencedUsersInput>;
  referencedUsers?: InputMaybe<NewsWrapContentCreateNestedManyWithoutReferencedUsersInput>;
  scratchpad?: InputMaybe<ScratchpadCreateNestedOneWithoutUserInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  tokens?: InputMaybe<TokenCreateNestedManyWithoutUserInput>;
  workspace: WorkspaceCreateNestedOneWithoutUsersInput;
};

export type UserCreateWithoutWorkspaceInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutUsersInput>;
  activityComments?: InputMaybe<ActivityCommentCreateNestedManyWithoutUserInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  clientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutUserInput>;
  clientEmployer?: InputMaybe<ClientCreateNestedOneWithoutEmployeesInput>;
  content?: InputMaybe<ContentCreateNestedManyWithoutCreatedByInput>;
  coveragePieces?: InputMaybe<CoveragePieceCreateNestedManyWithoutCreatedByInput>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  files?: InputMaybe<FileMetadataCreateNestedManyWithoutUploadedByInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  lastOnlineAt?: InputMaybe<Scalars['DateTime']['input']>;
  managedClients?: InputMaybe<ClientCreateNestedManyWithoutAgencyManagersInput>;
  meetingNotes?: InputMaybe<MeetingNotesCreateNestedManyWithoutCreatedByInput>;
  name: Scalars['String']['input'];
  newsJackItems?: InputMaybe<NewsJackItemCreateNestedManyWithoutStatusChangedByInput>;
  newsJackRevisions?: InputMaybe<NewsJackRevisionCreateNestedManyWithoutCreatedByInput>;
  newsWrapContent?: InputMaybe<NewsWrapContentCreateNestedManyWithoutCreatedByInput>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutUserInput>;
  recentlyViewedContent?: InputMaybe<RecentlyViewedContentCreateNestedManyWithoutUserInput>;
  referencedInActivityComments?: InputMaybe<ActivityCommentCreateNestedManyWithoutReferencedUsersInput>;
  referencedInClientComments?: InputMaybe<ClientCommentCreateNestedManyWithoutReferencedUsersInput>;
  referencedUsers?: InputMaybe<NewsWrapContentCreateNestedManyWithoutReferencedUsersInput>;
  role: RoleCreateNestedOneWithoutUsersInput;
  scratchpad?: InputMaybe<ScratchpadCreateNestedOneWithoutUserInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  tokens?: InputMaybe<TokenCreateNestedManyWithoutUserInput>;
};

export type UserListRelationFilter = {
  every?: InputMaybe<UserWhereInput>;
  none?: InputMaybe<UserWhereInput>;
  some?: InputMaybe<UserWhereInput>;
};

export type UserNullableRelationFilter = {
  is?: InputMaybe<UserWhereInput>;
  isNot?: InputMaybe<UserWhereInput>;
};

export type UserOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum UserOrderByRelevanceFieldEnum {
  Bio = 'bio',
  ClientId = 'clientId',
  Email = 'email',
  Id = 'id',
  ImageUrl = 'imageUrl',
  Name = 'name',
  RoleId = 'roleId',
  Timezone = 'timezone',
  WorkspaceId = 'workspaceId'
}

export type UserOrderByRelevanceInput = {
  fields: Array<UserOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type UserOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<UserOrderByRelevanceInput>;
  activities?: InputMaybe<ActivityOrderByRelationAggregateInput>;
  activityComments?: InputMaybe<ActivityCommentOrderByRelationAggregateInput>;
  bio?: InputMaybe<SortOrder>;
  clientComments?: InputMaybe<ClientCommentOrderByRelationAggregateInput>;
  clientEmployer?: InputMaybe<ClientOrderByWithRelationAndSearchRelevanceInput>;
  clientId?: InputMaybe<SortOrderInput>;
  content?: InputMaybe<ContentOrderByRelationAggregateInput>;
  coveragePieces?: InputMaybe<CoveragePieceOrderByRelationAggregateInput>;
  deletedAt?: InputMaybe<SortOrderInput>;
  email?: InputMaybe<SortOrder>;
  files?: InputMaybe<FileMetadataOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  imageUrl?: InputMaybe<SortOrder>;
  isArchived?: InputMaybe<SortOrder>;
  lastOnlineAt?: InputMaybe<SortOrder>;
  managedClients?: InputMaybe<ClientOrderByRelationAggregateInput>;
  meetingNotes?: InputMaybe<MeetingNotesOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  newsJackItems?: InputMaybe<NewsJackItemOrderByRelationAggregateInput>;
  newsJackRevisions?: InputMaybe<NewsJackRevisionOrderByRelationAggregateInput>;
  newsWrapContent?: InputMaybe<NewsWrapContentOrderByRelationAggregateInput>;
  notifications?: InputMaybe<NotificationOrderByRelationAggregateInput>;
  recentlyViewedContent?: InputMaybe<RecentlyViewedContentOrderByRelationAggregateInput>;
  referencedInActivityComments?: InputMaybe<ActivityCommentOrderByRelationAggregateInput>;
  referencedInClientComments?: InputMaybe<ClientCommentOrderByRelationAggregateInput>;
  referencedUsers?: InputMaybe<NewsWrapContentOrderByRelationAggregateInput>;
  role?: InputMaybe<RoleOrderByWithRelationAndSearchRelevanceInput>;
  roleId?: InputMaybe<SortOrder>;
  scratchpad?: InputMaybe<ScratchpadOrderByWithRelationAndSearchRelevanceInput>;
  timezone?: InputMaybe<SortOrder>;
  tokens?: InputMaybe<TokenOrderByRelationAggregateInput>;
  workspace?: InputMaybe<WorkspaceOrderByWithRelationAndSearchRelevanceInput>;
  workspaceId?: InputMaybe<SortOrder>;
};

export type UserRelationFilter = {
  is?: InputMaybe<UserWhereInput>;
  isNot?: InputMaybe<UserWhereInput>;
};

export enum UserScalarFieldEnum {
  Bio = 'bio',
  ClientId = 'clientId',
  DeletedAt = 'deletedAt',
  Email = 'email',
  Id = 'id',
  ImageUrl = 'imageUrl',
  IsArchived = 'isArchived',
  LastOnlineAt = 'lastOnlineAt',
  Name = 'name',
  RoleId = 'roleId',
  Timezone = 'timezone',
  WorkspaceId = 'workspaceId'
}

export type UserScalarWhereInput = {
  AND?: InputMaybe<Array<UserScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserScalarWhereInput>>;
  OR?: InputMaybe<Array<UserScalarWhereInput>>;
  bio?: InputMaybe<StringFilter>;
  clientId?: InputMaybe<StringNullableFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  imageUrl?: InputMaybe<StringFilter>;
  isArchived?: InputMaybe<BoolFilter>;
  lastOnlineAt?: InputMaybe<DateTimeFilter>;
  name?: InputMaybe<StringFilter>;
  roleId?: InputMaybe<StringFilter>;
  timezone?: InputMaybe<StringFilter>;
  workspaceId?: InputMaybe<StringFilter>;
};

export type UserUpdateInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutUsersNestedInput>;
  activityComments?: InputMaybe<ActivityCommentUpdateManyWithoutUserNestedInput>;
  bio?: InputMaybe<StringFieldUpdateOperationsInput>;
  clientComments?: InputMaybe<ClientCommentUpdateManyWithoutUserNestedInput>;
  clientEmployer?: InputMaybe<ClientUpdateOneWithoutEmployeesNestedInput>;
  content?: InputMaybe<ContentUpdateManyWithoutCreatedByNestedInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutCreatedByNestedInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileMetadataUpdateManyWithoutUploadedByNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  isArchived?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastOnlineAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  managedClients?: InputMaybe<ClientUpdateManyWithoutAgencyManagersNestedInput>;
  meetingNotes?: InputMaybe<MeetingNotesUpdateManyWithoutCreatedByNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  newsJackItems?: InputMaybe<NewsJackItemUpdateManyWithoutStatusChangedByNestedInput>;
  newsJackRevisions?: InputMaybe<NewsJackRevisionUpdateManyWithoutCreatedByNestedInput>;
  newsWrapContent?: InputMaybe<NewsWrapContentUpdateManyWithoutCreatedByNestedInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserNestedInput>;
  recentlyViewedContent?: InputMaybe<RecentlyViewedContentUpdateManyWithoutUserNestedInput>;
  referencedInActivityComments?: InputMaybe<ActivityCommentUpdateManyWithoutReferencedUsersNestedInput>;
  referencedInClientComments?: InputMaybe<ClientCommentUpdateManyWithoutReferencedUsersNestedInput>;
  referencedUsers?: InputMaybe<NewsWrapContentUpdateManyWithoutReferencedUsersNestedInput>;
  role?: InputMaybe<RoleUpdateOneRequiredWithoutUsersNestedInput>;
  scratchpad?: InputMaybe<ScratchpadUpdateOneWithoutUserNestedInput>;
  timezone?: InputMaybe<StringFieldUpdateOperationsInput>;
  tokens?: InputMaybe<TokenUpdateManyWithoutUserNestedInput>;
  workspace?: InputMaybe<WorkspaceUpdateOneRequiredWithoutUsersNestedInput>;
};

export type UserUpdateManyMutationInput = {
  bio?: InputMaybe<StringFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  isArchived?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastOnlineAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  timezone?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserUpdateManyWithWhereWithoutActivitiesInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutClientEmployerInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutManagedClientsInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutReferencedInActivityCommentsInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutReferencedInClientCommentsInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutReferencedUsersInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutRoleInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutWorkspaceInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithoutActivitiesNestedInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutActivitiesInput>>;
  create?: InputMaybe<Array<UserCreateWithoutActivitiesInput>>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutActivitiesInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutActivitiesInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutActivitiesInput>>;
};

export type UserUpdateManyWithoutClientEmployerNestedInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutClientEmployerInput>>;
  create?: InputMaybe<Array<UserCreateWithoutClientEmployerInput>>;
  createMany?: InputMaybe<UserCreateManyClientEmployerInputEnvelope>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutClientEmployerInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutClientEmployerInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutClientEmployerInput>>;
};

export type UserUpdateManyWithoutManagedClientsNestedInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutManagedClientsInput>>;
  create?: InputMaybe<Array<UserCreateWithoutManagedClientsInput>>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutManagedClientsInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutManagedClientsInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutManagedClientsInput>>;
};

export type UserUpdateManyWithoutReferencedInActivityCommentsNestedInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutReferencedInActivityCommentsInput>>;
  create?: InputMaybe<Array<UserCreateWithoutReferencedInActivityCommentsInput>>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutReferencedInActivityCommentsInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutReferencedInActivityCommentsInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutReferencedInActivityCommentsInput>>;
};

export type UserUpdateManyWithoutReferencedInClientCommentsNestedInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutReferencedInClientCommentsInput>>;
  create?: InputMaybe<Array<UserCreateWithoutReferencedInClientCommentsInput>>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutReferencedInClientCommentsInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutReferencedInClientCommentsInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutReferencedInClientCommentsInput>>;
};

export type UserUpdateManyWithoutReferencedUsersNestedInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutReferencedUsersInput>>;
  create?: InputMaybe<Array<UserCreateWithoutReferencedUsersInput>>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutReferencedUsersInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutReferencedUsersInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutReferencedUsersInput>>;
};

export type UserUpdateManyWithoutRoleNestedInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutRoleInput>>;
  create?: InputMaybe<Array<UserCreateWithoutRoleInput>>;
  createMany?: InputMaybe<UserCreateManyRoleInputEnvelope>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutRoleInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutRoleInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutRoleInput>>;
};

export type UserUpdateManyWithoutWorkspaceNestedInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutWorkspaceInput>>;
  create?: InputMaybe<Array<UserCreateWithoutWorkspaceInput>>;
  createMany?: InputMaybe<UserCreateManyWorkspaceInputEnvelope>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutWorkspaceInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutWorkspaceInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutWorkspaceInput>>;
};

export type UserUpdateOneRequiredWithoutActivityCommentsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutActivityCommentsInput>;
  create?: InputMaybe<UserCreateWithoutActivityCommentsInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutActivityCommentsInput>;
  upsert?: InputMaybe<UserUpsertWithoutActivityCommentsInput>;
};

export type UserUpdateOneRequiredWithoutClientCommentsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutClientCommentsInput>;
  create?: InputMaybe<UserCreateWithoutClientCommentsInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutClientCommentsInput>;
  upsert?: InputMaybe<UserUpsertWithoutClientCommentsInput>;
};

export type UserUpdateOneRequiredWithoutContentNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutContentInput>;
  create?: InputMaybe<UserCreateWithoutContentInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutContentInput>;
  upsert?: InputMaybe<UserUpsertWithoutContentInput>;
};

export type UserUpdateOneRequiredWithoutCoveragePiecesNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCoveragePiecesInput>;
  create?: InputMaybe<UserCreateWithoutCoveragePiecesInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutCoveragePiecesInput>;
  upsert?: InputMaybe<UserUpsertWithoutCoveragePiecesInput>;
};

export type UserUpdateOneRequiredWithoutMeetingNotesNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutMeetingNotesInput>;
  create?: InputMaybe<UserCreateWithoutMeetingNotesInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutMeetingNotesInput>;
  upsert?: InputMaybe<UserUpsertWithoutMeetingNotesInput>;
};

export type UserUpdateOneRequiredWithoutNewsJackRevisionsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutNewsJackRevisionsInput>;
  create?: InputMaybe<UserCreateWithoutNewsJackRevisionsInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutNewsJackRevisionsInput>;
  upsert?: InputMaybe<UserUpsertWithoutNewsJackRevisionsInput>;
};

export type UserUpdateOneRequiredWithoutNewsWrapContentNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutNewsWrapContentInput>;
  create?: InputMaybe<UserCreateWithoutNewsWrapContentInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutNewsWrapContentInput>;
  upsert?: InputMaybe<UserUpsertWithoutNewsWrapContentInput>;
};

export type UserUpdateOneRequiredWithoutRecentlyViewedContentNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutRecentlyViewedContentInput>;
  create?: InputMaybe<UserCreateWithoutRecentlyViewedContentInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutRecentlyViewedContentInput>;
  upsert?: InputMaybe<UserUpsertWithoutRecentlyViewedContentInput>;
};

export type UserUpdateOneWithoutFilesNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutFilesInput>;
  create?: InputMaybe<UserCreateWithoutFilesInput>;
  delete?: InputMaybe<UserWhereInput>;
  disconnect?: InputMaybe<UserWhereInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutFilesInput>;
  upsert?: InputMaybe<UserUpsertWithoutFilesInput>;
};

export type UserUpdateOneWithoutNewsJackItemsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutNewsJackItemsInput>;
  create?: InputMaybe<UserCreateWithoutNewsJackItemsInput>;
  delete?: InputMaybe<UserWhereInput>;
  disconnect?: InputMaybe<UserWhereInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutNewsJackItemsInput>;
  upsert?: InputMaybe<UserUpsertWithoutNewsJackItemsInput>;
};

export type UserUpdateToOneWithWhereWithoutActivityCommentsInput = {
  data: UserUpdateWithoutActivityCommentsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutClientCommentsInput = {
  data: UserUpdateWithoutClientCommentsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutContentInput = {
  data: UserUpdateWithoutContentInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutCoveragePiecesInput = {
  data: UserUpdateWithoutCoveragePiecesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutFilesInput = {
  data: UserUpdateWithoutFilesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutMeetingNotesInput = {
  data: UserUpdateWithoutMeetingNotesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutNewsJackItemsInput = {
  data: UserUpdateWithoutNewsJackItemsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutNewsJackRevisionsInput = {
  data: UserUpdateWithoutNewsJackRevisionsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutNewsWrapContentInput = {
  data: UserUpdateWithoutNewsWrapContentInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutRecentlyViewedContentInput = {
  data: UserUpdateWithoutRecentlyViewedContentInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateWithWhereUniqueWithoutActivitiesInput = {
  data: UserUpdateWithoutActivitiesInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutClientEmployerInput = {
  data: UserUpdateWithoutClientEmployerInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutManagedClientsInput = {
  data: UserUpdateWithoutManagedClientsInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutReferencedInActivityCommentsInput = {
  data: UserUpdateWithoutReferencedInActivityCommentsInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutReferencedInClientCommentsInput = {
  data: UserUpdateWithoutReferencedInClientCommentsInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutReferencedUsersInput = {
  data: UserUpdateWithoutReferencedUsersInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutRoleInput = {
  data: UserUpdateWithoutRoleInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutWorkspaceInput = {
  data: UserUpdateWithoutWorkspaceInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithoutActivitiesInput = {
  activityComments?: InputMaybe<ActivityCommentUpdateManyWithoutUserNestedInput>;
  bio?: InputMaybe<StringFieldUpdateOperationsInput>;
  clientComments?: InputMaybe<ClientCommentUpdateManyWithoutUserNestedInput>;
  clientEmployer?: InputMaybe<ClientUpdateOneWithoutEmployeesNestedInput>;
  content?: InputMaybe<ContentUpdateManyWithoutCreatedByNestedInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutCreatedByNestedInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileMetadataUpdateManyWithoutUploadedByNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  isArchived?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastOnlineAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  managedClients?: InputMaybe<ClientUpdateManyWithoutAgencyManagersNestedInput>;
  meetingNotes?: InputMaybe<MeetingNotesUpdateManyWithoutCreatedByNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  newsJackItems?: InputMaybe<NewsJackItemUpdateManyWithoutStatusChangedByNestedInput>;
  newsJackRevisions?: InputMaybe<NewsJackRevisionUpdateManyWithoutCreatedByNestedInput>;
  newsWrapContent?: InputMaybe<NewsWrapContentUpdateManyWithoutCreatedByNestedInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserNestedInput>;
  recentlyViewedContent?: InputMaybe<RecentlyViewedContentUpdateManyWithoutUserNestedInput>;
  referencedInActivityComments?: InputMaybe<ActivityCommentUpdateManyWithoutReferencedUsersNestedInput>;
  referencedInClientComments?: InputMaybe<ClientCommentUpdateManyWithoutReferencedUsersNestedInput>;
  referencedUsers?: InputMaybe<NewsWrapContentUpdateManyWithoutReferencedUsersNestedInput>;
  role?: InputMaybe<RoleUpdateOneRequiredWithoutUsersNestedInput>;
  scratchpad?: InputMaybe<ScratchpadUpdateOneWithoutUserNestedInput>;
  timezone?: InputMaybe<StringFieldUpdateOperationsInput>;
  tokens?: InputMaybe<TokenUpdateManyWithoutUserNestedInput>;
  workspace?: InputMaybe<WorkspaceUpdateOneRequiredWithoutUsersNestedInput>;
};

export type UserUpdateWithoutActivityCommentsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutUsersNestedInput>;
  bio?: InputMaybe<StringFieldUpdateOperationsInput>;
  clientComments?: InputMaybe<ClientCommentUpdateManyWithoutUserNestedInput>;
  clientEmployer?: InputMaybe<ClientUpdateOneWithoutEmployeesNestedInput>;
  content?: InputMaybe<ContentUpdateManyWithoutCreatedByNestedInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutCreatedByNestedInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileMetadataUpdateManyWithoutUploadedByNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  isArchived?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastOnlineAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  managedClients?: InputMaybe<ClientUpdateManyWithoutAgencyManagersNestedInput>;
  meetingNotes?: InputMaybe<MeetingNotesUpdateManyWithoutCreatedByNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  newsJackItems?: InputMaybe<NewsJackItemUpdateManyWithoutStatusChangedByNestedInput>;
  newsJackRevisions?: InputMaybe<NewsJackRevisionUpdateManyWithoutCreatedByNestedInput>;
  newsWrapContent?: InputMaybe<NewsWrapContentUpdateManyWithoutCreatedByNestedInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserNestedInput>;
  recentlyViewedContent?: InputMaybe<RecentlyViewedContentUpdateManyWithoutUserNestedInput>;
  referencedInActivityComments?: InputMaybe<ActivityCommentUpdateManyWithoutReferencedUsersNestedInput>;
  referencedInClientComments?: InputMaybe<ClientCommentUpdateManyWithoutReferencedUsersNestedInput>;
  referencedUsers?: InputMaybe<NewsWrapContentUpdateManyWithoutReferencedUsersNestedInput>;
  role?: InputMaybe<RoleUpdateOneRequiredWithoutUsersNestedInput>;
  scratchpad?: InputMaybe<ScratchpadUpdateOneWithoutUserNestedInput>;
  timezone?: InputMaybe<StringFieldUpdateOperationsInput>;
  tokens?: InputMaybe<TokenUpdateManyWithoutUserNestedInput>;
  workspace?: InputMaybe<WorkspaceUpdateOneRequiredWithoutUsersNestedInput>;
};

export type UserUpdateWithoutClientCommentsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutUsersNestedInput>;
  activityComments?: InputMaybe<ActivityCommentUpdateManyWithoutUserNestedInput>;
  bio?: InputMaybe<StringFieldUpdateOperationsInput>;
  clientEmployer?: InputMaybe<ClientUpdateOneWithoutEmployeesNestedInput>;
  content?: InputMaybe<ContentUpdateManyWithoutCreatedByNestedInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutCreatedByNestedInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileMetadataUpdateManyWithoutUploadedByNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  isArchived?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastOnlineAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  managedClients?: InputMaybe<ClientUpdateManyWithoutAgencyManagersNestedInput>;
  meetingNotes?: InputMaybe<MeetingNotesUpdateManyWithoutCreatedByNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  newsJackItems?: InputMaybe<NewsJackItemUpdateManyWithoutStatusChangedByNestedInput>;
  newsJackRevisions?: InputMaybe<NewsJackRevisionUpdateManyWithoutCreatedByNestedInput>;
  newsWrapContent?: InputMaybe<NewsWrapContentUpdateManyWithoutCreatedByNestedInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserNestedInput>;
  recentlyViewedContent?: InputMaybe<RecentlyViewedContentUpdateManyWithoutUserNestedInput>;
  referencedInActivityComments?: InputMaybe<ActivityCommentUpdateManyWithoutReferencedUsersNestedInput>;
  referencedInClientComments?: InputMaybe<ClientCommentUpdateManyWithoutReferencedUsersNestedInput>;
  referencedUsers?: InputMaybe<NewsWrapContentUpdateManyWithoutReferencedUsersNestedInput>;
  role?: InputMaybe<RoleUpdateOneRequiredWithoutUsersNestedInput>;
  scratchpad?: InputMaybe<ScratchpadUpdateOneWithoutUserNestedInput>;
  timezone?: InputMaybe<StringFieldUpdateOperationsInput>;
  tokens?: InputMaybe<TokenUpdateManyWithoutUserNestedInput>;
  workspace?: InputMaybe<WorkspaceUpdateOneRequiredWithoutUsersNestedInput>;
};

export type UserUpdateWithoutClientEmployerInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutUsersNestedInput>;
  activityComments?: InputMaybe<ActivityCommentUpdateManyWithoutUserNestedInput>;
  bio?: InputMaybe<StringFieldUpdateOperationsInput>;
  clientComments?: InputMaybe<ClientCommentUpdateManyWithoutUserNestedInput>;
  content?: InputMaybe<ContentUpdateManyWithoutCreatedByNestedInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutCreatedByNestedInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileMetadataUpdateManyWithoutUploadedByNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  isArchived?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastOnlineAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  managedClients?: InputMaybe<ClientUpdateManyWithoutAgencyManagersNestedInput>;
  meetingNotes?: InputMaybe<MeetingNotesUpdateManyWithoutCreatedByNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  newsJackItems?: InputMaybe<NewsJackItemUpdateManyWithoutStatusChangedByNestedInput>;
  newsJackRevisions?: InputMaybe<NewsJackRevisionUpdateManyWithoutCreatedByNestedInput>;
  newsWrapContent?: InputMaybe<NewsWrapContentUpdateManyWithoutCreatedByNestedInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserNestedInput>;
  recentlyViewedContent?: InputMaybe<RecentlyViewedContentUpdateManyWithoutUserNestedInput>;
  referencedInActivityComments?: InputMaybe<ActivityCommentUpdateManyWithoutReferencedUsersNestedInput>;
  referencedInClientComments?: InputMaybe<ClientCommentUpdateManyWithoutReferencedUsersNestedInput>;
  referencedUsers?: InputMaybe<NewsWrapContentUpdateManyWithoutReferencedUsersNestedInput>;
  role?: InputMaybe<RoleUpdateOneRequiredWithoutUsersNestedInput>;
  scratchpad?: InputMaybe<ScratchpadUpdateOneWithoutUserNestedInput>;
  timezone?: InputMaybe<StringFieldUpdateOperationsInput>;
  tokens?: InputMaybe<TokenUpdateManyWithoutUserNestedInput>;
  workspace?: InputMaybe<WorkspaceUpdateOneRequiredWithoutUsersNestedInput>;
};

export type UserUpdateWithoutContentInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutUsersNestedInput>;
  activityComments?: InputMaybe<ActivityCommentUpdateManyWithoutUserNestedInput>;
  bio?: InputMaybe<StringFieldUpdateOperationsInput>;
  clientComments?: InputMaybe<ClientCommentUpdateManyWithoutUserNestedInput>;
  clientEmployer?: InputMaybe<ClientUpdateOneWithoutEmployeesNestedInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutCreatedByNestedInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileMetadataUpdateManyWithoutUploadedByNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  isArchived?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastOnlineAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  managedClients?: InputMaybe<ClientUpdateManyWithoutAgencyManagersNestedInput>;
  meetingNotes?: InputMaybe<MeetingNotesUpdateManyWithoutCreatedByNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  newsJackItems?: InputMaybe<NewsJackItemUpdateManyWithoutStatusChangedByNestedInput>;
  newsJackRevisions?: InputMaybe<NewsJackRevisionUpdateManyWithoutCreatedByNestedInput>;
  newsWrapContent?: InputMaybe<NewsWrapContentUpdateManyWithoutCreatedByNestedInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserNestedInput>;
  recentlyViewedContent?: InputMaybe<RecentlyViewedContentUpdateManyWithoutUserNestedInput>;
  referencedInActivityComments?: InputMaybe<ActivityCommentUpdateManyWithoutReferencedUsersNestedInput>;
  referencedInClientComments?: InputMaybe<ClientCommentUpdateManyWithoutReferencedUsersNestedInput>;
  referencedUsers?: InputMaybe<NewsWrapContentUpdateManyWithoutReferencedUsersNestedInput>;
  role?: InputMaybe<RoleUpdateOneRequiredWithoutUsersNestedInput>;
  scratchpad?: InputMaybe<ScratchpadUpdateOneWithoutUserNestedInput>;
  timezone?: InputMaybe<StringFieldUpdateOperationsInput>;
  tokens?: InputMaybe<TokenUpdateManyWithoutUserNestedInput>;
  workspace?: InputMaybe<WorkspaceUpdateOneRequiredWithoutUsersNestedInput>;
};

export type UserUpdateWithoutCoveragePiecesInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutUsersNestedInput>;
  activityComments?: InputMaybe<ActivityCommentUpdateManyWithoutUserNestedInput>;
  bio?: InputMaybe<StringFieldUpdateOperationsInput>;
  clientComments?: InputMaybe<ClientCommentUpdateManyWithoutUserNestedInput>;
  clientEmployer?: InputMaybe<ClientUpdateOneWithoutEmployeesNestedInput>;
  content?: InputMaybe<ContentUpdateManyWithoutCreatedByNestedInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileMetadataUpdateManyWithoutUploadedByNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  isArchived?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastOnlineAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  managedClients?: InputMaybe<ClientUpdateManyWithoutAgencyManagersNestedInput>;
  meetingNotes?: InputMaybe<MeetingNotesUpdateManyWithoutCreatedByNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  newsJackItems?: InputMaybe<NewsJackItemUpdateManyWithoutStatusChangedByNestedInput>;
  newsJackRevisions?: InputMaybe<NewsJackRevisionUpdateManyWithoutCreatedByNestedInput>;
  newsWrapContent?: InputMaybe<NewsWrapContentUpdateManyWithoutCreatedByNestedInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserNestedInput>;
  recentlyViewedContent?: InputMaybe<RecentlyViewedContentUpdateManyWithoutUserNestedInput>;
  referencedInActivityComments?: InputMaybe<ActivityCommentUpdateManyWithoutReferencedUsersNestedInput>;
  referencedInClientComments?: InputMaybe<ClientCommentUpdateManyWithoutReferencedUsersNestedInput>;
  referencedUsers?: InputMaybe<NewsWrapContentUpdateManyWithoutReferencedUsersNestedInput>;
  role?: InputMaybe<RoleUpdateOneRequiredWithoutUsersNestedInput>;
  scratchpad?: InputMaybe<ScratchpadUpdateOneWithoutUserNestedInput>;
  timezone?: InputMaybe<StringFieldUpdateOperationsInput>;
  tokens?: InputMaybe<TokenUpdateManyWithoutUserNestedInput>;
  workspace?: InputMaybe<WorkspaceUpdateOneRequiredWithoutUsersNestedInput>;
};

export type UserUpdateWithoutFilesInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutUsersNestedInput>;
  activityComments?: InputMaybe<ActivityCommentUpdateManyWithoutUserNestedInput>;
  bio?: InputMaybe<StringFieldUpdateOperationsInput>;
  clientComments?: InputMaybe<ClientCommentUpdateManyWithoutUserNestedInput>;
  clientEmployer?: InputMaybe<ClientUpdateOneWithoutEmployeesNestedInput>;
  content?: InputMaybe<ContentUpdateManyWithoutCreatedByNestedInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutCreatedByNestedInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  isArchived?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastOnlineAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  managedClients?: InputMaybe<ClientUpdateManyWithoutAgencyManagersNestedInput>;
  meetingNotes?: InputMaybe<MeetingNotesUpdateManyWithoutCreatedByNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  newsJackItems?: InputMaybe<NewsJackItemUpdateManyWithoutStatusChangedByNestedInput>;
  newsJackRevisions?: InputMaybe<NewsJackRevisionUpdateManyWithoutCreatedByNestedInput>;
  newsWrapContent?: InputMaybe<NewsWrapContentUpdateManyWithoutCreatedByNestedInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserNestedInput>;
  recentlyViewedContent?: InputMaybe<RecentlyViewedContentUpdateManyWithoutUserNestedInput>;
  referencedInActivityComments?: InputMaybe<ActivityCommentUpdateManyWithoutReferencedUsersNestedInput>;
  referencedInClientComments?: InputMaybe<ClientCommentUpdateManyWithoutReferencedUsersNestedInput>;
  referencedUsers?: InputMaybe<NewsWrapContentUpdateManyWithoutReferencedUsersNestedInput>;
  role?: InputMaybe<RoleUpdateOneRequiredWithoutUsersNestedInput>;
  scratchpad?: InputMaybe<ScratchpadUpdateOneWithoutUserNestedInput>;
  timezone?: InputMaybe<StringFieldUpdateOperationsInput>;
  tokens?: InputMaybe<TokenUpdateManyWithoutUserNestedInput>;
  workspace?: InputMaybe<WorkspaceUpdateOneRequiredWithoutUsersNestedInput>;
};

export type UserUpdateWithoutManagedClientsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutUsersNestedInput>;
  activityComments?: InputMaybe<ActivityCommentUpdateManyWithoutUserNestedInput>;
  bio?: InputMaybe<StringFieldUpdateOperationsInput>;
  clientComments?: InputMaybe<ClientCommentUpdateManyWithoutUserNestedInput>;
  clientEmployer?: InputMaybe<ClientUpdateOneWithoutEmployeesNestedInput>;
  content?: InputMaybe<ContentUpdateManyWithoutCreatedByNestedInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutCreatedByNestedInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileMetadataUpdateManyWithoutUploadedByNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  isArchived?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastOnlineAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  meetingNotes?: InputMaybe<MeetingNotesUpdateManyWithoutCreatedByNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  newsJackItems?: InputMaybe<NewsJackItemUpdateManyWithoutStatusChangedByNestedInput>;
  newsJackRevisions?: InputMaybe<NewsJackRevisionUpdateManyWithoutCreatedByNestedInput>;
  newsWrapContent?: InputMaybe<NewsWrapContentUpdateManyWithoutCreatedByNestedInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserNestedInput>;
  recentlyViewedContent?: InputMaybe<RecentlyViewedContentUpdateManyWithoutUserNestedInput>;
  referencedInActivityComments?: InputMaybe<ActivityCommentUpdateManyWithoutReferencedUsersNestedInput>;
  referencedInClientComments?: InputMaybe<ClientCommentUpdateManyWithoutReferencedUsersNestedInput>;
  referencedUsers?: InputMaybe<NewsWrapContentUpdateManyWithoutReferencedUsersNestedInput>;
  role?: InputMaybe<RoleUpdateOneRequiredWithoutUsersNestedInput>;
  scratchpad?: InputMaybe<ScratchpadUpdateOneWithoutUserNestedInput>;
  timezone?: InputMaybe<StringFieldUpdateOperationsInput>;
  tokens?: InputMaybe<TokenUpdateManyWithoutUserNestedInput>;
  workspace?: InputMaybe<WorkspaceUpdateOneRequiredWithoutUsersNestedInput>;
};

export type UserUpdateWithoutMeetingNotesInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutUsersNestedInput>;
  activityComments?: InputMaybe<ActivityCommentUpdateManyWithoutUserNestedInput>;
  bio?: InputMaybe<StringFieldUpdateOperationsInput>;
  clientComments?: InputMaybe<ClientCommentUpdateManyWithoutUserNestedInput>;
  clientEmployer?: InputMaybe<ClientUpdateOneWithoutEmployeesNestedInput>;
  content?: InputMaybe<ContentUpdateManyWithoutCreatedByNestedInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutCreatedByNestedInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileMetadataUpdateManyWithoutUploadedByNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  isArchived?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastOnlineAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  managedClients?: InputMaybe<ClientUpdateManyWithoutAgencyManagersNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  newsJackItems?: InputMaybe<NewsJackItemUpdateManyWithoutStatusChangedByNestedInput>;
  newsJackRevisions?: InputMaybe<NewsJackRevisionUpdateManyWithoutCreatedByNestedInput>;
  newsWrapContent?: InputMaybe<NewsWrapContentUpdateManyWithoutCreatedByNestedInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserNestedInput>;
  recentlyViewedContent?: InputMaybe<RecentlyViewedContentUpdateManyWithoutUserNestedInput>;
  referencedInActivityComments?: InputMaybe<ActivityCommentUpdateManyWithoutReferencedUsersNestedInput>;
  referencedInClientComments?: InputMaybe<ClientCommentUpdateManyWithoutReferencedUsersNestedInput>;
  referencedUsers?: InputMaybe<NewsWrapContentUpdateManyWithoutReferencedUsersNestedInput>;
  role?: InputMaybe<RoleUpdateOneRequiredWithoutUsersNestedInput>;
  scratchpad?: InputMaybe<ScratchpadUpdateOneWithoutUserNestedInput>;
  timezone?: InputMaybe<StringFieldUpdateOperationsInput>;
  tokens?: InputMaybe<TokenUpdateManyWithoutUserNestedInput>;
  workspace?: InputMaybe<WorkspaceUpdateOneRequiredWithoutUsersNestedInput>;
};

export type UserUpdateWithoutNewsJackItemsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutUsersNestedInput>;
  activityComments?: InputMaybe<ActivityCommentUpdateManyWithoutUserNestedInput>;
  bio?: InputMaybe<StringFieldUpdateOperationsInput>;
  clientComments?: InputMaybe<ClientCommentUpdateManyWithoutUserNestedInput>;
  clientEmployer?: InputMaybe<ClientUpdateOneWithoutEmployeesNestedInput>;
  content?: InputMaybe<ContentUpdateManyWithoutCreatedByNestedInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutCreatedByNestedInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileMetadataUpdateManyWithoutUploadedByNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  isArchived?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastOnlineAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  managedClients?: InputMaybe<ClientUpdateManyWithoutAgencyManagersNestedInput>;
  meetingNotes?: InputMaybe<MeetingNotesUpdateManyWithoutCreatedByNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  newsJackRevisions?: InputMaybe<NewsJackRevisionUpdateManyWithoutCreatedByNestedInput>;
  newsWrapContent?: InputMaybe<NewsWrapContentUpdateManyWithoutCreatedByNestedInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserNestedInput>;
  recentlyViewedContent?: InputMaybe<RecentlyViewedContentUpdateManyWithoutUserNestedInput>;
  referencedInActivityComments?: InputMaybe<ActivityCommentUpdateManyWithoutReferencedUsersNestedInput>;
  referencedInClientComments?: InputMaybe<ClientCommentUpdateManyWithoutReferencedUsersNestedInput>;
  referencedUsers?: InputMaybe<NewsWrapContentUpdateManyWithoutReferencedUsersNestedInput>;
  role?: InputMaybe<RoleUpdateOneRequiredWithoutUsersNestedInput>;
  scratchpad?: InputMaybe<ScratchpadUpdateOneWithoutUserNestedInput>;
  timezone?: InputMaybe<StringFieldUpdateOperationsInput>;
  tokens?: InputMaybe<TokenUpdateManyWithoutUserNestedInput>;
  workspace?: InputMaybe<WorkspaceUpdateOneRequiredWithoutUsersNestedInput>;
};

export type UserUpdateWithoutNewsJackRevisionsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutUsersNestedInput>;
  activityComments?: InputMaybe<ActivityCommentUpdateManyWithoutUserNestedInput>;
  bio?: InputMaybe<StringFieldUpdateOperationsInput>;
  clientComments?: InputMaybe<ClientCommentUpdateManyWithoutUserNestedInput>;
  clientEmployer?: InputMaybe<ClientUpdateOneWithoutEmployeesNestedInput>;
  content?: InputMaybe<ContentUpdateManyWithoutCreatedByNestedInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutCreatedByNestedInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileMetadataUpdateManyWithoutUploadedByNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  isArchived?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastOnlineAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  managedClients?: InputMaybe<ClientUpdateManyWithoutAgencyManagersNestedInput>;
  meetingNotes?: InputMaybe<MeetingNotesUpdateManyWithoutCreatedByNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  newsJackItems?: InputMaybe<NewsJackItemUpdateManyWithoutStatusChangedByNestedInput>;
  newsWrapContent?: InputMaybe<NewsWrapContentUpdateManyWithoutCreatedByNestedInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserNestedInput>;
  recentlyViewedContent?: InputMaybe<RecentlyViewedContentUpdateManyWithoutUserNestedInput>;
  referencedInActivityComments?: InputMaybe<ActivityCommentUpdateManyWithoutReferencedUsersNestedInput>;
  referencedInClientComments?: InputMaybe<ClientCommentUpdateManyWithoutReferencedUsersNestedInput>;
  referencedUsers?: InputMaybe<NewsWrapContentUpdateManyWithoutReferencedUsersNestedInput>;
  role?: InputMaybe<RoleUpdateOneRequiredWithoutUsersNestedInput>;
  scratchpad?: InputMaybe<ScratchpadUpdateOneWithoutUserNestedInput>;
  timezone?: InputMaybe<StringFieldUpdateOperationsInput>;
  tokens?: InputMaybe<TokenUpdateManyWithoutUserNestedInput>;
  workspace?: InputMaybe<WorkspaceUpdateOneRequiredWithoutUsersNestedInput>;
};

export type UserUpdateWithoutNewsWrapContentInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutUsersNestedInput>;
  activityComments?: InputMaybe<ActivityCommentUpdateManyWithoutUserNestedInput>;
  bio?: InputMaybe<StringFieldUpdateOperationsInput>;
  clientComments?: InputMaybe<ClientCommentUpdateManyWithoutUserNestedInput>;
  clientEmployer?: InputMaybe<ClientUpdateOneWithoutEmployeesNestedInput>;
  content?: InputMaybe<ContentUpdateManyWithoutCreatedByNestedInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutCreatedByNestedInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileMetadataUpdateManyWithoutUploadedByNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  isArchived?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastOnlineAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  managedClients?: InputMaybe<ClientUpdateManyWithoutAgencyManagersNestedInput>;
  meetingNotes?: InputMaybe<MeetingNotesUpdateManyWithoutCreatedByNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  newsJackItems?: InputMaybe<NewsJackItemUpdateManyWithoutStatusChangedByNestedInput>;
  newsJackRevisions?: InputMaybe<NewsJackRevisionUpdateManyWithoutCreatedByNestedInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserNestedInput>;
  recentlyViewedContent?: InputMaybe<RecentlyViewedContentUpdateManyWithoutUserNestedInput>;
  referencedInActivityComments?: InputMaybe<ActivityCommentUpdateManyWithoutReferencedUsersNestedInput>;
  referencedInClientComments?: InputMaybe<ClientCommentUpdateManyWithoutReferencedUsersNestedInput>;
  referencedUsers?: InputMaybe<NewsWrapContentUpdateManyWithoutReferencedUsersNestedInput>;
  role?: InputMaybe<RoleUpdateOneRequiredWithoutUsersNestedInput>;
  scratchpad?: InputMaybe<ScratchpadUpdateOneWithoutUserNestedInput>;
  timezone?: InputMaybe<StringFieldUpdateOperationsInput>;
  tokens?: InputMaybe<TokenUpdateManyWithoutUserNestedInput>;
  workspace?: InputMaybe<WorkspaceUpdateOneRequiredWithoutUsersNestedInput>;
};

export type UserUpdateWithoutRecentlyViewedContentInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutUsersNestedInput>;
  activityComments?: InputMaybe<ActivityCommentUpdateManyWithoutUserNestedInput>;
  bio?: InputMaybe<StringFieldUpdateOperationsInput>;
  clientComments?: InputMaybe<ClientCommentUpdateManyWithoutUserNestedInput>;
  clientEmployer?: InputMaybe<ClientUpdateOneWithoutEmployeesNestedInput>;
  content?: InputMaybe<ContentUpdateManyWithoutCreatedByNestedInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutCreatedByNestedInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileMetadataUpdateManyWithoutUploadedByNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  isArchived?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastOnlineAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  managedClients?: InputMaybe<ClientUpdateManyWithoutAgencyManagersNestedInput>;
  meetingNotes?: InputMaybe<MeetingNotesUpdateManyWithoutCreatedByNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  newsJackItems?: InputMaybe<NewsJackItemUpdateManyWithoutStatusChangedByNestedInput>;
  newsJackRevisions?: InputMaybe<NewsJackRevisionUpdateManyWithoutCreatedByNestedInput>;
  newsWrapContent?: InputMaybe<NewsWrapContentUpdateManyWithoutCreatedByNestedInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserNestedInput>;
  referencedInActivityComments?: InputMaybe<ActivityCommentUpdateManyWithoutReferencedUsersNestedInput>;
  referencedInClientComments?: InputMaybe<ClientCommentUpdateManyWithoutReferencedUsersNestedInput>;
  referencedUsers?: InputMaybe<NewsWrapContentUpdateManyWithoutReferencedUsersNestedInput>;
  role?: InputMaybe<RoleUpdateOneRequiredWithoutUsersNestedInput>;
  scratchpad?: InputMaybe<ScratchpadUpdateOneWithoutUserNestedInput>;
  timezone?: InputMaybe<StringFieldUpdateOperationsInput>;
  tokens?: InputMaybe<TokenUpdateManyWithoutUserNestedInput>;
  workspace?: InputMaybe<WorkspaceUpdateOneRequiredWithoutUsersNestedInput>;
};

export type UserUpdateWithoutReferencedInActivityCommentsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutUsersNestedInput>;
  activityComments?: InputMaybe<ActivityCommentUpdateManyWithoutUserNestedInput>;
  bio?: InputMaybe<StringFieldUpdateOperationsInput>;
  clientComments?: InputMaybe<ClientCommentUpdateManyWithoutUserNestedInput>;
  clientEmployer?: InputMaybe<ClientUpdateOneWithoutEmployeesNestedInput>;
  content?: InputMaybe<ContentUpdateManyWithoutCreatedByNestedInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutCreatedByNestedInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileMetadataUpdateManyWithoutUploadedByNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  isArchived?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastOnlineAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  managedClients?: InputMaybe<ClientUpdateManyWithoutAgencyManagersNestedInput>;
  meetingNotes?: InputMaybe<MeetingNotesUpdateManyWithoutCreatedByNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  newsJackItems?: InputMaybe<NewsJackItemUpdateManyWithoutStatusChangedByNestedInput>;
  newsJackRevisions?: InputMaybe<NewsJackRevisionUpdateManyWithoutCreatedByNestedInput>;
  newsWrapContent?: InputMaybe<NewsWrapContentUpdateManyWithoutCreatedByNestedInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserNestedInput>;
  recentlyViewedContent?: InputMaybe<RecentlyViewedContentUpdateManyWithoutUserNestedInput>;
  referencedInClientComments?: InputMaybe<ClientCommentUpdateManyWithoutReferencedUsersNestedInput>;
  referencedUsers?: InputMaybe<NewsWrapContentUpdateManyWithoutReferencedUsersNestedInput>;
  role?: InputMaybe<RoleUpdateOneRequiredWithoutUsersNestedInput>;
  scratchpad?: InputMaybe<ScratchpadUpdateOneWithoutUserNestedInput>;
  timezone?: InputMaybe<StringFieldUpdateOperationsInput>;
  tokens?: InputMaybe<TokenUpdateManyWithoutUserNestedInput>;
  workspace?: InputMaybe<WorkspaceUpdateOneRequiredWithoutUsersNestedInput>;
};

export type UserUpdateWithoutReferencedInClientCommentsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutUsersNestedInput>;
  activityComments?: InputMaybe<ActivityCommentUpdateManyWithoutUserNestedInput>;
  bio?: InputMaybe<StringFieldUpdateOperationsInput>;
  clientComments?: InputMaybe<ClientCommentUpdateManyWithoutUserNestedInput>;
  clientEmployer?: InputMaybe<ClientUpdateOneWithoutEmployeesNestedInput>;
  content?: InputMaybe<ContentUpdateManyWithoutCreatedByNestedInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutCreatedByNestedInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileMetadataUpdateManyWithoutUploadedByNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  isArchived?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastOnlineAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  managedClients?: InputMaybe<ClientUpdateManyWithoutAgencyManagersNestedInput>;
  meetingNotes?: InputMaybe<MeetingNotesUpdateManyWithoutCreatedByNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  newsJackItems?: InputMaybe<NewsJackItemUpdateManyWithoutStatusChangedByNestedInput>;
  newsJackRevisions?: InputMaybe<NewsJackRevisionUpdateManyWithoutCreatedByNestedInput>;
  newsWrapContent?: InputMaybe<NewsWrapContentUpdateManyWithoutCreatedByNestedInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserNestedInput>;
  recentlyViewedContent?: InputMaybe<RecentlyViewedContentUpdateManyWithoutUserNestedInput>;
  referencedInActivityComments?: InputMaybe<ActivityCommentUpdateManyWithoutReferencedUsersNestedInput>;
  referencedUsers?: InputMaybe<NewsWrapContentUpdateManyWithoutReferencedUsersNestedInput>;
  role?: InputMaybe<RoleUpdateOneRequiredWithoutUsersNestedInput>;
  scratchpad?: InputMaybe<ScratchpadUpdateOneWithoutUserNestedInput>;
  timezone?: InputMaybe<StringFieldUpdateOperationsInput>;
  tokens?: InputMaybe<TokenUpdateManyWithoutUserNestedInput>;
  workspace?: InputMaybe<WorkspaceUpdateOneRequiredWithoutUsersNestedInput>;
};

export type UserUpdateWithoutReferencedUsersInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutUsersNestedInput>;
  activityComments?: InputMaybe<ActivityCommentUpdateManyWithoutUserNestedInput>;
  bio?: InputMaybe<StringFieldUpdateOperationsInput>;
  clientComments?: InputMaybe<ClientCommentUpdateManyWithoutUserNestedInput>;
  clientEmployer?: InputMaybe<ClientUpdateOneWithoutEmployeesNestedInput>;
  content?: InputMaybe<ContentUpdateManyWithoutCreatedByNestedInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutCreatedByNestedInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileMetadataUpdateManyWithoutUploadedByNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  isArchived?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastOnlineAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  managedClients?: InputMaybe<ClientUpdateManyWithoutAgencyManagersNestedInput>;
  meetingNotes?: InputMaybe<MeetingNotesUpdateManyWithoutCreatedByNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  newsJackItems?: InputMaybe<NewsJackItemUpdateManyWithoutStatusChangedByNestedInput>;
  newsJackRevisions?: InputMaybe<NewsJackRevisionUpdateManyWithoutCreatedByNestedInput>;
  newsWrapContent?: InputMaybe<NewsWrapContentUpdateManyWithoutCreatedByNestedInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserNestedInput>;
  recentlyViewedContent?: InputMaybe<RecentlyViewedContentUpdateManyWithoutUserNestedInput>;
  referencedInActivityComments?: InputMaybe<ActivityCommentUpdateManyWithoutReferencedUsersNestedInput>;
  referencedInClientComments?: InputMaybe<ClientCommentUpdateManyWithoutReferencedUsersNestedInput>;
  role?: InputMaybe<RoleUpdateOneRequiredWithoutUsersNestedInput>;
  scratchpad?: InputMaybe<ScratchpadUpdateOneWithoutUserNestedInput>;
  timezone?: InputMaybe<StringFieldUpdateOperationsInput>;
  tokens?: InputMaybe<TokenUpdateManyWithoutUserNestedInput>;
  workspace?: InputMaybe<WorkspaceUpdateOneRequiredWithoutUsersNestedInput>;
};

export type UserUpdateWithoutRoleInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutUsersNestedInput>;
  activityComments?: InputMaybe<ActivityCommentUpdateManyWithoutUserNestedInput>;
  bio?: InputMaybe<StringFieldUpdateOperationsInput>;
  clientComments?: InputMaybe<ClientCommentUpdateManyWithoutUserNestedInput>;
  clientEmployer?: InputMaybe<ClientUpdateOneWithoutEmployeesNestedInput>;
  content?: InputMaybe<ContentUpdateManyWithoutCreatedByNestedInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutCreatedByNestedInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileMetadataUpdateManyWithoutUploadedByNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  isArchived?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastOnlineAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  managedClients?: InputMaybe<ClientUpdateManyWithoutAgencyManagersNestedInput>;
  meetingNotes?: InputMaybe<MeetingNotesUpdateManyWithoutCreatedByNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  newsJackItems?: InputMaybe<NewsJackItemUpdateManyWithoutStatusChangedByNestedInput>;
  newsJackRevisions?: InputMaybe<NewsJackRevisionUpdateManyWithoutCreatedByNestedInput>;
  newsWrapContent?: InputMaybe<NewsWrapContentUpdateManyWithoutCreatedByNestedInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserNestedInput>;
  recentlyViewedContent?: InputMaybe<RecentlyViewedContentUpdateManyWithoutUserNestedInput>;
  referencedInActivityComments?: InputMaybe<ActivityCommentUpdateManyWithoutReferencedUsersNestedInput>;
  referencedInClientComments?: InputMaybe<ClientCommentUpdateManyWithoutReferencedUsersNestedInput>;
  referencedUsers?: InputMaybe<NewsWrapContentUpdateManyWithoutReferencedUsersNestedInput>;
  scratchpad?: InputMaybe<ScratchpadUpdateOneWithoutUserNestedInput>;
  timezone?: InputMaybe<StringFieldUpdateOperationsInput>;
  tokens?: InputMaybe<TokenUpdateManyWithoutUserNestedInput>;
  workspace?: InputMaybe<WorkspaceUpdateOneRequiredWithoutUsersNestedInput>;
};

export type UserUpdateWithoutWorkspaceInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutUsersNestedInput>;
  activityComments?: InputMaybe<ActivityCommentUpdateManyWithoutUserNestedInput>;
  bio?: InputMaybe<StringFieldUpdateOperationsInput>;
  clientComments?: InputMaybe<ClientCommentUpdateManyWithoutUserNestedInput>;
  clientEmployer?: InputMaybe<ClientUpdateOneWithoutEmployeesNestedInput>;
  content?: InputMaybe<ContentUpdateManyWithoutCreatedByNestedInput>;
  coveragePieces?: InputMaybe<CoveragePieceUpdateManyWithoutCreatedByNestedInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileMetadataUpdateManyWithoutUploadedByNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imageUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  isArchived?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastOnlineAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  managedClients?: InputMaybe<ClientUpdateManyWithoutAgencyManagersNestedInput>;
  meetingNotes?: InputMaybe<MeetingNotesUpdateManyWithoutCreatedByNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  newsJackItems?: InputMaybe<NewsJackItemUpdateManyWithoutStatusChangedByNestedInput>;
  newsJackRevisions?: InputMaybe<NewsJackRevisionUpdateManyWithoutCreatedByNestedInput>;
  newsWrapContent?: InputMaybe<NewsWrapContentUpdateManyWithoutCreatedByNestedInput>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutUserNestedInput>;
  recentlyViewedContent?: InputMaybe<RecentlyViewedContentUpdateManyWithoutUserNestedInput>;
  referencedInActivityComments?: InputMaybe<ActivityCommentUpdateManyWithoutReferencedUsersNestedInput>;
  referencedInClientComments?: InputMaybe<ClientCommentUpdateManyWithoutReferencedUsersNestedInput>;
  referencedUsers?: InputMaybe<NewsWrapContentUpdateManyWithoutReferencedUsersNestedInput>;
  role?: InputMaybe<RoleUpdateOneRequiredWithoutUsersNestedInput>;
  scratchpad?: InputMaybe<ScratchpadUpdateOneWithoutUserNestedInput>;
  timezone?: InputMaybe<StringFieldUpdateOperationsInput>;
  tokens?: InputMaybe<TokenUpdateManyWithoutUserNestedInput>;
};

export type UserUpsertWithWhereUniqueWithoutActivitiesInput = {
  create: UserCreateWithoutActivitiesInput;
  update: UserUpdateWithoutActivitiesInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutClientEmployerInput = {
  create: UserCreateWithoutClientEmployerInput;
  update: UserUpdateWithoutClientEmployerInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutManagedClientsInput = {
  create: UserCreateWithoutManagedClientsInput;
  update: UserUpdateWithoutManagedClientsInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutReferencedInActivityCommentsInput = {
  create: UserCreateWithoutReferencedInActivityCommentsInput;
  update: UserUpdateWithoutReferencedInActivityCommentsInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutReferencedInClientCommentsInput = {
  create: UserCreateWithoutReferencedInClientCommentsInput;
  update: UserUpdateWithoutReferencedInClientCommentsInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutReferencedUsersInput = {
  create: UserCreateWithoutReferencedUsersInput;
  update: UserUpdateWithoutReferencedUsersInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutRoleInput = {
  create: UserCreateWithoutRoleInput;
  update: UserUpdateWithoutRoleInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutWorkspaceInput = {
  create: UserCreateWithoutWorkspaceInput;
  update: UserUpdateWithoutWorkspaceInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithoutActivityCommentsInput = {
  create: UserCreateWithoutActivityCommentsInput;
  update: UserUpdateWithoutActivityCommentsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutClientCommentsInput = {
  create: UserCreateWithoutClientCommentsInput;
  update: UserUpdateWithoutClientCommentsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutContentInput = {
  create: UserCreateWithoutContentInput;
  update: UserUpdateWithoutContentInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutCoveragePiecesInput = {
  create: UserCreateWithoutCoveragePiecesInput;
  update: UserUpdateWithoutCoveragePiecesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutFilesInput = {
  create: UserCreateWithoutFilesInput;
  update: UserUpdateWithoutFilesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutMeetingNotesInput = {
  create: UserCreateWithoutMeetingNotesInput;
  update: UserUpdateWithoutMeetingNotesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutNewsJackItemsInput = {
  create: UserCreateWithoutNewsJackItemsInput;
  update: UserUpdateWithoutNewsJackItemsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutNewsJackRevisionsInput = {
  create: UserCreateWithoutNewsJackRevisionsInput;
  update: UserUpdateWithoutNewsJackRevisionsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutNewsWrapContentInput = {
  create: UserCreateWithoutNewsWrapContentInput;
  update: UserUpdateWithoutNewsWrapContentInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutRecentlyViewedContentInput = {
  create: UserCreateWithoutRecentlyViewedContentInput;
  update: UserUpdateWithoutRecentlyViewedContentInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  activities?: InputMaybe<ActivityListRelationFilter>;
  activityComments?: InputMaybe<ActivityCommentListRelationFilter>;
  bio?: InputMaybe<StringFilter>;
  clientComments?: InputMaybe<ClientCommentListRelationFilter>;
  clientEmployer?: InputMaybe<ClientNullableRelationFilter>;
  clientId?: InputMaybe<StringNullableFilter>;
  content?: InputMaybe<ContentListRelationFilter>;
  coveragePieces?: InputMaybe<CoveragePieceListRelationFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  email?: InputMaybe<StringFilter>;
  files?: InputMaybe<FileMetadataListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  imageUrl?: InputMaybe<StringFilter>;
  isArchived?: InputMaybe<BoolFilter>;
  lastOnlineAt?: InputMaybe<DateTimeFilter>;
  managedClients?: InputMaybe<ClientListRelationFilter>;
  meetingNotes?: InputMaybe<MeetingNotesListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  newsJackItems?: InputMaybe<NewsJackItemListRelationFilter>;
  newsJackRevisions?: InputMaybe<NewsJackRevisionListRelationFilter>;
  newsWrapContent?: InputMaybe<NewsWrapContentListRelationFilter>;
  notifications?: InputMaybe<NotificationListRelationFilter>;
  recentlyViewedContent?: InputMaybe<RecentlyViewedContentListRelationFilter>;
  referencedInActivityComments?: InputMaybe<ActivityCommentListRelationFilter>;
  referencedInClientComments?: InputMaybe<ClientCommentListRelationFilter>;
  referencedUsers?: InputMaybe<NewsWrapContentListRelationFilter>;
  role?: InputMaybe<RoleRelationFilter>;
  roleId?: InputMaybe<StringFilter>;
  scratchpad?: InputMaybe<ScratchpadNullableRelationFilter>;
  timezone?: InputMaybe<StringFilter>;
  tokens?: InputMaybe<TokenListRelationFilter>;
  workspace?: InputMaybe<WorkspaceRelationFilter>;
  workspaceId?: InputMaybe<StringFilter>;
};

export type UserWhereUniqueInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  activities?: InputMaybe<ActivityListRelationFilter>;
  activityComments?: InputMaybe<ActivityCommentListRelationFilter>;
  bio?: InputMaybe<StringFilter>;
  clientComments?: InputMaybe<ClientCommentListRelationFilter>;
  clientEmployer?: InputMaybe<ClientNullableRelationFilter>;
  clientId?: InputMaybe<StringNullableFilter>;
  content?: InputMaybe<ContentListRelationFilter>;
  coveragePieces?: InputMaybe<CoveragePieceListRelationFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  email?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<FileMetadataListRelationFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<StringFilter>;
  isArchived?: InputMaybe<BoolFilter>;
  lastOnlineAt?: InputMaybe<DateTimeFilter>;
  managedClients?: InputMaybe<ClientListRelationFilter>;
  meetingNotes?: InputMaybe<MeetingNotesListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  newsJackItems?: InputMaybe<NewsJackItemListRelationFilter>;
  newsJackRevisions?: InputMaybe<NewsJackRevisionListRelationFilter>;
  newsWrapContent?: InputMaybe<NewsWrapContentListRelationFilter>;
  notifications?: InputMaybe<NotificationListRelationFilter>;
  recentlyViewedContent?: InputMaybe<RecentlyViewedContentListRelationFilter>;
  referencedInActivityComments?: InputMaybe<ActivityCommentListRelationFilter>;
  referencedInClientComments?: InputMaybe<ClientCommentListRelationFilter>;
  referencedUsers?: InputMaybe<NewsWrapContentListRelationFilter>;
  role?: InputMaybe<RoleRelationFilter>;
  roleId?: InputMaybe<StringFilter>;
  scratchpad?: InputMaybe<ScratchpadNullableRelationFilter>;
  timezone?: InputMaybe<StringFilter>;
  tokens?: InputMaybe<TokenListRelationFilter>;
  workspace?: InputMaybe<WorkspaceRelationFilter>;
  workspaceId?: InputMaybe<StringFilter>;
};

export type Workspace = {
  __typename?: 'Workspace';
  _count?: Maybe<WorkspaceCount>;
  createdAt: Scalars['DateTime']['output'];
  hasPaidSubscription: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  loginTokenExpiryHours: Scalars['Int']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  newswrapEmail?: Maybe<Scalars['String']['output']>;
  permissions: Array<WorkspacePermissions>;
  signupTokenExpiryHours: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  useAutomaticNewsWrapGeneration: Scalars['Boolean']['output'];
};

export type WorkspaceCount = {
  __typename?: 'WorkspaceCount';
  clients: Scalars['Int']['output'];
  newsWraps: Scalars['Int']['output'];
  roles: Scalars['Int']['output'];
  users: Scalars['Int']['output'];
};


export type WorkspaceCountClientsArgs = {
  where?: InputMaybe<ClientWhereInput>;
};


export type WorkspaceCountNewsWrapsArgs = {
  where?: InputMaybe<NewsWrapWhereInput>;
};


export type WorkspaceCountRolesArgs = {
  where?: InputMaybe<RoleWhereInput>;
};


export type WorkspaceCountUsersArgs = {
  where?: InputMaybe<UserWhereInput>;
};

export type WorkspaceCreateNestedOneWithoutNewsWrapsInput = {
  connect?: InputMaybe<WorkspaceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<WorkspaceCreateOrConnectWithoutNewsWrapsInput>;
  create?: InputMaybe<WorkspaceCreateWithoutNewsWrapsInput>;
};

export type WorkspaceCreateNestedOneWithoutRolesInput = {
  connect?: InputMaybe<WorkspaceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<WorkspaceCreateOrConnectWithoutRolesInput>;
  create?: InputMaybe<WorkspaceCreateWithoutRolesInput>;
};

export type WorkspaceCreateNestedOneWithoutUsersInput = {
  connect?: InputMaybe<WorkspaceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<WorkspaceCreateOrConnectWithoutUsersInput>;
  create?: InputMaybe<WorkspaceCreateWithoutUsersInput>;
};

export type WorkspaceCreateOrConnectWithoutNewsWrapsInput = {
  create: WorkspaceCreateWithoutNewsWrapsInput;
  where: WorkspaceWhereUniqueInput;
};

export type WorkspaceCreateOrConnectWithoutRolesInput = {
  create: WorkspaceCreateWithoutRolesInput;
  where: WorkspaceWhereUniqueInput;
};

export type WorkspaceCreateOrConnectWithoutUsersInput = {
  create: WorkspaceCreateWithoutUsersInput;
  where: WorkspaceWhereUniqueInput;
};

export type WorkspaceCreateWithoutNewsWrapsInput = {
  clients?: InputMaybe<ClientCreateNestedManyWithoutWorkspaceInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  hasPaidSubscription?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  loginTokenExpiryHours?: InputMaybe<Scalars['Int']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  newswrapEmail?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<WorkspaceCreatepermissionsInput>;
  roles?: InputMaybe<RoleCreateNestedManyWithoutWorkspaceInput>;
  signupTokenExpiryHours?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  useAutomaticNewsWrapGeneration?: InputMaybe<Scalars['Boolean']['input']>;
  users?: InputMaybe<UserCreateNestedManyWithoutWorkspaceInput>;
};

export type WorkspaceCreateWithoutRolesInput = {
  clients?: InputMaybe<ClientCreateNestedManyWithoutWorkspaceInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  hasPaidSubscription?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  loginTokenExpiryHours?: InputMaybe<Scalars['Int']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  newsWraps?: InputMaybe<NewsWrapCreateNestedManyWithoutWorkspaceInput>;
  newswrapEmail?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<WorkspaceCreatepermissionsInput>;
  signupTokenExpiryHours?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  useAutomaticNewsWrapGeneration?: InputMaybe<Scalars['Boolean']['input']>;
  users?: InputMaybe<UserCreateNestedManyWithoutWorkspaceInput>;
};

export type WorkspaceCreateWithoutUsersInput = {
  clients?: InputMaybe<ClientCreateNestedManyWithoutWorkspaceInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  hasPaidSubscription?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  loginTokenExpiryHours?: InputMaybe<Scalars['Int']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  newsWraps?: InputMaybe<NewsWrapCreateNestedManyWithoutWorkspaceInput>;
  newswrapEmail?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<WorkspaceCreatepermissionsInput>;
  roles?: InputMaybe<RoleCreateNestedManyWithoutWorkspaceInput>;
  signupTokenExpiryHours?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  useAutomaticNewsWrapGeneration?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WorkspaceCreatepermissionsInput = {
  set: Array<WorkspacePermissions>;
};

export enum WorkspaceOrderByRelevanceFieldEnum {
  Id = 'id',
  Logo = 'logo',
  Name = 'name',
  NewswrapEmail = 'newswrapEmail'
}

export type WorkspaceOrderByRelevanceInput = {
  fields: Array<WorkspaceOrderByRelevanceFieldEnum>;
  search: Scalars['String']['input'];
  sort: SortOrder;
};

export type WorkspaceOrderByWithRelationAndSearchRelevanceInput = {
  _relevance?: InputMaybe<WorkspaceOrderByRelevanceInput>;
  clients?: InputMaybe<ClientOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  hasPaidSubscription?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  loginTokenExpiryHours?: InputMaybe<SortOrder>;
  logo?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  newsWraps?: InputMaybe<NewsWrapOrderByRelationAggregateInput>;
  newswrapEmail?: InputMaybe<SortOrderInput>;
  permissions?: InputMaybe<SortOrder>;
  roles?: InputMaybe<RoleOrderByRelationAggregateInput>;
  signupTokenExpiryHours?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  useAutomaticNewsWrapGeneration?: InputMaybe<SortOrder>;
  users?: InputMaybe<UserOrderByRelationAggregateInput>;
};

export enum WorkspacePermissions {
  CanEditPublications = 'CAN_EDIT_PUBLICATIONS'
}

export type WorkspaceRelationFilter = {
  is?: InputMaybe<WorkspaceWhereInput>;
  isNot?: InputMaybe<WorkspaceWhereInput>;
};

export type WorkspaceUpdateInput = {
  clients?: InputMaybe<ClientUpdateManyWithoutWorkspaceNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  hasPaidSubscription?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  loginTokenExpiryHours?: InputMaybe<IntFieldUpdateOperationsInput>;
  logo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  newsWraps?: InputMaybe<NewsWrapUpdateManyWithoutWorkspaceNestedInput>;
  newswrapEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  permissions?: InputMaybe<WorkspaceUpdatepermissionsInput>;
  roles?: InputMaybe<RoleUpdateManyWithoutWorkspaceNestedInput>;
  signupTokenExpiryHours?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  useAutomaticNewsWrapGeneration?: InputMaybe<BoolFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutWorkspaceNestedInput>;
};

export type WorkspaceUpdateOneRequiredWithoutNewsWrapsNestedInput = {
  connect?: InputMaybe<WorkspaceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<WorkspaceCreateOrConnectWithoutNewsWrapsInput>;
  create?: InputMaybe<WorkspaceCreateWithoutNewsWrapsInput>;
  update?: InputMaybe<WorkspaceUpdateToOneWithWhereWithoutNewsWrapsInput>;
  upsert?: InputMaybe<WorkspaceUpsertWithoutNewsWrapsInput>;
};

export type WorkspaceUpdateOneRequiredWithoutRolesNestedInput = {
  connect?: InputMaybe<WorkspaceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<WorkspaceCreateOrConnectWithoutRolesInput>;
  create?: InputMaybe<WorkspaceCreateWithoutRolesInput>;
  update?: InputMaybe<WorkspaceUpdateToOneWithWhereWithoutRolesInput>;
  upsert?: InputMaybe<WorkspaceUpsertWithoutRolesInput>;
};

export type WorkspaceUpdateOneRequiredWithoutUsersNestedInput = {
  connect?: InputMaybe<WorkspaceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<WorkspaceCreateOrConnectWithoutUsersInput>;
  create?: InputMaybe<WorkspaceCreateWithoutUsersInput>;
  update?: InputMaybe<WorkspaceUpdateToOneWithWhereWithoutUsersInput>;
  upsert?: InputMaybe<WorkspaceUpsertWithoutUsersInput>;
};

export type WorkspaceUpdateToOneWithWhereWithoutNewsWrapsInput = {
  data: WorkspaceUpdateWithoutNewsWrapsInput;
  where?: InputMaybe<WorkspaceWhereInput>;
};

export type WorkspaceUpdateToOneWithWhereWithoutRolesInput = {
  data: WorkspaceUpdateWithoutRolesInput;
  where?: InputMaybe<WorkspaceWhereInput>;
};

export type WorkspaceUpdateToOneWithWhereWithoutUsersInput = {
  data: WorkspaceUpdateWithoutUsersInput;
  where?: InputMaybe<WorkspaceWhereInput>;
};

export type WorkspaceUpdateWithoutNewsWrapsInput = {
  clients?: InputMaybe<ClientUpdateManyWithoutWorkspaceNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  hasPaidSubscription?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  loginTokenExpiryHours?: InputMaybe<IntFieldUpdateOperationsInput>;
  logo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  newswrapEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  permissions?: InputMaybe<WorkspaceUpdatepermissionsInput>;
  roles?: InputMaybe<RoleUpdateManyWithoutWorkspaceNestedInput>;
  signupTokenExpiryHours?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  useAutomaticNewsWrapGeneration?: InputMaybe<BoolFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutWorkspaceNestedInput>;
};

export type WorkspaceUpdateWithoutRolesInput = {
  clients?: InputMaybe<ClientUpdateManyWithoutWorkspaceNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  hasPaidSubscription?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  loginTokenExpiryHours?: InputMaybe<IntFieldUpdateOperationsInput>;
  logo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  newsWraps?: InputMaybe<NewsWrapUpdateManyWithoutWorkspaceNestedInput>;
  newswrapEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  permissions?: InputMaybe<WorkspaceUpdatepermissionsInput>;
  signupTokenExpiryHours?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  useAutomaticNewsWrapGeneration?: InputMaybe<BoolFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutWorkspaceNestedInput>;
};

export type WorkspaceUpdateWithoutUsersInput = {
  clients?: InputMaybe<ClientUpdateManyWithoutWorkspaceNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  hasPaidSubscription?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  loginTokenExpiryHours?: InputMaybe<IntFieldUpdateOperationsInput>;
  logo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  newsWraps?: InputMaybe<NewsWrapUpdateManyWithoutWorkspaceNestedInput>;
  newswrapEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  permissions?: InputMaybe<WorkspaceUpdatepermissionsInput>;
  roles?: InputMaybe<RoleUpdateManyWithoutWorkspaceNestedInput>;
  signupTokenExpiryHours?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  useAutomaticNewsWrapGeneration?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type WorkspaceUpdatepermissionsInput = {
  push?: InputMaybe<Array<WorkspacePermissions>>;
  set?: InputMaybe<Array<WorkspacePermissions>>;
};

export type WorkspaceUpsertWithoutNewsWrapsInput = {
  create: WorkspaceCreateWithoutNewsWrapsInput;
  update: WorkspaceUpdateWithoutNewsWrapsInput;
  where?: InputMaybe<WorkspaceWhereInput>;
};

export type WorkspaceUpsertWithoutRolesInput = {
  create: WorkspaceCreateWithoutRolesInput;
  update: WorkspaceUpdateWithoutRolesInput;
  where?: InputMaybe<WorkspaceWhereInput>;
};

export type WorkspaceUpsertWithoutUsersInput = {
  create: WorkspaceCreateWithoutUsersInput;
  update: WorkspaceUpdateWithoutUsersInput;
  where?: InputMaybe<WorkspaceWhereInput>;
};

export type WorkspaceWhereInput = {
  AND?: InputMaybe<Array<WorkspaceWhereInput>>;
  NOT?: InputMaybe<Array<WorkspaceWhereInput>>;
  OR?: InputMaybe<Array<WorkspaceWhereInput>>;
  clients?: InputMaybe<ClientListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  hasPaidSubscription?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  loginTokenExpiryHours?: InputMaybe<IntFilter>;
  logo?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  newsWraps?: InputMaybe<NewsWrapListRelationFilter>;
  newswrapEmail?: InputMaybe<StringNullableFilter>;
  permissions?: InputMaybe<EnumWorkspacePermissionsNullableListFilter>;
  roles?: InputMaybe<RoleListRelationFilter>;
  signupTokenExpiryHours?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  useAutomaticNewsWrapGeneration?: InputMaybe<BoolFilter>;
  users?: InputMaybe<UserListRelationFilter>;
};

export type WorkspaceWhereUniqueInput = {
  AND?: InputMaybe<Array<WorkspaceWhereInput>>;
  NOT?: InputMaybe<Array<WorkspaceWhereInput>>;
  OR?: InputMaybe<Array<WorkspaceWhereInput>>;
  clients?: InputMaybe<ClientListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  hasPaidSubscription?: InputMaybe<BoolFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  loginTokenExpiryHours?: InputMaybe<IntFilter>;
  logo?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  newsWraps?: InputMaybe<NewsWrapListRelationFilter>;
  newswrapEmail?: InputMaybe<StringNullableFilter>;
  permissions?: InputMaybe<EnumWorkspacePermissionsNullableListFilter>;
  roles?: InputMaybe<RoleListRelationFilter>;
  signupTokenExpiryHours?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  useAutomaticNewsWrapGeneration?: InputMaybe<BoolFilter>;
  users?: InputMaybe<UserListRelationFilter>;
};
